import React from 'react';
import Button from '~app/components/Button';
import EventsModal from './EventsModal';
import { usePostRecord } from '~/hooks/usePostRecord';
import SuccessModal from '~/components/SuccessModal';
import { API_URLS, QUERY_KEYS } from '../../../api/config';
import { useLocaleContext } from '~app/Integrations/Localization';
import messages from '../../../messages';
import { FormattedMessage } from '~app/Integrations/Localization';
const EventsModalAction = ({ actionType }: { actionType: string }) => {
  const {
    onConfirmAddRecord,
    onAddRecord,
    recordForAdd,
    onCancel,
    showSuccessModal,
    onSuccessClose,
  } = usePostRecord({
    QUERY_KEYS: QUERY_KEYS.FETCH_EVENTS,
    API_URL: API_URLS.ADD_NEW_EVENT,
  });
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  return (
    <>
      <Button onClick={() => onAddRecord(actionType)} variant='contained'>
        <FormattedMessage {...messages.addNewEvent} />
      </Button>
      {recordForAdd && (
        <EventsModal
          onConfirmAddRecord={onConfirmAddRecord}
          onAddRecord={onAddRecord}
          onCancel={onCancel}
        />
      )}
      {showSuccessModal && (
        <SuccessModal
          open={showSuccessModal}
          message={formatMessage(messages.eventSuccessMessage)}
          onClose={onSuccessClose}
        />
      )}
    </>
  );
};

export default EventsModalAction;
