export const API_URLS = {
  CHANGE_PASSWORD: '/change-password/admin',
  BANK_DOCUMENT_UPDATE: (id: number | string) => `/user-documents/${id}`,
  BANK_DOCUMENT_UPLOAD: (id: number | string) => `users/${id}/upload`,
  APPROVE_REJECT_KYC: (id: string | number, status: string) =>
    `user-documents/${id}/${status}`,
  GET_DOC_TYPES_LIST: '/kyc-required-documents',

  SET_EXPIRY_DATE: (id: string | number) =>
    `user-documents/${id}/set-expire-date`,
};
