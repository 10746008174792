import React, { FC, useState } from 'react';
import {
  Popover,
  Button,
  Box,
  Autocomplete,
  TextField,
  Link,
  Typography,
} from '@mui/material';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import messages from '~/modules/Reports/messages';
import { FormattedMessage } from '~app/Integrations/Localization';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';

interface PopoverComponentProps {
  buttonLabel?: React.ReactNode;
  options: Array<{ label: string; value: string }>;
  data?: any;
  isLoading?: boolean;
  handleFormatChange?: (value: string | null) => void;
  selectedFormat?: any;
}

const DownloadReport: FC<PopoverComponentProps> = ({
  buttonLabel = <VerticalAlignBottomIcon />,
  options,
  data,
  isLoading,
  handleFormatChange,
  selectedFormat,
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverAnchorElement, setPopoverAnchorElement] =
    useState<HTMLButtonElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorElement(event.currentTarget);
    setIsPopoverOpen(true);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(false);
    setPopoverAnchorElement(null);
  };

  return (
    <>
      <Button variant='contained' onClick={handlePopoverOpen}>
        {buttonLabel}
      </Button>
      <Popover
        open={isPopoverOpen}
        anchorEl={popoverAnchorElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box width={'250px'} p={2}>
          <Typography>
            <FormattedMessage {...messages.downloadReport} />
          </Typography>
          <Autocomplete
            size='small'
            disabled={isLoading}
            sx={{ width: { xs: '100%', md: '100%' }, mt: 1 }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={formatMessage(messages.format)}
                label={formatMessage(messages.type)}
                name={formatMessage(messages.format)}
              />
            )}
            getOptionLabel={(option) => option?.label ?? ''}
            options={options}
            value={selectedFormat}
            onChange={(event, value) => handleFormatChange(value)}
          />
          <Button
            disabled={isLoading}
            sx={{ mt: 2 }}
            size='small'
            variant='contained'
          >
            <Link
              href={`data:${data?.data.content_type};base64,${data?.data.content}`}
              download={data?.data.file_name}
              sx={{ color: 'inherit', textDecoration: 'none' }}
            >
              {isLoading ? (
                <FormattedMessage {...messages.loading} />
              ) : (
                <FormattedMessage {...messages.download} />
              )}
            </Link>
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default DownloadReport;
