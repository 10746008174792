import * as yup from 'yup';
import { tradingAccountMessages } from '../../messages';
export const MUTATION_KEYS = {
  APPROVE_REJECT_TRADING: 'approveRejectTradingAccount',

  // GX_INJECT_MUTATION_KEY
} as const;

export const getApproveRejectSchema = (formatMessage: Function) =>
  yup.object().shape({
    remark: yup
      .string()
      .required(formatMessage(tradingAccountMessages.remarkRequired))
      .matches(
        /^\S[a-zA-Z0-9\s]+$/,
        formatMessage(tradingAccountMessages.remarkValidation),
      )
      .min(4, formatMessage(tradingAccountMessages.remarkMinLength))
      .max(100, formatMessage(tradingAccountMessages.remarkMaxLength)),
  });
