import * as yup from 'yup';
import messages from '../../messages';
export const MUTATION_KEYS = {
  APPROVE_REJECT_TRADING: 'approveRejectTradingAccount',

  // GX_INJECT_MUTATION_KEY
} as const;

export const getApproveRejectSchema = (formatMessage: Function) =>
  yup.object().shape({
    remark: yup
      .string()
      .required(formatMessage(messages.remarkRequired))
      .matches(/^\S[a-zA-Z0-9\s]+$/, formatMessage(messages.remarkValidation))
      .min(4, formatMessage(messages.remarkMinLength))
      .max(100, formatMessage(messages.remarkMaxLength)),
  });
