import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  eventSuccessMessage: {
    id: 'emailing.successMessage',
    defaultMessage: 'Event Added Successfully',
  },
  addNewEvent: {
    id: 'emailing.addNewEvent',
    defaultMessage: 'Add New Event',
  },
  addNewTemplate: {
    id: 'emailing.addNewTemplate',
    defaultMessage: 'Add New Template',
  },
  save: {
    id: 'emailing.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'emailing.cancel',
    defaultMessage: 'Cancel',
  },
  evenModalSubTitle: {
    id: 'emailing.evenModalSubTitle',
    defaultMessage: 'Fill in the below detail to add new event',
  },
  eventName: {
    id: 'emailing.eventName',
    defaultMessage: 'Event Name',
  },
  eventNameRequired: {
    id: 'emailing.eventNameRequired',
    defaultMessage: 'Event Name is required',
  },
});
