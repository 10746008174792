/* eslint-disable complexity */
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Button,
  Box,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
import { RenderDocumentContent } from '~/components/ShowDoc';

const ViewDocModal = ({
  account,
  toggle,
  setValue,
  value,
}: {
  account: any;
  toggle: () => void;
  setValue: (boolean: boolean) => void;
  value: boolean;
}) => {
  const isMobile = useIsMobile();
  const { latest_version: documentData = {} } = account?.user_document || {};
  const {
    original_doc_location: documentUrl = '',
    content_type: contentType = '',
    extension = '',
  } = documentData as any;

  return (
    <Dialog
      open={value}
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: isMobile ? '100vw' : '800px',
          margin: isMobile ? 1 : 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: blue[50],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>
          <FormattedMessage {...messages.bank_account} />
        </Typography>

        <CloseButton onClose={() => setValue(false)} />
      </DialogTitle>
      <DialogContent sx={{ marginTop: '16px' }}>
        <DialogContentText
          sx={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '5px' }}
        >
          <Typography>
            <FormattedMessage {...messages.account_details} />
          </Typography>
        </DialogContentText>

        <Box
          display='flex'
          alignItems='center'
          justifyContent={'center'}
          gap={isMobile ? 0 : 4}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                label={<FormattedMessage {...messages.bank_name} />}
                disabled
                value={(account && account?.bank_name) || ''}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name={'bank_account_holder_name'}
                variant='outlined'
                label={
                  <FormattedMessage {...messages.bank_account_holder_name} />
                }
                value={account?.bank_account_name || ''}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_account_number} />}
                name='bank_account_number'
                disabled
                value={(account && account?.bank_account_number) || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                value={(account && account?.bank_code) || ''}
                label={<FormattedMessage {...messages.bank_ifsc} />}
                name='bank_ifsc'
                disabled
              />
            </Grid>
            {account?.bank_iban && account?.bank_iban !== '' && (
              <Grid item xs={12}>
                <TextField
                  name={'bank_iban'}
                  variant='outlined'
                  label={<FormattedMessage {...messages.bank_iban} />}
                  value={account?.bank_iban}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name={'bank_address'}
                variant='outlined'
                label={<FormattedMessage {...messages.bank_address} />}
                value={account?.bank_address}
                fullWidth
                size='small'
                disabled
              />
            </Grid>
          </Grid>
          <Box>
            <Typography>
              {account?.user_document?.latest_version?.filename}
            </Typography>
            <Grid
              item
              xs={12}
              display='flex'
              alignItems='center'
              justifyContent='center'
            >
              <RenderDocumentContent
                contentType={contentType}
                extension={extension}
                documentUrl={documentUrl}
              />
            </Grid>
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} mt={'24px'}>
          <Button
            onClick={() => setValue(false)}
            sx={{
              width: '100px',
            }}
            variant='outlined'
          >
            <FormattedMessage {...messages.close} />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ViewDocModal;
