import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  withdrawal: {
    id: 'withdrawal',
    defaultMessage: 'Withdrawal',
  },
  approveModalTitle: {
    id: 'withdrawal.approveModalTitle',
    defaultMessage: 'Approve Withdrawal?',
  },
  rejectModalTitle: {
    id: 'withdrawal.rejectModalTitle',
    defaultMessage: 'Reject Withdrawal?',
  },
  approveModalHelpText: {
    id: 'withdrawal.approveModalHelpText',
    defaultMessage: 'Please provide reasoning for approving the withdrawal.',
  },
  rejectModalHelpText: {
    id: 'withdrawal.rejectModalHelpText',
    defaultMessage: 'Please provide reasoning for rejecting the withdrawal.',
  },
  approved: {
    id: 'withdrawal.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'withdrawal.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'withdrawal.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'withdrawal.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'withdrawal.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'withdrawal.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'withdrawal.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'withdrawal.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  approveSuccessMessage: {
    id: 'withdrawal.approveSuccessMessage',
    defaultMessage: 'Withdrawal approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'withdrawal.rejectSuccessMessage',
    defaultMessage: 'Withdrawal rejected successfully!',
  },
  approveFailedMessage: {
    id: 'withdrawal.approveFailedMessage',
    defaultMessage: 'Withdrawal approval failed!',
  },
  rejectFailedMessage: {
    id: 'withdrawal.rejectFailedMessage',
    defaultMessage: 'Withdrawal rejection failed!',
  },
  paymentMethodRequired: {
    id: 'withdrawal.paymentMethodRequired',
    defaultMessage: 'Payment method is required',
  },
  choosePaymentMethod: {
    id: 'withdrawal.choosePaymentMethod',
    defaultMessage: 'Choose payment method',
  },
  localAmount: {
    id: 'withdrawal.localAmount',
    defaultMessage: 'Local Amount',
  },
  accountType: {
    id: 'withdrawal.accountType',
    defaultMessage: 'From',
  },

  remarkValidation: {
    id: 'withdrawal.remarkValidation',
    defaultMessage: 'Remark should contain only letters and numbers',
  },
  remarkMinLength: {
    id: 'withdrawal.remarkMinLength',
    defaultMessage: 'Remark should be at least 4 characters',
  },
  remarkMaxLength: {
    id: 'withdrawal.remarkMaxLength',
    defaultMessage: 'Remark should be at most 100 characters',
  },
  local_amount_validation: {
    id: 'withdrawal.local_amount_validation',
    defaultMessage: 'Local amount should be at least 10 USD',
  },
  local_amount_required: {
    id: 'withdrawal.local_amount_required',
    defaultMessage: 'Local amount is required',
  },
  local_currency: {
    id: 'withdrawal.local_currency',
    defaultMessage: 'Local Currency',
  },
  payment_method: {
    id: 'withdrawal.payment_method',
    defaultMessage: 'Payment Method',
  },
  tradingAmount: {
    id: 'withdrawal.tradingAmount',
    defaultMessage: 'Trading Amount',
  },
  tradingCurrency: {
    id: 'withdrawal.tradingCurrency',
    defaultMessage: 'Trading Currency',
  },
  bank_account_holder_name: {
    id: 'bankAccounts.bank_account_holder_name',
    defaultMessage: 'Account Holder Name',
  },
  bank_name: {
    id: 'bankAccounts.bank_name',
    defaultMessage: 'Bank Name',
  },
  bank_ifsc: {
    id: 'bankAccounts.bank_ifsc',
    defaultMessage: 'Bank IFSC ',
  },
  bank_iban: {
    id: 'bankAccounts.bank_iban',
    defaultMessage: 'Bank IBAN',
  },
  bankName: {
    id: 'bankAccounts.bankName',
    defaultMessage: 'Bank Name',
  },
  accountName: {
    id: 'bankAccounts.accountName',
    defaultMessage: 'Account Name',
  },
  accountNumber: {
    id: 'bankAccounts.accountNumber',
    defaultMessage: 'Account Number',
  },
  bankIban: {
    id: 'bankAccounts.bankIban',
    defaultMessage: 'Bank IBAN',
  },
  bankIFSC: {
    id: 'bankAccounts.bankIFSC',
    defaultMessage: 'Bank IFSC',
  },
  bankAccountNumber: {
    id: 'bankAccounts.bankAccountNumber',
    defaultMessage: 'Account Number',
  },
  notes: {
    id: 'bankAccounts.notes',
    defaultMessage: 'Note',
  },

  amountRequired: {
    id: 'deposit.amountRequired',
    defaultMessage: 'Amount is required',
  },
  tradingAmountValidation: {
    id: 'deposit.tradingAmountValidation',
    defaultMessage: 'Trading amount should be at least 10 USD',
  },

  view_Withdrawal: {
    id: 'withdrawal.view_Withdrawal',
    defaultMessage: 'Withdrawal Details',
  },
  from_account: {
    id: 'withdrawal.from_account',
    defaultMessage: 'From',
  },
  payment_details: {
    id: 'withdrawal.payment_details',
    defaultMessage: 'Payment Details',
  },
  bank_details: {
    id: 'withdrawal.bank_details',
    defaultMessage: 'Bank Details',
  },
});
