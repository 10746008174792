import React from 'react';
import { Box, Button } from '@mui/material';
import SkeletonWrapper from './SkeletonWrapper';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import SuccessModal from '~/components/SuccessModal';
const ApproveRejectBtn = ({
  account,
  onApproveRecord,
  onRejectRecord,
  isLoading,
}: // isEdit,
// handleSubmit,
// showSuccessModal,
// setShowSuccessModal,
{
  account: any;
  onApproveRecord?: Function;
  onRejectRecord?: Function;
  isLoading: boolean;
  // isEdit: boolean;
  // handleSubmit: Function;
  // showSuccessModal: boolean;
  // setShowSuccessModal: Function;
}) => (
  <Box mt={'32px'} display={'flex'} gap={1}>
    {/* <Button
      onClick={() => {
        handleSubmit();
      }}
      variant='contained'
    >
      Update
    </Button> */}

    {account?.status !== 'APPROVED' && account?.status !== 'REJECTED' && (
      <SkeletonWrapper isLoading={isLoading}>
        <Button
          variant='contained'
          sx={{
            marginRight: '10px',
          }}
          onClick={() => onApproveRecord?.(account)}
        >
          <FormattedMessage {...messages.approve} />
        </Button>
        <Button
          onClick={() => onRejectRecord?.(account)}
          color={'secondary'}
          variant='outlined'
        >
          <FormattedMessage {...messages.reject} />
        </Button>
      </SkeletonWrapper>
    )}
    {/* <SuccessModal
      open={showSuccessModal}
      onClose={() => {
        setShowSuccessModal(false);
      }}
      message={'Bank details updated successfully'}
    /> */}
  </Box>
);

export default ApproveRejectBtn;
