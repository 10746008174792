import React from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { getTemplateTableColumns } from './TemplateTableColumn';
import Box from '~app/components/Box';
import { API_URLS, QUERY_KEYS } from '../../api/config';
const Templates = () => {
  const memoizedColumns = React.useMemo(() => getTemplateTableColumns({}), []);

  return (
    <>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_EMAIL_TEMPLATES,
            queryKeyProp: [QUERY_KEYS.FETCH_EMAIL_TEMPLATES],
            select: (data: any) => data,
          }}
          columns={memoizedColumns}
          pageCount={10}
        />
      </Box>
    </>
  );
};

export default Templates;
