/* eslint-disable max-statements */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';

import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import useToast from '../../../hooks/useToast';
import { useUserAuth } from '../../../shell/components/Auth/AuthProvider';

import { useMemo, useState } from 'react';
import { MODE, REBATE_STRUCTURE_VALUE } from '~/constants';
import {
  FIXED_REBATE_TABLE_COLUMNS,
  IB_AGREEMENT_INITIAL_VALUES,
  SPREAD_TABLE_COLUMNS,
} from '../constants';
import useMutationFactory from '../services/mutations/useMutationFactory';
import useGetIBAgreement from './useGetIbAgreement';

const useIbAgreement = () => {
  const { triggerSuccessToast, triggerErrorToast } = useToast();

  const { auth } = useUserAuth();

  const location = useLocation();

  const queryClient = useQueryClient();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const { data: agreementData, isAgreementDataPending } = useGetIBAgreement(
    location.state,
  );

  const { fetchIbAgreement } = useMutationFactory();

  const { mutate } = useMutation<any, any, any>({
    ...fetchIbAgreement(),
  });
  const [mode, setMode] = useState(MODE.ADD);

  const onSuccessResponse = (data: any) => {
    triggerSuccessToast(data?.message);
    setMode(MODE.EDIT);
  };
  const initialValues = useMemo(() => {
    if (agreementData) {
      setMode(MODE.EDIT);
      return {
        ...IB_AGREEMENT_INITIAL_VALUES,
        tier: (agreementData as any)?.data?.tier,
        rebate_structure: (agreementData as any)?.data?.rebate_structure,
      };
    } else {
      return IB_AGREEMENT_INITIAL_VALUES;
    }
  }, [agreementData]);
  const formik = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      const payload = {
        rebate_structure: values?.rebate_structure?.id || '',
        tier: values?.tier?.id || '',
        user: location?.state,
      };

      mutate(payload, {
        onSuccess: (data) => {
          onSuccessResponse(data);
        },
        onError: (error) => {
          triggerErrorToast(error);
        },
      });
    },
    validationSchema: null,
    validateOnMount: true,
    enableReinitialize: true,
  });
  const columns = useMemo(
    () =>
      formik.values.rebate_structure?.id ===
      REBATE_STRUCTURE_VALUE.SPREAD_REVENUE
        ? SPREAD_TABLE_COLUMNS
        : FIXED_REBATE_TABLE_COLUMNS,
    [formik.values.rebate_structure?.id],
  );

  const renderListItem = (data: any) =>
    mode === MODE.EDIT
      ? data?.data?.rebate_structure_info
      : (data as any)?.data;
  return {
    formik,
    formatMessage,
    userId: location.state,
    mode,
    setMode,
    columns,
    agreementData,
    renderListItem,
    location,
    isAgreementDataPending,
  };
};
export default useIbAgreement;
