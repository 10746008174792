import { useState } from 'react';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import { post } from 'v2s-ui-core/app/Integrations/ApiService';
import { AddRecordActionProps, Payload } from '~/config/types';

// Custom Hook
// eslint-disable-next-line max-statements
export const usePostRecord = ({
  QUERY_KEYS,
  API_URL,
  messages,
  setShowAddBankAccountModal,
  showAddBankAccountModal,
}: AddRecordActionProps) => {
  // States
  const [recordForAdd, setRecordForAdd] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Hooks
  const { showToastNotification } = useShowToastNotifications();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const queryClient = useQueryClient();

  // Functions
  const addRecord = async <T>(payload: Payload) => {
    try {
      const response = await post<T>(API_URL, payload); // Change to POST request
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationOptions = {
    mutationFn: addRecord,
  };

  const { mutate, status } = useMutation(mutationOptions);

  const onAddRecord = (record: any) => {
    setRecordForAdd(record);
  };

  const onCancel = () => {
    setRecordForAdd(null);
  };

  const onSuccess = () => {
    setShowSuccessModal(true);
    setShowAddBankAccountModal(false);

    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS] });
    onCancel();
  };

  const onError = (error: any) => {
    showToastNotification(
      `${error?.data.errors[0]?.detail || 'Unknown error'}`,
      'error',
    );
  };

  const onConfirmAddRecord = (payload: Payload) => {
    mutate(payload, { onSuccess, onError });
  };

  const onSuccessClose = () => {
    setShowSuccessModal(false);
  };

  // Returns
  return {
    recordForAdd,
    onAddRecord,
    onCancel,
    onConfirmAddRecord,
    onSuccessClose,
    showSuccessModal,
    status,
  };
};
