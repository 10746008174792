import React, { useState } from 'react';
import Box from '~app/components/Box';
import { Button, Grid, TextField, Typography } from '@mui/material';
import AsyncDropdown from '~app/components/AsyncDropdown';
import Editor from './components/Editor';
import Checkbox from '@mui/material/Checkbox';
import { useAddTemplateForm } from './hooks/useAddTemplateForm';
import PreviewTemplate from './components/PreviewTemplate';
import SuccessModal from '~/components/SuccessModal';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from './messages';

const AddTemplate = () => {
  const [headers, setHeaders] = useState(null);
  const [footer, setFooter] = useState(null);
  const [showPreview, setShowPreview] = useState(false);

  const {
    formik: {
      values,
      setFieldValue,
      handleSubmit,
      handleChange,
      touched,
      setFieldTouched,
      errors,
    },
    showSuccessModal,
    onSuccessClose,
  } = useAddTemplateForm();

  return (
    <Box py={'24px'} px={'16px'}>
      <Typography fontSize={'16px'} fontWeight={400}>
        <FormattedMessage {...messages.TemplateHeaderText} />
      </Typography>
      <Grid container spacing={2} mt={'10px'}>
        <Grid item xs={12}>
          <TextField
            size='small'
            onChange={(e) => {
              setFieldTouched('template_name', true);
              handleChange(e);
            }}
            name='template_name'
            value={values.template_name}
            autoComplete='off'
            fullWidth
            label={<FormattedMessage {...messages.templateName} />}
            error={touched.template_name && Boolean(errors.template_name)}
            onBlur={() => {
              setFieldTouched('template_name', true);
            }}
            helperText={touched.template_name && errors.template_name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            size='small'
            autoComplete='off'
            name='subject'
            fullWidth
            label={<FormattedMessage {...messages.subject} />}
            onChange={(e) => {
              setFieldTouched('subject', true);
              handleChange(e);
            }}
            value={values.subject}
            error={touched.subject && Boolean(errors.subject)}
            helperText={touched.subject && errors.subject}
            onBlur={() => {
              setFieldTouched('subject', true);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncDropdown
            InputProps={{
              label: <FormattedMessage {...messages.event} />,
              error: touched.event && Boolean(errors.event),
              //@ts-ignore
              helperText: touched.event && errors.event,
              onBlur: () => {
                setFieldTouched('event', true);
              },
            }}
            queryProps={{
              resource: '/events',
              queryKeyProp: ['fetchEvents'],
              select: (data: any) => data.data,
            }}
            value={values.event as any}
            onChange={(e, value) =>
              handleChange({
                target: {
                  name: 'event',
                  value: value,
                },
              })
            }
            getOptionLabel={(option) => option?.name || ''}
            getOptionKey={(option) => option?.id}
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncDropdown
            InputProps={{
              label: <FormattedMessage {...messages.header} />,
            }}
            queryProps={{
              resource: '/headers',
              queryKeyProp: ['fetchHeaders'],
              select: (data: any) => data.data,
            }}
            value={headers && (headers as any)}
            onChange={(e, value) => setHeaders(value)}
            getOptionLabel={(option) => option?.name || ''}
            getOptionKey={(option) => option.id}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            setFieldTouched={setFieldTouched}
            touched={touched}
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncDropdown
            InputProps={{
              label: <FormattedMessage {...messages.footer} />,
            }}
            queryProps={{
              resource: '/footers',
              queryKeyProp: ['fetchFooters'],
              select: (data: any) => data.data,
            }}
            value={footer && (footer as any)}
            onChange={(e, value) => setFooter(value)}
            getOptionLabel={(option) => option?.name || ''}
            getOptionKey={(option) => option.id}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <FormattedMessage {...messages.isHtmlTemplate} />
          </Typography>
          <Box display='flex' ml={-2}>
            <Box display='flex' alignItems='center' mr={2}>
              <Checkbox
                checked={values.is_html_template === true}
                onChange={() => setFieldValue('is_html_template', true)}
              />
              <Typography>
                <FormattedMessage {...messages.yes} />
              </Typography>
            </Box>
            <Box display='flex' alignItems='center'>
              <Checkbox
                checked={values.is_html_template === false}
                onChange={() => setFieldValue('is_html_template', false)}
              />
              <Typography>
                <FormattedMessage {...messages.no} />
              </Typography>
            </Box>
          </Box>
        </Grid>
        {/*  */}
        <Grid item>
          <Box display='flex' gap={2}>
            <Typography
              style={{
                textDecoration: 'underline',
                color: '#08345D',
                cursor: 'pointer',
              }}
            >
              <FormattedMessage {...messages.testMail} />
            </Typography>
            <Typography
              style={{
                textDecoration: 'underline',
                color: '#08345D',
                cursor: 'pointer',
              }}
              onClick={() => setShowPreview(true)}
            >
              <FormattedMessage {...messages.Preview} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        gap={2}
        mt={2}
      >
        <Button
          variant='contained'
          onClick={() => {
            handleSubmit();
          }}
        >
          <FormattedMessage {...messages.AddTemplate} />
        </Button>
        <Button variant='outlined'>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </Box>
      {showPreview && (
        <PreviewTemplate values={values} setShowPreview={setShowPreview} />
      )}
      {/* success Modal */}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message='Template Created successfully'
      />
    </Box>
  );
};

export default AddTemplate;
