import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { REQUEST_STATUS } from '~/config/constants';
import { TUpdateFinancialDetailsPayload } from '../../services/mutations/types';
import { FINANCIAL_INFO_VALUES, getFinancialInfoSchema } from '../constants';
import useGetFinancialDetail from './useGetFinancialDetail';
import useUpdateFinancialDetails from './useUpdateFinancialDetails';
import { useLocation } from 'react-router-dom';
const useFinancialDetailsForm = ({ onTabChange }: any) => {
  const location = useLocation();

  const userId = location.state;

  const { financialInfo, isLoading } = useGetFinancialDetail(userId);
  const { updateFinancialDetails, status, isUpdateLoading } =
    useUpdateFinancialDetails();

  const [state, setState] = useState({
    showFinancialSuccessModal: false,
    disabledFinancialFormFields: true,
  });
  const { showFinancialSuccessModal, disabledFinancialFormFields } = state;

  useEffect(() => {
    if (status === REQUEST_STATUS.SUCCESS) {
      setState(() => ({
        ...state,
        showFinancialSuccessModal: true,
      }));
    }
  }, [status]);
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const handleCloseSuccessModal = () => {
    setState(() => ({
      ...state,
      showFinancialSuccessModal: false,
    }));
  };

  const initialValues = useMemo(() => {
    if (financialInfo) {
      return { ...FINANCIAL_INFO_VALUES, ...financialInfo };
    } else {
      return FINANCIAL_INFO_VALUES;
    }
  }, [financialInfo]);

  const onUpdateValues = (values: any) => {
    const updatedPayload = {
      education_level: values.education_level?.id,
      employment_status: values.employment_status?.id,
      estimated_net_worth: values.estimated_net_worth?.id,
      estimated_annual_income: values.estimated_annual_income?.id,
      estimated_investment_amount: values.estimated_investment_amount?.id,
      source_of_funds: values.source_of_funds?.id,
      source_of_wealth: values.source_of_wealth?.id,
      expected_origin_of_funds: values.expected_origin_of_funds?.id,
      trading_purpose: values.trading_purpose?.id,
    };

    const payload: TUpdateFinancialDetailsPayload = {
      values: updatedPayload,
      userId,
    };
    updateFinancialDetails(payload);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      onUpdateValues(values);
    },
    validationSchema: getFinancialInfoSchema(formatMessage),
    enableReinitialize: true,
  });

  return {
    ...formik,
    showFinancialSuccessModal,
    handleCloseSuccessModal,
    formik,
    disabledFinancialFormFields,
    isLoading,
    isUpdateLoading,
  };
};

export default useFinancialDetailsForm;
