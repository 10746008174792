import { FC, useMemo } from 'react';
import React from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { getTradersTableColumns } from './TradersTableColumn';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import Box from '~app/components/Box';
import useFilters from 'v2s-ui-core/app/hooks/useFilters';
import { FILTERS } from '~/config/constants';
import TableFilters from '~/components/TableFilters';
const TradersTable: FC<{}> = () => {
  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchTraders',
  });

  const memoizedColumns = useMemo(() => getTradersTableColumns(), []);

  return (
    <Box>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'16px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_TRADERS,
            queryKeyProp: [QUERY_KEYS.FETCH_TRADERS],
            select: (data: any) => data,
          }}
          filters={filters}
          columns={memoizedColumns as ColumnDef<unknown>[]}
        />
      </Box>
    </Box>
  );
};

export default TradersTable;
