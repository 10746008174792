/* eslint-disable arrow-body-style */
import { useMemo } from 'react';
import { getIBsTableColumns } from './IBsTableColumn';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import Box from '~app/components/Box';
import TableFilters from '~/components/TableFilters/index';
import useFilters from '~app/hooks/useFilters';
import { FILTERS } from '~/config/constants';
import { API_URLS, QUERY_KEYS } from '../../api/config';
const IBs = () => {
  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: QUERY_KEYS.FETCH_IBS,
  });

  const memoizedColumns = useMemo(() => getIBsTableColumns(), []);

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'16px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_IBS,
            queryKeyProp: [QUERY_KEYS.FETCH_IBS],
            select: (data: any) => data,
          }}
          columns={memoizedColumns}
          filters={filters}
        />
      </Box>
    </>
  );
};

export default IBs;
