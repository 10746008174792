import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ActionButtons from '~/components/Actions/ActionButtons';
import { formatDate } from '~/utils/FormateDate';
import {
  INTERNAL_TRANSFERS_STATUS,
  INTERNAL_TRANSFERS_STATUS_CONFIG,
} from '../constants';
import { internalTransfersProps } from '../types';
import TruncatedHeader from '~/components/TruncatedHeader';
import DateFilterComponent from '~/components/DateFIlter';

export const getInternalTransferColumns = (props: {
  onApproveRecord?: Function;
  onRejectRecord?: Function;
}): ColumnDef<internalTransfersProps>[] => [
  {
    accessorKey: 'id',
    header: 'Transaction ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'from_user_id',
    cell: ({ row }: any) => row.original?.from_user_id?.id,
    header: 'From User ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'to_user_id',
    cell: ({ row }: any) => row.original?.to_user_id?.id,
    header: 'To User ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'from_user_email',
    cell: ({ row }: any) => row.original?.from_user_id?.email,
    header: 'User Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'transfer_type',
    header: 'Transfer Type',
    enableColumnFilter: true,
  },
  {
    id: 'sourceAccountType',
    accessorFn: (row: internalTransfersProps) =>
      row.balance_history?.[0]?.account_type,
    enableColumnFilter: true,
    header: () => (
      <TruncatedHeader text='Source Account Type' maxLength={14} useTooltip />
    ),
  },
  {
    accessorKey: 'from_account_id',
    header: 'Source Account ID',
    enableColumnFilter: true,
  },
  {
    id: 'targetAccountType',
    accessorFn: (row: internalTransfersProps) =>
      row.balance_history?.[1]?.account_type,
    enableColumnFilter: true,
    header: () => (
      <TruncatedHeader text='Target Account Type' maxLength={14} useTooltip />
    ),
  },
  {
    accessorKey: 'to_account_id',
    header: 'Target Account ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'to_amount',
    header: 'Transfer Amount',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'to_currency',
    header: 'Currency',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'remark',
    header: 'Remarks',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'note',
    header: 'Notes',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'meta_comment',
    header: 'Meta Comments',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'ip_address',
    header: 'Initiated From (IP)',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'created_by.email',
    header: 'Created By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_by.email',
    header: 'Updated By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'status',
    header: 'Actions',
    enablePinning: true,

    cell: (cell) => {
      const { getValue, row } = cell;
      const status = getValue() as string;

      return (
        <ActionButtons
          status={status}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original as object}
          ACCOUNT_STATUS={INTERNAL_TRANSFERS_STATUS}
          ACCOUNT_STATUS_CONFIG={INTERNAL_TRANSFERS_STATUS_CONFIG}
        />
      );
    },
    enableSorting: false,
  },
];
