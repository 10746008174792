/* eslint-disable complexity */
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, styled, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import Button from 'v2s-ui-core/app/components/Button';
import useUpdateKycDocument from '~/modules/MyKyc/hooks/useUpdateKycDocument';
import { theme } from '~/shell/theme/theme';
import useDocumentUploads, {
  TUploadDocResponse,
} from '../../hooks/useDocumentUploads';
import CustomTooltip from '../CustomTooltip';
import { ShowStatus } from '../ShowStatus';
import { handleUpload, onFileUploadChange } from './utils';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

//todo: revamp this component as per ui design

const FileUploadWrapper = ({
  customNode,
  storeUploadDocKey,
  disabled,
  fileName,
  onSelectFile,
  documentMappigId,
  bulkUpload = false,
  isUpdateKyc = false,
  updateKycId,
  setIsEditSuccess,
  title,
  setUpdateLoader,
  minWidth,
  isBankForm,
  mode,
  formik,
  fieldKey,
  isBankAccountFormViewMode = false,
  bankAccountDetails,
}: {
  customNode?: React.ReactNode;
  storeUploadDocKey?: (uploadDocResponse: TUploadDocResponse) => void;
  disabled?: boolean;
  fileName: string;
  onSelectFile?: (file: File) => void;
  documentMappigId: number;
  bulkUpload?: boolean;
  isUpdateKyc?: boolean;
  updateKycId?: number;
  setIsEditSuccess?: any;
  title?: string;
  setUpdateLoader?: (isLoading: boolean) => void;
  minWidth?: any;
  isBankForm?: boolean;
  mode?: string;
  formik: any;
  fieldKey?: string;
  isBankAccountFormViewMode?: boolean;
  bankAccountDetails?: any;
}) => {
  const {
    mutate,
    uploadDocResponse,
    isPending: isUploadDocPending,
    formatMessage,
  } = useDocumentUploads({
    storeUploadDocKey,
    isUpdateKyc,
  });
  const {
    updateUserKycDocument: mutateKyc,
    status: updateKycStatus,
    // error: updateKycError,
    isPending: isPendingKycUpdate,
    showToastNotification,
  } = useUpdateKycDocument({});

  useEffect(() => {
    if (
      (updateKycStatus === 'success' && isUpdateKyc) ||
      (uploadDocResponse?.status_code === 201 && isUpdateKyc)
    ) {
      setIsEditSuccess(true);
      setUpdateLoader(false);
    }
    if (
      (isPendingKycUpdate === true || isUploadDocPending === true) &&
      isUpdateKyc
    ) {
      setUpdateLoader(true);
    } else if (
      (isPendingKycUpdate === false && isUpdateKyc) ||
      (isUploadDocPending === false && isUpdateKyc)
    ) {
      setUpdateLoader(false);
    }
  }, [
    updateKycStatus === 'success',
    isPendingKycUpdate,
    uploadDocResponse,
    isUploadDocPending,
  ]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          gap: 5,
        }}
      >
        {customNode ? (
          customNode
        ) : (
          <Button
            component='label'
            role={undefined}
            variant='outlined'
            size='small'
            tabIndex={-1}
            disabled={disabled}
            sx={{
              width: { xs: '100%', sm: 'auto' },
              height: '39px',
              p: 1,
              ml: title ? '1px' : '0px',
              minWidth: minWidth ? minWidth : 'auto',
              // width: '113px',
              borderColor:
                formik?.errors?.[fieldKey] && formik?.touched?.[fieldKey]
                  ? theme.palette.error.light
                  : '',
              backgroundColor: disabled
                ? theme.palette.grey[50]
                : theme.palette.success.light,
              color:
                formik?.errors?.[fieldKey] && formik?.touched?.[fieldKey]
                  ? theme.palette.error.light
                  : disabled
                  ? theme.palette.warning.light
                  : theme.palette.text.secondary,
              '&:hover': {
                backgroundColor: disabled
                  ? theme.palette.grey[50]
                  : theme.palette.primary.main,

                color: disabled
                  ? theme.palette.warning.light
                  : theme.palette.common.white,
              },
            }}
            startIcon={<CloudUploadIcon />}
            onBlur={() => {
              formik.setFieldTouched(fieldKey || fileName, true);
            }}
          >
            <Typography
              noWrap
              sx={{
                lineHeight: {
                  xs: isBankForm ? '15px' : '',
                },
                fontSize: '16px',
                py: '4px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {title || 'Upload'}
            </Typography>
            <VisuallyHiddenInput
              type='file'
              accept='.pdf,.jpg,.png,.heic'
              onChange={(event) => {
                const file = event.target.files?.[0];
                onFileUploadChange(
                  file,
                  event,
                  bulkUpload,
                  mode,
                  onSelectFile,
                  isUpdateKyc,
                  updateKycId,
                  documentMappigId,
                  fileName,
                  mutate,
                  formik,
                  fieldKey,
                  showToastNotification,
                  formatMessage,
                );
              }}
            />
          </Button>
        )}
        {formik?.values?.status && (
          <ShowStatus status={formik?.values?.status} height='40px' />
        )}
      </Box>
      {fieldKey &&
        formik?.errors?.[fieldKey] &&
        !formik?.values?.[fieldKey] &&
        formik.touched?.[fieldKey] && (
          <Typography variant='caption' color='error' m={'0px 14px 0px'}>
            {formik?.errors?.[fieldKey]}
          </Typography>
        )}
      {!isUpdateKyc && (
        <CustomTooltip
          title={uploadDocResponse?.data?.latest_version?.filename}
          onClick={() =>
            window.open(
              uploadDocResponse?.data?.latest_version?.original_doc_location,
              '_blank',
            )
          }
        >
          <Typography
            fontWeight={theme.typography.fontWeightRegular}
            fontSize={14}
            color={theme.palette.primary.main}
            sx={{
              display: 'inline-block',
              width: '42ch',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            {uploadDocResponse?.data?.latest_version?.filename}
          </Typography>
        </CustomTooltip>
      )}

      {isBankAccountFormViewMode && !uploadDocResponse?.data && (
        <CustomTooltip
          title={bankAccountDetails?.user_document?.latest_version?.filename}
          onClick={() => {
            window.open(
              bankAccountDetails?.user_document?.latest_version
                ?.original_doc_location,
              '_blank',
            );
          }}
        >
          <Typography
            fontWeight={theme.typography.fontWeightRegular}
            fontSize={14}
            color={theme.palette.primary.main}
            sx={{
              display: 'inline-block',
              width: '42ch',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            {bankAccountDetails?.user_document?.latest_version?.filename}
          </Typography>
        </CustomTooltip>
      )}
    </>
  );
};

export default FileUploadWrapper;
