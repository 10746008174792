import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../../services/queries/useQueryFactory';

const useGetCorporateDetail = <TData, TError = Error>(id: number) => {
  const queryDetails = useQueryFactory<TData, TError>();
  const { data, isLoading } = useQuery({
    ...queryDetails.getCorporateDetails(id),
  });

  return { corporateInfo: (data as any)?.data || {}, isLoading };
};

export default useGetCorporateDetail;
