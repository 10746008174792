import * as yup from 'yup';
import {
  getAddressLine1Schema,
  getAddressLine2Schema,
  getCityTownSchema,
  getCountrySchema,
  getDateOfBirthSchema,
  getEmailSchema,
  getFirstNameSchema,
  getGenderSchema,
  getLastNameSchema,
  getNationalitySchema,
  getPhoneNumberSchema,
  getPreferredLanguageSchema,
  getStateProvinceSchema,
  getUserTypeSchema,
  getZipPostalCodeSchema,
} from '~/schema/ProfileSchema';

export const getPersonalInfoSchema = (formatMessage: (msg: any) => string) =>
  yup.object().shape({
    preferred_language: getPreferredLanguageSchema(formatMessage),
    first_name: getFirstNameSchema(formatMessage),
    last_name: getLastNameSchema(formatMessage),
    email: getEmailSchema(formatMessage),
    address_line1: getAddressLine1Schema(formatMessage),
    address_line2: getAddressLine2Schema(formatMessage),
    city_town: getCityTownSchema(formatMessage),
    state_province: getStateProvinceSchema(formatMessage),
    zip_postal_code: getZipPostalCodeSchema(formatMessage),
    country: getCountrySchema(formatMessage),
    phone_number: getPhoneNumberSchema(formatMessage),
    date_of_birth: getDateOfBirthSchema(formatMessage),
    gender: getGenderSchema(formatMessage),
    nationality: getNationalitySchema(formatMessage),
    user_type: getUserTypeSchema(formatMessage),
  });
