import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import useToast from '~/hooks/useToast';
import { MutationDetails } from '../../services/mutations/types';
import useMutationFactory from '../../services/mutations/useMutationFactory';
import { QUERY_KEYS } from '../../services/queries/constants';

const useUpdateJointAccountDetails = <TData, TError = Error>(
  args?: ReturnType<MutationDetails<TData, TError>['updateJointDetails']>,
) => {
  const mutationDetails = useMutationFactory<TData, TError>();
  const { triggerErrorToast } = useToast();

  const queryClient = useQueryClient();

  const {
    mutate: updateJointAccountDetails,
    status,
    data,
    error,
    isPending,
  } = useMutation({
    ...mutationDetails.updateJointDetails(),
    ...args,
    onSuccess: (data: any) => {
      // triggerSuccessToast(data?.message);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_JOINT_ACCOUNT_DETAILS],
      });
    },
    onError: (error: any) => {
      triggerErrorToast(error);
    },
  });

  return {
    updateJointAccountDetails,
    status,
    data,
    error,
    isUpdateLoading: isPending,
  };
};

export default useUpdateJointAccountDetails;
