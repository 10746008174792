import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { getApproveRejectSchema } from '../services/mutations/constants';
import { ApproveRejectInternalTransferProps } from '../types';

export const useApproveRejectInternalTransfers = ({
  onConfirmApproveReject,
  recordForApproveReject,
}: {
  onConfirmApproveReject: ApproveRejectInternalTransferProps['onConfirmApproveReject'];
  recordForApproveReject: ApproveRejectInternalTransferProps['recordForApproveReject'];
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const formik = useFormik({
    initialValues: {
      remark: '',
    },

    onSubmit: (values, { resetForm }) => {
      onConfirmApproveReject({
        remark: values.remark,
        status: recordForApproveReject.action === 'Approved' ? 2 : 3,
        id: recordForApproveReject?.record?.id,
      });
      resetForm({ values: { remark: '' } });
    },

    validationSchema: getApproveRejectSchema(formatMessage),
    enableReinitialize: true,
  });

  return formik;
};
