import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../../services/queries/useQueryFactory';

const useGetPersonalDetails = <TData, TError = Error>(userId: number) => {
  const queryDetails = useQueryFactory<TData, TError>();
  const { data, isFetched, isLoading } = useQuery({
    ...queryDetails.getUserProfile(userId),
  });

  return { personalInfo: data?.data, isFetched, isLoading };
};

export default useGetPersonalDetails;
