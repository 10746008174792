export const API_URLS = {
  GET_DOC_TYPES_LIST: '/kyc-required-documents',

  UPLOAD_KYC_DOCUMENT: (id: number) => `/users/${id}/multiple-upload`,

  APPLY_KYC_REQUIREMENT_DOCUMENT: '/apply/kyc-requirement-documents',

  UPDATE_KYC_DOCUMENT: (id: string | number) => `/user-documents/${id}`,

  GET_UPLOADED_DOC_LIST: '/users',

  UPLOAD_KYC_FORM: '/kyc-form',
  APPROVE_REJECT_KYC: (id: string | number, status: string) =>
    `user-documents/${id}/${status}`,
};
