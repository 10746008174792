import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import home from '~/assets/images/home.svg';
import { useLocation } from 'react-router-dom';
import { useBreadCrumb } from '~/hooks/BreadCrumb/useBreadCrumb';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import { useTheme } from '~app/styles';
import HomeIcon from '@mui/icons-material/Home';
const Breadcrumb = ({
  headerProps,
}: {
  headerProps?: {
    title: string;
    breadcrumbs?: {
      label: string;
      href?: string;
    }[];
  };
}) => {
  const { pathname } = useLocation();
  const { breadCrumbs } = useBreadCrumb(pathname);
  const theme = useTheme();

  return (
    <Breadcrumbs
      separator={<ChevronRightIcon fontSize='small' />}
      sx={{
        pb: '10px',
        '& .MuiBreadcrumbs-li': {
          '&:not(:last-child)': {
            '& a': {
              color: 'black',
              textDecoration: 'none',
            },
          },
          '&:last-child': {
            '& a': {
              color: theme.palette.secondary.main,
              textDecoration: 'none',
            },
          },
        },
      }}
    >
      <Link
        underline='none'
        color='inherit'
        href={`${APPLICATION_URLS.HOME_PAGE}`}
        variant='subtitle2'
      >
        <img src={home} alt='home' />
      </Link>

      {breadCrumbs.map((breadcrumb, index) => (
        <Link
          style={{
            cursor: 'default',
          }}
          underline='none'
          key={index}
          color='inherit'
          variant='subtitle2'
        >
          {breadcrumb.title}
        </Link>
      ))}

      {headerProps?.breadcrumbs?.map((breadcrumb, index) => (
        <Link
          key={index}
          underline='none'
          color='inherit'
          href={breadcrumb.href}
          variant='subtitle2'
        >
          {breadcrumb.label}
        </Link>
      ))}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
