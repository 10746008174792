import Box from '@mui/material/Box';

import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import Button from 'v2s-ui-core/app/components/Button';
import { DialogContent, DialogContentText, ListItemIcon } from '@mui/material';
import success from '~/assets/images/success.svg';

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
  message: string;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  open,
  onClose,
  message,
  // eslint-disable-next-line arrow-body-style
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        // aria-labelledby='responsive-dialog-title
        PaperProps={{
          component: 'form',

          sx: {
            minWidth: {
              sm: '528px !important',
              xs: '343px',
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ListItemIcon
            sx={[
              {
                minWidth: 0,
                justifyContent: 'center',
              },
            ]}
          >
            <img className='menu-icon' src={success} alt={'success-toast'} />
          </ListItemIcon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color={blue[700]}
            style={{ textAlign: 'center', marginBottom: 1 }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box display={'flex'} justifyContent={'right'}>
            <Button
              variant='contained'
              sx={{ height: '42px !important', width: '66px !important' }}
              size='large'
              onClick={() => onClose()}
              autoFocus
            >
              ok
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SuccessModal;
