// @ts-ignore
import CKEditor from 'react-ckeditor-component';

function Editor({
  values,
  setFieldValue,
  errors,
  setFieldTouched,
  touched,
}: any) {
  const handleEditorChange = (event: any) => {
    const data = event.editor.getData(); // Retrieve the updated content
    setFieldValue('body', data); // Update the Formik state
  };

  return (
    <>
      <CKEditor
        config={{
          versionCheck: false,
          removePlugins: 'elementspath', // example configuration
          toolbar: [
            [
              'Bold',
              'Italic',
              'Underline',
              'Strike',
              'Subscript',
              'Superscript',
              '-',
              'Undo',
              'Redo',
            ],
            [
              'NumberedList',
              'BulletedList',
              '-',
              'Outdent',
              'Indent',
              'Blockquote',
            ],
            ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
            ['Link', 'Unlink'],
            ['Image', 'Table', 'HorizontalRule', 'SpecialChar'],
            ['Styles', 'Format', 'Font', 'FontSize', 'TextColor', 'BGColor'],
            ['Maximize', 'ShowBlocks'],
          ],
        }}
        content={values.body}
        events={{
          change: handleEditorChange,
        }}
        id='ckeditor'
        data={values.body}
      />
    </>
  );
}

export default Editor;
