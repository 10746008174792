import * as yup from 'yup';
import { getOldPasswordSchema, getPasswordSchema } from '~/schema';
// we don't have status id
export const ACCOUNT_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  ACTIVE: 'ACTIVE',
} as const;

export const MODES = {
  VIEW: 'view',
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
  CREATE: 'create',
  UPDATE: 'update',
  UPLOAD: 'upload',
  IS_FIRST_TIME: 'isFirstTime',
} as const;

export const COUNTRIES = {
  INDIA: 'India',
  //add as needed
};

export const FILTERS = {
  SEARCH: 'value',
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

export const INPUT_FIELDS_LENGTHS = {
  NAME: {
    MIN: 3,
    MAX: 100,
  },
  LAST_NAME: {
    MIN: 0,
    MAX: 255,
  },
  EMAIL: {
    MIN: 0,
    MAX: 255,
  },
  PHONE_NUMBER: {
    MIN: 0,
    MAX: 16,
  },
  REFERRAL: {
    MIN: 0,
    MAX: 255,
  },
  REMARK: {
    MIN: 0,
    MAX: 255,
  },
  PASSWORD: {
    MIN: 8,
    MAX: 20,
  },
  ADDRESS: {
    MIN: 8,
    MAX: 50,
  },
} as const;

export const INPUT_FIELD_REGEX = {
  EMAIL: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
  PHONE_NUMBER: /^\+?\d{1,4}\s?\d{1,14}$/,
} as const;

export const CHANGE_PASSWORD_FORM_VALUES = {
  new_password: '',
  confirm_password: '',
  old_password: '',
} as const;

export const getChangePasswordSchema = (formatMessage: any) =>
  yup.object().shape({
    old_password: getOldPasswordSchema(formatMessage),
    new_password: getPasswordSchema(formatMessage),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('new_password'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

export const STATUS_COLUMNS_TO_PIN = ['status'];

export const ACTIONS_COLUMNS_TO_PIN = ['actions'];

//kyc

export const GET_KYC_UPLOAD_MODE = {
  EDIT: 'EDIT',
  ADD: 'ADD',
};

export const BANK_STATEMENT_DOCUMENT_DETAIL = {
  document_name: 'Bank Statement',
  id: 11,
};

export const DEFAULT_COUNTRY_CURRENCY = {
  INDIA: { name: 'INR', id: 4 },
  USD: { name: 'USD', id: 1 },
};
export const INPUT_FIELDS_LENGTHS_PROFILE = {
  FIRST_NAME: {
    MIN: 3,
    MAX: 100,
  },
  LAST_NAME: {
    MIN: 3,
    MAX: 100,
  },
  EMAIL: {
    MIN: 0,
    MAX: 255,
  },

  PHONE_NUMBER: {
    MIN: 8,
    MAX: 16,
  },
  REFERRAL: {
    MIN: 0,
    MAX: 255,
  },
  REMARK: {
    MIN: 8,
    MAX: 255,
  },
  PASSWORD: {
    MIN: 8,
    MAX: 20,
  },
  ADDRESS: {
    MIN: 3,
    MAX: 50,
  },
  IFSC: {
    MIN: 11,
    MAX: 11,
  },
  IBAN: {
    MIN: 8,
    MAX: 34,
  },
  ACCOUNT_NUMBER: {
    MIN: 6,
    MAX: 20,
  },
  BANK_NAME: {
    MIN: 3,
    MAX: 50,
  },
  CITY: {
    MIN: 3,
    MAX: 30,
  },
  STATE: {
    MIN: 2,
    MAX: 30,
  },
  POST_CODE: {
    MIN: 5,
    MAX: 30,
  },
  NATIONALITY: {
    MIN: 2,
    MAX: 30,
  },
  COMPANY_NAME: {
    MAX: 100,
  },
  COMPANY_ADDRESS_LINE_1: {
    MIN: 3,
    MAX: 150,
  },
  COMPANY_ADDRESS_LINE_2: {
    MIN: 3,
    MAX: 150,
  },
  COMPANY_LICENSE: {
    MIN: 3,
    MAX: 50,
  },
} as const;

export const INPUT_FIELD_REGEX_PROFILE = {
  EMAIL: /^[^\s][a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  PASSWORD:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/,
  PHONE_NUMBER: /^\+?\d{1,4}\s?\d{1,14}$/,
  NAME: /^[a-zA-Z]+(?: [a-zA-Z]+)*$/,
  IFSC: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  IBAN: /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/,
  // ADDRESS: /^[a-zA-Z0-9#' & * ^" ()|/\.,\-\[\]\s]*$/,
  ADDRESS: /^[a-zA-Z0-9 ,\\\-/#'\"().\[\]]*$/,

  REMARKS: /^[^\s].*[^\s]$/,
} as const;

export const MINIMUM_AGE_ALLOWED = 18;

export const REQUEST_STATUS = {
  ERROR: 'error',
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
} as const;

export const ALLOWED_KEYS_FOR_NUMBER_INPUT = [
  'Backspace',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'ArrowDown',
  'Tab',
  'Delete',
];

export const SINGLE_DIGIT_REGEX = /^\d$/;

export const UPLOADED_DOCUMENT_STATUS = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING: 'Pending',
  ACTIVE: 'Active',
} as const;

export const ALLOWED_UPLOAD_FILE_SIZE = 4 * 1024 * 1024;

export const ALLOWED_UPLOAD_FILE_LISTS = [
  'application/pdf',
  'image/png',
  'image/jpg',
  'image/heic',
  'image/jpeg',
];
