import messages from './messages';

// status from api
export const KYC_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'approve',
  REJECTED: 'reject',
};

export const KYC_STATUS_MESSAGE = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const KYC_STATUS_CONFIG = {
  [KYC_STATUS_MESSAGE.PENDING]: {
    label: messages.pending,
    color: 'secondary',
  },
  [KYC_STATUS_MESSAGE.APPROVED]: {
    label: messages.approved,
    color: 'success',
  },
  [KYC_STATUS_MESSAGE.REJECTED]: { label: messages.rejected, color: 'error' },
};

export const EXPIRY_STATUS = {
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};

// export const documentLabel = (
//   documentType: string,
//   documentSide: string,
// ): string => {
//   const labelMap: { [key: string]: string } = {
//     POI: 'Proof of Identity',
//     POA: 'Proof of Address',
//     Others: 'Other',
//   };

//   const sideMap: { [key: string]: string } = {
//     Front: ' (front)',
//     Back: ' (back)',
//   };

//   return `${labelMap[documentType]}${sideMap[documentSide]}`;
// };
// Update the documentLabel function to generate labels dynamically
export const documentLabel = (
  documentType: string,
  documentSide: string,
): string => {
  const labelMap: { [key: string]: string } = {
    POI: 'Proof of Identity',
    POA: 'Proof of Address',
    Others: 'Other',
  };

  const sideMap: { [key: string]: string } = {
    Front: ' (front)',
    Back: ' (back)',
  };

  return `${labelMap[documentType]}${sideMap[documentSide]}`;
};

export const documentOrder: Record<string, number> = {
  'POI Front': 1,
  'POI Back': 2,
  'POA Front': 3,
  'POA Back': 4,
  'Others Front': 5,
  'Others Back': 6,
};
