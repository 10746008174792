import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { TraderRes } from '../../types';
import { Link } from 'react-router-dom';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import { formatDate } from '~/utils/FormateDate';
import DateFilterComponent from '~/components/DateFIlter';
import { noReferralValue, defaultReferralDisplay } from '../../constants';

export const getTradersTableColumns = (): ColumnDef<TraderRes>[] => [
  {
    header: 'User ID',
    enableColumnFilter: true,
    accessorKey: 'id',
  },
  {
    header: 'Registration Date',
    enableColumnFilter: true,
    accessorKey: 'registration_date',
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    header: 'First Name',
    enableColumnFilter: true,
    accessorKey: 'first_name',
  },
  {
    header: 'Last Name',
    enableColumnFilter: true,
    accessorKey: 'last_name',
  },
  {
    header: 'Email',
    enableColumnFilter: true,
    accessorKey: 'email',
    cell: ({ row }: any) => (
      <Link state={row.original.id} to={APPLICATION_URLS?.USER_PROFILE}>
        {row?.original.email}
      </Link>
    ),
  },
  {
    header: 'Country',
    enableColumnFilter: true,
    accessorKey: 'country',
    cell: ({ row }: any) => row?.original?.country?.countryEn,
  },
  {
    header: 'Phone',
    enableColumnFilter: true,
    accessorKey: 'phone_number',
  },
  {
    header: 'Account Type',
    enableColumnFilter: true,
    accessorKey: 'user_type',
    cell: ({ row }: any) => row?.original?.user_type?.type,
  },
  {
    header: 'KYC Status',
    enableColumnFilter: true,
    accessorKey: 'kyc_status',
    cell: ({ row }: any) => row?.original?.kyc_status,
  },
  {
    header: 'Status',
    enableColumnFilter: true,
    accessorKey: 'status',
  },
  {
    header: 'Referral',
    enableColumnFilter: true,
    accessorKey: 'referral',
    cell: ({ row }: any) =>
      row?.original?.referral === noReferralValue
        ? defaultReferralDisplay
        : row?.original?.referral,
  },
];
