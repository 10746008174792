import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  userProfile: {
    id: 'userProfile.profile',
    defaultMessage: 'Profile',
  },
  bankAccount: {
    id: 'userProfile.bankAccount',
    defaultMessage: 'Bank Accounts',
  },
  kycDocument: {
    id: 'userProfile.kycDocument',
    defaultMessage: 'KYC Documents',
  },
  tradingAccounts: {
    id: 'userProfile.tradingAccounts',
    defaultMessage: 'Trading Accounts',
  },
  wallets: {
    id: 'userProfile.wallets',
    defaultMessage: 'Wallet',
  },
  transactions: {
    id: 'userProfile.transactions',
    defaultMessage: 'Transactions',
  },
  noData: {
    id: 'userProfile.noData',
    defaultMessage: 'No data available',
  },
  genealogy: {
    id: 'userProfile.genealogy',
    defaultMessage: 'IB Genealogy',
  },
  network: {
    id: 'userProfile.network',
    defaultMessage: 'IB Network',
  },
  statement: {
    id: 'userProfile.statement',
    defaultMessage: 'IB Statement',
  },
  agreement: {
    id: 'userProfile.agreement',
    defaultMessage: 'IB Agreement',
  },
  rebateStructure: {
    id: 'userProfile.rebateStructure',
    defaultMessage: 'Choose Rebate Structure',
  },
  tier: {
    id: 'userProfile.tier',
    defaultMessage: 'Select Tier',
  },
  submit: {
    id: 'userProfile.submit',
    defaultMessage: 'Submit',
  },
  cancel: {
    id: 'userProfile.cancel',
    defaultMessage: 'Cancel',
  },
  level: {
    id: 'userProfile.level',
    defaultMessage: 'Level {id}',
  },
  ibLevel: {
    id: 'userProfile.ibLevel',
    defaultMessage: 'IB Level ',
  },
  masterIBEmail: {
    id: 'userProfile.masterIBEmail',
    defaultMessage: 'Master IB Email',
  },
  masterIBID: {
    id: 'userProfile.masterIBID',
    defaultMessage: 'Master IB ID',
  },
  masterIBName: {
    id: 'userProfile.masterIBName',
    defaultMessage: 'Master IB Name',
  },
});
