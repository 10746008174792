import TextField from '@mui/material/TextField';
import { styled } from 'v2s-ui-core/app/styles';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input[type=number]': {
    MozAppearance: 'textfield',
    WebkitAppearance: 'none',
    margin: 0,
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
    {
      WebkitAppearance: 'none',
      margin: 0,
    },
}));
