import { NavigateFunction } from 'react-router-dom';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import { logger } from '~/utils/logger';
import { AUTH_TOKENS_KEY_MAP } from '../../EntryPoint/UnauthorizedInterceptorWithStorage';

const { ACCESS_TOKEN, REFRESH_TOKEN } = AUTH_TOKENS_KEY_MAP;

/* eslint-disable arrow-body-style */
export const checkIfUserIsAuthenticated = () => {
  const accessToken = getAuthToken();
  if (accessToken?.accessToken) return true;
  return false;
};

export const getAuthToken = () => {
  try {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);
    return { accessToken, refreshToken };
  } catch (e) {
    logger.error('Auth token not found');
    return;
  }
};

export const syncAuthDataInStorage = <T>(dataToSaveInStorage: T) => {
  //Logic to set auth token in storage
  sessionStorage.setItem('AUTH_DATA', JSON.stringify(dataToSaveInStorage));
  localStorage.setItem('AUTH_DATA', JSON.stringify(dataToSaveInStorage));
};

export const getAuthDataFromStorage = () => {
  const authString = localStorage.getItem('AUTH_DATA');
  if (authString) return JSON.parse(authString);
  return null;
};

export const setAuthToken = (access_token: string, refresh_token: string) => {
  //Logic to retrieve auth token from storage
  localStorage.setItem(ACCESS_TOKEN, access_token);
  localStorage.setItem(REFRESH_TOKEN, refresh_token);
  sessionStorage.setItem(ACCESS_TOKEN, access_token);
  sessionStorage.setItem(REFRESH_TOKEN, refresh_token);
  // return accessToken, refreshToken;
};

// export const onLogout = () => {
//   sessionStorage.clear();
// };

export const onLogout = (
  setAuth: any,
  navigate: NavigateFunction,
  QueryClient: any,
) => {
  setAuth(null);
  navigate(APPLICATION_URLS.LOGIN);
  localStorage.clear();
  sessionStorage.clear();
  QueryClient.removeQueries();
};
