import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { getApproveRejectSchema } from '~/modules/BankAccounts/services/mutations/constants';
import { ApproveRejectBankAccountProps } from '.././types';

export const useApproveRejectBankAccount = ({
  onConfirmApproveReject,
  recordForApproveReject,
}: {
  onConfirmApproveReject: ApproveRejectBankAccountProps['onConfirmApproveReject'];
  recordForApproveReject: ApproveRejectBankAccountProps['recordForApproveReject'];
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const formik = useFormik({
    initialValues: { remark: '' },
    onSubmit: (values, { resetForm }) => {
      onConfirmApproveReject({
        remark: values.remark,
        id: recordForApproveReject?.record.id,
        status:
          recordForApproveReject?.action === 'APPROVED'
            ? 'APPROVED'
            : 'REJECTED',
      });
    },
    validationSchema: getApproveRejectSchema(formatMessage),
    enableReinitialize: true,
  });

  return formik;
};
