/* eslint-disable complexity */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import messages from '~/modules/Deposits/messages';
import {
  DEPOSIT_STATUS,
  getAccountLabel,
  getAccountValue,
} from '~/modules/Deposits/constants';
import { useApproveRejectDepositAccount } from '~/modules/Deposits/hooks/useApproveRejectDepositAccount';
import { ApproveRejectDepositProps } from '~/modules/Deposits/types';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
import { RenderDocumentContent } from '~/components/ShowDoc';
import { REQUEST_STATUS } from '~/constants';
import { Box, Typography } from '@mui/material';
import { PAYMENT_METHODS } from '~/components/Actions/constant';

const ApproveRejectDeposit = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
  status,
}: ApproveRejectDepositProps) => {
  const {
    handleChange,
    handleSubmit,
    errors,
    values,
    touched,
    setFieldTouched,
  } = useApproveRejectDepositAccount({
    onConfirmApproveReject,
    recordForApproveReject,
  });
  const isMobile = useIsMobile();
  const { latest_version: documentData = {} } =
    recordForApproveReject.record.user_document || {};
  const {
    original_doc_location: documentUrl = '',
    content_type: contentType = '',
    extension = '',
  } = documentData as any;

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: isMobile ? '100vw' : '800px',

          margin: isMobile ? 1 : 0,
          pb: isMobile ? 2 : 1,
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        {recordForApproveReject.action === DEPOSIT_STATUS.APPROVED ? (
          <FormattedMessage {...messages.approveModalTitle} />
        ) : (
          <FormattedMessage {...messages.rejectModalTitle} />
        )}
        <CloseButton onClose={onCancel} />
      </DialogTitle>
      <DialogContent sx={{ width: '100%', padding: isMobile ? 2 : 1 }}>
        {/*Show bank docs*/}

        {/* Form Fields */}

        <Box
          display='flex'
          alignItems='center'
          justifyContent={'center'}
          gap={isMobile ? 0 : 4}
          px={isMobile ? 0 : 2}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid container spacing={2} mt={0}>
            <>
              <Grid item xs={12}>
                <TextField
                  name={'trading_amount'}
                  onChange={handleChange}
                  value={values?.trading_amount}
                  required
                  type='number'
                  variant='outlined'
                  label={<FormattedMessage {...messages.tradingAmount} />}
                  error={Boolean(errors.trading_amount)}
                  helperText={errors.trading_amount}
                  size='small'
                  fullWidth
                  disabled={
                    recordForApproveReject?.action === DEPOSIT_STATUS.REJECTED
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={'trading_currency'}
                  variant='outlined'
                  value={recordForApproveReject?.record?.trading_currency}
                  label={<FormattedMessage {...messages.tradingCurrency} />}
                  size='small'
                  disabled
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name={'local_amount'}
                  variant='outlined'
                  onChange={(e) => {
                    setFieldTouched('local_amount', true);
                    handleChange(e);
                  }}
                  disabled={
                    recordForApproveReject?.action === DEPOSIT_STATUS.REJECTED
                  }
                  onBlur={() => setFieldTouched('local_amount', true)}
                  value={values?.local_amount}
                  label={<FormattedMessage {...messages.local_amount} />}
                  error={touched.local_amount && Boolean(errors.trading_amount)}
                  helperText={touched.local_amount && errors.trading_amount}
                  size='small'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name={'local_currency'}
                  variant='outlined'
                  value={recordForApproveReject?.record?.local_currency}
                  label={<FormattedMessage {...messages.local_currency} />}
                  size='small'
                  disabled
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  value={getAccountValue(recordForApproveReject?.record)}
                  label={getAccountLabel(recordForApproveReject?.record)}
                  size='small'
                  disabled
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name={'notes'}
                  variant='outlined'
                  label={<FormattedMessage {...messages.notes} />}
                  value={recordForApproveReject?.record?.note}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>
            </>

            <Grid item xs={12}>
              <TextField
                name={'remark'}
                variant='outlined'
                onChange={(e) => {
                  setFieldTouched('remark', true);
                  handleChange(e);
                }}
                onBlur={() => setFieldTouched('remark', true)}
                label={<FormattedMessage {...messages.addRemark} />}
                size='small'
                required
                fullWidth
                type='text'
                error={touched.remark && Boolean(errors.remark)}
                helperText={touched.remark && errors.remark}
              />
            </Grid>
          </Grid>
          <Box>
            <Typography mt={1}>
              {
                recordForApproveReject?.record?.user_document?.latest_version
                  ?.filename
              }
            </Typography>
            {(recordForApproveReject?.record?.payment_method ===
              PAYMENT_METHODS.wire ||
              recordForApproveReject?.record?.payment_method ===
                PAYMENT_METHODS.local) && (
              <RenderDocumentContent
                contentType={contentType}
                extension={extension}
                documentUrl={documentUrl}
              />
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: isMobile ? 2 : 3 }}>
        <Button
          disabled={status === REQUEST_STATUS.PENDING}
          variant='contained'
          onClick={() => handleSubmit()}
        >
          {recordForApproveReject.action === DEPOSIT_STATUS.APPROVED ? (
            <FormattedMessage {...messages.approve} />
          ) : (
            <FormattedMessage {...messages.reject} />
          )}
        </Button>
        <Button onClick={onCancel} variant='outlined'>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRejectDeposit;
