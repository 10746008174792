import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import useAsyncMemo from '~/hooks/useAsyncMemo';
import useToast from '~/hooks/useToast';
import { handleDownload } from '~/utils';
import { isMobile } from '~app/utils';
import { CloseButton } from '../Actions/CloseButton';
import { messages } from './messages';
import {
  convertFileToBase64,
  handleFileContent,
  isDocumentImage,
  isDocumentPdf,
} from './utils';

export const ViewFileDialog = ({
  file,
  viewFileDialog,
  closeViewFileDialog,
}: {
  file: any;
  viewFileDialog: boolean;
  closeViewFileDialog: () => void;
}) => {
  const { showToastNotification } = useShowToastNotifications();
  const { triggerErrorToast } = useToast();

  const fileContent = useAsyncMemo(
    async () =>
      file !== null && file?.name !== ''
        ? await convertFileToBase64(file)
        : null,
    [file],
  );

  const blobUrl = React.useMemo(() => {
    try {
      return URL.createObjectURL(handleFileContent(fileContent));
    } catch (err) {
      triggerErrorToast(err);
      return null;
    }
  }, [fileContent]);

  const onDownload = () => {
    handleDownload(fileContent, file?.name, showToastNotification);
    closeViewFileDialog();
  };
  if (fileContent === null)
    return (
      <Box>
        <CircularProgress />
      </Box>
    );

  return (
    <Dialog
      fullWidth
      open={viewFileDialog}
      PaperProps={{
        component: 'form',
        sx: {
          maxWidth: '698px',
          width: isMobile() ? '100vw' : '698px',
          margin: isMobile() ? 1 : 0,
          height:
            isDocumentPdf(file?.type, file?.name) || isDocumentImage(file?.type)
              ? '80%'
              : 'auto',
        },
      }}
      onClose={() => closeViewFileDialog()}
    >
      <DialogTitle
        sx={{
          backgroundColor: blue[50],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography className='h6-card-dialog-title'>
          <FormattedMessage {...messages.viewDocument} />
        </Typography>
        <CloseButton onClose={closeViewFileDialog} />
      </DialogTitle>

      <DialogContent sx={{ marginTop: '16px' }}>
        {!!fileContent && isDocumentPdf(file?.type, file?.name) ? (
          <iframe
            src={blobUrl}
            width='100%'
            height='100%'
            style={{
              border: '1px solid #e0e0e0',
            }}
            title={file?.name}
          />
        ) : isDocumentImage(file?.type) ? (
          <img
            alt='Document'
            src={blobUrl}
            style={{
              width: '100%',
              height: '100%',
              border: '1px solid #e0e0e0',
            }}
          />
        ) : (
          <Typography variant='body1'>
            <FormattedMessage {...messages.viewPreviewDialogInfo} />
          </Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={closeViewFileDialog}>
          <FormattedMessage {...messages.close} />
        </Button>
        <Button variant='contained' onClick={() => onDownload()}>
          <FormattedMessage {...messages.download} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
