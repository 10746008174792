import { Box, Grid, Skeleton } from '@mui/material';

const FormSkeleton = () => (
  <Box sx={{ padding: 2 }}>
    <Grid container mt={2} spacing={2} justifyContent='space-between'>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'flex-start'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={86}
          sx={{ display: 'inline-block' }}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'center'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={86}
          sx={{ display: 'inline-block' }}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'flex-end'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={86}
          //   sx={{ marginLeft: 'auto' }}
        />
      </Grid>
    </Grid>
    <Grid
      container
      spacing={2}
      sx={{ paddingLeft: { md: 2, xs: 2 }, paddingRight: { md: 0, xs: 1 } }}
      mt={4}
    >
      <Skeleton
        variant='rectangular'
        height={155}
        sx={{ display: 'inline-block', width: { md: '100%', xs: '100%' } }}
      />
    </Grid>
    <Grid container mt={2} spacing={2} justifyContent='space-between'>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'flex-start'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={86}
          sx={{ display: 'inline-block' }}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'center'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={86}
          sx={{ display: 'inline-block' }}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'flex-end'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={86}
          //   sx={{ marginLeft: 'auto' }}
        />
      </Grid>
    </Grid>
    <Grid container mt={2} spacing={2} justifyContent='space-between'>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'flex-start'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={43}
          sx={{ display: 'inline-block' }}
        />
      </Grid>
    </Grid>
    <Grid container mt={2} spacing={2} justifyContent='space-between'>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'flex-start'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={86}
          sx={{ display: 'inline-block' }}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'center'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={86}
          sx={{ display: 'inline-block' }}
        />
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        display={{ md: 'flex', xs: 'block' }}
        justifyContent={'flex-end'}
      >
        <Skeleton
          variant='rectangular'
          width={297}
          height={86}
          //   sx={{ marginLeft: 'auto' }}
        />
      </Grid>
    </Grid>
  </Box>
);

export default FormSkeleton;
