import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import DateFilterComponent from '~/components/DateFIlter';

export const getCreditInTableColumns = (props: {}): ColumnDef<unknown>[] => [
  {
    accessorKey: 'id',
    header: 'ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'credit_type',
    header: 'Credit Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'created_by',
    cell: ({ row }: any) => row.original?.created_by?.email,
    header: 'Created By',
    enableColumnFilter: true,
  },
];
