import React from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import { ShowStatus } from '~/components/Status/ShowStatus';
import AsyncDropdown from '~app/components/AsyncDropdown';
import { useLocation } from 'react-router-dom';
import { API_URLS } from '../api/config';
import { QUERY_KEYS } from '../services/queries/constants';

// eslint-disable-next-line arrow-body-style
const AccountHeader = ({
  account,
  setAccount,
  data,
  setShowAddBankAccountModal,
}: any) => {
  const location = useLocation();

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'flex-end'}
        alignItems={'center'}
        pb={'16px'}
        borderBottom={'1px solid #D0D5DD'}
      >
        <Button
          onClick={() => setShowAddBankAccountModal(true)}
          variant='contained'
        >
          Add Bank Account
        </Button>
      </Box>

      <Box
        borderBottom={'1px solid #D0D5DD'}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        py={'20px'}
      >
        <Typography
          width={'220px'}
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '14px', sm: '20px' },
            marginRight: '16px',
          }}
        >
          <FormattedMessage {...messages.bank_accounts} />
        </Typography>

        <ShowStatus status={account && account?.status} />
        <Grid
          container
          spacing={2}
          justifyContent={'flex-end'}
          alignItems={'center'}
        >
          <Grid item xs={12} sm={4} ml={'16px'}>
            <AsyncDropdown
              InputProps={{
                label: <FormattedMessage {...messages.choose_bank} />,
              }}
              queryProps={{
                resource: API_URLS.FETCH_BANK_ACCOUNT_INFO(location.state),
                queryKeyProp: [QUERY_KEYS.FETCH_BANK_ACCOUNT_INFOS],
                select: (data: any) => data.data,
              }}
              value={account && (account as any)}
              onChange={(e, value) => setAccount(value)}
              getOptionLabel={(option) => option.bank_name}
              getOptionKey={(option) => option.id}
            />
          </Grid>
          {/* <Grid item>
            <Button variant='contained'>Add Bank Account</Button>
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  );
};

export default AccountHeader;
