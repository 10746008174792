import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import AppBaseLayout, {
  AppBaseLayoutProps,
} from '~/shell/components/Layout/components/AppLayout';
import Templates from './modules/Templates';
import Events from './modules/Events';
import Button from '~app/components/Button';
import EventsModalAction from './modules/Events/components/EventsModalAction';
import AddTemplate from './modules/AddTemplate';
import { Link } from 'react-router-dom';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from './messages';

const ACTIONS = {
  event: 'event',
};

const EMAILING_ROUTE = '/emailing';

const SUB_ROUTES_FOR_EMAILING_MAP: {
  path: string;
  element: typeof AppBaseLayout;
  AppBaseLayoutProps: AppBaseLayoutProps;
}[] = [
  {
    path: APPLICATION_URLS.EVENTS,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Events',
        actionButtons: <EventsModalAction actionType={ACTIONS.event} />,
      },
      contentProps: {
        content: <Events />,
      },
    },
  },
  {
    path: APPLICATION_URLS.TEMPLATES,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Email Templates',
        actionButtons: (
          <Link to={EMAILING_ROUTE + APPLICATION_URLS.ADD_TEMPLATE}>
            <Button variant='contained'>
              <FormattedMessage {...messages.addNewTemplate} />
            </Button>
          </Link>
        ),
      },
      contentProps: {
        content: <Templates />,
      },
    },
  },

  {
    path: APPLICATION_URLS.ADD_TEMPLATE,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Add New Template',
      },
      contentProps: {
        content: <AddTemplate />,
      },
    },
  },
];
export { SUB_ROUTES_FOR_EMAILING_MAP };
