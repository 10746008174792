import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ActionButtons from '~/components/Actions/ActionButtons';

import {
  BANK_ACCOUNT_STATUS,
  BANK_ACCOUNT_STATUS_CONFIG,
} from '../../constants';
import DateFilterComponent from '~/components/DateFIlter';

export const getBankAccountsTableColumns = (props: {
  onViewRecord?: Function;
  onApproveRecord?: Function;
  onRejectRecord?: Function;
  showViewButton?: boolean;
}): ColumnDef<unknown>[] => [
  {
    accessorKey: 'id',
    header: 'Case ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_id',
    cell: ({ row }: any) => row.original?.created_by?.id,
    header: 'User ID',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'first_name',
    cell: ({ row }: any) => row.original?.created_by?.first_name,
    header: 'First Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'last_name',
    cell: ({ row }: any) => row.original?.created_by?.last_name,
    header: 'Last Name',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'user_email',
    cell: ({ row }: any) => row.original?.created_by?.email,
    header: 'User Email',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'bank_name',
    header: 'Bank Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_account_name',
    header: 'Account Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_account_number',
    header: 'Account Number',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'bank_iban',
    header: 'Bank IBAN',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_code',
    header: 'Bank Code',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'bank_address',
    header: 'Bank Address',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'created_by',
    cell: ({ row }: any) => row.original?.created_by?.email,
    header: 'Created By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_by',
    cell: ({ row }: any) => row.original?.updated_by?.email,
    header: 'Updated By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'deleted_at',
    header: 'Deleted At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'deleted_by',
    cell: ({ row }: any) => row.original?.deleted_by?.email,
    header: 'Deleted By',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'actions',
    header: 'Actions',
    enablePinning: true,
    enableColumnFilter: false,
    cell: ({ cell }: any) => {
      const { row } = cell;
      const status = row.original.status;

      return (
        <ActionButtons
          viewBankAccountDoc
          status={status}
          onViewRecord={props?.onViewRecord}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original}
          showViewButton={false}
          ACCOUNT_STATUS={BANK_ACCOUNT_STATUS}
          ACCOUNT_STATUS_CONFIG={BANK_ACCOUNT_STATUS_CONFIG}
        />
      );
    },
  },
];
