import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';

export const getEventsTableColumns = (props: {}): ColumnDef<unknown>[] => [
  {
    accessorKey: 'id',
    header: 'Event ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'name',
    header: 'Event Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by',
    header: 'Created By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_by',
    header: 'Updated By',
    enableColumnFilter: true,
  },
];
