import AppBaseLayout from '~/shell/components/Layout/components/AppLayout';
import InternalTransfersTable from './components/index';
import { QUERY_KEYS } from './services/queries/constants';
const BankAccounts = () => (
  <AppBaseLayout
    contentProps={{
      content: <InternalTransfersTable />,
    }}
    headerProps={{
      title: 'Transfers',
      actionButtons: <></>,
    }}
    ShowRefresh
    QUERY_KEYS={QUERY_KEYS.FETCH_INTERNAL_TRANSFERS}
  />
);

export default BankAccounts;
