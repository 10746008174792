import type { RouteProps } from 'react-router-dom';

import APPLICATION_URLS from './appsConfig';
import { lazy } from 'react';
import BankAccounts from '~/modules/BankAccounts';
import Deposits from '~/modules/Deposits';
import Withdrawal from '~/modules/Withdrawal';
import Requests from '~/modules/Requests';
import Traders from '~/modules/Traders';
import Dashboard from '~/modules/Dashboard';
import UserProfile from '~/modules/UserProfile';
import InternalTransfers from '~/modules/InternalTransfers';
import Credits from '~/modules/Credit';
import IbManagement from '~/modules/IbManagement';
import IbProfile from '~/modules/IbProfile';
import Emailing from '~/modules/Emailing';
import Reports from '~/modules/Reports';
const UserManagement = lazy(() => import('~/modules/UserManagement'));
const AuthApp = lazy(() => import('~/modules/Auth'));
// import Traders from '~/modules/Traders';

// GX_INJECT_MODULES_IMPORT

//-------------------------------DEFAULT ROUTES-------------------------------//

const DEFAULT_ROUTES: RouteProps[] = [];

//-------------------------------AUTH ROUTES-------------------------------//

const AUTH_ROUTES: RouteProps[] = [
  {
    path: APPLICATION_URLS.AUTH,
    element: <AuthApp />,
  },
  {
    path: APPLICATION_URLS.TRADERS,
    element: <Traders />,
  },
  // GX_INJECT_AUTH_ROUTES
];

//-------------------------------PROTECTED ROUTES-------------------------------//

const PROTECTED_ROUTES: RouteProps[] = [
  {
    path: APPLICATION_URLS.USER_MANAGEMENT,
    element: <UserManagement />,
  },
  {
    path: APPLICATION_URLS.BANK_ACCOUNTS,
    element: <BankAccounts />,
  },
  {
    path: APPLICATION_URLS.DEPOSITS,
    element: <Deposits />,
  },
  {
    path: APPLICATION_URLS.WITHDRAWAL,
    element: <Withdrawal />,
  },
  {
    path: APPLICATION_URLS.REQUESTS,
    element: <Requests />,
  },
  {
    path: APPLICATION_URLS.TRADERS,
    element: <Traders />,
  },
  {
    path: APPLICATION_URLS.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: APPLICATION_URLS.USER_PROFILE,
    element: <UserProfile />,
  },
  {
    path: APPLICATION_URLS.INTERNAL_TRANSFERS,
    element: <InternalTransfers />,
  },
  {
    path: APPLICATION_URLS.CREDIT,
    element: <Credits />,
  },
  {
    path: APPLICATION_URLS.IB_MANAGEMENT,
    element: <IbManagement />,
  },
  {
    path: APPLICATION_URLS.IB_PROFILE,
    element: <IbProfile />,
  },
  {
    path: APPLICATION_URLS.EMAILING,
    element: <Emailing />,
  },
  {
    path: APPLICATION_URLS.REPORTS,
    element: <Reports />,
  },
  // GX_INJECT_PROTECTED_ROUTES
];

export { AUTH_ROUTES, PROTECTED_ROUTES, DEFAULT_ROUTES };
