import { ListItemIcon, Typography } from '@mui/material';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { alpha, styled } from 'v2s-ui-core/app/styles';
import TraderIcon from '~/assets/images/TraderIcon.svg';
import PersonIcon from '@mui/icons-material/Person';
import { USER_TYPE } from '~/modules/IbManagement/constants';

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
  },
}));
function EndIcon(props: any) {
  return (
    <ListItemIcon
      sx={{
        minWidth: 0,
        pr: 1,
      }}
    >
      {/* <PersonIcon /> */}
      <PersonIcon
        color={
          props?.userType === USER_TYPE.Individual_Trader
            ? 'secondary'
            : 'primary'
        }
      />{' '}
    </ListItemIcon>
  );
}
export const renderTreeItems = (node?: any) => (
  <>
    {node?.sub_ibs?.length > 0 ? (
      <CustomTreeItem
        key={node?.id}
        itemId={node?.id?.toString()}
        label={
          <TreeLabelView
            firstName={node?.first_name}
            lastName={node?.last_name}
            userType={node?.user_type}
            phone_number={node?.phone_number}
            email={node?.email}
            level={node?.level}
            isIndividualIbTradingIcon={node?.sub_ibs?.length === 0}
            id={node?.id}
          />
        }
      >
        {node?.direct_trader?.length > 0 &&
          node.direct_trader?.map((subNode: any) =>
            renderTradingTreeItems(subNode),
          )}

        {node?.sub_ibs?.length > 0 &&
          node.sub_ibs?.map((subNode: any) => renderTreeItems(subNode))}
      </CustomTreeItem>
    ) : (
      <TreeLabelView
        firstName={node?.first_name}
        lastName={node?.last_name}
        userType={node?.user_type}
        phone_number={node?.phone_number}
        email={node?.email}
        level={node?.level}
        isIndividualIbTradingIcon={node?.sub_ibs?.length === 0}
        id={node?.id}
      />
    )}
  </>
);

const renderTradingTreeItems = (node: any) => {
  if (!node) return null;

  return (
    <TreeLabelView
      firstName={node?.first_name}
      lastName={node?.last_name}
      userType={node?.user_type}
      phone_number={node?.phone_number}
      email={node?.email}
      level={node?.level}
      isTradingIcon
      id={node?.id}
    />
  );
};

const TreeLabelView = (props: any) => (
  <Typography display={'flex'} alignItems={'center'}>
    {(props?.isTradingIcon || props?.isIndividualIbTradingIcon) && (
      <EndIcon userType={props?.userType} />
    )}{' '}
    {`${props?.firstName} ${props?.lastName},${props?.email},${props?.phone_number}, Account Type:${props?.userType}, Level:${props?.level}`}
  </Typography>
);
