import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { useIsMobile } from '~/hooks/useIsMobile';
import { Button, Grid, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  FormattedMessage,
  useLocaleContext,
} from '~app/Integrations/Localization';
import messages from '../../../messages';

const EventsModal = ({ onConfirmAddRecord, onAddRecord, onCancel }: any) => {
  const isMobile = useIsMobile();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(formatMessage(messages.eventNameRequired)),
    }),
    onSubmit: (values, { resetForm }) => {
      onConfirmAddRecord({
        ...values,
      });
      resetForm({ values: { name: '' } });
    },
  });

  return (
    <>
      <Dialog
        open={true}
        onClose={onCancel}
        PaperProps={{
          component: 'form',
          sx: {
            width: isMobile ? '100vw' : '444px',
            margin: isMobile ? 1 : 0,
          },
        }}
        fullScreen={false}
      >
        <DialogTitle sx={{ backgroundColor: blue[50] }}>
          <FormattedMessage {...messages.addNewEvent} />
        </DialogTitle>

        <DialogContent sx={{ width: '100%', padding: isMobile ? 2 : 1 }}>
          <DialogContentText ml={2} mt={2}>
            <FormattedMessage {...messages.evenModalSubTitle} />
          </DialogContentText>

          <TextField
            sx={{ ml: 2, my: '10px' }}
            label={<FormattedMessage {...messages.eventName} />}
            size='small'
            name='name'
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </DialogContent>

        <DialogActions sx={{ borderTop: '1px solid #e0e0e0', py: '12px' }}>
          <Button
            onClick={() => formik.handleSubmit()}
            sx={{ width: '101px' }}
            variant='contained'
          >
            <FormattedMessage {...messages.save} />
          </Button>
          <Button variant='outlined' onClick={onCancel} sx={{ width: '101px' }}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventsModal;
