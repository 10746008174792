import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  downloadReport: {
    id: 'reports.downloadReport',
    defaultMessage: 'Download Report',
  },
  format: {
    id: 'reports.format',
    defaultMessage: 'Format',
  },
  type: {
    id: 'reports.type',
    defaultMessage: 'Select Report Type',
  },
  download: {
    id: 'reports.download',
    defaultMessage: 'Download',
  },
  loading: {
    id: 'reports.loading',
    defaultMessage: 'Loading...',
  },
});
