import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import {
  FormattedMessage,
  useLocaleContext,
} from 'v2s-ui-core/app/Integrations/Localization';
import useChangePassword from '~/hooks/useChangePassword';
import PasswordInputField from '~/modules/Auth/components/PasswordInputField';
import { CloseButton } from '../Actions/CloseButton';
import messages from './messages';

const ChangePasswordModal = ({
  closeChangePasswordModal,
  openChangePasswordModal,
}: {
  closeChangePasswordModal: any;
  openChangePasswordModal: boolean;
}) => {
  const { formik, isPending } = useChangePassword({
    closeChangePasswordModal,
  });

  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  return (
    <>
      <Dialog
        open={openChangePasswordModal}
        PaperProps={{
          component: 'form',
          sx: {
            minWidth: { md: '444px', xs: '356x' },
            maxHeight: { md: 'auto', xs: 'auto' },
            '& .MuiDialogContent-root': {
              pl: '16px',
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            height: '48px',
            bgcolor: blue[50],
          }}
        >
          <Typography
            textAlign={'center'}
            variant='h6'
            sx={{ display: 'flex', alignItems: 'center', height: '100%' }}
          >
            <FormattedMessage {...messages.changePassword} />
          </Typography>

          <CloseButton onClose={closeChangePasswordModal} />
        </DialogTitle>

        <DialogContent>
          <Box pt={2}>
            <Box
              className='formContainer'
              display='flex'
              width={{ md: '80%', sx: '96%' }}
              flexDirection='column'
            >
              <>
                <Box display={'flex'}>
                  <PasswordInputField
                    name={'old_password'}
                    variant='outlined'
                    required
                    label={formatMessage(messages.oldPassword)}
                    onChange={formik.handleChange}
                    fullWidth
                    size='small'
                    onBlur={() => {
                      formik.setFieldTouched('old_password', true);
                    }}
                    error={
                      !!(
                        formik.errors?.old_password &&
                        formik.touched?.old_password
                      )
                    }
                    helperText={
                      formik.touched?.old_password &&
                      formik.errors?.old_password?.toString()
                    }
                  />
                </Box>
                <Box display={'flex'} my={2}>
                  <PasswordInputField
                    name={'new_password'}
                    variant='outlined'
                    required
                    label={formatMessage(messages.newPassword)}
                    onChange={formik.handleChange}
                    fullWidth
                    size='small'
                    onBlur={() => {
                      formik.setFieldTouched('new_password', true);
                    }}
                    error={
                      !!(
                        formik.errors?.new_password &&
                        formik.touched?.new_password
                      )
                    }
                    helperText={
                      formik.touched?.new_password &&
                      formik.errors?.new_password?.toString()
                    }
                  />
                </Box>
                <Box display={'flex'}>
                  <PasswordInputField
                    name={'confirm_password'}
                    variant='outlined'
                    required
                    label={formatMessage(messages.retypePassword)}
                    onChange={formik.handleChange}
                    fullWidth
                    size='small'
                    onBlur={() => {
                      formik.setFieldTouched('confirm_password', true);
                    }}
                    error={
                      !!(
                        formik.errors?.confirm_password &&
                        formik.touched?.confirm_password
                      )
                    }
                    helperText={
                      formik.touched?.confirm_password &&
                      formik.errors?.confirm_password?.toString()
                    }
                  />
                </Box>
              </>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            display={{ md: 'flex', xs: 'grid' }}
            justifyContent={'center'}
            p={1}
            pr={{ xs: 7, md: 0 }}
            mr={{ xs: 7, md: 0 }}
          >
            <Button
              variant='contained'
              size='medium'
              onClick={() => formik.handleSubmit()}
              disabled={isPending}
            >
              {isPending ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                <FormattedMessage {...messages.changePasswordBtn} />
              )}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChangePasswordModal;
