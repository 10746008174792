import { Route, Routes } from 'react-router-dom';
import { SUB_ROUTES_FOR_EMAILING_MAP } from './constants';

const Emailing = () => (
  <Routes>
    {SUB_ROUTES_FOR_EMAILING_MAP.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={<route.element {...route.AppBaseLayoutProps} />}
      />
    ))}
  </Routes>
);

export default Emailing;
