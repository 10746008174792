import { useState } from 'react';
import { post, patch, put } from 'v2s-ui-core/app/Integrations/ApiService';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import useToast, { TErrorResponse } from './useToast';
import { useLocation } from 'react-router-dom';
import { API_URLS } from '~/api/config';
const useBankDocumentUpload = <
  TData extends TUploadDocResponse,
  TError extends TErrorResponse,
>(args: {
  mutationOptions?: UseMutationOptions<TData, TError, TUploadDocMutationVars>;
  storeUploadDocKey: (uploadDocResponse: TUploadDocResponse) => void;
  userId: number;
  isBankUpload: boolean;
}) => {
  const [uploadDocResponse, setUploadDocResponse] =
    useState<TUploadDocResponse | null>(null);

  const { triggerErrorToast, triggerSuccessToast } = useToast();
  const location = useLocation();

  const onSuccessHandler = (data: TUploadDocResponse) => {
    args.storeUploadDocKey(data);
    setUploadDocResponse(data);
    triggerSuccessToast(data.message);
  };

  const documentUploadMutation = useMutation({
    mutationFn: (payload) =>
      uploadDocumentService(
        args.isBankUpload
          ? API_URLS.BANK_DOCUMENT_UPLOAD(args?.userId)
          : API_URLS.BANK_DOCUMENT_UPDATE(args?.userId),
        payload,
        args.isBankUpload,
      ), // Use args.userId instead of userId
    onSuccess: onSuccessHandler,
    onError: (error) => triggerErrorToast(error),
    ...args.mutationOptions,
  });

  const uploadDoc = async (payload: TUploadDocMutationVars) => {
    documentUploadMutation.mutate(payload);
  };

  return { ...documentUploadMutation, uploadDocResponse, uploadDoc };
};

//todo: document_mapping_id and document_name should be dynamic and dependent on document type

const uploadDocumentService = async (
  url: string,
  payload: TUploadDocMutationVars,
  isBankUpload: boolean,
) => {
  const __payload = {
    file_name: payload.fileName,
    content: payload.file,
    document_mapping_id: payload?.documentMappigId,
    document_name: payload.fileName,
  };

  const createRequestPayload = async () => {
    const fileReader = new FileReader();
    const content = await new Promise((resolve) => {
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(__payload?.content);
    });

    const fileName = `${__payload?.file_name}_${new Date()
      .toISOString()
      .replace(/:/g, '-')}.${__payload.content.type.split('/')[1]}`;

    return {
      document_mapping_id: __payload?.document_mapping_id,
      file_name: fileName,
      content,
    };
  };

  try {
    const requestPayload = await createRequestPayload();
    const uploadMethod = isBankUpload ? post : put;
    const response = await uploadMethod<TUploadDocResponse>(
      url,
      requestPayload,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default useBankDocumentUpload;

export interface TUploadDocMutationVars {
  file: File;
  fileName: string;
  documentMappigId?: number | string;
}

export interface TUploadDocResponse {
  data: Data;
  status_code: number;
  message: string;
}

export interface Data {
  id: number;
  user: number;
  document_type_mapping: number;
  document_name: string;
  document_type: string;
  document_side: string;
  guid: string;
  expire_at: null;
  latest_version: LatestVersion;
}

export interface LatestVersion {
  version: number;
  original_doc_location: string;
  extension: string;
  content_type: string;
  size: number;
  remark: null;
  status: Status;
  created_at: Date;
  filename: string;
}

export interface Status {
  id: number;
  status_name: string;
}
