import * as Yup from 'yup';
import messages from './messages';

export const validationSchema = (formatMessage: Function) =>
  Yup.object({
    template_name: Yup.string()
      .required(formatMessage(messages.templateNameRequired))
      .matches(/^[a-zA-Z\s]+$/, formatMessage(messages.templateNameValidation)),
    subject: Yup.string()
      .required(formatMessage(messages.subjectRequired))
      .matches(/^[a-zA-Z\s]+$/, formatMessage(messages.subjectValidation)),
    body: Yup.string().required(formatMessage(messages.bodyRequired)),
    event: Yup.object().required(formatMessage(messages.eventRequired)),
    // header: Yup.object().required(formatMessage(messages.headerRequired)),
    // footer: Yup.object().required(formatMessage(messages.footerRequired)),
  });
