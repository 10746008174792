import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { TradingAccountsTableColumns } from './TradingAccountsTableColumns';
import ApproveRejectTradingAccount from './ApproveRejectTradingAccount'; // Import the component
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { useApproveRejectActions } from '~/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { ACCOUNT_STATUS } from '../../constants';
import { tradingAccountMessages } from '../../messages';
import SuccessModal from '~/components/SuccessModal';
import TableFilters from '~/components/TableFilters';
import Box from '~app/components/Box';
import useFilters from 'v2s-ui-core/app/hooks/useFilters';
import { ACTIONS_COLUMNS_TO_PIN, FILTERS } from '~/config/constants';

const TradingAccountsTable: FC<{}> = () => {
  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchNewTradingAccounts',
  });
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    showSuccessModal,
    successMessage,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_NEW_TRADING_ACCOUNTS,
    API_URLS: API_URLS.APPROVE_REJECT_TRADING_ACCOUNT,
    STATUS: ACCOUNT_STATUS,
    messages: tradingAccountMessages,
  });

  const handleConfirmApproveReject = () => {
    const payload = {
      crm_status:
        recordForApproveReject?.action === 'APPROVED' ? 'APPROVED' : 'REJECTED',
      id: recordForApproveReject?.record.id,
    };
    onConfirmApproveReject(payload);
  };

  const memoizedColumns = useMemo(
    () =>
      TradingAccountsTableColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );
  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'16px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_NEW_TRADING_ACCOUNTS,
            queryKeyProp: [QUERY_KEYS.FETCH_NEW_TRADING_ACCOUNTS],
            select: (data: any) => data,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
          filters={filters}
          columnsToPin={ACTIONS_COLUMNS_TO_PIN}
        />
      </Box>
      {recordForApproveReject && (
        <ApproveRejectTradingAccount
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={handleConfirmApproveReject}
        />
      )}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default TradingAccountsTable;
