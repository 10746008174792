import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { DepositsTableColumns } from './DepositsTableColumns';
import ApproveRejectDeposit from './ApproveRejectDeposit/index';
import { DEPOSIT_TRANSACTION_TYPE, DEPOSIT_STATUS } from '../../constants';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import messages from '../../messages';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import SuccessModal from '~/components/SuccessModal';
import TableFilters from '~/components/TableFilters';
import Box from '~app/components/Box';
import useFilters from 'v2s-ui-core/app/hooks/useFilters';
import { ACTIONS_COLUMNS_TO_PIN, FILTERS } from '~/config/constants';
import ViewDeposit from './ApproveRejectDeposit/ViewDeposit';
const DepositsTable: FC<{}> = () => {
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    showSuccessModal,
    status,
    onViewRecord,
    viewRecord,
    setViewRecord,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_DEPOSITS,
    API_URLS: API_URLS.APPROVE_REJECT_DEPOSIT,
    STATUS: DEPOSIT_STATUS,
    messages: messages,
  });

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchDeposits',
  });

  const memoizedColumns = useMemo(
    () =>
      DepositsTableColumns({
        onViewRecord,
        onApproveRecord,
        onRejectRecord,
      }),
    [],
  );

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'16px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>

      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_DEPOSITS,
            queryKeyProp: [QUERY_KEYS.FETCH_DEPOSITS],
            select: (data: any) => data,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
          filters={{
            transaction_type: DEPOSIT_TRANSACTION_TYPE.DEPOSIT,
            ...filters,
          }}
          columnsToPin={ACTIONS_COLUMNS_TO_PIN}
        />
      </Box>

      {recordForApproveReject && (
        <ApproveRejectDeposit
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
          status={status}
        />
      )}

      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />

      {viewRecord && (
        <ViewDeposit
          onCancel={() => {
            setViewRecord(null);
          }}
          recordToView={viewRecord}
        />
      )}
    </>
  );
};

export default DepositsTable;
