/* eslint-disable no-console */
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import useDocumentUpload, {
  TUploadDocMutationVars,
} from '../useBankDocumentUpload';
import SuccessModal from '~/components/SuccessModal';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useIsMobile } from '../useIsMobile';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import useBankDocumentUpload from '../useBankDocumentUpload';
import { theme } from '~/shell/theme/theme';
import { Link } from 'react-router-dom';
import { useLocaleContext } from '~app/Integrations/Localization';
import messages from '~/constants/messages';

interface FileUploadProps {
  onUploadSuccess?: (documentId: string) => void; // Callback to return the uploaded document ID
  disabled?: boolean;
  documentMappingId?: number | string; // Dynamic document mapping ID
  userId?: number;
  isBankUpload?: boolean;
}

const BankFileUpload: React.FC<FileUploadProps> = ({
  onUploadSuccess,
  disabled,
  documentMappingId,
  userId,
  isBankUpload = false,
}) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const location = useLocation();
  const { showToastNotification } = useShowToastNotifications();
  const isMobile = useIsMobile();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const { uploadDoc, isError, data } = useBankDocumentUpload({
    userId: userId,
    isBankUpload: isBankUpload,
    mutationOptions: {
      onSuccess: (response) => {
        setFileName(null);
        onUploadSuccess(response.data.id.toString());
        setIsSuccessModalOpen(true);
      },
      onError: (error) => {
        console.error('Error uploading document:', error);
        showToastNotification(`${error?.data.errors[0].detail}`, 'error');
      },
    },
    storeUploadDocKey: () => {
      // Optional: Store or cache document information if needed
    },
  });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileName(file.name);

    const payload: TUploadDocMutationVars = {
      file,
      fileName: file.name,
      documentMappigId: documentMappingId,
      // documentMappigId: documentMappingId,
    };

    uploadDoc(payload);
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        position='relative'
        gap={2}
      >
        <Button
          component='label'
          variant='outlined'
          disabled={disabled}
          sx={{
            textTransform: 'none',
            color: theme.palette.text.secondary,
            fontWeight: 'bold',
            bgcolor: theme.palette.success.light,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            height: '40px',

            '&:hover': {
              bgcolor: theme.palette.primary.dark,
              color: theme.palette.common.white,
            },
          }}
        >
          {isMobile ? (
            <UploadFileIcon />
          ) : (
            <Box display='flex' alignItems='center' gap={1}>
              <UploadFileIcon />
              <Typography variant='body2'>
                {formatMessage(messages.upload)}
              </Typography>
            </Box>
          )}

          <input type='file' hidden onChange={handleFileUpload} />
        </Button>

        {fileName && (
          <Typography
            variant='body2'
            position={'absolute'}
            top={isMobile ? '56px' : 'auto'}
            left={isMobile ? '0' : 'calc(100% + 16px)'}
            width={isMobile ? '100%' : 'auto'}
            color={isError ? 'error' : 'text.secondary'}
          >
            {isError
              ? 'Failed to upload.'
              : `${
                  fileName.length > 10
                    ? `${fileName.slice(0, 12)}...`
                    : fileName
                }`}
          </Typography>
        )}

        <Link
          to={data?.data?.latest_version?.original_doc_location}
          target='_blank'
        >
          {data?.data?.latest_version?.filename}
        </Link>
      </Box>
      <SuccessModal
        open={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        message={formatMessage(messages.uploadSuccessMessage)}
      />
    </>
  );
};

export default BankFileUpload;
