import * as yup from 'yup';
import messages from '../../messages';
export const MUTATION_KEYS = {
  APPROVE_REJECT_DEPOSIT: 'approveRejectDeposit',
  // GX_INJECT_MUTATION_KEY
} as const;

export const getApproveRejectSchema = (
  formatMessage: Function,
  action: string,
) =>
  yup.object().shape({
    remark: yup
      .string()
      .transform((value) => value.replace(/\s+/g, ''))
      .required(formatMessage(messages.remarkRequired))
      .matches(/^\S[a-zA-Z0-9\s]+$/, formatMessage(messages.remarkValidation))
      .matches(/^[^\s].*[^\s]$/, formatMessage(messages.remarkValidation))
      .min(4, formatMessage(messages.remarkMinLength))
      .max(100, formatMessage(messages.remarkMaxLength)),

    local_amount: yup
      .number()
      .required(formatMessage(messages.local_amount_required))
      .min(10, formatMessage(messages.local_amount_validation)),

    trading_amount: yup
      .number()
      .required(formatMessage(messages.amountRequired))
      .min(10, formatMessage(messages.tradingAmountValidation)),
  });
