import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogContentText,
  Grid,
  TextField,
  Button,
  Box,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from '~app/Integrations/Localization';
import { myKycMessages } from '../../myKycMessages';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
import { Dispatch, SetStateAction } from 'react';
import { ViewPOAFields } from './ViewPOAFields';
import { isDocTypePOA, isDocTypePOI } from './util';
import { RenderDocumentContent } from '~/components/ShowDoc';

const ViewKycDocModal = ({ document, onClose }: any) => {
  const {
    document_type = '',
    document_name = '',
    document_side = '',
    latest_version: documentData = {},
  } = document || {};
  const {
    original_doc_location: documentUrl = '',
    content_type: contentType = '',
    document_no = '',
    extension = '',
  } = documentData;

  const isMobile = useIsMobile();

  // const onClose = () => {
  //   setDocument((prev) => ({
  //     ...prev,
  //     open: false,
  //     documentDetail: null,
  //   }));
  // };

  return (
    <Dialog
      open={true}
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: isMobile ? '100vws' : '800px',
          margin: isMobile ? 1 : 0,
        },
      }}
    >
      <DialogTitle
        sx={{
          backgroundColor: blue[50],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>
          <FormattedMessage {...myKycMessages.kycDetails} />
        </Typography>
        <CloseButton onClose={onClose} />
      </DialogTitle>
      <DialogContent sx={{ marginTop: '16px' }}>
        <DialogContentText
          sx={{ borderBottom: '1px solid #e0e0e0', paddingBottom: '5px' }}
        >
          <Typography>
            <FormattedMessage {...myKycMessages.documentDetails} />
          </Typography>
        </DialogContentText>

        {/* <Grid
          item
          xs={12}
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
     
        </Grid> */}
        <Box
          display='flex'
          alignItems='center'
          justifyContent={'center'}
          gap={isMobile ? 0 : 4}
          // px={isMobile ? 0 : 2}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12}>
              <TextField
                size='small'
                label={<FormattedMessage {...myKycMessages.document_name} />}
                disabled
                value={document?.document_name || ''}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                size='small'
                label={<FormattedMessage {...myKycMessages.document_type} />}
                disabled
                value={document?.document_type || ''}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size='small'
                label={<FormattedMessage {...myKycMessages.fullName} />}
                disabled
                value={document?.user_detail?.full_name || ''}
                fullWidth
              />
            </Grid>

            {isDocTypePOA(document_type) ? (
              <ViewPOAFields user_details={document?.user_detail} />
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    label={<FormattedMessage {...myKycMessages.dob} />}
                    disabled
                    value={document?.user_detail?.date_of_birth || ''}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size='small'
                    label={<FormattedMessage {...myKycMessages.country} />}
                    disabled
                    fullWidth
                    value={document?.user_detail?.country?.countryEn || ''}
                  />
                </Grid>

                {isDocTypePOI(document_type, document_side) && (
                  <Grid item xs={12}>
                    <TextField
                      size='small'
                      label={<FormattedMessage {...myKycMessages.poi_number} />}
                      disabled
                      value={document_no || ''}
                      fullWidth
                    />
                  </Grid>
                )}
              </>
            )}
            <Grid item xs={12}>
              <TextField
                size='small'
                label={<FormattedMessage {...myKycMessages.expire_at} />}
                disabled
                value={document?.expire_at || 'Na'}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box>
            {/* <Typography>{document?.latest_version?.filename}</Typography> */}
            <RenderDocumentContent
              contentType={contentType}
              extension={extension}
              documentUrl={documentUrl}
            />
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} mt={'24px'}>
          <Button
            onClick={onClose}
            sx={{
              width: '100px',
            }}
            variant='outlined'
          >
            <FormattedMessage {...myKycMessages.close} />
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ViewKycDocModal;
