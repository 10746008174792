import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import {
  FormattedMessage,
  useLocaleContext,
} from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import { CloseButton } from '~/components/Actions/CloseButton';
import { KYC_STATUS_MESSAGE } from './constants';
import { useApproveRejectKyc } from '~/hooks/useApproveRejectKycAccount';
import messages from './messages';
import { isDocTypePOA, isDocTypePOI } from './util';
import { ViewPOAFields } from './ViewPOAFields';
import { DOCUMENT_STATUS } from '~/constants';
import { RenderDocumentContent } from '~/components/ShowDoc';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useIsMobile } from '~/hooks/useIsMobile';
import TruncatedHeader from '~/components/TruncatedHeader';
import YearPickCalender from '~/components/DOB_Calender';
import { getMaxDOBFromCurrentDate } from '~/utils';
import dayjs from 'dayjs';
import AsyncDropdown from '~app/components/AsyncDropdown';

// eslint-disable-next-line complexity
const ApproveRejectKyc = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
  onApproveRecord,
  onRejectRecord,
}: any) => {
  const {
    formik: {
      handleSubmit,
      handleChange,
      values,
      errors,
      touched,
      setFieldTouched,
      setFieldValue,
    },
    setStatus,
  } = useApproveRejectKyc({
    onConfirmApproveReject,
    recordForApproveReject,
  });
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const isMobile = useIsMobile();
  const { record = {} } = recordForApproveReject || {};

  const {
    document_type = '',
    document_name = '',
    document_side = '',
    latest_version: documentData = {},
  } = record || {};
  const {
    original_doc_location: documentUrl = '',
    content_type: contentType = '',

    extension = '',
  } = documentData;

  const handleApprove = () => {
    setStatus(DOCUMENT_STATUS.APPROVE);
    onApproveRecord(
      recordForApproveReject?.record,
      KYC_STATUS_MESSAGE.APPROVED,
    );
    handleSubmit();
  };

  const handleReject = () => {
    setStatus(DOCUMENT_STATUS.REJECT);
    onRejectRecord(recordForApproveReject?.record, KYC_STATUS_MESSAGE.REJECTED);
    handleSubmit();
  };

  return (
    <Dialog
      open={Boolean(recordForApproveReject)}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: isMobile ? '100vw' : '800px',
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        <FormattedMessage {...messages.kycModalTitle} />
        <CloseButton onClose={onCancel} />
      </DialogTitle>

      <DialogContent>
        <Box
          display='flex'
          justifyContent={'space-between'}
          alignItems='center'
          gap={isMobile ? 0 : 4}
          // bgcolor={'red'}
          // px={isMobile ? 0 : 2}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid container spacing={2} mt={1}>
            {isDocTypePOI(document_type, document_side) && (
              <Grid display={'flex'} alignItems={'center'} gap={1} item xs={12}>
                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                <Typography fontSize={'14px'}>
                  Please enter POI number to approve this document
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name='document_name'
                variant='outlined'
                label={<FormattedMessage {...messages.documentName} />}
                value={document_name || ''}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant='outlined'
                label={<FormattedMessage {...messages.document_type} />}
                value={document_type || ''}
                fullWidth
                size='small'
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                name='first_name'
                label={<FormattedMessage {...messages.first_name} />}
                value={values?.first_name || ''}
                fullWidth
                onChange={(e) => {
                  setFieldTouched('first_name', true);
                  handleChange(e);
                }}
                onBlur={(e) => setFieldTouched('first_name', true)}
                error={touched?.first_name && Boolean(errors?.first_name)}
                helperText={touched?.first_name && errors?.first_name}
                size='small'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                name='last_name'
                label={<FormattedMessage {...messages.last_name} />}
                value={values?.last_name || ''}
                fullWidth
                onChange={(e) => {
                  setFieldTouched('last_name', true);
                  handleChange(e);
                }}
                onBlur={(e) => setFieldTouched('last_name', true)}
                error={touched?.last_name && Boolean(errors?.last_name)}
                helperText={touched?.last_name && errors?.first_name}
                size='small'
              />
            </Grid>
            <Grid item xs={12}>
              <AsyncDropdown
                // @ts-ignore
                value={values?.country || null}
                onChange={(e, value) =>
                  handleChange({
                    target: {
                      name: 'country',
                      value: value,
                    },
                  })
                }
                queryProps={{
                  resource: '/countries',
                  queryKeyProp: ['GET_COUNTRIES'],
                  select: (data: any) => data.data,
                }}
                InputProps={{
                  label: <FormattedMessage {...messages.country} />,
                  required: true,
                  name: 'country',
                  placeholder: 'Select',

                  onBlur: () => {
                    setFieldTouched('country', true);
                  },
                  error: !!(errors.country && touched.country),
                  // @ts-ignore
                  helperText: touched.country && errors.country,
                }}
                getOptionLabel={(option) => option?.countryEn}
                getOptionKey={(option) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>

            {isDocTypePOA(document_type) ? (
              <ViewPOAFields
                values={values}
                errors={errors}
                touched={touched}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                handleChange={handleChange}
              />
            ) : (
              <>
                <Grid item xs={12}>
                  <YearPickCalender
                    value={values.date_of_birth || null}
                    onChange={(date: Date) => {
                      if (date?.toString() === 'Invalid Date') {
                        setFieldValue('date_of_birth', null);
                      }
                      setFieldValue(
                        'date_of_birth',
                        date?.toISOString()?.split('T')[0],
                      );
                    }}
                    label={formatMessage(messages.dob)}
                    maxDate={dayjs(getMaxDOBFromCurrentDate(new Date()))}
                    onBlur={() => {
                      setFieldTouched('date_of_birth', true);
                    }}
                    error={!!(errors?.date_of_birth && touched?.date_of_birth)}
                    helperText={touched?.date_of_birth && errors?.date_of_birth}
                  />
                </Grid>

                {isDocTypePOI(document_type, document_side) && (
                  <Grid item xs={12}>
                    <TextField
                      size='small'
                      label={<FormattedMessage {...messages.poi_number} />}
                      name='document_no'
                      onChange={(e) => {
                        setFieldTouched('document_no', true);
                        handleChange({
                          target: {
                            name: 'document_no',
                            value: e.target.value.toUpperCase(),
                          },
                        });
                      }}
                      fullWidth
                      onBlur={() => setFieldTouched('document_no', true)}
                      value={values.document_no || ''}
                      error={touched.document_no && Boolean(errors.document_no)}
                      helperText={touched.document_no && errors.document_no}
                    />
                  </Grid>
                )}
              </>
            )}
            {recordForApproveReject?.record.document_type !== 'Others' && (
              <Grid item xs={12}>
                <YearPickCalender
                  value={values.expire_at || null}
                  onChange={(date: Date) => {
                    setFieldValue(
                      'expire_at',
                      date?.toISOString()?.split('T')[0],
                    );
                  }}
                  label={formatMessage(messages.expire_at)}
                  onBlur={() => {
                    setFieldTouched('expire_at', true);
                  }}
                  minDate={dayjs()}
                  maxDate={dayjs().add(20, 'year')}
                  error={!!(errors?.expire_at && touched?.expire_at)}
                  helperText={touched?.expire_at && errors?.expire_at}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                name='remark'
                variant='outlined'
                label={<FormattedMessage {...messages.addRemark} />}
                onChange={handleChange}
                value={values.remark}
                size='small'
                required
                error={touched.remark && Boolean(errors.remark)}
                helperText={touched.remark && errors.remark}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={1}>
            <RenderDocumentContent
              contentType={contentType}
              extension={extension}
              documentUrl={documentUrl}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: 3 }}>
        <Button variant='contained' onClick={handleApprove}>
          <FormattedMessage {...messages.approve} />
        </Button>
        <Button variant='contained' onClick={handleReject}>
          <FormattedMessage {...messages.reject} />
        </Button>
        <Button variant='outlined' onClick={onCancel}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRejectKyc;
