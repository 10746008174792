/* eslint-disable complexity */
import { Autocomplete, Box, Button, Grid, TextField } from '@mui/material';
import { blue } from '@mui/material/colors';
import dayjs from 'dayjs';
import AsyncDropdown from 'v2s-ui-core/app/components/AsyncDropdown';
import {
  FormattedMessage,
  useLocaleContext,
} from 'v2s-ui-core/app/Integrations/Localization';
import { isMobile } from 'v2s-ui-core/app/utils';
import { NumberInput } from '~/components/Input/NumberInput';
import { theme } from '~/shell/theme/theme';
import { getMaxDOBFromCurrentDate } from '~/utils';
import YearPickCalender from '~/components/DOB_Calender/index';
import SuccessMessageModal from '~/components/SuccessMessageModal';
import {
  GENDERS,
  IMyProfileTabProps,
} from '../PersonalDetails/constants/index';
import messages from '../messages';
import usePersonalInfoForm from './hooks/usePersonalInfoForm';
import FormSkeleton from '~/components/Skeleton/FormSkeleton';
import { API_URLS } from '../api/config';
import { useIsMobile } from '~/hooks/useIsMobile';

const PersonalInfo = ({ onTabChange, handleViewInfo }: IMyProfileTabProps) => {
  const isMobile = useIsMobile();
  const {
    handleChange,
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    isResponseSuccess,
    handleCloseSuccessModal,
    setFieldTouched,
    disabledPersonalFormFields,
    isLoading,
    isUpdateLoading,
  } = usePersonalInfoForm({ onTabChange, handleViewInfo });

  const handleTabSubmit = () => {
    handleSubmit();
  };

  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  if (isLoading || isUpdateLoading) {
    return (
      <Box>
        <FormSkeleton />
      </Box>
    );
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      paddingX={2}
      paddingBottom={5}
      paddingTop={2}
    >
      <Grid container columnSpacing={{ md: 2, xs: 2 }}>
        <Grid item xs={12} sm={4} mb={{ xs: 1, md: 1 }} mt={{ xs: 2, md: 1 }}>
          <AsyncDropdown
            // @ts-ignore
            value={values?.preferred_language || null}
            onChange={(e, value) =>
              handleChange({
                target: {
                  name: 'preferred_language',
                  value: value,
                },
              })
            }
            queryProps={{
              resource: '/languages',
              queryKeyProp: ['GET_LANGUAGE'],
              select: (data: any) => data.data,
            }}
            InputProps={{
              label: 'language',
              required: true,
              name: 'language',
              placeholder: 'Select ',
              onBlur: () => {
                setFieldTouched('preferred_language', true);
              },
              error: !!(
                errors.preferred_language && touched.preferred_language
              ),
              helperText: errors?.preferred_language?.toString(),
            }}
            getOptionLabel={(option) => option.language}
            getOptionKey={(option) => option.language}
            isOptionEqualToValue={(option, value) =>
              option.language === value.language
            }
          />
        </Grid>

        <Grid item xs={12} sm={4} mb={{ xs: 1, md: 1 }} mt={{ xs: 2, md: 1 }}>
          <AsyncDropdown
            // @ts-ignore
            value={values?.user_type_dd || null}
            onChange={(e, value) =>
              handleChange({
                target: {
                  name: 'user_type',
                  value: value,
                },
              })
            }
            queryProps={{
              resource: API_URLS.GET_USER_TYPE,
              queryKeyProp: ['getTradersType'],
              select: (data: any) => data.data,
            }}
            InputProps={{
              label: 'User Type',
              required: true,
              name: 'language',
              placeholder: 'Select ',
              onBlur: () => {
                setFieldTouched('user_type', true);
              },
              error: !!(errors.user_type && touched.user_type),
              helperText: touched.user_type && errors?.user_type?.toString(),
            }}
            getOptionLabel={(option) => option?.type}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={4} mt={isMobile ? 2 : 2} mb={isMobile ? 1 : 0}>
          <Autocomplete
            id='gender'
            size='small'
            value={GENDERS.find((data) => data.value === values.gender) || null}
            options={GENDERS}
            autoHighlight
            disableClearable
            clearIcon={false}
            onChange={(event, value) => setFieldValue('gender', value.value)}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box
                  key={key}
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...optionProps}
                >
                  {option.label}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label='Gender'
                placeholder='Gender'
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                value={GENDERS.find((data) => data.value === values.gender)}
                onBlur={() => {
                  setFieldTouched('gender', true);
                }}
                error={!!(errors?.gender && touched?.gender)}
                helperText={touched?.gender && errors?.gender}
                onChange={handleChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='First name'
            placeholder='Enter first name'
            fullWidth
            margin='normal'
            name='first_name'
            value={values.first_name}
            onBlur={() => {
              setFieldTouched('first_name', true);
            }}
            error={!!(errors?.first_name && touched?.first_name)}
            helperText={touched?.first_name && errors?.first_name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='Last name'
            placeholder='Enter last name'
            margin='normal'
            name='last_name'
            fullWidth
            value={values.last_name}
            onBlur={() => {
              setFieldTouched('last_name', true);
            }}
            error={!!(errors?.last_name && touched?.last_name)}
            helperText={touched?.last_name && errors?.last_name}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4} mt={2} mb={1}>
          <YearPickCalender
            value={values.date_of_birth || null}
            onChange={(date: Date) => {
              if (date?.toString() === 'Invalid Date') {
                setFieldValue('date_of_birth', null);
              }
              setFieldValue(
                'date_of_birth',
                date?.toISOString()?.split('T')[0],
              );
            }}
            label='Date of Birth'
            maxDate={dayjs(getMaxDOBFromCurrentDate(new Date()))}
            onBlur={() => {
              setFieldTouched('date_of_birth', true);
            }}
            error={!!(errors?.date_of_birth && touched?.date_of_birth)}
            helperText={touched?.date_of_birth && errors?.date_of_birth}
            textColor={
              disabledPersonalFormFields
                ? `${theme?.palette?.text?.disabled}`
                : `${theme?.palette?.text?.primary}`
            }
          />
        </Grid>
        {values?.poi_number && (
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              autoComplete='off'
              label='POI Number'
              placeholder='POI Number'
              margin='normal'
              fullWidth
              name='poi_number'
              disabled
              value={values?.poi_number}
            />
          </Grid>
        )}
        <Grid item container columnSpacing={{ md: 2, xs: 2 }}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              size='small'
              disabled={true}
              autoComplete='off'
              label='Email'
              placeholder='Email'
              fullWidth
              margin='normal'
              name='email'
              value={values.email}
              onBlur={() => {
                setFieldTouched('email', true);
              }}
              error={!!(errors?.email && touched?.email)}
              helperText={touched?.email && errors?.email}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberInput
              required
              size='small'
              autoComplete='off'
              label='Phone number'
              placeholder='Phone number'
              margin='normal'
              fullWidth
              name='phone_number'
              value={values.phone_number}
              onBlur={() => {
                setFieldTouched('phone_number', true);
              }}
              error={!!(errors?.phone_number && touched?.phone_number)}
              helperText={touched?.phone_number && errors?.phone_number}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} mt={isMobile ? 0 : 0}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='Address line 1'
            placeholder='Address line 1'
            margin='normal'
            fullWidth
            multiline
            name='address_line1'
            value={values.address_line1}
            onBlur={() => {
              setFieldTouched('address_line1', true);
            }}
            error={!!(errors?.address_line1 && touched?.address_line1)}
            helperText={touched?.address_line1 && errors?.address_line1}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            size='small'
            autoComplete='off'
            label='Address line 2'
            required
            multiline
            placeholder='Address line 2'
            margin='normal'
            fullWidth
            name='address_line2'
            value={values.address_line2}
            onBlur={() => {
              setFieldTouched('address_line2', true);
            }}
            error={!!(errors?.address_line2 && touched?.address_line2)}
            helperText={touched?.address_line2 && errors?.address_line2}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='City/Town'
            placeholder='City/Town'
            margin='normal'
            name='city_town'
            fullWidth
            value={values.city_town}
            onBlur={() => {
              setFieldTouched('city_town', true);
            }}
            error={!!(errors?.city_town && touched?.city_town)}
            helperText={touched?.city_town && errors?.city_town}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='State/province'
            placeholder='State/province'
            margin='normal'
            fullWidth
            name='state_province'
            value={values.state_province}
            onBlur={() => {
              setFieldTouched('state_province', true);
            }}
            error={!!(errors?.state_province && touched?.state_province)}
            helperText={touched?.state_province && errors?.state_province}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4} mt={isMobile ? 2 : 2}>
          <AsyncDropdown
            // @ts-ignore
            value={values?.country || null}
            onChange={(e, value) =>
              handleChange({
                target: {
                  name: 'country',
                  value: value,
                },
              })
            }
            queryProps={{
              resource: '/countries',
              queryKeyProp: ['GET_COUNTRIES'],
              select: (data: any) => data.data,
            }}
            InputProps={{
              label: formatMessage(messages.country),
              required: true,
              name: 'countryEn',
              placeholder: 'Select',

              onBlur: () => {
                setFieldTouched('country', true);
              },
              error: !!(errors.country && touched.country),
              helperText: touched.country && errors.country,
            }}
            getOptionLabel={(option) => option?.countryEn}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>
        <Grid item xs={12} sm={4} mt={isMobile ? 1 : 0}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='Zip/Postal code'
            placeholder='Zip/Postal code'
            margin='normal'
            fullWidth
            name='zip_postal_code'
            value={values.zip_postal_code}
            onBlur={() => {
              setFieldTouched('zip_postal_code', true);
            }}
            error={!!(errors?.zip_postal_code && touched?.zip_postal_code)}
            helperText={touched?.zip_postal_code && errors?.zip_postal_code}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='Nationality'
            placeholder='Nationality'
            margin='normal'
            fullWidth
            name='nationality'
            value={values.nationality}
            onBlur={() => {
              setFieldTouched('nationality', true);
            }}
            error={!!(errors?.nationality && touched?.nationality)}
            helperText={touched?.nationality && errors?.nationality}
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Box
        mt={2}
        display={'flex'}
        justifyContent={isMobile ? 'center' : 'flex-start'}
      >
        <Button
          variant='contained'
          size='medium'
          sx={{ width: '113px' }}
          onClick={() => handleTabSubmit()}
        >
          Update
        </Button>
      </Box>

      {!!isResponseSuccess && (
        <SuccessMessageModal
          message={formatMessage(messages.personalInfoSuccessMessage)}
          handleOk={handleCloseSuccessModal}
          open={isResponseSuccess}
        />
      )}
    </Box>
  );
};

export default PersonalInfo;
