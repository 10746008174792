import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { TransactionHistory } from '../types';
import DateFilterComponent from '~/components/DateFIlter';
import TruncatedHeader from '~/components/TruncatedHeader';

export const WalletHistoryColumn = (): ColumnDef<TransactionHistory>[] => [
  {
    accessorKey: 'id',
    header: 'Transaction ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'transaction_type',
    cell: ({ row }: any) =>
      row.original?.user_transaction?.payment_method?.transaction_type,
    header: 'Transaction Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'account_type',
    cell: ({ row }: any) => row.original?.user_transaction?.account_type,
    header: 'Account Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_currency',
    cell: ({ row }: any) => row.original?.user_transaction?.trading_currency,
    header: 'Trading Currency',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_amount',
    cell: ({ row }: any) => row.original?.user_transaction?.trading_amount,
    header: 'Trading Amount',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'balance',
    header: () => (
      <TruncatedHeader
        text='Balance After Transaction'
        maxLength={14}
        useTooltip
      />
    ),
    enableColumnFilter: true,
  },
  {
    accessorKey: 'status',
    cell: ({ row }: any) => row.original?.user_transaction?.status,
    header: 'Transaction Status',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'requested_date',
    cell: ({ row }: any) => row.original?.user_transaction?.requested_date,
    header: 'Requested Date',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'payment_method',
    cell: ({ row }: any) =>
      row.original?.user_transaction?.payment_method?.name,
    header: 'Payment Method',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'payment_reference',
    cell: ({ row }: any) => row.original?.user_transaction?.payment_reference,
    header: 'Payment Reference',
    enableColumnFilter: true,
  },
  {
    header: 'Created At',
    enableColumnFilter: true,
    accessorKey: 'created_at',
    cell: ({ row }: any) => row.original?.user_transaction?.created_at,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },

  {
    header: 'Updated At',
    enableColumnFilter: true,
    accessorKey: 'updated_at',
    cell: ({ row }: any) => row.original?.user_transaction?.updated_at,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    header: 'Created By',
    enableColumnFilter: true,
    accessorKey: 'created_by',
    cell: ({ row }: any) => row.original?.user_transaction?.created_by?.email,
  },
  {
    header: 'Updated By',
    enableColumnFilter: true,
    accessorKey: 'updated_by',
    cell: ({ row }: any) => row.original?.user_transaction?.updated_by?.email,
  },
];
