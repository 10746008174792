import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  firstNameRequired: {
    defaultMessage: 'First name is required',
    id: 'schema.firstNameRequired',
  },
  firstNameTooLong: {
    defaultMessage: 'First name must be at most 255 characters',
    id: 'schema.firstNameTooLong',
  },
  lastNameRequired: {
    defaultMessage: 'Last name is required',
    id: 'schema.lastNameRequired',
  },
  lastNameTooLong: {
    defaultMessage: 'Last name must be at most 255 characters',
    id: 'schema.lastNameTooLong',
  },
  enterNewPassword: {
    id: 'schema.enterNewPassword',
    defaultMessage: 'Enter new password',
  },
  emailRequired: {
    defaultMessage: 'Email is required',
    id: 'schema.emailRequired',
  },
  invalidEmail: {
    defaultMessage: 'Enter a valid email address',
    id: 'schema.invalidEmail',
  },
  emailTooLong: {
    defaultMessage: 'Email must be at most 255 characters',
    id: 'schema.emailTooLong',
  },
  noCountrySelected: {
    defaultMessage: 'Please select at least one country',
    id: 'schema.noCountrySelected',
  },
  invalidPhoneNumber: {
    defaultMessage:
      "Phone number must be entered in the format: '+999 9999999999'. Up to 16 digits allowed.",
    id: 'schema.invalidPhoneNumber',
  },
  phoneNumberTooLong: {
    defaultMessage: 'Phone number must be at most 16 digits',
    id: 'schema.phoneNumberTooLong',
  },
  phoneNumberRequired: {
    defaultMessage: 'Phone number is required',
    id: 'schema.phoneNumberRequired',
  },
  referralRequired: {
    defaultMessage: 'Referral is required',
    id: 'schema.referralRequired',
  },
  referralTooLong: {
    defaultMessage: 'Referral must be at most 255 characters',
    id: 'schema.referralTooLong',
  },
  metaCommentRequired: {
    defaultMessage: 'Meta comment is required',
    id: 'schema.metaCommentRequired',
  },
  metaTooLong: {
    defaultMessage: 'Meta comment must be at most 255 characters',
    id: 'schema.metaTooLong',
  },
  addressTooLong: {
    defaultMessage: 'Address must be at most 100 characters .',
    id: 'schema.referralTooLong',
  },
  passwordRequired: {
    defaultMessage: 'Password is required',
    id: 'schema.passwordRequired',
  },
  invalidPassword: {
    defaultMessage:
      'Password must be at least 8 characters, contain at least one uppercase letter, one lowercase letter, one number and one special character.',
    id: 'schema.invalidPassword',
  },
  tncNotAgreed: {
    defaultMessage: 'Please click to agree the terms & conditions.',
    id: 'schema.tncNotAgreed',
  },
  fieldRequired: {
    defaultMessage: 'This field is required',
    id: 'schema.fieldRequired',
  },
  localCurrencyRequired: {
    defaultMessage: 'Local currency is required',
    id: 'schema.localCurrencyRequired',
  },
  cryptoCurrencyRequired: {
    defaultMessage: 'Crypto currency is required',
    id: 'schema.cryptoCurrencyRequired',
  },
  mt5AccountRequired: {
    defaultMessage: 'MT5 Account is required',
    id: 'schema.mt5AccountRequired',
  },
  paymentMethodRequired: {
    defaultMessage: 'Payment method is required',
    id: 'schema.paymentMethodRequired',
  },
  tradingAmountRequired: {
    defaultMessage: 'Trading amount is required',
    id: 'schema.tradingAmountRequired',
  },
  localAmountRequired: {
    defaultMessage: ' Amount is required',
    id: 'schema.localAmountRequired',
  },
  localAmountMin: {
    defaultMessage: ' Amount must be a positive number',
    id: 'schema.localAmountMin',
  },
  bankAccountRequired: {
    defaultMessage: 'Bank Account is required',
    id: 'schema.bankAccountRequired',
  },
  tradingCurrencyRequired: {
    defaultMessage: 'Trading currency is required',
    id: 'schema.tradingCurrencyRequired',
  },
  noteTooLong: {
    defaultMessage: 'Note must be at most 255 characters',
    id: 'schema.noteTooLong',
  },
  noteRequired: {
    defaultMessage: 'Note is required',
    id: 'schema.noteRequired',
  },
  languageRequired: {
    defaultMessage: 'Language is required',
    id: 'schema.languageRequired',
  },
  addressLine1Required: {
    defaultMessage: 'Address line 1 is required',
    id: 'schema.addressLine1Required',
  },
  addressLine2Required: {
    defaultMessage: 'Address line 2 is required',
    id: 'schema.addressLine2Required',
  },
  cityTownRequired: {
    defaultMessage: 'City/Town is required',
    id: 'schema.cityTownRequired',
  },
  stateProvinceRequired: {
    defaultMessage: 'State/Province is required',
    id: 'schema.stateProvinceRequired',
  },
  zipPostalCodeRequired: {
    defaultMessage: 'Zip/Postal code is required',
    id: 'schema.zipPostalCodeRequired',
  },
  genderRequired: {
    defaultMessage: 'Gender is required',
    id: 'schema.genderRequired',
  },
  nationalityRequired: {
    defaultMessage: 'Nationality is required',
    id: 'schema.nationalityRequired',
  },
  dateOfBirthRequired: {
    defaultMessage: 'Date of birth is required',
    id: 'schema.dateOfBirthRequired',
  },
  preferredLanguageRequired: {
    defaultMessage: 'Preferred language is required',
    id: 'schema.preferredLanguageRequired',
  },
  fullNameRequired: {
    defaultMessage: 'Full name is required',
    id: 'schema.fullNameRequired',
  },
  leverageRequired: {
    defaultMessage: 'Leverage is required',
    id: 'schema.leverageRequired',
  },
  toAccountRequired: {
    id: 'schema.toAccountRequired',
    defaultMessage: 'To account field is required.',
  },
  fromAccountRequired: {
    id: 'schema.fromAccountRequired',
    defaultMessage: 'From account field is required.',
  },
  compDateOfBirthRequired: {
    id: 'schema.compDateOfBirthRequired',
    defaultMessage: 'Company Start Date is required',
  },
  compNameRequired: {
    id: 'schema.compNameRequired',
    defaultMessage: 'Company Name is required',
  },
  companyLicenseRequired: {
    id: 'schema.companyLicenseRequired',
    defaultMessage: 'Company License is required',
  },
  transferTypeRequired: {
    id: 'schema.transferTypeRequired',
    defaultMessage: 'Transfer type is required',
  },
  cryptoAddRequired: {
    defaultMessage: 'Crypto Account address is required',
    id: 'schema.cryptoAddRequired',
  },
  oldPasswordRequired: {
    defaultMessage: 'Old Password is required',
    id: 'schema.passwordRequired',
  },
  invalidOldPassword: {
    id: 'schema.invalidPassword',
    defaultMessage: 'Old Password must be at least 8 characters',
  },
  roleRequired: {
    defaultMessage: 'Role is required',
    id: 'schema.roleRequired',
  },
  roleTooLong: {
    defaultMessage: 'Role must be at most 15 characters',
    id: 'schema.roleTooLong',
  },
  actionRequired: {
    defaultMessage: 'Action is required',
    id: 'schema.actionRequired',
  },
  actionTooLong: {
    defaultMessage: 'Action must be at most 15 characters',
    id: 'schema.actionTooLong',
  },
  permissionNameRequired: {
    defaultMessage: 'Permission name is required',
    id: 'schema.permissionNameRequired',
  },
  permissionTooLong: {
    defaultMessage: 'Permission must be at most 50 characters',
    id: 'schema.permissionTooLong',
  },

  firstNameMinLength: {
    defaultMessage: 'First name should be at least 3 characters',
    id: 'schema.firstNameMinLength',
  },

  lastNameMinLength: {
    defaultMessage: 'Last name should be at least 3 characters',
    id: 'schema.lastNameMinLength',
  },

  phoneNumberMinLength: {
    defaultMessage: 'Phone number should be at least 8 digits',
    id: 'schema.phoneNumberMinLength',
  },

  addressTooShort: {
    defaultMessage: 'Address must be at least 3 characters long.',
    id: 'schema.referralTooLong',
  },

  loginInvalidPassword: {
    defaultMessage:
      'Your password must be at least 8 characters long and include uppercase, lowercase, a number, and a special character',
    id: 'schema.invalidPassword',
  },

  localTransferAmountRequired: {
    defaultMessage: 'Please enter the transfer amount.',
    id: 'schema.localTransferAmountRequired',
  },
  localAmountDepositRequired: {
    defaultMessage: 'Please enter the amount to deposit.',
    id: 'schema.localAmountDepositRequired',
  },
  localDepositAmountMin: {
    defaultMessage: 'The minimum deposit amount is 10 USD.',
    id: 'schema.localAmountMin',
  },

  noteTooShort: {
    defaultMessage: 'The note must have at least 8 characters.',
    id: 'schema.noteTooShort',
  },

  cityTooShort: {
    id: 'schema.cityTooShort',
    defaultMessage: 'City/Town name should be minimum 3 character',
  },
  cityTooLong: {
    id: 'schema.cityTooLong',
    defaultMessage: 'City/Town name must be at most 30 character',
  },

  stateTooShort: {
    id: 'schema.stateTooShort',
    defaultMessage: 'State name should be minimum 2 character',
  },
  stateTooLong: {
    id: 'schema.stateTooShort',
    defaultMessage: 'State name must be at most 30 character',
  },

  pinCodeTooShort: {
    id: 'schema.pinCodeTooShort',
    defaultMessage: 'Zip/Postal code should be minimum 5 character',
  },
  pinCodeTooLong: {
    id: 'schema.pinCodeTooLong',
    defaultMessage: 'Zip/Postal code must be at most 30 character',
  },

  nationalityTooShort: {
    defaultMessage: 'Nationality should be minimum 2 character',
    id: 'schema.nationalityTooShort',
  },
  nationalityTooLong: {
    id: 'schema.nationalityTooLong',
    defaultMessage: 'Nationality must be at most 30 character',
  },

  compNameTooLong: {
    defaultMessage: 'Company Name must be at most 100 characters',
    id: 'schema.compNameTooLong',
  },
  compAddr1TooShort: {
    id: 'schema.compAddr1TooShort',
    defaultMessage: 'Company Address Line 1 should be minimum 3 character',
  },
  compAddr1TooLong: {
    id: 'schema.compAddr1TooLong',
    defaultMessage: 'Company Address Line 1 must be at most 50 characters',
  },
  compAddr2TooShort: {
    id: 'schema.compAddr2TooShort',
    defaultMessage: 'Company Address Line 2 should be minimum 3 character',
  },
  compAddr2TooLong: {
    id: 'schema.compAddr2TooLong',
    defaultMessage: 'Company Address Line 2 must be at most 50 characters',
  },
  companyLicenseTooShort: {
    id: 'schema.companyLicenseTooShort',
    defaultMessage: 'Company License should be minimum 3 character',
  },
  companyLicenseTooLong: {
    id: 'schema.companyLicenseTooLong',
    defaultMessage: 'Company License must be at most 50 characters',
  },

  messageRequired: {
    defaultMessage: ' Message is required.',
    id: 'schema.messageRequired',
  },
  subjectRequired: {
    defaultMessage: ' Subject is required.',
    id: 'schema.subjectRequired',
  },
  invalidField: {
    id: 'schema.invalidField',
    defaultMessage:
      'This field cannot contain special characters or numbers. Please enter only alphabetic characters',
  },

  tradingAmountMin: {
    id: 'schema.tradingAmountMin',
    defaultMessage: 'Conversion amount should be at least 10 USD',
  },
  depositAmountMin: {
    id: 'schema.depositAmountMin',
    defaultMessage: 'Deposit amount should be at least 10 USD',
  },
  documentRequired: {
    id: 'bankAccounts.documentRequired',
    defaultMessage: 'Please upload the payment receipt.',
  },
  withdrawalAmountMin: {
    defaultMessage: ' Amount should be minimum 10 USD',
    id: 'schema.localAmountMin',
  },
  invalidDate: {
    defaultMessage: 'Please enter a valid date.',
    id: 'schema.invalidDate',
  },
  dobCantBeFuture: {
    id: 'schema.dobCantBeFuture',
    defaultMessage: 'Date of birth cannot be from the future.',
  },
  companyEstablishedCantBeFuture: {
    id: 'schema.companyEstablishedCantBeFuture',
    defaultMessage: 'Company established date cannot be from the future.',
  },
  addressLine2Invalid: {
    defaultMessage: 'Please enter valid address.',
    id: 'schema.addressLine2Required',
  },
  invalidAddress: {
    defaultMessage: `Please use only letters, numbers, spaces, and special characters like " , \ - / # ' \" ( ) . [ ].`,
    id: 'schema.invalidAddress',
  },
  ageMustBe18: {
    id: 'schema.ageMustBe18',
    defaultMessage: 'Please select 18 years or older to use this service.',
  },
  invalidNote: {
    id: 'schema.invalidNote',
    defaultMessage: 'Please enter valid note.',
  },
  userTypeRequired: {
    id: 'schema.userTypeRequired',
    defaultMessage: 'Please select user type.',
  },
});
