import { useMemo } from 'react';
import { QueryDetails } from './types';
import { QUERY_KEYS } from './constants';
import ApiServices from '../../api';
import {
  TDocumentTypeList,
  TDocumentTypeListResponse,
  TUserDocumentsResponse,
} from '../../config/types';

const { GET_DOC_TYPES_LIST, GET_UPLOADED_DOC_LIST } = QUERY_KEYS;

export const useQueryFactory = <TData, TError = Error>() => {
  const queryDetails = useMemo<QueryDetails<TData, TError>>(
    () => ({
      [GET_DOC_TYPES_LIST]: () => ({
        queryKey: [GET_DOC_TYPES_LIST],
        queryFn: () => ApiServices.getDocTypesList<TDocumentTypeListResponse>(),
      }),
      [GET_UPLOADED_DOC_LIST]: (id: number) => ({
        queryKey: [GET_UPLOADED_DOC_LIST],
        queryFn: () =>
          ApiServices.getUploadedDocList<TUserDocumentsResponse>(id),
      }),
    }),
    [],
  );

  return queryDetails;
};
