import { useMemo } from 'react';
import ApiServices from '../../api';
import { MUTATION_KEYS } from './constants';
import { MutationDetails } from './types';

const useMutationFactory = <TData, TError = Error>() => {
  const { FETCH_IB_AGREEMENT, POST_IB_AGREEMENT } = MUTATION_KEYS;
  const mutationDetails = useMemo<MutationDetails<TData, TError>>(
    () => ({
      [FETCH_IB_AGREEMENT]: () => ({
        mutationKey: [FETCH_IB_AGREEMENT],
        mutationFn: (payload: any) =>
          ApiServices.fetchIbAgreement<TData>(payload),
      }),
      [POST_IB_AGREEMENT]: () => ({
        mutationKey: [POST_IB_AGREEMENT],
        mutationFn: (payload: any) =>
          ApiServices.fetchIbAgreement<TData>(payload),
      }),
    }),
    [],
  );
  return mutationDetails;
};

export default useMutationFactory;
