/* eslint-disable max-params */
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import FullPageLoader from '~/components/FullPageLoader';
import SuccessMessageModal from '~/components/SuccessMessageModal';
import { TDocumentsTypes } from '~/config/types';
import { useKycApproveRejectActions } from '~/hooks/useApproveRejectActions';
import { fileToBase64 } from '~/utils';
import { API_URLS } from '../../api/config';
import messages from '../../components/ApproveRejectModal/messages';
import { FileStateItem } from '../../config/types';
import { UPLOAD_FILE_TYPE } from '../../constants';
import useUploadKycDocument from '../../hooks/usePostKyc';
import { myKycMessages } from '../../myKycMessages';
import ApproveRejectKyc from '../ApproveRejectModal/ApproveRejectModal';
import { KYC_STATUS_MESSAGE } from '../ApproveRejectModal/constants';
import DocumentFields from './DocumentFields';
import SuccessModal from '~/components/SuccessModal';
import ViewKycDocModal from '../ViewKyc/ViewKycDoc';
import OthersDocumentFields from './OthersFields';
import Instructions from './Instruction';
import AdditionalFields from './AdditonalFields';
import { disableBulkKycBtn } from '../../utils';
import { useIsMobile } from '~/hooks/useIsMobile';

const MyKycLayout = () => {
  const [documents, setDocuments] = useState<TDocumentsTypes | undefined>(
    undefined,
  );
  const [isEditSuccess, setIsEditSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useIsMobile();
  // const [isShowSuccess, setIsShowSuccess] = useState(false);
  const {
    formik,
    isLoading: isUploadDocLoader,
    uploadedDoc,
    isGetDocLoader,
    isShowSuccess,
    uploadedFiles,
    setUploadedFiles,
    onCloseSuccessModal,
  } = useUploadKycDocument({
    setDocuments,
    isEditSuccess,
    setIsEditSuccess,
  });
  const {
    recordForApproveReject,
    onApproveRecord,
    onApproveRejectRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccess,
    showSuccessModal,
    successMessage,
    onSuccessClose,
    onViewRecord,
    viewRecord,
    setViewRecord,
  } = useKycApproveRejectActions({
    QUERY_KEYS: `getUploadedDocList`,
    API_URLS: API_URLS.APPROVE_REJECT_KYC,
    STATUS: KYC_STATUS_MESSAGE,
    user: false,
    messages: messages,
  });

  const onFileSelect = async (
    file: File,
    key: string,
    dropDownValue: any,
    docType: string,
    index?: number,
    user_document_id?: number,
  ) => {
    const base64Content = await fileToBase64(file);

    setUploadedFiles((prevState) => {
      const documentMappingId =
        key === UPLOAD_FILE_TYPE.BACK
          ? dropDownValue.documentSide.BackId
          : key === UPLOAD_FILE_TYPE.OTHER
          ? dropDownValue?.id
          : dropDownValue.documentSide.FrontId;

      const newFileObj = {
        document_mapping_id: documentMappingId,
        content: base64Content,
        file_name: file.name,
        type: key,
        docType,
        user_document_id: user_document_id || 0,
        document_type_mapping: documentMappingId,
      };

      const existingDocTypeIndex = prevState.findIndex(
        (item) =>
          item.docType === docType &&
          item.document_mapping_id === documentMappingId &&
          item.type === key,
      );

      let updatedState;

      if (existingDocTypeIndex !== -1) {
        updatedState = prevState.map((item, index) =>
          index === existingDocTypeIndex
            ? {
                ...item,
                content: base64Content,
                file_name: file.name,
              }
            : item,
        );
      } else {
        const hasSameDocTypeDifferentMapping = prevState.some(
          (item) =>
            item.docType === docType &&
            item.type === key &&
            item.document_mapping_id !== documentMappingId,
        );

        if (hasSameDocTypeDifferentMapping) {
          updatedState = prevState.filter((item) => item.docType !== docType);
        } else {
          updatedState = [...prevState];
        }

        updatedState.push(newFileObj);
      }

      const filteredState = updatedState.filter(
        (item) => Object.keys(item).length > 0,
      );

      formik.setFieldValue('bulk_files', filteredState);
      return filteredState;
    });
  };

  function filterByDocType(docType: string): Array<any> {
    return uploadedDoc?.data.filter((item) => item.document_type === docType);
  }
  if (isGetDocLoader) {
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <FullPageLoader open={isUploadDocLoader || isLoading} />
      <Box paddingTop='17px' paddingBottom={{ md: 4, xs: 2 }}>
        <Box className='header'>
          <Typography variant='h5' color={'main'} gutterBottom>
            {!!uploadedDoc?.data.length ? (
              <FormattedMessage
                id='myKycMessages.uploadedKycTitle'
                {...myKycMessages.uploadedKycTitle}
              />
            ) : (
              <FormattedMessage
                id='myKycMessages.CompleteYourKYC'
                {...myKycMessages.CompleteYourKYC}
              />
            )}
          </Typography>
          <Typography variant='body2'>
            {!!uploadedDoc?.data.length ? (
              <FormattedMessage
                id='myKycMessages.uploadedKYCSubHeader'
                {...myKycMessages.uploadedKYCSubHeader}
              />
            ) : (
              <FormattedMessage
                id='myKycMessages.MyKYCSubHeader'
                {...myKycMessages.MyKYCSubHeader}
              />
            )}
          </Typography>
          <Instructions />
        </Box>
      </Box>
      {documents && (
        <Grid container spacing={2}>
          {Object.entries(documents)
            .filter(
              ([key]) =>
                key !== 'Bank' &&
                key !== 'Additional Documents' &&
                key !== 'Others' &&
                key !== 'Additional Documents',
            )
            .map(([docType, options]) => (
              <DocumentFields
                key={docType}
                docType={docType as keyof TDocumentsTypes}
                options={options}
                documents={documents}
                setDocuments={setDocuments}
                formik={formik}
                onFileSelect={onFileSelect}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                uploadedData={filterByDocType(docType)}
                setIsEditSuccess={setIsEditSuccess}
                setUpdateLoader={setIsLoading}
                isBulkUpload={true}
                onApproveRejectRecord={onApproveRejectRecord}
                onViewRecord={onViewRecord}
              />
            ))}
          {Object.entries(documents)
            .filter(([key]) => key === 'Others')
            .map(([docType, options]) => (
              <OthersDocumentFields
                key={docType}
                docType={docType as keyof TDocumentsTypes}
                options={options}
                documents={documents}
                setDocuments={setDocuments}
                formik={formik}
                onFileSelect={onFileSelect}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                uploadedData={filterByDocType(docType)}
                setIsEditSuccess={setIsEditSuccess}
                setUpdateLoader={setIsLoading}
                isBulkUpload={true}
                onApproveRejectRecord={onApproveRejectRecord}
                onViewRecord={onViewRecord}
              />
            ))}
          {Object.entries(documents)
            .filter(([key]) => key === 'Additional Documents')
            .map(([docType, options]) => (
              <AdditionalFields
                key={docType}
                docType={docType as keyof TDocumentsTypes}
                options={options}
                documents={documents}
                setDocuments={setDocuments}
                formik={formik}
                onFileSelect={onFileSelect}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                uploadedData={filterByDocType(docType)}
                setIsEditSuccess={setIsEditSuccess}
                setUpdateLoader={setIsLoading}
                isBulkUpload={true}
              />
            ))}
        </Grid>
      )}

      {!isUploadDocLoader && (
        <Box
          display={'flex'}
          justifyContent={isMobile ? 'center' : 'left'}
          paddingTop={isMobile ? 4 : 2}
        >
          <Button
            variant='contained'
            size='large'
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={disableBulkKycBtn(formik, uploadedDoc)}
          >
            <FormattedMessage
              id='myKycMessages.submitKYC'
              {...myKycMessages.submitKYC}
            />
          </Button>
        </Box>
      )}

      <ApproveRejectKyc
        recordForApproveReject={recordForApproveReject}
        onApproveRecord={onApproveRecord}
        onRejectRecord={onRejectRecord}
        onCancel={onCancel}
        onConfirmApproveReject={onConfirmApproveReject}
        onSuccess={onSuccess}
        onApproveRejectRecord={onApproveRejectRecord}
      />
      {showSuccessModal && (
        <SuccessModal
          message={successMessage}
          open={showSuccessModal}
          onClose={onSuccessClose}
        />
      )}

      {viewRecord && (
        <ViewKycDocModal
          document={viewRecord}
          onClose={() => {
            setViewRecord(null);
          }}
          // open={Boolean(documentDetail)}
          // onClose={onClose}
          // documentDetail={documentDetail}
        />
      )}

      {isShowSuccess && (
        <SuccessMessageModal
          message={
            <FormattedMessage
              id='myKycMessages.successUploadDDocRequest'
              {...myKycMessages.successUploadDDocRequest}
            />
          }
          handleOk={onCloseSuccessModal}
          open={isShowSuccess}
        />
      )}
    </Box>
  );
};
export default MyKycLayout;
