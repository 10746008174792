import { get, post } from 'v2s-ui-core/app/Integrations/ApiService';
import { API_URLS } from './config';

const ApiServices = {
  getIBAgreement: async <T>(userId: number) => {
    try {
      const response = await get<T>(API_URLS.GET_AGREEMENT(userId));
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDefaultAgreement: async <T>(rebate: any, params: any) => {
    try {
      const response = await get<T>(API_URLS.GET_DEFAULT_AGREEMENT(rebate), {
        params,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  fetchIbAgreement: async <T>(payload: any) => {
    try {
      const response = await post<T>(API_URLS.CREATE_AGREEMENT, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ApiServices;
