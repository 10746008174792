import BankAccounts from '~/assets/images/Bank Accounts.svg';
import InternalTransfersIcon from '~/assets/images/ChangeLeverage.svg';
import ClientsInOutIcon from '~/assets/images/ClientsInOut.svg';
import CRMUserManagement from '~/assets/images/CRMUserManagement.svg';
import DepositsIcon from '~/assets/images/Deposits.svg';
import EmailingIcon from '~/assets/images/Emailing.svg';
import IBManagementIcon from '~/assets/images/IBManagement.svg';
import NotificationsIcon from '~/assets/images/Notifications.svg';
import ReportsIcon from '~/assets/images/Reports.svg';
import RequestsIcon from '~/assets/images/Requests.svg';
import TradingSettingsIcon from '~/assets/images/TradingSettings.svg';
import WithdrawalIcon from '~/assets/images/Withdrawal.svg';
import UserManagement from '~/assets/images/UserManagement.svg';
import RolesAndPermissions from '~/assets/images/RolesAndPermissions.svg';
import ResetPasswordIcon from '~/assets/images/ResetPassword.svg';
import NewTradingAccountIcon from '~/assets/images/NewTradingAccount.svg';
import CreditIn from '~/assets/images/CreditIn.svg';
import CreditOut from '~/assets/images/CreditOut.svg';
import Traders from '~/assets/images/Traders.svg';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import Dashboard from '~/assets/images/Dashboard.svg';
import IBs from '~/assets/images/IBs.svg';
import Events from '~/assets/images/Events.svg';
import Templates from '~/assets/images/Templates.svg';
import genealogy from '~/assets/images/genealogy.svg';
import Actions from '~/assets/images/Actions.svg';
import Permissions from '~/assets/images/Permissions.svg';
import { SIDEBAR_ITEM } from './types';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from './messages';

export const DRAWER_WIDTH = 240;

export const SELECTED_MENU_STYLE =
  'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(352deg) brightness(103%) contrast(101%)';

export const DEFAULT_MENU_STYLE =
  'brightness(0) saturate(100%) invert(79%) sepia(11%) saturate(422%) hue-rotate(184deg) brightness(79%) contrast(88%)';

export const SIDEBAR_MENUS: SIDEBAR_ITEM[] = [
  {
    menuName: <FormattedMessage {...messages.dashboard} />,
    altText: 'Dashboard',
    icon: Dashboard,
    id: 'dashboard',
    path: APPLICATION_URLS.DASHBOARD,
  },
  {
    menuName: <FormattedMessage {...messages.traders} />,
    altText: 'Traders',
    icon: Traders,
    id: 'traders',
    path: APPLICATION_URLS.TRADERS,
  },
  {
    menuName: <FormattedMessage {...messages.bankAccounts} />,
    altText: 'Bank Accounts',
    icon: BankAccounts,
    id: 'bankAccounts',
    path: APPLICATION_URLS.BANK_ACCOUNTS,
  },

  {
    menuName: <FormattedMessage {...messages.deposits} />,
    altText: 'Deposits',
    icon: DepositsIcon,
    id: 'deposits',
    path: APPLICATION_URLS.DEPOSITS,
  },
  {
    menuName: <FormattedMessage {...messages.withdrawal} />,
    altText: 'Withdrawal',
    icon: WithdrawalIcon,
    id: 'Withdrawal',
    path: APPLICATION_URLS.WITHDRAWAL,
  },
  {
    menuName: <FormattedMessage {...messages.internalTransfers} />,
    altText: 'Transfers',
    icon: InternalTransfersIcon,
    id: 'Transfers',
    path: APPLICATION_URLS.INTERNAL_TRANSFERS,
  },
  {
    menuName: <FormattedMessage {...messages.Credit} />,
    altText: 'Credit In/Out',
    icon: ClientsInOutIcon,
    id: 'Client In/Out',
    path: APPLICATION_URLS.CREDIT,
    submenus: [
      {
        icon: CreditIn,
        id: 'Credit In',
        menuName: 'Credit In',
        altText: 'Credit In',
        path: APPLICATION_URLS.CREDIT_IN,
      },
      {
        icon: CreditOut,
        id: 'Credit Out',
        menuName: 'Credit Out',
        altText: 'Credit Out',
        path: APPLICATION_URLS.CREDIT_OUT,
      },
    ],
  },
  {
    menuName: <FormattedMessage {...messages.reports} />,
    altText: 'Reports',
    icon: ReportsIcon,
    id: 'Reports',
    path: APPLICATION_URLS.REPORTS,
    submenus: [
      {
        icon: null,
        id: 'Clients List',
        menuName: 'Clients List',
        altText: 'Clients List',
        path: APPLICATION_URLS.CLIENTS_LIST,
      },
      {
        icon: DepositsIcon,
        id: 'Deposit Report',
        menuName: 'Deposit Report',
        altText: 'Deposit Report',
        path: APPLICATION_URLS.DEPOSIT_REPORT,
      },
      {
        icon: WithdrawalIcon,
        id: 'Withdrawal Report',
        menuName: 'Withdrawal Report',
        altText: 'Withdrawal Report',
        path: APPLICATION_URLS.WITHDRAWAL_REPORT,
      },
      {
        icon: InternalTransfersIcon,
        id: 'Transfers Report',
        menuName: 'Transfer Report',
        altText: 'Transfer Report',
        path: APPLICATION_URLS.TRANSFER_REPORT,
      },
      {
        icon: ClientsInOutIcon,
        id: 'Credit Report',
        menuName: 'Credit Report',
        altText: 'Credit Report',
        path: APPLICATION_URLS.CREDIT_REPORTS,
      },
    ],
  },
  {
    menuName: <FormattedMessage {...messages.requests} />,
    altText: 'Requests',
    icon: RequestsIcon,
    id: 'Requests',
    path: APPLICATION_URLS.REQUESTS,
    submenus: [
      {
        icon: NewTradingAccountIcon,
        id: 'New Trading Account',
        menuName: 'New Trading Account',
        altText: 'New TradingAccount',
        path: APPLICATION_URLS.NEW_TRADING_ACCOUNT,
      },
      {
        icon: InternalTransfersIcon,
        id: 'Leverage Change',
        menuName: 'Leverage Change',
        altText: 'Leverage Change',
        path: APPLICATION_URLS.LEVERAGE_CHANGE,
      },
      {
        icon: ResetPasswordIcon,
        id: 'passwordChange',
        menuName: 'Password Change',
        altText: 'Password Change',
        path: APPLICATION_URLS.PASSWORD_CHANGE,
      },
    ],
  },
  {
    menuName: <FormattedMessage {...messages.emailing} />,
    altText: 'Emailing',
    icon: EmailingIcon,
    id: 'Emailing',
    path: APPLICATION_URLS.EMAILING,
    submenus: [
      {
        icon: Templates,
        altText: 'Templates',
        id: 'Templates',
        menuName: 'Templates',
        path: APPLICATION_URLS.TEMPLATES,
      },
      {
        icon: Events,
        altText: 'Events',
        id: 'Events',
        menuName: 'Events',
        path: APPLICATION_URLS.EVENTS,
      },
    ],
  },
  {
    menuName: <FormattedMessage {...messages.notifications} />,
    altText: 'Notifications',
    icon: NotificationsIcon,
    id: 'Notifications',
    path: APPLICATION_URLS.NOTIFICATIONS,
  },
  {
    menuName: <FormattedMessage {...messages.tradingSettings} />,
    altText: 'Trading Settings',
    icon: TradingSettingsIcon,
    id: 'tradingSettings',
    path: APPLICATION_URLS.TRADING_SETTINGS,
  },
  {
    menuName: <FormattedMessage {...messages.ibManagement} />,
    altText: 'IB Management',
    icon: IBManagementIcon,
    id: 'ibManagement',
    path: APPLICATION_URLS.IB_MANAGEMENT,
    submenus: [
      {
        icon: IBs,
        id: 'IBS',
        menuName: 'IBs',
        altText: 'IBs',
        path: APPLICATION_URLS.IBS,
      },
      {
        icon: genealogy,
        id: 'IB Genealogy',
        menuName: 'IB Genealogy',
        altText: 'IB Genealogy',
        path: APPLICATION_URLS.IB_GENEALOGY,
      },
    ],
  },
  {
    menuName: <FormattedMessage {...messages.crmUserManagement} />,
    altText: 'CRM User Management',
    icon: CRMUserManagement,
    id: 'crmUserManagement',
    path: APPLICATION_URLS.USER_MANAGEMENT,
    submenus: [
      {
        icon: UserManagement,
        id: 'userManagement',
        menuName: <FormattedMessage {...messages.userManagement} />,
        altText: 'User Management',
        path: APPLICATION_URLS.CRM_USERS,
      },
      {
        icon: Actions,
        id: 'actions',
        menuName: <FormattedMessage {...messages.actions} />,
        altText: 'Actions',
        path: APPLICATION_URLS.CRM_USER_ACTIONS,
      },
      {
        icon: Permissions,
        id: 'permissions',
        menuName: <FormattedMessage {...messages.permissions} />,
        altText: 'Permissions',
        path: APPLICATION_URLS.PERMISSIONS,
      },
      {
        icon: RolesAndPermissions,
        id: 'rolesAndPermissions',
        menuName: <FormattedMessage {...messages.rolesAndPermissions} />,
        altText: 'Roles & Permissions',
        path: APPLICATION_URLS.ROLES_AND_PERMISSIONS,
      },
    ],
  },
];
