import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  educationLevel: {
    id: 'myProfile.educationLevel',
    defaultMessage: 'Education level',
  },
  intendedPurposeAndNatureOfTrading: {
    id: 'myProfile.intendedPurposeAndNatureOfTrading',
    defaultMessage: 'Intended purpose & nature of trading',
  },
  employmentStatus: {
    id: 'myProfile.employmentStatus',
    defaultMessage: 'Employment status',
  },
  estimatedNetWorth: {
    id: 'myProfile.estimatedNetWorth',
    defaultMessage: 'Estimated Net Worth (USD)',
  },
  estimatedAnnualIncome: {
    id: 'myProfile.estimatedAnnualIncome',
    defaultMessage: 'Estimated Annual Income (USD)',
  },
  estimatedInvestmentAmount: {
    id: 'myProfile.estimatedInvestmentAmount',
    defaultMessage: 'Estimated Investment Amount (USD)',
  },
  sourceOfFunds: {
    id: 'myProfile.sourceOfFunds',
    defaultMessage: 'Source of Funds',
  },
  sourceOfWealth: {
    id: 'myProfile.sourceOfWealth',
    defaultMessage: 'Source of Wealth',
  },
  expectedOriginOfIncomingFunds: {
    id: 'myProfile.expectedOriginOfIncomingFunds',
    defaultMessage: 'Expected origin of incoming funds to the trading account',
  },
  save: {
    id: 'myProfile.save',
    defaultMessage: 'Save',
  },
  successFinancialUpdate: {
    id: 'userMenu.successFinancialUpdate',
    defaultMessage: ' Financial information has been successfully updated',
  },
  select: {
    id: 'myProfile.select',
    defaultMessage: 'Select',
  },
  financialInformation: {
    id: 'userMenu.FinancialInformation',
    defaultMessage: 'Financial Information',
  },
  corporateInformation: {
    id: 'userMenu.corporateInformation',
    defaultMessage: 'Corporate Information',
  },
  successProfileUpdate: {
    id: 'userMenu.successProfileUpdate',
    defaultMessage: 'Your profile has been successfully updated',
  },
  successJointAccountUpdate: {
    id: 'userMenu.successJointAccountUpdate',
    defaultMessage: 'Your joint account has been successfully updated',
  },
  successChangePasswordUpdate: {
    id: 'userMenu.successChangePasswordUpdate',
    defaultMessage: 'Your password has been successfully updated',
  },
  educationLevelRequired: {
    id: 'userMenu.educationLevelRequired',
    defaultMessage: 'Please select your education level.',
  },
  tradingPurposeRequired: {
    id: 'userMenu.tradingPurposeRequired',
    defaultMessage:
      'Please specify the intended purpose and nature of trading.',
  },
  employmentStatusRequired: {
    id: 'userMenu.employmentStatusRequired',
    defaultMessage: 'Please select your employment status.',
  },
  estimatedNetWorthRequired: {
    id: 'userMenu.estimatedNetWorthRequired',
    defaultMessage: 'Please enter your estimated net worth in USD.',
  },
  estimatedAnnualIncomeRequired: {
    id: 'userMenu.estimatedAnnualIncomeRequired',
    defaultMessage: 'Please enter your estimated annual income in USD.',
  },
  estimatedInvestmentAmountRequired: {
    id: 'userMenu.estimatedInvestmentAmountRequired',
    defaultMessage: 'Please enter your estimated investment amount in USD.',
  },
  sourceOfFundsRequired: {
    id: 'userMenu.sourceOfFundsRequired',
    defaultMessage: 'Please specify the source of your funds.',
  },
  expectedOriginOfFundsRequired: {
    id: 'userMenu.expectedOriginOfFundsRequired',
    defaultMessage:
      'Please specify the expected origin of incoming funds to the trading account.',
  },
});
