import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  companyName: {
    id: 'myCorporate.companyName',
    defaultMessage: 'Company Name',
  },
  company_license: {
    id: 'myCorporate.company_license',
    defaultMessage: 'Company License',
  },
  company_address_line1: {
    id: 'myCorporate.company_address_line1',
    defaultMessage: 'Company Address Line 1',
  },
  company_address_line2: {
    id: 'myCorporate.company_address_line2',
    defaultMessage: 'Company Address Line 2',
  },
  company_city_town: {
    id: 'myCorporate.company_city_town',
    defaultMessage: 'Company City/Town',
  },
  company_zip_postal_code: {
    id: 'myCorporate.company_zip_postal_code',
    defaultMessage: 'Company Zip/Postal Code',
  },
  company_state_province: {
    id: 'myCorporate.company_state_province',
    defaultMessage: 'Company State/Province',
  },
  company_country: {
    id: 'myCorporate.company_country',
    defaultMessage: 'Country',
  },
  company_date_of_birth: {
    id: 'myCorporate.company_date_of_birth',
    defaultMessage: 'Company Established Date',
  },
  update: {
    id: 'myCorporate.update',
    defaultMessage: 'Update',
  },
  successCorporateUpdate: {
    id: 'myCorporate.successFinancialUpdate',
    defaultMessage: ' Corporate information has been successfully updated',
  },
  edit: {
    id: 'myCorporate.edit',
    defaultMessage: 'Edit',
  },
  select: {
    id: 'myCorporate.select',
    defaultMessage: 'Select',
  },
});
