import { Box, Typography } from '@mui/material';
import { FormattedMessage } from '~app/Integrations/Localization';
import { myKycMessages } from '../../myKycMessages';

const Instructions = () => (
  <Box>
    <ul style={{ listStyleType: 'disc', marginTop: '0px', fontSize: '14px' }}>
      <li>
        <Typography variant='body2' color={'main'}>
          <FormattedMessage {...myKycMessages.instruction1} />
        </Typography>
      </li>
      <li>
        <Typography variant='body2' color={'main'}>
          <FormattedMessage {...myKycMessages.instruction2} />
        </Typography>
      </li>
      <li>
        <Typography variant='body2' color={'main'}>
          <FormattedMessage {...myKycMessages.instruction3} />
        </Typography>
      </li>
      <li>
        <Typography variant='body2' color={'main'}>
          <FormattedMessage {...myKycMessages.instruction4} />
        </Typography>
      </li>
    </ul>
  </Box>
);
export default Instructions;
