import messages from './messages';
import * as Yup from 'yup';

// status from api
export const BANK_ACCOUNT_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const BANK_ACCOUNT_STATUS_CONFIG = {
  [BANK_ACCOUNT_STATUS.PENDING]: {
    label: messages.pending,
    color: 'secondary',
  },
  [BANK_ACCOUNT_STATUS.APPROVED]: {
    label: messages.approved,
    color: 'success',
  },
  [BANK_ACCOUNT_STATUS.REJECTED]: { label: messages.rejected, color: 'error' },
};

const COUNTRY_NAME = 'India';

export const validationSchema = (formatMessage: Function, country: string) =>
  Yup.object({
    bank_name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, formatMessage(messages.bankNameValidation))
      .min(3, formatMessage(messages.bankNameMinLength))
      .max(50, formatMessage(messages.bankNameMaxLength))
      .required(formatMessage(messages.bank_name_required)),

    bank_account_name: Yup.string()
      .required(formatMessage(messages.bank_account_name_required))
      .matches(/^[a-zA-Z\s]+$/, formatMessage(messages.accountNameValidation))
      .min(3, formatMessage(messages.accountNameMinLength))
      .max(50, formatMessage(messages.accountNameMaxLength)),

    bank_account_number: Yup.string()
      .required(formatMessage(messages.bank_account_number_required))
      .matches(
        /^[a-zA-Z0-9]+$/,
        formatMessage(messages.bankAccountNumberValidation),
      ),

    bank_iban:
      country === COUNTRY_NAME
        ? Yup.string().optional()
        : Yup.string()
            .required(formatMessage(messages.bank_iban_required))
            .matches(/^[A-Z0-9]+$/, formatMessage(messages.bankIbanValidation))
            .min(8, formatMessage(messages.bankIbanMinLength))
            .max(25, formatMessage(messages.bankIbanMaxLength)),

    bank_code:
      country === COUNTRY_NAME
        ? Yup.string()
            .required(formatMessage(messages.bank_ifsc_required))
            .matches(/^[A-Z0-9]{11}$/, formatMessage(messages.ifscValidation))
        : Yup.string().optional(),

    bank_address: Yup.string()
      .required(formatMessage(messages.bank_address_required))
      .min(3, formatMessage(messages.bankAddressMinLength))
      .max(100, formatMessage(messages.bankAddressMaxLength)),

    user_document: Yup.string().required(
      formatMessage(messages.user_document_required),
    ),
  });
