/* eslint-disable complexity */
import { Box, Button, Grid } from '@mui/material';
import AsyncDropdown from 'v2s-ui-core/app/components/AsyncDropdown';
import {
  FormattedMessage,
  useLocaleContext,
} from 'v2s-ui-core/app/Integrations/Localization';
import FormSkeleton from '~/components/Skeleton/FormSkeleton';
import SuccessMessageModal from '~/components/SuccessMessageModal';
import { IMyProfileTabProps } from '../PersonalDetails/constants';
import { API_URLS } from './api/config';
import { FINANCIAL_INFO_KEYS } from './constants';
import useFinancialDetailsForm from './hooks/useFinancialDetailsForm';
import messages from './messages';
import { useIsMobile } from '~/hooks/useIsMobile';

const FinancialInfo = ({ handleViewInfo, onTabChange }: IMyProfileTabProps) => {
  const isMobile = useIsMobile();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const {
    values,
    handleChange,
    handleSubmit,
    showFinancialSuccessModal,
    handleCloseSuccessModal,
    formik,
    isLoading,
    isUpdateLoading,
  } = useFinancialDetailsForm({ onTabChange });
  if (isLoading || isUpdateLoading) {
    return (
      <Box>
        <FormSkeleton />
      </Box>
    );
  }
  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      paddingX={2}
      paddingBottom={5}
      paddingTop={2}
    >
      <Grid container spacing={2} columnSpacing={2}>
        <Grid
          item
          container
          paddingLeft={2}
          width={'100%'}
          mt={{ md: 1, xs: 2 }}
          gap={1}
        >
          <Grid item xs={12} sm={6} alignSelf={'center'}>
            <AsyncDropdown
              // @ts-ignore
              value={values?.[FINANCIAL_INFO_KEYS.EDUCATION_LEVEL] || null}
              onChange={(e, value) =>
                handleChange({
                  target: {
                    name: FINANCIAL_INFO_KEYS.EDUCATION_LEVEL,
                    value,
                  },
                })
              }
              queryProps={{
                resource: API_URLS.GET_EDUCATION_LEVELS,
                queryKeyProp: ['GET_EDUCATION_LEVELS'],
                select: (data: any) => data.data,
              }}
              InputProps={{
                label: <FormattedMessage {...messages.educationLevel} />,
                required: true,
                name: FINANCIAL_INFO_KEYS.EDUCATION_LEVEL,
                placeholder: formatMessage(messages.select),

                onBlur: () => {
                  formik.setFieldTouched(
                    FINANCIAL_INFO_KEYS.EDUCATION_LEVEL,
                    true,
                  );
                },
                error: !!(
                  formik.errors?.[FINANCIAL_INFO_KEYS.EDUCATION_LEVEL] &&
                  formik.touched?.[FINANCIAL_INFO_KEYS.EDUCATION_LEVEL]
                ),
                helperText:
                  formik.touched?.[FINANCIAL_INFO_KEYS.EDUCATION_LEVEL] &&
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.EDUCATION_LEVEL
                  ]?.toString(),
              }}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <AsyncDropdown
              // @ts-ignore
              value={values?.[FINANCIAL_INFO_KEYS.TRADING_PURPOSE] || null}
              onChange={(e, value) =>
                handleChange({
                  target: {
                    name: FINANCIAL_INFO_KEYS.TRADING_PURPOSE,
                    value,
                  },
                })
              }
              queryProps={{
                resource: API_URLS.GET_INTENDED_PURPOSES,
                queryKeyProp: ['GET_INTENDED_PURPOSES'],
                select: (data: any) => data.data,
              }}
              InputProps={{
                label: (
                  <FormattedMessage
                    {...messages.intendedPurposeAndNatureOfTrading}
                  />
                ),
                placeholder: formatMessage(messages.select),

                required: true,
                name: FINANCIAL_INFO_KEYS.TRADING_PURPOSE,

                onBlur: () =>
                  formik.setFieldTouched(
                    FINANCIAL_INFO_KEYS.TRADING_PURPOSE,
                    true,
                  ),

                error: !!(
                  formik.errors?.[FINANCIAL_INFO_KEYS.TRADING_PURPOSE] &&
                  formik.touched?.[FINANCIAL_INFO_KEYS.TRADING_PURPOSE]
                ),
                helperText:
                  formik.touched?.[FINANCIAL_INFO_KEYS.TRADING_PURPOSE] &&
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.TRADING_PURPOSE
                  ]?.toString(),
              }}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <AsyncDropdown
              // @ts-ignore
              value={values?.[FINANCIAL_INFO_KEYS.EMPLOYMENT_STATUS] || null}
              onChange={(e, value) =>
                handleChange({
                  target: {
                    name: FINANCIAL_INFO_KEYS.EMPLOYMENT_STATUS,
                    value,
                  },
                })
              }
              queryProps={{
                resource: API_URLS.GET_EMPLOYMENT_STATUSES,
                queryKeyProp: ['GET_EMPLOYMENT_STATUSES'],
                select: (data: any) => data.data,
              }}
              InputProps={{
                label: <FormattedMessage {...messages.employmentStatus} />,
                required: true,
                name: FINANCIAL_INFO_KEYS.EMPLOYMENT_STATUS,
                placeholder: formatMessage(messages.select),

                onBlur: () =>
                  formik.setFieldTouched(
                    FINANCIAL_INFO_KEYS.EMPLOYMENT_STATUS,
                    true,
                  ),

                error: !!(
                  formik.errors?.[FINANCIAL_INFO_KEYS.EMPLOYMENT_STATUS] &&
                  formik.touched?.[FINANCIAL_INFO_KEYS.EMPLOYMENT_STATUS]
                ),
                helperText:
                  formik.touched?.[FINANCIAL_INFO_KEYS.EMPLOYMENT_STATUS] &&
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.EMPLOYMENT_STATUS
                  ]?.toString(),
              }}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <AsyncDropdown
              // @ts-ignore
              value={values?.[FINANCIAL_INFO_KEYS.ESTIMATED_NET_WORTH] || null}
              onChange={(e, value) =>
                handleChange({
                  target: {
                    name: FINANCIAL_INFO_KEYS.ESTIMATED_NET_WORTH,
                    value,
                  },
                })
              }
              queryProps={{
                resource: API_URLS.GET_NET_WORTH_RANGES,
                queryKeyProp: ['GET_NET_WORTH_RANGES'],
                select: (data: any) => data.data,
              }}
              InputProps={{
                label: <FormattedMessage {...messages.estimatedNetWorth} />,
                required: true,
                name: FINANCIAL_INFO_KEYS.ESTIMATED_NET_WORTH,
                placeholder: formatMessage(messages.select),

                // @ts-ignore
                onBlur: () =>
                  formik.setFieldTouched(
                    FINANCIAL_INFO_KEYS.ESTIMATED_NET_WORTH,
                    true,
                  ),

                error: !!(
                  formik.errors?.[FINANCIAL_INFO_KEYS.ESTIMATED_NET_WORTH] &&
                  formik.touched?.[FINANCIAL_INFO_KEYS.ESTIMATED_NET_WORTH]
                ),
                helperText:
                  formik.touched?.[FINANCIAL_INFO_KEYS.ESTIMATED_NET_WORTH] &&
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.ESTIMATED_NET_WORTH
                  ]?.toString(),
              }}
              getOptionLabel={(option) => option.range}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <AsyncDropdown
              // @ts-ignore
              value={
                values?.[FINANCIAL_INFO_KEYS.ESTIMATED_ANNUAL_INCOME] || null
              }
              onChange={(e, value) =>
                handleChange({
                  target: {
                    name: FINANCIAL_INFO_KEYS.ESTIMATED_ANNUAL_INCOME,
                    value,
                  },
                })
              }
              queryProps={{
                resource: API_URLS.GET_ANNUAL_INCOME_RANGES,
                queryKeyProp: ['GET_ANNUAL_INCOME_RANGES'],
                select: (data: any) => data.data,
              }}
              InputProps={{
                label: <FormattedMessage {...messages.estimatedAnnualIncome} />,
                required: true,
                name: FINANCIAL_INFO_KEYS.ESTIMATED_ANNUAL_INCOME,
                placeholder: formatMessage(messages.select),

                onBlur: () =>
                  formik.setFieldTouched(
                    FINANCIAL_INFO_KEYS.ESTIMATED_ANNUAL_INCOME,
                    true,
                  ),

                error: !!(
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.ESTIMATED_ANNUAL_INCOME
                  ] &&
                  formik.touched?.[FINANCIAL_INFO_KEYS.ESTIMATED_ANNUAL_INCOME]
                ),
                helperText:
                  formik.touched?.[
                    FINANCIAL_INFO_KEYS.ESTIMATED_ANNUAL_INCOME
                  ] &&
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.TRADING_PURPOSE
                  ]?.toString(),
              }}
              getOptionLabel={(option) => option.range}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <AsyncDropdown
              // @ts-ignore
              value={
                values?.[FINANCIAL_INFO_KEYS.ESTIMATED_INVESTMENT_AMOUNT] ||
                null
              }
              onChange={(e, value) =>
                handleChange({
                  target: {
                    name: FINANCIAL_INFO_KEYS.ESTIMATED_INVESTMENT_AMOUNT,
                    value,
                  },
                })
              }
              queryProps={{
                resource: API_URLS.GET_INVESTMENT_AMOUNT_RANGES,
                queryKeyProp: ['GET_INVESTMENT_AMOUNT_RANGES'],
                select: (data: any) => data.data,
              }}
              InputProps={{
                label: (
                  <FormattedMessage {...messages.estimatedInvestmentAmount} />
                ),
                required: true,
                name: FINANCIAL_INFO_KEYS.ESTIMATED_INVESTMENT_AMOUNT,
                placeholder: formatMessage(messages.select),

                onBlur: () =>
                  formik.setFieldTouched(
                    FINANCIAL_INFO_KEYS.ESTIMATED_INVESTMENT_AMOUNT,
                    true,
                  ),

                error: !!(
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.ESTIMATED_INVESTMENT_AMOUNT
                  ] &&
                  formik.touched?.[
                    FINANCIAL_INFO_KEYS.ESTIMATED_INVESTMENT_AMOUNT
                  ]
                ),
                helperText:
                  formik.touched?.[
                    FINANCIAL_INFO_KEYS.ESTIMATED_INVESTMENT_AMOUNT
                  ] &&
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.ESTIMATED_INVESTMENT_AMOUNT
                  ]?.toString(),
              }}
              getOptionLabel={(option) => option.range}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <AsyncDropdown
              // @ts-ignore
              value={values?.[FINANCIAL_INFO_KEYS.SOURCE_OF_FUNDS] || null}
              onChange={(e, value) =>
                handleChange({
                  target: {
                    name: FINANCIAL_INFO_KEYS.SOURCE_OF_FUNDS,
                    value,
                  },
                })
              }
              queryProps={{
                resource: API_URLS.GET_SOURCE_OF_FUNDS_AND_WEALTH,
                queryKeyProp: ['GET_SOURCE_OF_FUNDS_AND_WEALTH'],
                select: (data: any) => data.data,
              }}
              InputProps={{
                label: <FormattedMessage {...messages.sourceOfFunds} />,
                required: true,
                name: FINANCIAL_INFO_KEYS.SOURCE_OF_FUNDS,
                placeholder: formatMessage(messages.select),

                onBlur: () =>
                  formik.setFieldTouched(
                    FINANCIAL_INFO_KEYS.SOURCE_OF_FUNDS,
                    true,
                  ),

                error: !!(
                  formik.errors?.[FINANCIAL_INFO_KEYS.SOURCE_OF_FUNDS] &&
                  formik.touched?.[FINANCIAL_INFO_KEYS.SOURCE_OF_FUNDS]
                ),
                helperText:
                  formik.touched?.[FINANCIAL_INFO_KEYS.SOURCE_OF_FUNDS] &&
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.SOURCE_OF_FUNDS
                  ]?.toString(),
              }}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>

          <Grid item xs={12} sm={6} mt={2}>
            <AsyncDropdown
              // @ts-ignore
              value={
                values?.[FINANCIAL_INFO_KEYS.EXPECTED_ORIGIN_OF_FUNDS] || null
              }
              onChange={(e, value) =>
                handleChange({
                  target: {
                    name: FINANCIAL_INFO_KEYS.EXPECTED_ORIGIN_OF_FUNDS,
                    value,
                  },
                })
              }
              queryProps={{
                resource: API_URLS.GET_EXPECTED_ORIGIN_OF_FUNDS,
                queryKeyProp: ['GET_EXPECTED_ORIGIN_OF_FUNDS'],
                select: (data: any) => data.data,
              }}
              InputProps={{
                label: (
                  <FormattedMessage
                    {...messages.expectedOriginOfIncomingFunds}
                  />
                ),
                required: true,
                placeholder: formatMessage(messages.select),

                name: FINANCIAL_INFO_KEYS.EXPECTED_ORIGIN_OF_FUNDS,
                onBlur: () =>
                  formik.setFieldTouched(
                    FINANCIAL_INFO_KEYS.EXPECTED_ORIGIN_OF_FUNDS,
                    true,
                  ),

                error: !!(
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.EXPECTED_ORIGIN_OF_FUNDS
                  ] &&
                  formik.touched?.[FINANCIAL_INFO_KEYS.EXPECTED_ORIGIN_OF_FUNDS]
                ),
                helperText:
                  formik.touched?.[
                    FINANCIAL_INFO_KEYS.EXPECTED_ORIGIN_OF_FUNDS
                  ] &&
                  formik.errors?.[
                    FINANCIAL_INFO_KEYS.EXPECTED_ORIGIN_OF_FUNDS
                  ]?.toString(),
              }}
              getOptionLabel={(option) => option.name}
              getOptionKey={(option) => option.id}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Grid>
        </Grid>
      </Grid>

      <Box
        mt={3}
        display={'flex'}
        justifyContent={isMobile ? 'center' : 'flex-start'}
      >
        <Button
          variant='contained'
          size='medium'
          sx={{ width: '113px' }}
          onClick={() => handleSubmit()}
        >
          Update
        </Button>
      </Box>

      {!!showFinancialSuccessModal && (
        <SuccessMessageModal
          message={formatMessage(messages.successFinancialUpdate)}
          handleOk={handleCloseSuccessModal}
          open={showFinancialSuccessModal}
        />
      )}
    </Box>
  );
};

export default FinancialInfo;
