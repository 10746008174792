import { Box, ListItemIcon } from '@mui/material';
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import successIcon from '~/assets/images/successIcon.svg';

export default function SuccessMessageModal({
  message,
  handleOk,
  open,
}: {
  message: any;
  handleOk?: any;
  open: any;
}) {
  const handleClose = () => {
    handleOk();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        // aria-labelledby='responsive-dialog-title
        PaperProps={{
          component: 'form',

          sx: {
            minWidth: {
              sm: '528px !important',
              xs: '343px',
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ListItemIcon
            sx={[
              {
                minWidth: 0,
                justifyContent: 'center',
              },
            ]}
          >
            <img
              className='menu-icon'
              src={successIcon as string}
              alt={'success-toast'}
            />
          </ListItemIcon>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            color={blue[700]}
            style={{ textAlign: 'center', marginBottom: 1 }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box display={'flex'} justifyContent={'right'}>
            <Button
              variant='contained'
              sx={{
                height: '42px !important',
                width: '66px !important',
                mb: 1,
              }}
              size='large'
              onClick={() => handleClose()}
              autoFocus
            >
              ok
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
