/* eslint-disable max-statements */
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import CustomTooltip from '~/components/CustomTooltip';
import FileUploadWrapper from '~/components/FileUploadWrapper';
import { ShowStatus } from '~/components/ShowStatus';
import { ViewFileDialog } from '~/components/ViewFileDialog';
import {
  GET_KYC_UPLOAD_MODE,
  UPLOADED_DOCUMENT_STATUS,
} from '~/config/constants';
import { TDocuments, TDocumentsTypes } from '~/config/types';
import { theme } from '~/shell/theme/theme';
import { previewOrOpenFileInNewTab } from '~/utils';
import { UPLOAD_FILE_TYPE } from '../../constants';
import { myKycMessages } from '../../myKycMessages';
import { onDocumentSelection, setFieldsValues } from '../../utils';
// import Divider from 'v2s-ui-core/app/components/Divider';
import { Divider } from '@mui/material';
import DocumentExpiry from '../DocumentExpiry/DocumentExpiry';
import { blue } from '@mui/material/colors';
import { useIsMobile } from '~/hooks/useIsMobile';

// eslint-disable-next-line complexity
const DocumentFields = ({
  docType,
  options,
  documents,
  setDocuments,
  formik,
  onFileSelect,
  uploadedFiles,
  setUploadedFiles,
  uploadedData,
  setIsEditSuccess,
  setUpdateLoader,
  isBulkUpload = true,
  onApproveRejectRecord,
  onViewRecord,
}: {
  docType: keyof TDocumentsTypes;
  options: TDocuments[];
  documents: TDocumentsTypes;
  setDocuments: React.Dispatch<React.SetStateAction<TDocumentsTypes>>;
  formik: any;
  onFileSelect: any;
  uploadedFiles: any;
  setUploadedFiles: any;
  uploadedData: any;
  setIsEditSuccess: any;
  setUpdateLoader?: (isLoading: boolean) => void;
  isBulkUpload: boolean;
  onApproveRejectRecord?: any;
  onViewRecord?: any;
}) => {
  const [dropDownValue, setDropDownValue] = useState({
    documentName: '',
    documentSide: {
      FrontId: null,
      BackId: null,
      BackSideRequired: false,
      FrontSideRequired: false,
      poa_front_doc_id: 0,
      poa_back_doc_id: 0,
      poi_back_doc_id: 0,
      poi_front_doc_id: 0,
    },
  });

  const isMobile = useIsMobile();
  const [viewFile, setViewFile] = useState({
    open: false,
    file: null,
  });

  const isDisabled = (side: string) =>
    !!options[0]?.sides?.find(
      (s) => s.document_side === side && side === 'Front',
    );

  const isBackSide =
    dropDownValue.documentSide.BackId !== undefined &&
    dropDownValue.documentSide.BackId !== null;

  useEffect(() => {
    if (!!uploadedData?.length) {
      setFieldsValues({
        setDropDownValue,
        uploadedData,
        options,
        formik,
        docType,
        setUpdateLoader,
      });
    }
  }, [JSON.stringify(uploadedData)]);

  const frontDocValues = formik.getFieldProps(
    `${docType.toLocaleLowerCase()}_front`,
  ).value;
  const backDocValues = formik.getFieldProps(
    `${docType.toLocaleLowerCase()}_back`,
  ).value;

  const frontDocFieldValue = formik.values[`${docType.toLowerCase()}_front`];
  const backDocFieldValue = formik.values[`${docType.toLowerCase()}_back`];
  const url_front = frontDocFieldValue?.file_location || null;
  const url_back = backDocFieldValue?.file_location || null;

  const mode = formik.getFieldProps(`poi_front`).value?.id
    ? GET_KYC_UPLOAD_MODE.EDIT
    : GET_KYC_UPLOAD_MODE.ADD;

  const filteredFrontDocValues = uploadedData?.find(
    (item: any) => item?.id === frontDocValues?.id,
  );

  const filteredBackDocValues = uploadedData?.find(
    (item: any) => item?.id === backDocValues?.id,
  );

  // console.log('backDocFieldValue', backDocFieldValue);
  // console.log(
  //   'frontDocFieldValue',
  //   frontDocFieldValue,
  //   'filteredFrontDocValues',
  //   filteredFrontDocValues,
  // );
  return (
    <Grid
      item
      display={'flex'}
      flexDirection={'column'}
      flexWrap={'wrap'}
      width={'100%'}
      gap={2}
      key={docType}
    >
      <Grid
        container
        spacing={2}
        sx={{
          flexDirection: 'row',
          // alignItems: { xs: 'center', sm: 'flex-start' },
        }}
      >
        <Grid item xs={12}>
          <Box
            sx={{ width: '100%' }}
            display='flex'
            flexDirection='column'
            alignItems={'flex-start'}
          >
            <Box
              display='grid'
              gridTemplateColumns={{
                xs: !!frontDocValues?.status ? '1fr' : '1fr auto',
                md: '1fr auto',
                sm: '1fr auto',
              }}
              alignItems='center'
              gap={1}
            >
              <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                gap={1}
                sx={{ width: '100%' }}
              >
                <TextField
                  size='small'
                  label={
                    <FormattedMessage
                      id={`myKycMessages.${
                        docType as keyof typeof myKycMessages
                      }`}
                      {...myKycMessages?.[
                        docType as keyof typeof myKycMessages
                      ]}
                      values={{ type: 'Front' }}
                    />
                  }
                  select
                  fullWidth
                  required={
                    (dropDownValue as any)?.documentSide?.FrontSideRequired ||
                    false
                  }
                  sx={{
                    width: { xs: '224px', md: '236px' },
                    '@media (max-width: 392px)': {
                      width: '188px',
                    },
                  }}
                  // disabled={
                  //   !isDisabled(options[0]?.sides[0]?.document_side) ||
                  //   !!frontDocValues?.id ||
                  //   !!dropDownValue?.documentName
                  // }
                  value={dropDownValue.documentName}
                  onChange={(e) =>
                    onDocumentSelection(
                      e.target.value,
                      options,
                      setDropDownValue,
                      uploadedFiles,
                      setUploadedFiles,
                      docType,
                      formik,
                    )
                  }
                >
                  {options.map((opt) => (
                    <MenuItem key={opt.document_name} value={opt.document_name}>
                      {opt.document_name}
                    </MenuItem>
                  ))}
                </TextField>
                {!!frontDocValues?.status && (
                  <ShowStatus
                    height='42px'
                    width='113px'
                    status={frontDocValues?.status.toUpperCase()}
                  />
                )}
                {!isMobile &&
                  frontDocValues?.status &&
                  frontDocValues?.status !== 'Pending' && (
                    <Button
                      variant='outlined'
                      sx={{
                        textTransform: 'none',
                        color: '#768098',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        height: '40px',
                        mx: 1,
                      }}
                      onClick={() => onViewRecord?.(filteredFrontDocValues)}
                    >
                      {/* <img src={viewDoc} alt='viewDoc' /> */}
                      view
                    </Button>
                  )}
              </Box>

              <Box
                display='flex'
                justifyContent={
                  'flex-end'
                  // backDocFieldValue?.remark ? 'space-between' : 'flex-end'
                }
                gap={2}
                alignItems='center'
                sx={{
                  gridColumn: {
                    xs: 'auto',

                    // xs: !!backDocValues?.status ? '1 / -1' : 'auto',
                    md: 'auto',
                  },
                }}
              >
                {' '}
                {!isMobile &&
                  frontDocValues?.id !== undefined &&
                  frontDocFieldValue?.status ===
                    UPLOADED_DOCUMENT_STATUS.PENDING && (
                    <Button
                      sx={{
                        height: '40px',
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        ml: 1,
                      }}
                      variant='contained'
                      color='primary'
                      onClick={() =>
                        onApproveRejectRecord(filteredFrontDocValues)
                      }
                    >
                      Actions
                    </Button>
                  )}
                {
                  <FileUploadWrapper
                    onSelectFile={(file) => {
                      formik.setFieldValue(
                        `${[docType.toLocaleLowerCase()]}_front`,
                        file,
                      );
                      onFileSelect(
                        file,
                        UPLOAD_FILE_TYPE.FRONT,
                        dropDownValue,
                        docType.toLocaleLowerCase(),
                        0,
                        (dropDownValue as any)?.documentSide[
                          `${docType.toLocaleLowerCase()}_front_doc_id`
                        ],
                      );
                    }}
                    disabled={!dropDownValue.documentName}
                    fileName={dropDownValue.documentName}
                    documentMappigId={dropDownValue.documentSide.FrontId}
                    bulkUpload={true}
                    isUpdateKyc={true}
                    updateKycId={frontDocValues?.id}
                    setIsEditSuccess={setIsEditSuccess}
                    title={frontDocFieldValue?.name ? 'Re Upload' : 'Upload'}
                    setUpdateLoader={setUpdateLoader}
                    minWidth='113px'
                    mode={mode}
                    formik={formik}
                  />
                }
              </Box>
            </Box>

            {!!frontDocValues?.name && (
              <CustomTooltip title={frontDocFieldValue?.name}>
                <Typography
                  fontWeight={theme.typography.fontWeightRegular}
                  fontSize={14}
                  color={theme.palette.primary.main}
                  sx={{
                    bgcolor: blue[50],
                    display: 'inline-block',
                    width: { md: '27ch', xs: '22ch', sm: '20ch' },
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    marginTop: {
                      xs:
                        frontDocFieldValue?.status ===
                        UPLOADED_DOCUMENT_STATUS.APPROVED
                          ? '-40px'
                          : frontDocFieldValue?.status ===
                            UPLOADED_DOCUMENT_STATUS.PENDING
                          ? '-40px'
                          : frontDocFieldValue?.status ===
                            UPLOADED_DOCUMENT_STATUS.REJECTED
                          ? '-40px'
                          : 1,
                      md: 1,
                      sm: 1,
                    },

                    marginBottom: {
                      xs:
                        frontDocFieldValue?.status !==
                        UPLOADED_DOCUMENT_STATUS.REJECTED
                          ? 1
                          : '0px',
                      md: '0px',
                      sm: '0px',
                    },
                  }}
                  onClick={() => {
                    previewOrOpenFileInNewTab(
                      url_front,
                      frontDocFieldValue,
                      setViewFile,
                    );
                  }}
                >
                  {frontDocFieldValue?.name}
                </Typography>
              </CustomTooltip>
            )}
            {isMobile && (
              <Box
                display='flex'
                alignItems={'center'}
                justifyContent={'flex-end'}
                mt={1}
              >
                {frontDocValues?.status &&
                  frontDocValues?.status !== 'Pending' && (
                    <Button
                      variant='outlined'
                      sx={{
                        textTransform: 'none',
                        color: '#768098',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '8px',
                        height: '40px',
                        mx: 1,
                      }}
                      onClick={() => onViewRecord?.(filteredFrontDocValues)}
                    >
                      {/* <img src={viewDoc} alt='viewDoc' /> */}
                      view
                    </Button>
                  )}

                <Box
                  display='flex'
                  justifyContent={
                    'flex-end'
                    // backDocFieldValue?.remark ? 'space-between' : 'flex-end'
                  }
                  gap={2}
                  alignItems='center'
                  sx={{
                    gridColumn: {
                      xs: 'auto',

                      // xs: !!backDocValues?.status ? '1 / -1' : 'auto',
                      md: 'auto',
                    },
                  }}
                >
                  {' '}
                  {frontDocValues?.id !== undefined &&
                    frontDocFieldValue?.status ===
                      UPLOADED_DOCUMENT_STATUS.PENDING && (
                      <Button
                        sx={{
                          height: '40px',
                          width: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px',
                          ml: 1,
                        }}
                        variant='contained'
                        color='primary'
                        onClick={() =>
                          onApproveRejectRecord(filteredFrontDocValues)
                        }
                      >
                        Actions
                      </Button>
                    )}
                  {/* <FileUploadWrapper
                  onSelectFile={(file) => {
                    formik.setFieldValue(
                      `${[docType.toLocaleLowerCase()]}_front`,
                      file,
                    );
                    onFileSelect(
                      file,
                      UPLOAD_FILE_TYPE.FRONT,
                      dropDownValue,
                      docType.toLocaleLowerCase(),
                      0,
                      (dropDownValue as any)?.documentSide[
                        `${docType.toLocaleLowerCase()}_front_doc_id`
                      ],
                    );
                  }}
                  disabled={!dropDownValue.documentName}
                  fileName={dropDownValue.documentName}
                  documentMappigId={dropDownValue.documentSide.FrontId}
                  bulkUpload={true}
                  isUpdateKyc={true}
                  updateKycId={frontDocValues?.id}
                  setIsEditSuccess={setIsEditSuccess}
                  title={frontDocFieldValue?.name ? 'Re Upload' : 'Upload'}
                  setUpdateLoader={setUpdateLoader}
                  minWidth='112px'
                  mode={mode}
                  formik={formik}
                /> */}
                </Box>
              </Box>
            )}
          </Box>
          {/* mobile */}

          <Box
            display={'flex'}
            flexDirection={isMobile ? 'column' : 'row'}
            gap={2}
            mt={'25px'}
            mb={'20px'}
          >
            {filteredFrontDocValues?.document_type === 'POI' && (
              <TextField
                sx={{
                  width: isMobile ? '200px' : '235px',
                }}
                variant='outlined'
                size='small'
                value={
                  filteredFrontDocValues?.latest_version?.document_no || ''
                }
                disabled
                label='POI number'
              />
            )}

            <DocumentExpiry document={filteredFrontDocValues} />
          </Box>
        </Grid>
        {/* <Divider
          orientation='horizontal'
          sx={{
            width: '100%',
            border: 'dashed 1px ',
            borderColor: theme.palette.warning.light,
          }}
        /> */}

        <Box
          height={'1px'}
          bgcolor={'#E5E7EB'}
          width={'100%'}
          borderBottom={'1px solid #E5E7EB'}
        ></Box>

        {((isBackSide &&
          frontDocFieldValue?.status !== UPLOADED_DOCUMENT_STATUS.APPROVED) ||
          (!!backDocValues?.name &&
            isBackSide &&
            frontDocFieldValue?.status ===
              UPLOADED_DOCUMENT_STATUS.APPROVED)) && (
          <Grid item xs={12} sm={12} md={12} mt={'5px'} mb={'20px'}>
            <Box
              sx={{ width: '100%' }}
              display='flex'
              flexDirection='column'
              alignItems={'flex-start'}
            >
              <Box
                display='grid'
                gridTemplateColumns={{
                  xs: !!backDocValues?.status ? '1fr' : '1fr auto',
                  md: '1fr auto',
                  sm: '1fr auto',
                }}
                alignItems='center'
                gap={1}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  gap={1}
                  sx={{ width: '100%' }}
                >
                  <TextField
                    size='small'
                    label={
                      <FormattedMessage
                        id={`myKycMessages.${
                          docType as keyof typeof myKycMessages
                        }`}
                        {...myKycMessages?.[
                          docType as keyof typeof myKycMessages
                        ]}
                        values={{ type: 'Back' }}
                      />
                    }
                    required={
                      (dropDownValue as any)?.documentSide?.BackSideRequired
                    }
                    fullWidth
                    sx={{
                      width: { xs: '224px', md: '236px' },
                      '@media (max-width: 392px)': {
                        width: '188px',
                      },
                    }}
                    disabled={
                      !isDisabled(options[0]?.sides[1]?.document_side) ||
                      !!uploadedData?.length
                    }
                    value={dropDownValue.documentName}
                  >
                    {options.map((opt) => (
                      <MenuItem key={opt.document_name} value={opt.sides[1].id}>
                        {opt.document_name}
                      </MenuItem>
                    ))}
                  </TextField>
                  {!!backDocValues?.status && (
                    <ShowStatus
                      height='42px'
                      width='113px'
                      status={backDocValues?.status.toUpperCase()}
                    />
                  )}
                  {!isMobile &&
                    backDocValues?.status &&
                    backDocValues?.status !== 'Pending' &&
                    backDocValues?.status !== 'Rejected' && (
                      <Button
                        variant='outlined'
                        sx={{
                          textTransform: 'none',
                          color: '#768098',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px',
                          height: '40px',
                          mx: 1,
                        }}
                        onClick={() => onViewRecord?.(filteredBackDocValues)}
                      >
                        {/* <img src={viewDoc} alt='viewDoc' /> */}
                        view
                      </Button>
                    )}
                </Box>

                <Box display='flex' justifyContent={'flex-end'} gap={2}>
                  {!isMobile &&
                    backDocFieldValue?.status !== undefined &&
                    backDocFieldValue?.status ===
                      UPLOADED_DOCUMENT_STATUS.PENDING && (
                      <Button
                        sx={{
                          height: '40px',
                          width: 'auto',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px',
                          ml: 1,
                        }}
                        variant='contained'
                        color='primary'
                        onClick={() =>
                          onApproveRejectRecord(filteredBackDocValues)
                        }
                      >
                        Actions
                      </Button>
                    )}
                  <Box
                    display='flex'
                    justifyContent={'flex-end'}
                    alignItems='center'
                    sx={{
                      gridColumn: {
                        xs: 'auto',
                        md: 'auto',
                      },
                    }}
                  >
                    {
                      <FileUploadWrapper
                        onSelectFile={(file) => {
                          formik.setFieldValue(
                            `${[docType.toLocaleLowerCase()]}_back`,
                            file,
                          );
                          onFileSelect(
                            file,
                            UPLOAD_FILE_TYPE.BACK,
                            dropDownValue,
                            docType.toLocaleLowerCase(),
                            1,
                            (dropDownValue as any)?.documentSide[
                              `${docType.toLocaleLowerCase()}_back_doc_id`
                            ],
                          );
                        }}
                        disabled={!dropDownValue.documentName}
                        fileName={dropDownValue.documentName}
                        documentMappigId={dropDownValue.documentSide.BackId}
                        bulkUpload={true}
                        isUpdateKyc={true}
                        updateKycId={backDocValues?.id}
                        setIsEditSuccess={setIsEditSuccess}
                        title={backDocFieldValue?.name ? 'Re Upload' : 'Upload'}
                        setUpdateLoader={setUpdateLoader}
                        minWidth='112px'
                        mode={mode}
                        formik={formik}
                      />
                    }
                  </Box>
                </Box>
              </Box>

              {!!backDocValues?.name && (
                <CustomTooltip title={backDocFieldValue?.name}>
                  <Typography
                    fontWeight={theme.typography.fontWeightRegular}
                    fontSize={14}
                    color={theme.palette.primary.main}
                    sx={{
                      bgcolor: blue[50],
                      display: 'inline-block',
                      width: { md: '27ch', xs: '22ch', sm: '20ch' },
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      marginTop: {
                        xs:
                          backDocFieldValue?.status ===
                          UPLOADED_DOCUMENT_STATUS.APPROVED
                            ? '-40px'
                            : backDocFieldValue?.status ===
                              UPLOADED_DOCUMENT_STATUS.PENDING
                            ? '-40px'
                            : backDocFieldValue?.status ===
                              UPLOADED_DOCUMENT_STATUS.REJECTED
                            ? '-40px'
                            : 1,
                        md: 1,
                        sm: 1,
                      },
                      marginBottom: {
                        xs:
                          backDocFieldValue?.status !==
                          UPLOADED_DOCUMENT_STATUS.REJECTED
                            ? 1
                            : '0px',
                        md: '0px',
                        sm: '0px',
                      },
                    }}
                    onClick={() =>
                      previewOrOpenFileInNewTab(
                        url_back,
                        backDocFieldValue,
                        setViewFile,
                      )
                    }
                  >
                    {backDocFieldValue?.name}
                  </Typography>
                </CustomTooltip>
              )}
              {isMobile && (
                <Box display='flex' justifyContent={'flex-end'} gap={2} mt={1}>
                  {backDocValues?.status &&
                    backDocValues?.status !== 'Pending' &&
                    backDocValues?.status !== 'Rejected' && (
                      <Button
                        variant='outlined'
                        sx={{
                          textTransform: 'none',
                          color: '#768098',
                          fontWeight: 'bold',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          gap: '8px',
                          height: '40px',
                          mx: 1,
                        }}
                        onClick={() => onViewRecord?.(filteredBackDocValues)}
                      >
                        {/* <img src={viewDoc} alt='viewDoc' /> */}
                        view
                      </Button>
                    )}
                  <Box display='flex' justifyContent={'flex-end'} gap={2}>
                    {backDocFieldValue?.status !== undefined &&
                      backDocFieldValue?.status ===
                        UPLOADED_DOCUMENT_STATUS.PENDING && (
                        <Button
                          sx={{
                            height: '40px',
                            width: 'auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '8px',
                            ml: 1,
                          }}
                          variant='contained'
                          color='primary'
                          onClick={() =>
                            onApproveRejectRecord(filteredBackDocValues)
                          }
                        >
                          Actions
                        </Button>
                      )}
                    <Box
                      display='flex'
                      justifyContent={'flex-end'}
                      alignItems='center'
                      sx={{
                        gridColumn: {
                          xs: 'auto',
                          md: 'auto',
                        },
                      }}
                    >
                      {/* <FileUploadWrapper
                      onSelectFile={(file) => {
                        formik.setFieldValue(
                          `${[docType.toLocaleLowerCase()]}_back`,
                          file,
                        );
                        onFileSelect(
                          file,
                          UPLOAD_FILE_TYPE.BACK,
                          dropDownValue,
                          docType.toLocaleLowerCase(),
                          1,
                          (dropDownValue as any)?.documentSide[
                            `${docType.toLocaleLowerCase()}_back_doc_id`
                          ],
                        );
                      }}
                      disabled={!dropDownValue.documentName}
                      fileName={dropDownValue.documentName}
                      documentMappigId={dropDownValue.documentSide.BackId}
                      bulkUpload={true}
                      isUpdateKyc={true}
                      updateKycId={backDocValues?.id}
                      setIsEditSuccess={setIsEditSuccess}
                      title={backDocFieldValue?.name ? 'Re Upload' : 'Upload'}
                      setUpdateLoader={setUpdateLoader}
                      minWidth='112px'
                      mode={mode}
                      formik={formik}
                    /> */}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
        )}
        {/* <Divider
          orientation='horizontal'
          sx={{
            width: '100%',
            border: 'dashed 1px ',
            borderColor: theme.palette.warning.light,
          }}
        /> */}
        <Box
          height={'1px'}
          bgcolor={'#E5E7EB'}
          width={'100%'}
          borderBottom={'1px solid #E5E7EB'}
        ></Box>
      </Grid>
      {viewFile?.open && (
        <ViewFileDialog
          file={viewFile?.file}
          viewFileDialog={!!viewFile?.open}
          closeViewFileDialog={() => setViewFile({ open: false, file: null })}
        />
      )}
    </Grid>
  );
};

export default DocumentFields;
