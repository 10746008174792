import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export const messages = defineMessages({
  viewPreviewDialogInfo: {
    id: 'fileUploader.viewPreviewDialogInfo',
    defaultMessage:
      ' Only images and pdf files can be previewed here, please download to view the file.',
  },
  close: {
    id: 'fileUploader.close',
    defaultMessage: 'Close',
  },
  download: {
    id: 'fileUploader.download',
    defaultMessage: 'Download',
  },
  viewDocument:{
    id: 'fileUploader.viewDocument',
    defaultMessage: 'View Document',
  },

});
