export const API_URLS = {
  FETCH_CREDIT_HISTORY: 'crm/user-credit-history',
  FETCH_TRADERS: '/crm/trading-accounts/approved',

  SET_CREDIT: (user_id: string, trading_account_id: string) =>
    `crm/credit/user/${user_id}/trading-accounts/${trading_account_id}`,

  // GX_INJECT_API_URL
};

export const QUERY_KEYS = {
  FETCH_CREDIT_HISTORY: 'fetchCreditHistory',
  FETCH_TRADERS: 'traders',

  // GX_INJECT_QUERY_KEY
};
