// Header.js
import { USER_PROFILE_TABS } from '../constants';
import { Box, Tabs, Tab } from '@mui/material';
import { FormattedMessage } from '~app/Integrations/Localization';
import { StyledTab, StyledTabs } from '~/constants/styles';

const UserProfileHeader = ({
  value,
  onChange,
}: {
  value: number;
  onChange: any;
}) => (
  <Box mb={'32px'} sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <StyledTabs
      value={value}
      onChange={onChange}
      variant='scrollable'
      scrollButtons='auto'
    >
      {USER_PROFILE_TABS.map((tab, index) => (
        <StyledTab key={index} label={<FormattedMessage {...tab.label} />} />
      ))}
    </StyledTabs>
  </Box>
);

export default UserProfileHeader;
