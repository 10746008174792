import messages from './messages';

// status from api
export const WITHDRAWALS_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const WITHDRAWALS_STATUS_CONFIG = {
  [WITHDRAWALS_STATUS.PENDING]: {
    label: messages.pending,
    color: 'secondary',
  },
  [WITHDRAWALS_STATUS.APPROVED]: {
    label: messages.approved,
    color: 'success',
  },
  [WITHDRAWALS_STATUS.REJECTED]: { label: messages.rejected, color: 'error' },
};

// export const WITHDRAWAL_TRASCATION_TYPE = {
//   DEPOSIT: 'DEPOSIT',
// };

export const getAccountValue = (record: any) => {
  if (record?.account_type === 'Wallet') {
    return record?.account_id?.id;
  } else if (record?.account_type === 'Trading') {
    return record?.account_id?.trading_account;
  } else {
    return '';
  }
};

// Define a function to get the account label based on account_type
export const getAccountLabel = (record: any) => {
  if (record?.account_type === 'Wallet') {
    return 'From Wallet';
  } else if (record?.account_type === 'Trading') {
    return 'From Trading';
  } else {
    return '';
  }
};
