import ProfileTabs from './modules/Profile/ProfileTabs';
import BankAccounts from './modules/BankAccount/index';
import TradingAccount from './modules/TradingAccount';
import messages from './messages';
import Wallet from './modules/Wallet/index';
import TransactionTabs from './modules/Transactions/TransactionTabs';
import KycDocs from '../Kyc';
import MyKycLayout from '../MyKyc/components/MyKycLayout';
import { LanguageType, GenderType } from './types';

const QUERY_KEYS = {
  FETCH_USER_PROFILE: 'fetchUserProfile',
  FETCH_BANK_ACCOUNT_INFOS: 'fetchBankAccountInfos',
  FETCH_KYC: 'kycDocuments',
  FETCH_TRADING_ACCOUNTS: 'fetchTradingAccounts',
  FETCH_WALLET: 'fetchWalletDetails',
  FETCH_DEPOSIT: 'fetchUserDeposits',
  FETCH_WITHDRAWAL: 'fetchUserWithdrawals',
};

export const USER_PROFILE_TABS = [
  {
    profile: 1,
    label: messages.userProfile,
    component: <ProfileTabs />,
  },
  {
    bank_Account: 2,
    label: messages.bankAccount,
    component: <BankAccounts />,
  },
  {
    Kyc_Document: 3,
    label: messages.kycDocument,
    component: <MyKycLayout />,
  },
  {
    Trading_Accounts: 4,
    label: messages.tradingAccounts,
    component: <TradingAccount />,
  },
  {
    Wallets: 5,
    label: messages.wallets,
    component: <Wallet />,
    key: QUERY_KEYS.FETCH_WALLET,
  },
  {
    Transactions: 6,
    label: messages.transactions,
    component: <TransactionTabs />,
  },
];
