import { useMutation } from 'v2s-ui-core/app/Integrations/react-query';
import useToast from '~/hooks/useToast';
import { MutationDetails } from '../services/mutation/types';
import useMutationFactory from '../services/mutation/useMutationFactory';
import { useShowToastNotifications } from '~app/containers/ToastNotifications/Provider';

const useUpdateKycDocument = <TData, TError = Error>(
  args?: ReturnType<MutationDetails<TData, TError>['updateUserKycDocument']>,
) => {
  const mutationDetails = useMutationFactory<TData, TError>();
  const { triggerErrorToast } = useToast();
  const { showToastNotification } = useShowToastNotifications();

  const {
    mutate: updateUserKycDocument,
    status,
    data,
    isPending,
    error,
  } = useMutation({
    ...mutationDetails.updateUserKycDocument(),
    ...args,
    onError: (error: any) => triggerErrorToast(error),
  });
  return {
    updateUserKycDocument,
    status,
    data,
    error,
    isPending,
    showToastNotification,
  };
};

export default useUpdateKycDocument;
