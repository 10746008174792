import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { useEdit } from '~/hooks/Edit/useEdit';
import { API_URLS } from '../api/config';
import { QUERY_KEYS } from '../services/queries/constants';
import React, { useState } from 'react';
import { validationSchema } from '../constants';
import { usePostRecord } from '~/hooks/usePostRecord';
import { useLocation } from 'react-router-dom';
import { useFetchData } from '~/hooks/fetchData/useFetchData';

export const useAddBankAccount = ({
  setShowAddBankAccountModal,
  showAddBankAccountModal,
}: {
  setShowAddBankAccountModal?: any;
  showAddBankAccountModal?: boolean;
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const location = useLocation();
  const userId = location.state;
  const { onConfirmAddRecord, showSuccessModal, onSuccessClose, status } =
    usePostRecord({
      API_URL: API_URLS.ADD_BANK_ACCOUNT,
      QUERY_KEYS: 'fetchBankAccounts',
      setShowAddBankAccountModal,
      showAddBankAccountModal,
    });

  const { data, refetch } = useFetchData<any>({
    url: `${API_URLS.FETCH_USER_DATA}${userId}`,
    queryKey: `${QUERY_KEYS.FETCH_USERS}+${userId}`,
  });

  React.useEffect(() => {
    refetch();
  }, [userId]);

  const formik = useFormik({
    initialValues: {
      bank_name: '',
      bank_account_name: '',
      bank_account_number: '',
      bank_iban: '',
      bank_code: '',
      bank_address: '',
      user_document: '',
    },
    validationSchema: validationSchema(
      formatMessage,
      data?.data?.country?.countryEn,
    ),
    onSubmit: (values, { resetForm }) => {
      onConfirmAddRecord({
        user: userId,
        bank_name: values.bank_name,
        bank_account_name: values.bank_account_name,
        bank_account_number: values.bank_account_number,
        bank_iban: values.bank_iban,
        bank_code: values.bank_code,
        bank_address: values.bank_address,
        user_document: values.user_document,
      });
      resetForm();
    },

    enableReinitialize: true,
  });

  return { formik, showSuccessModal, onSuccessClose, status };
};
