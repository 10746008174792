import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import messages from '~/modules/Withdrawal/messages';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
import { Box, Typography } from '@mui/material';
import { ViewWithdrawalProps } from '~/modules/Withdrawal/types';

const ViewWithdrawal = ({
  onCancel,
  recordToView,
}: {
  onCancel: () => void;
  recordToView: ViewWithdrawalProps;
}) => {
  const isMobile = useIsMobile();

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          width: isMobile ? '100vw' : '600px',
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        <FormattedMessage {...messages.view_Withdrawal} />
        <CloseButton onClose={onCancel} />
      </DialogTitle>
      <DialogContent sx={{ padding: 1 }}>
        <Box>
          <Grid container spacing={2} p={isMobile ? '8px' : '16px'} pt={2}>
            <>
              <Grid item xs={12}>
                <Typography>
                  <FormattedMessage {...messages.payment_details} />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name={'payment_method'}
                  fullWidth
                  variant='outlined'
                  value={recordToView.payment_method}
                  label={<FormattedMessage {...messages.payment_method} />}
                  size='small'
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name={'from_account'}
                  fullWidth
                  variant='outlined'
                  value={recordToView?.account_type}
                  label={<FormattedMessage {...messages.from_account} />}
                  size='small'
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  type='number'
                  name={'local_amount'}
                  value={recordToView.local_amount}
                  variant='outlined'
                  label={<FormattedMessage {...messages.localAmount} />}
                  size='small'
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name={'local_currency'}
                  variant='outlined'
                  value={recordToView.local_currency}
                  label={<FormattedMessage {...messages.local_currency} />}
                  size='small'
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name={'trading_amount'}
                  value={recordToView.trading_amount}
                  type='number'
                  variant='outlined'
                  label={<FormattedMessage {...messages.tradingAmount} />}
                  size='small'
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name={'trading_currency'}
                  variant='outlined'
                  value={recordToView.trading_currency}
                  label={<FormattedMessage {...messages.tradingCurrency} />}
                  size='small'
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Bank Details</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name={'bank_name'}
                  variant='outlined'
                  label={<FormattedMessage {...messages.bank_name} />}
                  value={recordToView.bank_account?.bank_name}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name={'bank_account_holder_name'}
                  variant='outlined'
                  label={
                    <FormattedMessage {...messages.bank_account_holder_name} />
                  }
                  value={recordToView.bank_account?.bank_account_name}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name={'bankAccountNumber'}
                  variant='outlined'
                  label={<FormattedMessage {...messages.bankAccountNumber} />}
                  value={recordToView.bank_account?.bank_account_number}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name={'bank_code'}
                  variant='outlined'
                  label={<FormattedMessage {...messages.bank_ifsc} />}
                  value={recordToView.bank_account?.bank_code}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>
              {recordToView.bank_account?.bank_iban &&
                recordToView.bank_account?.bank_iban !== '' && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name={'bank_iban'}
                      variant='outlined'
                      label={<FormattedMessage {...messages.bank_iban} />}
                      value={recordToView.bank_account?.bank_iban}
                      fullWidth
                      size='small'
                      disabled
                    />
                  </Grid>
                )}
              <Grid item xs={12}>
                <TextField
                  name={'notes'}
                  variant='outlined'
                  label={<FormattedMessage {...messages.notes} />}
                  value={recordToView.note}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>
            </>
          </Grid>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: 2 }}>
        <Button onClick={onCancel} variant='outlined'>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewWithdrawal;
