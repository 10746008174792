import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ActionButtons from '~/components/Actions/ActionButtons';
import { ACCOUNT_STATUS, ACCOUNT_STATUS_CONFIG } from '../../constants';
import TruncatedHeader from '~/components/TruncatedHeader';
import { AccountStatus } from '../../types';
import DateFilterComponent from '~/components/DateFIlter';
export const LeverageChangeTableColumns = (props: {
  onApproveRecord?: Function;
  onRejectRecord?: Function;
  handleViewLeverage?: Function;
}): ColumnDef<AccountStatus>[] => [
  {
    accessorKey: 'id',
    header: 'Request ID',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'user_first_name',
    cell: ({ row }: any) => row.original?.trading_account?.user?.first_name,
    header: 'First Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_last_name',
    cell: ({ row }: any) => row.original?.trading_account?.user?.last_name,
    header: 'Last Name',
    enableColumnFilter: true,
  },
  {
    accessorFn: (row) => row.trading_account?.leverage,
    header: 'Current Leverage',
    enableColumnFilter: true,
    accessorKey: 'current_leverage',
  },
  {
    accessorKey: 'requested_leverage',
    header: () => <TruncatedHeader maxLength={14} text='Requested Leverage' />,
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account',
    cell: ({ row }: any) => row.original?.trading_account?.id,
    header: 'Trading Account ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_account_login',
    cell: ({ row }: any) => row.original?.trading_account?.login,
    id: 'trading_account_login',
    header: () => (
      <TruncatedHeader maxLength={15} text='Trading Account Login' />
    ),
    enableColumnFilter: true,
  },
  {
    accessorKey: 'account_type',
    cell: ({ row }: any) => row.original?.trading_account?.account_type?.type,
    header: 'Account Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_group',
    header: 'Trading Group',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'status',
    header: 'Request Status',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'remark',
    header: 'Remark',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'created_by.email',
    header: 'Created By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_by',
    header: 'Updated By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    enablePinning: true,
    enableColumnFilter: false,

    cell: ({ cell }) => {
      const { row } = cell;
      const status = row.original.status;

      return (
        <>
          <ActionButtons
            status={status}
            onApproveRecord={props?.onApproveRecord}
            onRejectRecord={props?.onRejectRecord}
            rowData={row.original}
            ACCOUNT_STATUS={ACCOUNT_STATUS}
            ACCOUNT_STATUS_CONFIG={ACCOUNT_STATUS_CONFIG}
            handleViewLeverage={props?.handleViewLeverage}
            showLeverageHistory
          />
        </>
      );
    },
  },
];
