import Box from 'v2s-ui-core/app/components/Box';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import useFilters from 'v2s-ui-core/app/hooks/useFilters';
interface Column {
  accessorKey: string;
  header: string;
}
const IBAgreementHistory = ({
  resource,
  queryKeyProp,
  columns,
  type,
  userId,
  formik,
  mode,
  RESOURCE_API_URL,
  AGREEMENT_QUERY_KEY,
  renderListItem,
}: {
  resource: string;
  queryKeyProp: string;
  columns: Column[];
  type?: any;
  userId?: any;
  formik: any;
  mode: any;
  RESOURCE_API_URL: any;
  AGREEMENT_QUERY_KEY: any;
  renderListItem: any;
}) => {
  const { filters, applyFilters } = useFilters({
    namespace: 'ibAgreement',
    filterTypes: {},
    initFilters: {
      tier: formik?.values?.tier?.id,
    },
  });

  return (
    <Box className='ScrollableTableContainer'>
      <DataGridWithQuery
        queryProps={{
          resource: RESOURCE_API_URL,

          queryKeyProp: [AGREEMENT_QUERY_KEY],
          select: (data: any) => renderListItem(data),
          enabled: true,
        }}
        columns={columns}
        isPaginated={false}
        filters={filters}
        key={AGREEMENT_QUERY_KEY}
      />
    </Box>
  );
};
export default IBAgreementHistory;
