import { useNavigate } from 'react-router-dom';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import messages from '../../messages';

const SubIbDetailCard = ({ data }: any) => {
  const navigate = useNavigate();
  return (
    <Grid container item xs={12} spacing={2} sm={'auto'}>
      <Grid item width={{ md: '308px', sm: '274px' }} xs={12} sm={'auto'}>
        <TextField
          label={<FormattedMessage {...messages.masterIBName} />}
          name='master_ib_name'
          InputProps={{
            readOnly: true,

            style: {
              height: 40,
            },
          }}
          value={`${data?.data?.user?.first_name} ${data?.data?.user?.last_name}`}
          disabled
          fullWidth
        />
      </Grid>
      <Grid item width={{ md: '308px', sm: '274px' }} xs={12} sm={'auto'}>
        <TextField
          disabled
          InputProps={{
            readOnly: true,
            style: {
              height: 40,
            },
          }}
          label={<FormattedMessage {...messages.masterIBID} />}
          name='master_ib_id'
          value={data?.data?.user?.id || ''}
          fullWidth
        />
      </Grid>
      <Grid
        item
        width={{ md: '308px', sm: '274px', cursor: 'pointer' }}
        xs={12}
        sm={'auto'}
      >
        <TextField
          label={<FormattedMessage {...messages.masterIBEmail} />}
          sx={{
            cursor: 'pointer',
            '& .MuiInputBase-input': {
              cursor: 'pointer',
              textDecoration: 'underline',
              color: 'blue',
            },
          }}
          name='master_ib_email'
          InputProps={{
            onClick: () => {
              navigate(APPLICATION_URLS.IB_PROFILE, {
                state: data?.data?.user?.id,
              });
            },
            style: {
              cursor: 'pointer !important',
              textDecoration: 'underline',
              color: 'blue',
              height: 40,
            },
          }}
          value={data?.data?.user?.email || ''}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default SubIbDetailCard;
