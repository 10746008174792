import * as yup from 'yup';
import messages from './messages';
import {
  DOCUMENT_NO_REGEX,
  DOCUMENT_SIDE,
  REMARK_VALIDATION_REGEX,
} from '~/constants';
import {
  getAddressLine1Schema,
  getAddressLine2Schema,
  getCityTownSchema,
  getCountrySchema,
  getDateOfBirthSchema,
  getFirstNameSchema,
  getLastNameSchema,
  getStateProvinceSchema,
  getZipPostalCodeSchema,
} from '~/schema/ProfileSchema';
import { DOCUMENT_TYPES } from '~/constants';

export const MUTATION_KEYS = {
  APPROVE_REJECT_BANK_ACCOUNT: 'approveRejectBankAccount',
  // GX_INJECT_MUTATION_KEY
} as const;

export const getKycApproveRejectSchema = (
  formatMessage: any,
  Doctype: any,
  side: any,
) =>
  yup.object().shape({
    first_name: getFirstNameSchema(formatMessage),
    ...(Doctype === DOCUMENT_TYPES.POA || Doctype === DOCUMENT_TYPES.POI
      ? {
          expire_at: yup
            .string()
            .required(formatMessage(messages.expireAtRequired)),
        }
      : {}),
    last_name: getLastNameSchema(formatMessage),
    country: getCountrySchema(formatMessage),
    remark: yup
      .string()
      .required(formatMessage(messages.remarkRequired))
      .matches(
        REMARK_VALIDATION_REGEX,
        formatMessage(messages.remarkValidation),
      )
      .min(4, formatMessage(messages.remarkMinLength))
      .max(100, formatMessage(messages.remarkMaxLength)),
    ...(Doctype === DOCUMENT_TYPES.POI &&
      side === DOCUMENT_SIDE.FRONT && {
        document_no: yup
          .string()
          .required(formatMessage(messages.documentNumberRequired))
          .matches(
            DOCUMENT_NO_REGEX,
            formatMessage(messages.documentNumberValidation),
          )
          .min(4, formatMessage(messages.documentNumberMinLength))
          .max(30, formatMessage(messages.documentNumberMaxLength)),
      }),

    ...(Doctype === DOCUMENT_TYPES.POI
      ? { date_of_birth: getDateOfBirthSchema(formatMessage) }
      : {}),
    ...(Doctype === DOCUMENT_TYPES.POA && {
      address_line1: getAddressLine1Schema(formatMessage),
      address_line2: getAddressLine2Schema(formatMessage),
      city_town: getCityTownSchema(formatMessage),
      zip_postal_code: getZipPostalCodeSchema(formatMessage),
      state_province: getStateProvinceSchema(formatMessage),
    }),
  });
