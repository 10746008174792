/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import { useUserAuth } from '~/shell/components/Auth/AuthProvider';
import useToast from '../../../hooks/useToast';
import { COMPLETE_KYC_FORM_VALUES } from '../constants';
import useMutationFactory from '../services/mutation/useMutationFactory';
import { QUERY_KEYS } from '../services/quries/constants';
import useGetUploadedDoc from './useGetUploadedDoc';
import { useLocaleContext } from '~app/Integrations/Localization';
import { FileStateItem } from '../config/types';

const useUploadKycDocument = ({
  setDocuments,
  isEditSuccess,
  setIsEditSuccess,
}: {
  setDocuments: any;
  isEditSuccess: any;
  setIsEditSuccess: any;
}) => {
  const { uploadUserKycDocument, updateBulkDocument } = useMutationFactory();
  const { DocumentTypeList, auth } = useUserAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [uploadedFiles, setUploadedFiles] = useState<FileStateItem[]>([
    {} as FileStateItem,
  ]);
  const [error, setError] = useState(null);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const { mutate } = useMutation<any, any, any>({
    ...uploadUserKycDocument(auth?.id),
  });
  const { mutate: updateUserKycDocumentMutation } = useMutation<any, any, any>({
    ...updateBulkDocument(auth?.id),
  });
  const { triggerErrorToast } = useToast();
  const queryClient = useQueryClient();

  const {
    uploadedDoc,
    refetch,
    isLoading: isGetDocLoader,
  } = useGetUploadedDoc(auth.id);

  const onCloseSuccessModal = () => {
    setIsShowSuccess(false);
  };
  const onSuccessResponse = (data: any, formik: any) => {
    setIsShowSuccess(true);
    setIsLoading(false);
    setUploadedFiles([{} as FileStateItem]);
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_UPLOADED_DOC_LIST],
    });
    formik.setFieldValue('bulk_files', null);
  };
  const onSubmitUserKycDocument = (payload: any, formik: any) => {
    if (uploadedDoc?.data?.length) {
      const payloadWithOutDocMapId = payload?.documents?.map((item: any) => {
        const { document_type_mapping, docType, ...rest } = item;
        return rest;
      });
      updateUserKycDocumentMutation(
        { documents: payloadWithOutDocMapId },
        {
          onSuccess: (data) => onSuccessResponse(data, formik),
          onError: (error) => {
            triggerErrorToast(error);
            setError(error);
            setIsLoading(false);
          },
        },
      );
    } else {
      const payloadWithOutTypeMapId = payload?.documents?.map((item: any) => {
        const { document_type_mapping, user_document_id, docType, ...rest } =
          item;
        return rest;
      });
      mutate(
        { documents: payloadWithOutTypeMapId },
        {
          onSuccess: (data) => onSuccessResponse(data, formik),
          onError: (error) => {
            triggerErrorToast(error);
            setError(error);
            setIsLoading(false);
          },
        },
      );
    }
  };

  const formik = useFormik({
    initialValues: COMPLETE_KYC_FORM_VALUES,

    onSubmit: (values) => {
      setIsLoading(true);
      const payload = {
        documents: values.bulk_files,
      };
      onSubmitUserKycDocument(payload, formik);
    },
  });

  useEffect(() => {
    if (DocumentTypeList) {
      setDocuments(DocumentTypeList);
    }
    if (isEditSuccess) {
      setIsEditSuccess(false);
      setIsShowSuccess(true);
      refetch();
    }
  }, [DocumentTypeList, isEditSuccess]);
  useEffect(() => {
    refetch();
  }, []);
  return {
    formik,
    isLoading,
    error,
    uploadedDoc,
    refetchUploadedDocApi: refetch,
    isGetDocLoader,
    uploadedFiles,
    setUploadedFiles,
    isShowSuccess,
    setIsShowSuccess,
    onCloseSuccessModal,
    formatMessage,
  };
};
export default useUploadKycDocument;
