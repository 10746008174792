import React, { useMemo } from 'react';
import WalletDetails from './components/WalletDetails';
import { WalletHistoryColumn } from './components/WalletHistoryColumn';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { Box, Typography } from '@mui/material';
import { API_URLS } from './api/config';
import { ColumnDef } from '~app/components/DataGridCore';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import messages from './messages';
import TableFilters from '../../../../components/TableFilters';
import useFilters from '~app/hooks/useFilters';
import { FILTERS } from '~/config/constants';
import { QUERY_KEYS } from './constants';
const Wallet = () => {
  const location = useLocation();
  const memoizedColumns = useMemo(() => WalletHistoryColumn(), []);

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: QUERY_KEYS.FETCH_WALLET_DETAILS,
  });

  return (
    <>
      <WalletDetails />
      <Box mt={'64px'}>
        <Typography fontSize={'20px'} pb={'44px'}>
          <FormattedMessage {...messages.walletHistory} />
        </Typography>
        <Box className='tableContainer' width={'100%'}>
          <Box display='flex' mb={'26px'}>
            <TableFilters filters={filters} applyFilters={applyFilters} />
          </Box>
        </Box>
        <Box className='ScrollableTableContainer'>
          <DataGridWithQuery
            queryProps={{
              resource: API_URLS.FETCH_WALLET_DETAILS(location.state),
              select: (data: any) => data.data?.transaction_histories,
              queryKeyProp: [QUERY_KEYS.FETCH_WALLET_DETAILS],
            }}
            columns={memoizedColumns as ColumnDef<unknown>[]}
            filters={filters}
          />
        </Box>
      </Box>
    </>
  );
};

export default Wallet;
