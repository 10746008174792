export const API_ERROR_TYPES = {
  SERVER_ERROR: 'server_error',
  VALIDATION_ERROR: 'validation_error',
} as const;

export const BREADCRUMBS: Record<string, string> = {
  dashboard: 'Dashboard',
  traders: 'Traders',
  deposits: 'Deposits',
  withdrawal: 'withdrawal',
  'user-profile': 'User Profile',
  'bank-accounts': 'Bank Accounts',
  requests: 'Requests',
  'new-trading-account': 'New Trading Account',
  'leverage-change': 'Leverage Change',
  'password-change': 'Password Change',
  credit: 'Credit',
  'credit-in': 'Credit In',
  'credit-out': 'Credit Out',
  'user-management': 'User Management',
  transfers: 'Transfer',
  'ib-management': 'IB Management',
  ibs: 'IBs',
  'ib-network': 'IB Network',
  'ib-profile': 'IB Profile',
  emailing: 'Emailing',
  templates: 'Templates',
  events: 'Events',
  'roles-and-permissions': 'Roles & Permissions',
  'add-new-template': 'Add New Template',
  'crm-user-actions': 'Actions',
  reports: 'Reports',
  'clients-list': 'Clients List',
  'deposit-report': 'Deposit Report',
  'withdrawal-report': 'Withdrawal Report',
  'transfer-report': 'Transfer Report',
  permissions: 'Permissions',
};

export const CONTENT_TYPE_PDF = 'application/pdf';
export const CONTENT_TYPES_IMAGE = [
  'image/png',
  'image/jpeg',
  'image/jpg',
] as const;

export type ContentType =
  | typeof CONTENT_TYPE_PDF
  | (typeof CONTENT_TYPES_IMAGE)[number];

export const FILE_EXTENSION_PDF = '.pdf';
export type FileExtension = typeof FILE_EXTENSION_PDF;

export const AGREEMENT_TYPE = {
  SPREAD_REVENUE: 'SPREAD_REVENUE',
  FIXED_REBATE: 'FIXED_REBATE',
};
export const REBATE_TYPE = {
  SPREAD_REVENUE: 'spread-revenue',
  FIXED_REBATE: 'fixed-rebate',
};
export const REBATE_STRUCTURE_VALUE = {
  SPREAD_REVENUE: 1,
  FIXED_REBATE: 2,
};
export const MODE = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};
export const VALIDATION_MAX_LENGTHS = {
  ACTION_NAME: 15,
  ROLE_NAME: 15,
  PERMISSION_NAME: 50,
};

export const REQUEST_STATUS = {
  ERROR: 'error',
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
} as const;

export const REMARK_VALIDATION_REGEX =
  /^[a-zA-Z0-9 .*\+\?\^\$\{\}\(\)\[\]\|\\/]*$/;

export const DOCUMENT_NO_REGEX = /^[a-zA-Z0-9]+$/;

export const DOCUMENT_STATUS = {
  APPROVE: 'approve',
  REJECT: 'reject',
  PENDING: 'pending',
} as const;

export const DOCUMENT_TYPES = {
  POI: 'POI',
  POA: 'POA',
  OTHERS: 'others',
} as const;

export const DOCUMENT_SIDE = {
  FRONT: 'Front',
  BACK: 'Back',
} as const;
