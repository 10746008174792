import { ListItemIcon, Typography } from '@mui/material';
import { TreeItem, treeItemClasses } from '@mui/x-tree-view/TreeItem';
import { alpha, styled } from 'v2s-ui-core/app/styles';
import TraderIcon from '~/assets/images/TraderIcon.svg';
import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import AppBaseLayout, {
  AppBaseLayoutProps,
} from '~/shell/components/Layout/components/AppLayout';
import IBGenealogy from './modules/IBGenealogy';
import IBs from './modules/IBs';
import PersonIcon from '@mui/icons-material/Person';
import { QUERY_KEYS } from './api/config';

const SUB_ROUTES_FOR_IB_MANAGEMENT_MAP: {
  path: string;
  element: typeof AppBaseLayout;
  AppBaseLayoutProps: AppBaseLayoutProps;
}[] = [
  {
    path: APPLICATION_URLS.IBS,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'IBs',
      },
      contentProps: {
        content: <IBs />,
      },
      ShowRefresh: true,
      QUERY_KEYS: QUERY_KEYS.FETCH_IBS,
    },
  },

  {
    path: APPLICATION_URLS.IB_GENEALOGY,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'IB Genealogy',
      },
      contentProps: {
        content: <IBGenealogy />,
      },
    },
  },
];
export { SUB_ROUTES_FOR_IB_MANAGEMENT_MAP };

const CustomTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    padding: theme.spacing(0.5, 1),
    margin: theme.spacing(0.2, 0),
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
  },
}));

export const USER_TYPE = {
  Individual_Trader: 'Individual_Trader',
  Individual_IB: 'Individual_IB',
};

function EndIcon(props: any) {
  return (
    <ListItemIcon
      sx={{
        minWidth: 0,
        pr: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <PersonIcon
        color={
          props?.userType === USER_TYPE.Individual_Trader
            ? 'secondary'
            : 'primary'
        }
      />
    </ListItemIcon>
  );
}

export const renderMasterIBTreeItems = (node?: any) => (
  <>
    {node?.sub_ibs?.length > 0 ? (
      <CustomTreeItem
        key={node?.id}
        itemId={node?.id?.toString()}
        label={
          <TreeLabelView
            firstName={node?.first_name}
            lastName={node?.last_name}
            userType={node?.user_type}
            phone_number={node?.phone_number}
            email={node?.email}
            level={node?.level}
            isIndividualIbTradingIcon={node?.sub_ibs?.length === 0}
          />
        }
      >
        {node?.direct_trader?.length > 0 &&
          node.direct_trader?.map((subNode: any) =>
            renderTradingTreeItems(subNode),
          )}

        {node?.sub_ibs?.length > 0 &&
          node.sub_ibs?.map((subNode: any) => renderTreeItems(subNode))}
      </CustomTreeItem>
    ) : (
      <TreeLabelView
        firstName={node?.first_name}
        lastName={node?.last_name}
        userType={node?.user_type}
        phone_number={node?.phone_number}
        email={node?.email}
        level={node?.level}
        isIndividualIbTradingIcon={node?.sub_ibs?.length === 0}
        id={node?.id}
      />
    )}
  </>
);

export const renderTreeItems = (node?: any) => (
  <>
    {node?.sub_ibs?.length > 0 ? (
      <CustomTreeItem
        key={node?.id}
        itemId={node?.id?.toString()}
        label={
          <TreeLabelView
            firstName={node?.first_name}
            lastName={node?.last_name}
            userType={node?.user_type}
            phone_number={node?.phone_number}
            email={node?.email}
            level={node?.level}
            isIndividualIbTradingIcon={node?.sub_ibs?.length === 0}
            id={node?.id}
          />
        }
      >
        {node?.direct_trader?.length > 0 &&
          node.direct_trader?.map((subNode: any) =>
            renderTradingTreeItems(subNode),
          )}

        {node?.sub_ibs?.length > 0 &&
          node.sub_ibs?.map((subNode: any) => renderTreeItems(subNode))}
      </CustomTreeItem>
    ) : (
      <TreeLabelView
        firstName={node?.first_name}
        lastName={node?.last_name}
        userType={node?.user_type}
        phone_number={node?.phone_number}
        email={node?.email}
        level={node?.level}
        isIndividualIbTradingIcon={node?.sub_ibs?.length === 0}
        id={node?.id}
      />
    )}
  </>
);

// export const renderTreeItems = (node?: any) => (
//   <CustomTreeItem
//     key={node?.id}
//     itemId={node?.id?.toString()}
//     label={
//       <TreeLabelView
//         firstName={node?.first_name}
//         lastName={node?.last_name}
//         userType={node?.user_type}
//         phone_number={node?.phone_number}
//         email={node?.email}
//         level={node?.level}
//         isIndividualIbTradingIcon={node?.sub_ibs?.length === 0}
//       />
//     }
//   >
//     {node?.direct_trader?.length > 0 &&
//       node.direct_trader?.map((subNode: any) =>
//         renderTradingTreeItems(subNode),
//       )}

//     {node?.sub_ibs?.length > 0 &&
//       node.sub_ibs?.map((subNode: any) => renderTreeItems(subNode))}
//   </CustomTreeItem>
// );

export const renderTradingTreeItems = (node: any) => {
  if (!node) return null;

  return (
    <TreeLabelView
      firstName={node?.first_name}
      lastName={node?.last_name}
      userType={node?.user_type}
      phone_number={node?.phone_number}
      email={node?.email}
      level={node?.level}
      separator={', '}
      id={node?.id}
      isTradingIcon
    />
  );
};

const TreeLabelView = (props: any) => (
  <Typography display={'flex'} alignItems={'center'} key={props?.id}>
    {(props?.isTradingIcon || props?.isIndividualIbTradingIcon) && (
      <EndIcon userType={props?.userType} />
    )}
    {`${props?.firstName} ${props?.lastName}, ${props?.email}, ${props?.phone_number}, Account Type:${props?.userType}, Level:${props?.level}`}
  </Typography>
);
