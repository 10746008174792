/* eslint-disable max-params */
import React from 'react';
import {
  ALLOWED_UPLOAD_FILE_LISTS,
  ALLOWED_UPLOAD_FILE_SIZE,
  GET_KYC_UPLOAD_MODE,
} from '~/config/constants';
import messages from '~/constants/messages';

export const handleUpload = (
  event: React.ChangeEvent<HTMLInputElement>,
  bulkUpload?: boolean,
  mode?: string,
  onSelectFile?: (file: File) => void,
  isUpdateKyc?: boolean,
  updateKycId?: number,
  documentMappigId?: number,
  fileName?: string,
  mutate?: any,
) => {
  const file = event.target.files[0];
  if (bulkUpload && mode === GET_KYC_UPLOAD_MODE.ADD) {
    onSelectFile(file);
  } else if (isUpdateKyc && !!updateKycId) {
    onSelectFile(file);
  } else if (isUpdateKyc && !updateKycId && mode === GET_KYC_UPLOAD_MODE.EDIT) {
    onSelectFile(file);
  } else {
    mutate({
      file,
      fileName: fileName ?? 'Bank_statement',
      documentMappigId,
    });
  }
};

export const onFileUploadChange = (
  file: any,
  event: React.ChangeEvent<HTMLInputElement>,
  bulkUpload?: boolean,
  mode?: string,
  onSelectFile?: (file: File) => void,
  isUpdateKyc?: boolean,
  updateKycId?: number,
  documentMappigId?: number,
  fileName?: string,
  mutate?: any,
  formik?: any,
  fieldKey?: string,
  showToastNotification?: any,
  formatMessage?: any,
): void => {
  if (
    file &&
    file.size > ALLOWED_UPLOAD_FILE_SIZE &&
    !formik?.values?.[fieldKey]
  ) {
    event.target.value = null;

    showToastNotification(`${formatMessage(messages.fileToLarge)}`, 'error');
    return null;
  } else if (file && !ALLOWED_UPLOAD_FILE_LISTS.includes(file.type)) {
    event.target.value = null;

    showToastNotification(
      `${formatMessage(messages.fileFormatAllowed)}`,
      'error',
    );
    return null;
  }
  handleUpload(
    event,
    bulkUpload,
    mode,
    onSelectFile,
    isUpdateKyc,
    updateKycId,
    documentMappigId,
    fileName,
    mutate,
  );
};
