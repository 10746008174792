import React, { useEffect } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { getTabConfig } from './constants';
import { StyledTab, StyledTabs } from '~/constants/styles';
import { useTabToggle } from '~/hooks/useTabToggle';
import { useFetchData } from '~/hooks/fetchData/useFetchData';
import { useLocation } from 'react-router-dom';
import { UserProfileData } from './types';

const ProfileTabs = () => {
  const [selectedProfileTab, setSelectedProfileTab] = React.useState(0);
  const location = useLocation();
  const { data, refetch } = useFetchData<UserProfileData>({
    url: `user/${location.state}`,
    queryKey: 'getUserProfile',
  });
  useEffect(() => {
    refetch();
  }, [location.state]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedProfileTab(newValue);
  };
  const { onTabChange, onTabSubmit } = useTabToggle();

  return (
    <Box>
      <StyledTabs
        value={selectedProfileTab}
        onChange={handleChange}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginX: '5px',
        }}
      >
        {getTabConfig({
          onTabSubmit,
          userType: data?.data?.user_type_dd?.type,
          onTabChange,
        }).map((item) => (
          <StyledTab key={item.position} label={item.label} />
        ))}
      </StyledTabs>
      <Box sx={{ padding: 0 }}>
        {
          getTabConfig({
            onTabSubmit,
            userType: data?.data?.user_type_dd?.type,
            onTabChange,
          })[selectedProfileTab]?.element
        }
      </Box>
    </Box>
  );
};

export default ProfileTabs;
