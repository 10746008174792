/* eslint-disable complexity */
import { Box, Button, Divider, Grid, TextField } from '@mui/material';
import { blue } from '@mui/material/colors';
import dayjs from 'dayjs';
import AsyncDropdown from 'v2s-ui-core/app/components/AsyncDropdown';
import {
  FormattedMessage,
  useLocaleContext,
} from 'v2s-ui-core/app/Integrations/Localization';
import YearPickCalender from '~/components/DOB_Calender';
import FormSkeleton from '~/components/Skeleton/FormSkeleton';
import SuccessMessageModal from '~/components/SuccessMessageModal';
import { theme } from '~/shell/theme/theme';
import { IMyProfileTabProps } from '../PersonalDetails/constants';
import { CORPORATE_INFO_KEYS } from './constants';
import useCorporateDetailsForm from './hooks/useCorporateDetailsForm';
import messages from './messages';
import { useIsMobile } from '~/hooks/useIsMobile';

const CorporateInfo = ({ onTabChange }: IMyProfileTabProps) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const isMobile = useIsMobile();
  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    showCorporateSuccessModal,
    handleCloseSuccessModal,
    formik,
    disabledCorporateFormFields,
    isLoading,
    isUpdateLoading,
  } = useCorporateDetailsForm({ onTabChange });
  if (isLoading || isUpdateLoading) {
    return (
      <Box>
        <FormSkeleton />
      </Box>
    );
  }
  return (
    <Box
      paddingX={2}
      paddingBottom={5}
      paddingTop={2}
      display={'flex'}
      flexDirection={'column'}
    >
      <Grid container rowSpacing={isMobile ? 0 : 0.5} columnSpacing={2}>
        <Grid item xs={12} sm={4}>
          <Box>
            <TextField
              required
              size='small'
              autoComplete='off'
              fullWidth
              label={formatMessage(messages.companyName)}
              placeholder={formatMessage(messages.companyName)}
              margin='normal'
              name={CORPORATE_INFO_KEYS.COMPANY_NAME}
              value={values?.[CORPORATE_INFO_KEYS.COMPANY_NAME]}
              onBlur={() => {
                formik.setFieldTouched(CORPORATE_INFO_KEYS.COMPANY_NAME, true);
              }}
              error={
                !!(
                  formik.errors?.[CORPORATE_INFO_KEYS.COMPANY_NAME] &&
                  formik.touched?.[CORPORATE_INFO_KEYS.COMPANY_NAME]
                )
              }
              helperText={
                formik?.touched?.[CORPORATE_INFO_KEYS.COMPANY_NAME] &&
                formik?.errors?.[CORPORATE_INFO_KEYS.COMPANY_NAME]?.toString()
              }
              onChange={handleChange}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={formatMessage(messages.company_license)}
              placeholder={formatMessage(messages.company_license)}
              margin='normal'
              fullWidth
              name={CORPORATE_INFO_KEYS.COMPANY_LICENSE}
              value={values?.[CORPORATE_INFO_KEYS.COMPANY_LICENSE]}
              onBlur={() => {
                formik.setFieldTouched(
                  CORPORATE_INFO_KEYS.COMPANY_LICENSE,
                  true,
                );
              }}
              error={
                !!(
                  formik.errors?.[CORPORATE_INFO_KEYS.COMPANY_LICENSE] &&
                  formik.touched?.[CORPORATE_INFO_KEYS.COMPANY_LICENSE]
                )
              }
              helperText={
                formik?.touched?.[CORPORATE_INFO_KEYS.COMPANY_LICENSE] &&
                formik?.errors?.[
                  CORPORATE_INFO_KEYS.COMPANY_LICENSE
                ]?.toString()
              }
              onChange={handleChange}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} mt={2}>
          <YearPickCalender
            value={values.company_date_of_birth || undefined}
            onChange={(date: Date) => {
              if (date?.toString() === 'Invalid Date') {
                setFieldValue(CORPORATE_INFO_KEYS.COMPANY_DATE_OF_BIRTH, null);
              }
              setFieldValue(
                CORPORATE_INFO_KEYS.COMPANY_DATE_OF_BIRTH,
                date?.toISOString()?.split('T')?.[0],
              );
            }}
            label={formatMessage(messages.company_date_of_birth)}
            maxDate={dayjs()}
            onBlur={() => {
              formik.setFieldTouched(
                CORPORATE_INFO_KEYS.COMPANY_DATE_OF_BIRTH,
                true,
              );
            }}
            error={
              !!(
                formik.errors?.[CORPORATE_INFO_KEYS.COMPANY_DATE_OF_BIRTH] &&
                formik.touched?.[CORPORATE_INFO_KEYS.COMPANY_DATE_OF_BIRTH]
              )
            }
            helperText={
              formik?.touched?.[CORPORATE_INFO_KEYS.COMPANY_DATE_OF_BIRTH] &&
              formik?.errors?.[
                CORPORATE_INFO_KEYS.COMPANY_DATE_OF_BIRTH
              ]?.toString()
            }
            textColor={
              disabledCorporateFormFields
                ? theme.palette.text.disabled
                : theme.palette.text.primary
            }
          />
        </Grid>

        <Grid item xs={12} sm={12} mt={isMobile ? 1 : 0}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label={formatMessage(messages.company_address_line1)}
            placeholder={formatMessage(messages.company_address_line1)}
            margin='normal'
            fullWidth
            name={CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE1}
            value={values?.[CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE1]}
            onBlur={() => {
              formik.setFieldTouched(
                CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE1,
                true,
              );
            }}
            error={
              !!(
                formik.errors?.[CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE1] &&
                formik.touched?.[CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE1]
              )
            }
            helperText={
              formik?.touched?.[CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE1] &&
              formik?.errors?.[
                CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE1
              ]?.toString()
            }
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={12} mb={isMobile ? 0 : 1}>
          <TextField
            required={false}
            size='small'
            autoComplete='off'
            label={formatMessage(messages.company_address_line2)}
            placeholder={formatMessage(messages.company_address_line2)}
            margin='normal'
            fullWidth
            name={CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE2}
            value={values?.[CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE2]}
            onBlur={() => {
              formik.setFieldTouched(
                CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE2,
                true,
              );
            }}
            error={
              !!(
                formik.errors?.[CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE2] &&
                formik.touched?.[CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE2]
              )
            }
            helperText={
              formik?.touched?.[CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE2] &&
              formik?.errors?.[
                CORPORATE_INFO_KEYS.COMPANY_ADDRESS_LINE2
              ]?.toString()
            }
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={formatMessage(messages.company_city_town)}
              placeholder={formatMessage(messages.company_city_town)}
              margin='normal'
              fullWidth
              name={CORPORATE_INFO_KEYS.COMPANY_CITY_TOWN}
              value={values?.[CORPORATE_INFO_KEYS.COMPANY_CITY_TOWN]}
              onBlur={() => {
                formik.setFieldTouched(
                  CORPORATE_INFO_KEYS.COMPANY_CITY_TOWN,
                  true,
                );
              }}
              error={
                !!(
                  formik.errors?.[CORPORATE_INFO_KEYS.COMPANY_CITY_TOWN] &&
                  formik.touched?.[CORPORATE_INFO_KEYS.COMPANY_CITY_TOWN]
                )
              }
              helperText={
                formik?.touched?.[CORPORATE_INFO_KEYS.COMPANY_CITY_TOWN] &&
                formik?.errors?.[
                  CORPORATE_INFO_KEYS.COMPANY_CITY_TOWN
                ]?.toString()
              }
              onChange={handleChange}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={formatMessage(messages.company_state_province)}
              placeholder={formatMessage(messages.company_state_province)}
              margin='normal'
              fullWidth
              name={CORPORATE_INFO_KEYS.COMPANY_STATE_PROVINCE}
              value={values?.[CORPORATE_INFO_KEYS.COMPANY_STATE_PROVINCE]}
              onBlur={() => {
                formik.setFieldTouched(
                  CORPORATE_INFO_KEYS.COMPANY_STATE_PROVINCE,
                  true,
                );
              }}
              error={
                !!(
                  formik.errors?.[CORPORATE_INFO_KEYS.COMPANY_STATE_PROVINCE] &&
                  formik.touched?.[CORPORATE_INFO_KEYS.COMPANY_STATE_PROVINCE]
                )
              }
              helperText={
                formik?.touched?.[CORPORATE_INFO_KEYS.COMPANY_STATE_PROVINCE] &&
                formik?.errors?.[
                  CORPORATE_INFO_KEYS.COMPANY_STATE_PROVINCE
                ]?.toString()
              }
              onChange={handleChange}
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={4} mt={2}>
          <AsyncDropdown
            // @ts-ignore
            value={values?.company_country || null}
            onChange={(e, value) =>
              handleChange({
                target: {
                  name: 'company_country',
                  value: value,
                },
              })
            }
            queryProps={{
              resource: '/countries',
              queryKeyProp: ['GET_COUNTRIES'],
              select: (data: any) => data.data,
            }}
            InputProps={{
              label: formatMessage(messages.company_country),
              required: true,
              name: 'countryEn',
              placeholder: formatMessage(messages.select),

              onBlur: () => formik.setFieldTouched('company_country', true),

              error: !!(
                formik.errors?.company_country &&
                formik.touched?.company_country
              ),
              helperText:
                formik.touched?.company_country &&
                formik.errors?.company_country?.toString(),
            }}
            getOptionLabel={(option) => option?.countryEn}
            getOptionKey={(option) => option.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid>
        <Grid item xs={12} sm={4} mt={{ xs: 1, md: 0 }}>
          <Box>
            <TextField
              required
              size='small'
              autoComplete='off'
              label={formatMessage(messages.company_zip_postal_code)}
              placeholder={formatMessage(messages.company_zip_postal_code)}
              margin='normal'
              fullWidth
              name={CORPORATE_INFO_KEYS.COMPANY_ZIP_POSTAL_CODE}
              value={values?.[CORPORATE_INFO_KEYS.COMPANY_ZIP_POSTAL_CODE]}
              onBlur={() => {
                formik.setFieldTouched(
                  CORPORATE_INFO_KEYS.COMPANY_ZIP_POSTAL_CODE,
                  true,
                );
              }}
              error={
                !!(
                  formik.errors?.[
                    CORPORATE_INFO_KEYS.COMPANY_ZIP_POSTAL_CODE
                  ] &&
                  formik.touched?.[CORPORATE_INFO_KEYS.COMPANY_ZIP_POSTAL_CODE]
                )
              }
              helperText={
                formik?.touched?.[
                  CORPORATE_INFO_KEYS.COMPANY_ZIP_POSTAL_CODE
                ] &&
                formik?.errors?.[
                  CORPORATE_INFO_KEYS.COMPANY_ZIP_POSTAL_CODE
                ]?.toString()
              }
              onChange={handleChange}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        display={'flex'}
        mt={2}
        justifyContent={isMobile ? 'center' : 'flex-start'}
      >
        <Button
          variant='contained'
          size='medium'
          sx={{ width: '113px' }}
          onClick={() => handleSubmit()}
        >
          <FormattedMessage {...messages.update} />
        </Button>
      </Box>
      {!!showCorporateSuccessModal && (
        <SuccessMessageModal
          message={formatMessage(messages.successCorporateUpdate)}
          handleOk={handleCloseSuccessModal}
          open={showCorporateSuccessModal}
        />
      )}
    </Box>
  );
};

export default CorporateInfo;
