import * as yup from 'yup';
import messages from './validationMessage';
import { INPUT_FIELD_REGEX, INPUT_FIELDS_LENGTHS } from '~/config/constants';
import { VALIDATION_MAX_LENGTHS } from '~/constants';
// export const getBanAccountSchema = (formatMessage: (msg: any) => string) =>
//   yup.object().required(formatMessage(messages.required));

export const getPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .matches(
      INPUT_FIELD_REGEX.PASSWORD,
      formatMessage(messages.invalidPassword),
    )
    .required(formatMessage(messages.passwordRequired));

export const getOldPasswordSchema = (formatMessage: (msg: any) => string) =>
  yup.string().required(formatMessage(messages.oldPasswordRequired));

export const getEmailSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .max(
      INPUT_FIELDS_LENGTHS.EMAIL.MAX,
      formatMessage(messages.lastNameTooLong),
    )
    .email(formatMessage(messages.invalidEmail))
    .required(formatMessage(messages.emailRequired))
    .matches(INPUT_FIELD_REGEX.EMAIL, formatMessage(messages.invalidEmail));

export const getRoleSchema = (formatMessage: (msg: any) => string) =>
  yup.object().shape({
    name: yup
      .string()
      .max(
        VALIDATION_MAX_LENGTHS.ROLE_NAME,
        formatMessage(messages.roleTooLong),
      )
      .required(formatMessage(messages.roleRequired)),
  });

export const getActionNameSchema = (formatMessage: (msg: any) => string) =>
  yup.object().shape({
    name: yup
      .string()
      .max(
        VALIDATION_MAX_LENGTHS.ACTION_NAME,
        formatMessage(messages.actionTooLong),
      )
      .required(formatMessage(messages.actionRequired)),
  });

export const getPermissionNameSchema = (formatMessage: (msg: any) => string) =>
  yup.object().shape({
    name: yup
      .string()
      .max(
        VALIDATION_MAX_LENGTHS.PERMISSION_NAME,
        formatMessage(messages.permissionTooLong),
      )
      .required(formatMessage(messages.permissionNameRequired)),
  });

export const getFullNameSchema = (formatMessage: (msg: any) => string) =>
  yup
    .string()
    .transform((value) => value.replace(/\s+/g, ''))
    .required(formatMessage(messages.fullNameRequired));

export const getLeverageSchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.leverageRequired));

export const getLocalCurrencySchema = (formatMessage: (msg: any) => string) =>
  yup.object().required(formatMessage(messages.localCurrencyRequired));
