import * as yup from 'yup';
import { DOCUMENT_NO_REGEX, REMARK_VALIDATION_REGEX } from '~/constants';
import messages from '../../messages';

export const MUTATION_KEYS = {
  APPROVE_REJECT_BANK_ACCOUNT: 'approveRejectBankAccount',
  // GX_INJECT_MUTATION_KEY
} as const;

export const getApproveRejectSchema = (formatMessage: Function) =>
  yup.object().shape({
    remark: yup
      .string()
      .transform((value) => value.replace(/\s+/g, ''))
      .matches(/^[^\s].*[^\s]$/, formatMessage(messages.remarkValidation))
      .required(formatMessage(messages.remarkRequired))
      .matches(
        REMARK_VALIDATION_REGEX,
        formatMessage(messages.remarkValidation),
      )
      .min(4, formatMessage(messages.remarkMinLength))
      .max(100, formatMessage(messages.remarkMaxLength)),
    document_no: yup
      .string()
      .matches(
        DOCUMENT_NO_REGEX,
        formatMessage(messages.documentNumberValidation),
      )
      .min(4, formatMessage(messages.documentNumberMinLength))
      .max(30, formatMessage(messages.documentNumberMaxLength)),
  });
