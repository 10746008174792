export const API_URLS = {
  FETCH_WITHDRAWALS: '/crm/user-transactions',
  APPROVE_REJECT_WITHDRAWAL: (id: string | number) =>
    `crm/user/transactions/${id}`,

  // FETCH_PAYMENT_METHODS: 'client/payment-methods',
  FETCH_PAYMENT_METHODS: 'client/payment-methods?transaction_type=WITHDRAWAL',

  // GX_INJECT_API_URL
};

export const QUERY_KEYS = {
  FETCH_PAYMENT_METHODS: 'fetchPaymentMethods',
};
