/* eslint-disable complexity */
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid, TextField, IconButton } from '@mui/material';
import viewDoc from '~/assets/images/viewDoc.svg';
import { AccountType } from '../types';
import SkeletonWrapper from './SkeletonWrapper';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from '../messages';
import edit from '~/assets/images/edit.svg';
import { useBankInfoForm } from '../hook/useBankInfoForm';
import CloseIcon from '@mui/icons-material/Close';
import SuccessModal from '~/components/SuccessModal';
import BankFileUpload from '~/hooks/BankDocUpload';
import { updateFieldValueToUpperCase } from '~/utils';

const BankAccountForm = ({
  account,
  setValue,
  isLoading,
  isEdit,
  setIsEdit,
}: {
  account: AccountType;
  setValue: (boolean: boolean) => void;
  isLoading: boolean;
  isEdit: boolean;
  setIsEdit: (boolean: boolean) => void;
}) => {
  const {
    formik: {
      errors,
      values,
      setFieldValue,
      handleBlur,
      handleChange,
      handleSubmit,
      touched,
      setFieldTouched,
    },
    setShowSuccessModal,
    showSuccessModal,
  } = useBankInfoForm({
    account,
    isEdit,
    setIsEdit,
  });

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 2,
        }}
      >
        <Typography fontSize={'20px'} sx={{ mt: 0 }}>
          Bank Details
        </Typography>
        {/* {(account?.status === 'REJECTED' || account?.status === 'PENDING') && (
          <IconButton sx={{}}>
            {isEdit ? (
              <CloseIcon onClick={() => setIsEdit(!isEdit)} />
            ) : (
              <img onClick={() => setIsEdit(true)} src={edit} alt='edit' />
            )}
          </IconButton>
        )} */}
        {/* {isEdit && ( */}
        <Button
          onClick={() => {
            handleSubmit();
          }}
          sx={{ width: '100px' }}
          variant='contained'
        >
          Update
        </Button>
        {/* )} */}
      </Box>

      <Box mt={'20px'}>
        <Grid container spacing={0} columnSpacing={4}>
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_name} />}
                margin='normal'
                name='bank_name'
                value={values.bank_name || ''}
                onChange={(e) => {
                  setFieldTouched('bank_name', true);
                  handleChange(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_name', true);
                }}
                error={touched.bank_name && Boolean(errors.bank_name)}
                helperText={touched.bank_name && errors.bank_name?.toString()}
              />
            </SkeletonWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_account_name} />}
                margin='normal'
                name='bank_account_name'
                value={values.bank_account_name || ''}
                onChange={(e) => {
                  setFieldTouched('bank_account_name', true);
                  handleChange(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_account_name', true);
                }}
                error={
                  touched.bank_account_name && Boolean(errors.bank_account_name)
                }
                helperText={
                  touched.bank_account_name &&
                  errors.bank_account_name?.toString()
                }
              />
            </SkeletonWrapper>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={4}
          spacing={0}
          mt={isLoading ? '16px' : '0px'}
        >
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_account_number} />}
                margin='normal'
                name='bank_account_number'
                type='number'
                value={values.bank_account_number || ''}
                onChange={(e) => {
                  setFieldTouched('bank_account_number', true);
                  handleChange(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_account_number', true);
                }}
                error={
                  touched.bank_account_number &&
                  Boolean(errors.bank_account_number)
                }
                helperText={
                  touched.bank_account_number &&
                  errors.bank_account_number?.toString()
                }
              />
            </SkeletonWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_iban} />}
                margin='normal'
                name='bank_iban'
                value={values.bank_iban || ''}
                onChange={(e) => {
                  setFieldTouched('bank_iban', true);
                  updateFieldValueToUpperCase(setFieldValue)(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_iban', true);
                }}
                error={touched.bank_iban && Boolean(errors.bank_iban)}
                helperText={touched.bank_iban && errors.bank_iban?.toString()}
              />
            </SkeletonWrapper>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={4}
          spacing={0}
          mt={isLoading ? '16px' : '0px'}
        >
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_ifsc} />}
                margin='normal'
                name='bank_code'
                onChange={(e) => {
                  setFieldTouched('bank_code', true);
                  updateFieldValueToUpperCase(setFieldValue)(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_code', true);
                }}
                value={values.bank_code || ''}
                error={touched.bank_code && Boolean(errors.bank_code)}
                helperText={touched.bank_code && errors.bank_code?.toString()}
              />
            </SkeletonWrapper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <SkeletonWrapper isLoading={isLoading}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_address} />}
                margin='normal'
                name='bank_address'
                multiline
                value={values.bank_address || ''}
                onChange={(e) => {
                  setFieldTouched('bank_address', true);
                  handleChange(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_address', true);
                }}
                error={touched.bank_address && Boolean(errors.bank_address)}
                helperText={
                  touched.bank_address && errors.bank_address?.toString()
                }
              />
            </SkeletonWrapper>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={'32px'}>
        <SkeletonWrapper isLoading={isLoading}>
          <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
            Documents
          </Typography>
        </SkeletonWrapper>
        <Box
          display={'flex'}
          alignItems={'center'}
          gap={'8px'}
          marginTop={'32px'}
        >
          <SkeletonWrapper isLoading={isLoading}>
            <TextField
              fullWidth
              type='text'
              name='documents'
              size='small'
              label={<FormattedMessage {...messages.document_name} />}
              placeholder='document name'
              value={
                (account &&
                  account?.user_document?.document_name +
                    (account?.user_document?.latest_version?.extension
                      ? `${account?.user_document?.latest_version.extension}`
                      : '')) ||
                ''
              }
            />
          </SkeletonWrapper>

          {!isEdit && (
            <SkeletonWrapper isLoading={isLoading}>
              <Box width={'auto'}>
                <Button
                  onClick={() => setValue(true)}
                  variant='outlined'
                  sx={{
                    textTransform: 'none',
                    color: '#768098',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '8px',
                    height: '40px',
                  }}
                >
                  <img src={viewDoc} alt='viewDoc' />
                  <FormattedMessage {...messages.view} />
                </Button>
              </Box>
            </SkeletonWrapper>
          )}
        </Box>
        <Box mt={2}>
          {isEdit && (
            <SkeletonWrapper isLoading={isLoading}>
              <BankFileUpload
                onUploadSuccess={(documentId: string) => {
                  if (documentId) {
                    setFieldValue('document_id', documentId);
                  }
                }}
                documentMappingId={
                  account?.user_document?.document_type_mapping
                }
                userId={account?.user_document?.id}
              />
            </SkeletonWrapper>
          )}
        </Box>
      </Box>

      <SuccessModal
        open={showSuccessModal}
        onClose={() => {
          setShowSuccessModal(false);
        }}
        message={'Bank details updated successfully'}
      />
    </Box>
  );
};

export default BankAccountForm;
