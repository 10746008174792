import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomTooltip from '~/components/CustomTooltip';
import FileUploadWrapper from '~/components/FileUploadWrapper';
import { ShowStatus } from '~/components/ShowStatus';
import { ViewFileDialog } from '~/components/ViewFileDialog';
import { ACCOUNT_STATUS, UPLOADED_DOCUMENT_STATUS } from '~/config/constants';
import { TDocuments, TDocumentsTypes } from '~/config/types';
import { theme } from '~/shell/theme/theme';
import { previewOrOpenFileInNewTab } from '~/utils';
import { FormattedMessage } from '~app/Integrations/Localization';
import { UPLOAD_FILE_TYPE } from '../../constants';
import { myKycMessages } from '../../myKycMessages';
import {
  addNewOtherDoc,
  frontDocFieldValue,
  kycMode,
  setOtherFieldsValues,
  url_front,
} from '../../utils';
import { blue } from '@mui/material/colors';
import { useIsMobile } from '~/hooks/useIsMobile';

const AdditionalFields = ({
  docType,
  options,
  documents,
  setDocuments,
  formik,
  onFileSelect,
  uploadedFiles,
  setUploadedFiles,
  uploadedData,
  setIsEditSuccess,
  setUpdateLoader,
  isBulkUpload = true,
}: {
  docType: keyof TDocumentsTypes;
  options: TDocuments[];
  documents: TDocumentsTypes;
  setDocuments: React.Dispatch<React.SetStateAction<TDocumentsTypes>>;
  formik: any;
  onFileSelect: any;
  uploadedFiles: any;
  setUploadedFiles: any;
  uploadedData: any;
  setIsEditSuccess: any;
  setUpdateLoader?: (isLoading: boolean) => void;
  isBulkUpload: boolean;
}) => {
  const [dropDownValue, setDropDownValue] = useState({
    documentName: '',
    documentSide: {
      FrontId: null,
      FrontSideRequired: false,
      other_doc_1: 0,
      other_doc_2: 0,
      other_doc_3: 0,
      other_doc_4: 0,
      other_doc_5: 0,
    },
  });

  const [viewFile, setViewFile] = useState({
    open: false,
    file: null,
  });

  const isMobile = useIsMobile();
  const [fieldList, setFieldList] = useState([{}]);

  useEffect(() => {
    if (!!uploadedData?.length) {
      setOtherFieldsValues({
        setDropDownValue,
        uploadedData,
        options,
        formik,
        docType,
        setUpdateLoader,
      });
      setFieldList(uploadedData.map(() => ({})));
    }
  }, [JSON.stringify(uploadedData)]);

  return (
    <Grid item display={'flex'} flexWrap={'wrap'} width={'100%'} gap={2}>
      <Box className='header'>
        <Typography variant='h6' color={'main'} gutterBottom>
          <FormattedMessage {...myKycMessages.additionalDocuments} />
        </Typography>
      </Box>
      <Grid
        container
        spacing={isMobile ? 2 : 0}
        sx={{
          flexDirection: { xs: 'row', sm: 'row' },
          alignItems: { xs: 'center', sm: 'flex-start' },
        }}
      >
        {options.map((field, index) => (
          <Grid item xs={12} sm={12} md={12} key={index} mt={2}>
            <Box
              sx={{ width: '100%' }}
              display='flex'
              flexDirection='column'
              // gap={4}
              alignItems={'flex-start'}
            >
              <Box
                display='grid'
                gridTemplateColumns={{
                  xs: !!frontDocFieldValue(index, formik, docType)?.status
                    ? '1fr'
                    : '1fr auto',
                  md: '1fr auto',
                  sm: '1fr auto',
                }}
                alignItems='center'
                // gap={4}
              >
                <Box
                  display='flex'
                  flexDirection='row'
                  alignItems='center'
                  gap={1}
                  sx={{ width: '100%' }}
                >
                  <TextField
                    size='small'
                    placeholder='Others Document'
                    label={
                      <FormattedMessage
                        id={docType as string}
                        defaultMessage={
                          myKycMessages?.[docType as keyof typeof myKycMessages]
                            ?.defaultMessage || field.document_name
                        }
                      />
                    }
                    select={false}
                    fullWidth
                    sx={{
                      width: { xs: '224px', md: '236px' },
                      '@media (max-width: 392px)': {
                        width: '188px',
                      },
                    }}
                    disabled
                    value={dropDownValue?.documentName}
                  ></TextField>

                  {!!frontDocFieldValue(index, formik, docType)?.status && (
                    <ShowStatus
                      height='42px'
                      width='113px'
                      status={frontDocFieldValue(
                        index,
                        formik,
                        docType,
                      )?.status.toUpperCase()}
                    />
                  )}
                </Box>

                {
                  <Box
                    display='flex'
                    justifyContent={
                      'flex-end'
                      // backDocFieldValue?.remark ? 'space-between' : 'flex-end'
                    }
                    alignItems='center'
                    ml={2}
                    mt={1}
                    // my={}
                    // sx={{
                    //   gridColumn: {
                    //     xs: 'auto',

                    //     md: 'auto',
                    //   },
                    // }}
                  >
                    {frontDocFieldValue(index, formik, docType)?.status !==
                      ACCOUNT_STATUS.APPROVED && (
                      <FileUploadWrapper
                        onSelectFile={(file) => {
                          formik.setFieldValue(
                            `${[docType.toLocaleLowerCase()]}_front_${index}`,
                            file,
                          );
                          onFileSelect(
                            file,
                            UPLOAD_FILE_TYPE.OTHER,
                            options?.[index]?.sides?.[0],
                            `${docType.toLocaleLowerCase()}_${index}`,
                            index,
                            frontDocFieldValue(index, formik, docType)?.id,
                          );
                        }}
                        fileName={dropDownValue.documentName}
                        documentMappigId={dropDownValue.documentSide.FrontId}
                        bulkUpload={true}
                        isUpdateKyc={true}
                        updateKycId={
                          frontDocFieldValue(index, formik, docType)?.id
                        }
                        setIsEditSuccess={setIsEditSuccess}
                        title={
                          frontDocFieldValue(index, formik, docType)?.name
                            ? 'Re Upload'
                            : 'Upload'
                        }
                        setUpdateLoader={setUpdateLoader}
                        mode={kycMode(formik, uploadedFiles)}
                        formik={formik}
                        key={index}
                      />
                    )}
                  </Box>
                }
              </Box>

              <CustomTooltip
                title={frontDocFieldValue(index, formik, docType)?.name}
              >
                <Typography
                  fontWeight={theme.typography.fontWeightRegular}
                  fontSize={14}
                  color={theme.palette.primary.main}
                  sx={{
                    display: 'inline-block',
                    width: { md: '27ch', xs: '22ch', sm: '20ch' },
                    px: 1,
                    bgcolor: blue[50],
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    marginTop: {
                      xs:
                        frontDocFieldValue(index, formik, docType)?.status ===
                        UPLOADED_DOCUMENT_STATUS.APPROVED
                          ? '-40px'
                          : frontDocFieldValue(index, formik, docType)
                              ?.status === UPLOADED_DOCUMENT_STATUS.PENDING
                          ? '-40px'
                          : frontDocFieldValue(index, formik, docType)
                              ?.status === UPLOADED_DOCUMENT_STATUS.REJECTED
                          ? '-40px'
                          : 1,
                      md: 1,
                      sm: 1,
                    },
                  }}
                  onClick={() => {
                    previewOrOpenFileInNewTab(
                      url_front(index, formik, docType),
                      frontDocFieldValue(index, formik, docType),
                      setViewFile,
                    );
                  }}
                >
                  {frontDocFieldValue(index, formik, docType)?.name}
                </Typography>
              </CustomTooltip>
            </Box>
          </Grid>
        ))}
      </Grid>

      {viewFile?.open && (
        <ViewFileDialog
          file={viewFile?.file}
          viewFileDialog={!!viewFile?.open}
          closeViewFileDialog={() => setViewFile({ open: false, file: null })}
        />
      )}
    </Grid>
  );
};
export default AdditionalFields;
