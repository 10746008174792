import { useMemo } from 'react';
import { TDocumentTypeListResponse } from '~/config/types';
import { useUserAuth } from '~/shell/components/Auth/AuthProvider';
import ApiService from '../../api';
import { QUERY_KEYS } from './constants';
import { QueryDetails } from './types';

const { GET_DOC_TYPES_LIST } = QUERY_KEYS;

export const useQueryFactory = <TData, TError = Error>() => {
  const queryDetails = useMemo<QueryDetails<TData, TError>>(
    () => ({
      [GET_DOC_TYPES_LIST]: () => ({
        queryKey: [GET_DOC_TYPES_LIST],
        queryFn: () => ApiService.getDocTypesList<TDocumentTypeListResponse>(),
      }),
    }),
    [],
  );

  return queryDetails;
};
