import React from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { getEventsTableColumns } from './EventsTableColumn';
import Box from '~app/components/Box';
import { API_URLS, QUERY_KEYS } from '../../api/config';
const Events = () => {
  const memoizedColumns = React.useMemo(() => getEventsTableColumns({}), []);

  return (
    <>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_EVENTS,
            queryKeyProp: [QUERY_KEYS.FETCH_EVENTS],
            select: (data: any) => data,
          }}
          columns={memoizedColumns}
          pageCount={10}
        />
      </Box>
    </>
  );
};

export default Events;
