import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import ActionButtons from '~/components/Actions/ActionButtons';
import { ACCOUNT_STATUS, ACCOUNT_STATUS_CONFIG } from '../../constants';
import { TradingAccountStatus } from '../../types';
import TruncatedHeader from '~/components/TruncatedHeader';
import DateFilterComponent from '~/components/DateFIlter';
export const TradingAccountsTableColumns = (props: {
  onApproveRecord?: Function;
  onRejectRecord?: Function;
}): ColumnDef<TradingAccountStatus>[] => [
  {
    header: 'Request ID',
    enableColumnFilter: true,
    accessorKey: 'id',
  },
  {
    header: 'User ID',
    enableColumnFilter: true,
    accessorKey: 'user_id',
    cell: ({ row }: any) => row.original?.user?.id,
  },
  {
    header: 'User Name',
    enableColumnFilter: true,
    accessorKey: 'user_full_name',
    cell: ({ row }: any) => row.original?.user?.full_name,
  },
  {
    header: 'User Email',
    enableColumnFilter: true,
    accessorKey: 'user.email',
  },
  {
    header: 'CRM Status',
    enableColumnFilter: true,
    accessorKey: 'crm_status',
  },
  {
    header: 'Account Type',
    enableColumnFilter: true,
    accessorKey: 'account_type',
    cell: ({ row }: any) => row.original?.account_type?.type,
  },
  {
    header: 'Account Leverage',
    enableColumnFilter: true,
    accessorKey: 'leverage',
  },
  {
    header: 'Account Status',
    enableColumnFilter: true,
    accessorKey: 'status',
  },
  {
    enableColumnFilter: true,
    accessorKey: 'trading_account',
    header: () => (
      <TruncatedHeader
        text='Trading Account Number'
        maxLength={16}
        useTooltip
      />
    ),
  },

  {
    header: 'Trading Group',
    enableColumnFilter: true,
    accessorKey: 'trading_group',
    cell: ({ row }: any) => row.original?.trading_group?.name,
  },
  {
    header: 'Last Access IP',
    enableColumnFilter: true,
    accessorKey: 'last_access_ip',
  },
  {
    header: 'Last Access Time',
    enableColumnFilter: true,
    accessorKey: 'last_access_time',
  },
  {
    header: 'Created At',
    enableColumnFilter: true,
    accessorKey: 'created_at',
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    header: 'Updated At',
    enableColumnFilter: true,
    accessorKey: 'updated_at',
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'updated_by',
    header: 'Updated By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    enablePinning: true,
    enableColumnFilter: false,
    cell: ({ cell }) => {
      const { row } = cell;
      const status = row.original.crm_status;

      return (
        <ActionButtons
          status={status}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original}
          ACCOUNT_STATUS={ACCOUNT_STATUS}
          ACCOUNT_STATUS_CONFIG={ACCOUNT_STATUS_CONFIG}
        />
      );
    },
  },
];
