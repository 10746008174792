import React from 'react';
import { FC, useMemo, useState } from 'react';
import Box from '~app/components/Box';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../api/config';
import { QUERY_KEYS } from '../services/queries/constants';
import messages from '../messages';
import { getInternalTransferColumns } from './InternalTransferColumnTable';
import ApproveRejectInternalTransfer from './ApproveRejectInternalTransfers';
import { useApproveRejectActions } from '~/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { INTERNAL_TRANSFERS_STATUS } from '../constants';
import { ColumnDef } from '~app/components/DataGridCore';
import SuccessModal from '~/components/SuccessModal';
import TableFilters from '../../../components/TableFilters/index';
import useFilters from '~app/hooks/useFilters';
import {
  ACTIONS_COLUMNS_TO_PIN,
  FILTERS,
  STATUS_COLUMNS_TO_PIN,
} from '~/config/constants';

const InternalTransfersTable: FC<{}> = () => {
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    showSuccessModal,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_INTERNAL_TRANSFERS,
    API_URLS: API_URLS.APPROVE_REJECT_INTERNAL_TRANSFER,
    STATUS: INTERNAL_TRANSFERS_STATUS,
    user: false,
    messages: messages,
  });

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: QUERY_KEYS.FETCH_INTERNAL_TRANSFERS,
  });

  const memoizedColumns = useMemo(
    () =>
      getInternalTransferColumns({
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'16px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_INTERNAL_TRANSFERS,
            queryKeyProp: [QUERY_KEYS.FETCH_INTERNAL_TRANSFERS],
            select: (data: any) => data,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
          filters={filters}
          columnsToPin={STATUS_COLUMNS_TO_PIN}
        />
      </Box>
      {recordForApproveReject && (
        <ApproveRejectInternalTransfer
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default InternalTransfersTable;
