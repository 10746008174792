import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  approveModalTitle: {
    id: 'internalTransfers.approveModalTitle',
    defaultMessage: 'Approve Internal Transfer?',
  },
  rejectModalTitle: {
    id: 'internalTransfers.rejectModalTitle',
    defaultMessage: 'Reject Internal Transfer?',
  },
  approveModalHelpText: {
    id: 'internalTransfers.approveModalHelpText',
    defaultMessage: 'Please provide reasoning for approving the transfer.',
  },
  rejectModalHelpText: {
    id: 'internalTransfers.rejectModalHelpText',
    defaultMessage: 'Please provide reasoning for rejecting the transfer.',
  },
  bankAccountNumber: {
    id: 'internalTransfers.bankAccountNumber',
    defaultMessage: 'Internal Transfer Number',
  },
  approved: {
    id: 'internalTransfers.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'internalTransfers.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'internalTransfers.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'internalTransfers.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'internalTransfers.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'internalTransfers.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'internalTransfers.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'internalTransfers.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  clickToView: {
    id: 'internalTransfers.clickToView',
    defaultMessage: 'click here to view it.',
  },
  clickToClose: {
    id: 'internalTransfers.clickToClose',
    defaultMessage: 'click here to close it.',
  },
  approveSuccessMessage: {
    id: 'internalTransfers.approveSuccessMessage',
    defaultMessage: 'Internal transfer approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'internalTransfers.rejectSuccessMessage',
    defaultMessage: 'Internal transfer rejected successfully!',
  },
  approveFailedMessage: {
    id: 'internalTransfers.approveFailedMessage',
    defaultMessage: 'Internal transfer approval failed!',
  },
  rejectFailedMessage: {
    id: 'internalTransfers.rejectFailedMessage',
    defaultMessage: 'Internal transfer rejection failed!',
  },
  viewBankAccount: {
    id: 'internalTransfers.viewBankAccount',
    defaultMessage: 'View Internal Transfer',
  },
  remarkValidation: {
    id: 'internalTransfers.remarkValidation',
    defaultMessage: 'Remark should contain only letters and numbers',
  },
  remarkMinLength: {
    id: 'internalTransfers.remarkMinLength',
    defaultMessage: 'Remark should be at least 4 characters',
  },
  remarkMaxLength: {
    id: 'internalTransfers.remarkMaxLength',
    defaultMessage: 'Remark should be at most 100 characters',
  },
});
