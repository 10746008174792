/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { getPersonalInfoSchema } from '../config';
import { LANGUAGE_DEFAULT_VALUE, PERSONAL_INFO_VALUES } from '../constants';
import useGetPersonalDetails from './useGetPersonalDetails';
import useUpdatePersonalInfo from './useUpdatePersonalInfo';
import { TUpdateUserProfilePayload } from '../../services/mutations/types';
import { useLocation } from 'react-router-dom';
import { REQUEST_STATUS } from '~/constants';

const usePersonalInfoForm = ({ onTabChange, handleViewInfo }: any) => {
  const location = useLocation();

  const userId = location.state;
  const {
    updateUserProfile,
    status,
    isLoading: isUpdateLoading,
  } = useUpdatePersonalInfo();
  const { personalInfo, isLoading } = useGetPersonalDetails(userId);

  const [state, setState] = useState({
    isResponseSuccess: false,
    disabledPersonalFormFields: true,
  });
  const { isResponseSuccess, disabledPersonalFormFields } = state;

  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  useEffect(() => {
    if (status === REQUEST_STATUS.SUCCESS) {
      setState(() => ({
        ...state,
        isResponseSuccess: true,
      }));
    }
  }, [status]);
  const handleCloseSuccessModal = () => {
    setState(() => ({
      ...state,
      isResponseSuccess: false,
    }));
  };

  const initialValues = useMemo(() => {
    if (personalInfo) {
      return {
        ...PERSONAL_INFO_VALUES,
        ...personalInfo,
        preferred_language: {
          language:
            personalInfo?.preferred_language || LANGUAGE_DEFAULT_VALUE.ENGLISH,
          id:
            personalInfo?.preferred_language || LANGUAGE_DEFAULT_VALUE.ENGLISH,
        },
        user_type: {
          id: personalInfo?.user_type_dd.id,
          type: personalInfo?.user_type_dd.type,
        },
      };
    } else {
      return PERSONAL_INFO_VALUES;
    }
  }, [personalInfo]);

  const onUpdateValues = (values: any) => {
    const updatedPayload = {
      ...values,
      country: values.country?.id,
      preferred_language: values.preferred_language?.language,
      user_type: values?.user_type?.id,
    };
    delete updatedPayload.roadmap_progress;

    const payload: TUpdateUserProfilePayload = {
      values: updatedPayload,
      userId,
    };
    updateUserProfile(payload);
  };

  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    setFieldTouched,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, { resetForm }) => {
      const payload = {
        ...values,
      };
      delete payload.poi_number;
      onUpdateValues(payload);
    },
    validationSchema: getPersonalInfoSchema(formatMessage),
    enableReinitialize: true,
  });

  return {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    setFieldValue,
    errors,
    isResponseSuccess,
    handleCloseSuccessModal,
    setFieldTouched,
    disabledPersonalFormFields,
    isLoading,
    isUpdateLoading,
  };
};

export default usePersonalInfoForm;
