import { GenderType, LanguageType } from '~/modules/UserProfile/types';

export const PERSONAL_INFO_VALUES = {
  preferred_language: { language: 'English', id: 'English' },
  address_line1: '',
  address_line2: '',
  city_town: '',
  state_province: '',
  zip_postal_code: '',
  date_of_birth: '',
  gender: '',
  nationality: '',
  first_name: '',
  last_name: '',
  email: '',
  country: '',
  phone_number: '',
  poi_number: '',
  user_type: '',
};

export const LANGUAGE_DEFAULT_VALUE = {
  ENGLISH: 'English',
};

export const userTypeLabels: Record<string, string> = {
  Trader_Joint: 'Joint Account',
  IB_Joint: 'Joint Account',
  Trader_Corporate: 'Corporate Account',
  IB_Corporate: 'Corporate Account',
};

export const LANGUAGES: readonly LanguageType[] = [
  { id: '1', label: 'English' },
  { id: '2', label: 'Hindi' },
  { id: '3', label: 'Malayalam' },
  { id: '4', label: 'Tamil' },
];

export const CITIES: readonly LanguageType[] = [
  { id: '1', label: 'Mumbai' },
  { id: '2', label: 'Noida' },
  { id: '3', label: 'Gurugaon' },
];

export const GENDERS: readonly GenderType[] = [
  { id: '1', label: 'Male', value: 'M' },
  { id: '2', label: 'Female', value: 'F' },
  { id: '3', label: 'Other', value: 'O' },
];
export interface IGetTabConfig {
  onTabSubmit: () => void;
  userType?: any;
  onTabChange: (position: number) => void;
}

export interface IMyProfileTabProps {
  onTabSubmit?: () => void;
  onTabChange?: (position: number) => void;
  handleViewInfo?: () => void;
}
