import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  approveModalTitle: {
    id: 'deposits.approveModalTitle',
    defaultMessage: 'Approve Deposit?',
  },
  rejectModalTitle: {
    id: 'deposits.rejectModalTitle',
    defaultMessage: 'Reject Deposit?',
  },
  // approveModalHelpText: {
  //   id: 'deposits.approveModalHelpText',
  //   defaultMessage: 'Please provide reasoning for approving the deposit.',
  // },
  // rejectModalHelpText: {
  //   id: 'deposits.rejectModalHelpText',
  //   defaultMessage: 'Please provide reasoning for rejecting the deposit.',
  // },
  deposits: {
    id: 'deposits',
    defaultMessage: 'Deposits',
  },
  approved: {
    id: 'deposits.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'deposits.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'deposits.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'deposits.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'deposits.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'deposits.reject',
    defaultMessage: 'Reject',
  },

  depositAmount: {
    id: 'deposits.depositAmount',
    defaultMessage: 'Deposit Amount',
  },
  depositCurrency: {
    id: 'deposits.depositCurrency',
    defaultMessage: 'Deposit Currency',
  },
  tradingAmount: {
    id: 'deposits.tradingAmount',
    defaultMessage: 'Trading Amount',
  },
  tradingCurrency: {
    id: 'deposits.tradingCurrency',
    defaultMessage: 'Trading Currency',
  },
  cancel: {
    id: 'deposits.cancel',
    defaultMessage: 'Cancel',
  },
  approveSuccessMessage: {
    id: 'deposits.approveSuccessMessage',
    defaultMessage: 'Deposit approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'deposits.rejectSuccessMessage',
    defaultMessage: 'Deposit rejected successfully!',
  },
  approveFailedMessage: {
    id: 'deposits.approveFailedMessage',
    defaultMessage: 'Deposit approval failed!',
  },
  rejectFailedMessage: {
    id: 'deposits.rejectFailedMessage',
    defaultMessage: 'Deposit rejection failed!',
  },
  remarkRequired: {
    id: 'deposits.remarkRequired',
    defaultMessage: 'Remark is required',
  },

  amountRequired: {
    id: 'deposits.amountRequired',
    defaultMessage: 'Amount is required',
  },

  amountAtLeast: {
    id: 'deposits.amountAtLeast',
    defaultMessage: 'Amount must be at least 10 USD',
  },
  accountType: {
    id: 'deposits.accountType',
    defaultMessage: 'To',
  },
  clickToView: {
    id: 'deposits.clickToView',
    defaultMessage: 'click here to view it.',
  },
  clickToClose: {
    id: 'deposits.clickToClose',
    defaultMessage: 'click here to close it.',
  },
  approveModalHelpText: {
    id: 'deposits.approveModalHelpText',
    defaultMessage:
      'Warning: You confirm that you’ve read the document by approving. If not reviewed,',
  },
  rejectModalHelpText: {
    id: 'deposits.rejectModalHelpText',
    defaultMessage:
      'Warning: You confirm that you’ve read the document by rejecting. If not reviewed,',
  },
  remarkValidation: {
    id: 'deposits.remarkValidation',
    defaultMessage: 'Remark should contain only letters and numbers',
  },
  remarkMinLength: {
    id: 'deposits.remarkMinLength',
    defaultMessage: 'Remark should be at least 4 characters',
  },
  remarkMaxLength: {
    id: 'deposits.remarkMaxLength',
    defaultMessage: 'Remark should be at most 100 characters',
  },
  tradingAmountValidation: {
    id: 'deposits.tradingAmountValidation',
    defaultMessage: 'Trading amount should be at least 10 USD',
  },

  local_amount: {
    id: 'deposits.local_amount',
    defaultMessage: 'Local Amount',
  },
  local_currency: {
    id: 'deposits.local_currency',
    defaultMessage: 'Local Currency',
  },
  notes: {
    id: 'deposits.notes',
    defaultMessage: 'Note',
  },
  local_amount_required: {
    id: 'deposits.local_amount_required',
    defaultMessage: 'Local Amount is required',
  },
  local_amount_validation: {
    id: 'deposits.local_amount_validation',
    defaultMessage: 'Local Amount should be at least 10 USD',
  },
  deposit_details: {
    id: 'deposits.deposit_details',
    defaultMessage: 'Deposit Details',
  },
});
