import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../../services/queries/useQueryFactory';

const useGetJointAccountDetails = <TData, TError = Error>(userId: number) => {
  const queryDetails = useQueryFactory<TData, TError>();
  const { data, isFetched, isLoading } = useQuery({
    ...queryDetails.getJointDetails(userId),
  });
  return { jointAccountInfo: data?.data, isFetched, isLoading };
};

export default useGetJointAccountDetails;
