import { useState, useEffect } from 'react';

const useAsyncMemo = (
  asyncFunction: () => Promise<any>,
  dependencies: any[],
) => {
  const [value, setValue] = useState<any>(null);

  useEffect(() => {
    let isMounted = true;

    asyncFunction().then((result) => {
      if (isMounted) {
        setValue(result);
      }
    });

    return () => {
      isMounted = false;
    };
  }, dependencies);

  return value;
};

export default useAsyncMemo;
