import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { TRANSACTION_TABS } from './constants';
import { FormattedMessage } from '~app/Integrations/Localization';
import { StyledTab, StyledTabs } from '~/constants/styles';

const TransactionTabs = () => {
  const [selectedTab, setSelectedTab] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setSelectedTab(newTab);
  };

  return (
    <Box>
      <StyledTabs
        value={selectedTab}
        onChange={handleChange}
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          marginX: '10px',
        }}
      >
        {TRANSACTION_TABS.map((tab, index) => (
          <StyledTab key={index} label={<FormattedMessage {...tab.label} />} />
        ))}
      </StyledTabs>
      <Box sx={{ padding: 0 }}>{TRANSACTION_TABS[selectedTab]?.component}</Box>
    </Box>
  );
};

export default TransactionTabs;
