import * as yup from 'yup';
import {
  getAddressLine1Schema,
  getAddressLine2Schema,
  getCityTownSchema,
  getDateOfBirthSchema,
  getFullNameSchema,
  getGenderSchema,
  getStateProvinceSchema,
  getZipPostalCodeSchema,
} from '~/schema/ProfileSchema';

export const JOINT_ACCOUNT_VALUES = {
  secondary_name: '',
  secondary_gender: '',
  secondary_address_line1: '',
  secondary_address_line2: '',
  secondary_city_town: '',
  secondary_state_province: '',
  secondary_zip_postal_code: '',
  secondary_date_of_birth: '',
};

export const getJointDetailsInfoSchema = (
  formatMessage: (msg: any) => string,
) =>
  yup.object().shape({
    secondary_name: getFullNameSchema(formatMessage),
    secondary_gender: getGenderSchema(formatMessage),
    secondary_address_line1: getAddressLine1Schema(formatMessage),
    secondary_address_line2: getAddressLine2Schema(formatMessage),
    secondary_city_town: getCityTownSchema(formatMessage),
    secondary_state_province: getStateProvinceSchema(formatMessage),
    secondary_zip_postal_code: getZipPostalCodeSchema(formatMessage),
    secondary_date_of_birth: getDateOfBirthSchema(formatMessage),
  });
