import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../services/quries/useQueryFactory';
import { useLocation } from 'react-router-dom';

const useGetUploadedDoc = <TData, TError = Error>(userId: number) => {
  const location = useLocation();
  const queryDetails = useQueryFactory<TData, TError>();
  const { data, isLoading, isFetched, isError, status, refetch } = useQuery({
    ...queryDetails.getUploadedDocList(location.state),
  });
  return { uploadedDoc: data, isLoading, isFetched, isError, status, refetch };
};

export default useGetUploadedDoc;
