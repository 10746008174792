import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from 'v2s-ui-core/app/components/Box';
import Stack from 'v2s-ui-core/app/components/Stack';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import BreadCrumbs from './BreadCrumbs';
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useQueryClient } from '~app/Integrations/react-query';

export interface AppBaseLayoutProps {
  headerProps: {
    title: string;
    breadcrumbs?: {
      label: string;
      href?: string;
    }[];

    actionButtons?: React.ReactNode;
  };
  contentProps: {
    content: React.ReactNode;
    actionsLeft?: React.ReactNode;
    actionsRight?: React.ReactNode;
  };
  renderWithDefaultSettings?: boolean;
  headerTabs?: React.ReactNode;
  QUERY_KEYS?: string;
  ShowRefresh?: boolean;
}

const AppBaseLayout: React.FC<AppBaseLayoutProps> = ({
  headerProps,
  contentProps,
  renderWithDefaultSettings = true,
  headerTabs,
  QUERY_KEYS,
  ShowRefresh,
}) => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const handleRefresh = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS] });
  };

  return (
    <Box>
      <BreadCrumbs headerProps={headerProps} />
      <Stack
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        flexDirection='row'
        mb={0}
      >
        <Typography variant='h5'>{headerProps.title}</Typography>
        {headerProps.actionButtons && headerProps.actionButtons}
      </Stack>

      <Box paddingY={'12px'} mb={0}>
        {headerTabs}
      </Box>
      <Card
        sx={{
          position: 'relative',
        }}
      >
        <CardContent>
          <Box display={'flex'} flexDirection={'column'}>
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              position={'absolute'}
              right={'8px'}
              top={'14px'}
            >
              {ShowRefresh && (
                <Tooltip
                  title='Refresh'
                  disableFocusListener
                  disableTouchListener
                >
                  <IconButton
                    onClick={handleRefresh}
                    sx={{
                      transition: 'transform 0.2s ease-in-out',

                      '&:active': {
                        transform: 'rotate(360deg)',
                      },
                    }}
                  >
                    <RefreshIcon height={40} width={40} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box display={'flex'} gap={'12px'} justifyContent={'space-between'}>
              {contentProps.actionsLeft && contentProps.actionsLeft}
            </Box>
            <Box>{contentProps.content}</Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
export default AppBaseLayout;
