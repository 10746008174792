import { get, put } from 'v2s-ui-core/app/Integrations/ApiService';
import {
  TUpdateCorporateDetailsPayload,
  TUpdateFinancialDetailsPayload,
  TUpdateJointDetailsPayload,
  TUpdateUserProfilePayload,
} from '../services/mutations/types';
import { API_URLS } from './config';
import { useLocation } from 'react-router-dom';

const ApiServices = {
  getUserProfile: async <T>(id: number) => {
    try {
      const response = await get<T>(`${API_URLS.GET_USER_PROFILE}${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getFinancialDetails: async <T>(id: number) => {
    try {
      const response = await get<T>(`${API_URLS.GET_FINANCIAL_DETAILS}${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateUserProfile: async <T>({
    userId,
    values,
  }: TUpdateUserProfilePayload) => {
    try {
      const response = await put<T>(
        `${API_URLS.UPDATE_USER_PROFILE(userId)}`,
        values,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateFinancialDetails: async <T>({
    userId,
    values,
  }: TUpdateFinancialDetailsPayload) => {
    try {
      const response = await put<T>(
        `${API_URLS.UPDATE_FINANCIAL_DETAILS(userId)}`,
        values,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getJointAccountDetails: async <T>(id: number) => {
    try {
      const response = await get<T>(
        `${API_URLS.GET_JOINT_ACCOUNT_DETAILS}${id}`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateJointAccountDetails: async <T>({
    userId,
    values,
  }: TUpdateJointDetailsPayload) => {
    try {
      const response = await put<T>(
        `${API_URLS.UPDATE_JOINT_ACCOUNT_DETAILS(userId)}`,
        values,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCorporateDetails: async <T>(id: number) => {
    try {
      const response = await get<T>(`${API_URLS.GET_CORPORATE_DETAILS}${id}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  updateCorporateDetails: async <T>({
    userId,
    values,
  }: TUpdateCorporateDetailsPayload) => {
    try {
      const response = await put<T>(
        `${API_URLS.UPDATE_CORPORATE_DETAILS}${userId}`,
        values,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ApiServices;
