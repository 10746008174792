export const TRADING_FORM_KEYS = {
  POI_FRONT: 'poi_front',
  POI_BACK: 'poi_back',
  POA_FRONT: 'poa_front',
  POA_BACK: 'poa_back',
  OTHERs_FRONT: 'other_front',
  OTHERs_BACK: 'other_back',
} as const;

export const COMPLETE_KYC_FORM_VALUES = {
  poi_back: null as any,
  poi_front: null as any,
  poa_front: null as any,
  poa_back: null as any,
  others_front: null as any,
  others_back: null as any,
  bulk_files: null as any,
};

export const UPLOAD_FILE_TYPE = {
  FRONT: 'front',
  BACK: 'back',
  OTHER: 'other',
};

export const MY_KYC_STATUS = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
};
