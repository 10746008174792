import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { BANK_ACCOUNT_STATUS } from '~/modules/BankAccounts/constants';
import messages from '../../../../messages';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import { useApproveRejectBankAccount } from '../../../../hooks/useApproveRejectBankAccount';
import { ApproveRejectBankAccountProps } from '../../../../types';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
import { RenderDocumentContent } from '~/components/ShowDoc';
import { REQUEST_STATUS } from '~/constants';
import {} from '~app/styles';
import { Box, Typography } from '@mui/material';

const ApproveRejectBankAccount = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
  status,
}: ApproveRejectBankAccountProps) => {
  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldTouched,
  } = useApproveRejectBankAccount({
    onConfirmApproveReject,
    recordForApproveReject,
  });
  const isMobile = useIsMobile();
  const { latest_version: documentData = {} } =
    recordForApproveReject?.record?.user_document || {};
  const {
    original_doc_location: documentUrl = '',
    content_type: contentType = '',
    extension = '',
  } = documentData as any;

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: isMobile ? '100vw' : '800px',
          margin: isMobile ? 1 : 0,
        },
      }}
      fullScreen={false}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        {recordForApproveReject.action === BANK_ACCOUNT_STATUS.APPROVED ? (
          <FormattedMessage {...messages.approveModalTitle} />
        ) : (
          <FormattedMessage {...messages.rejectModalTitle} />
        )}
        <CloseButton onClose={onCancel} />
      </DialogTitle>

      <DialogContent sx={{ width: '100%', padding: isMobile ? 2 : 1 }}>
        {/* Form Fields */}

        <Box
          display='flex'
          alignItems='center'
          justifyContent={'center'}
          gap={isMobile ? 0 : 4}
          px={isMobile ? 0 : 2}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid container spacing={2} mt={0}>
            <Grid item xs={12}>
              <TextField
                name={'bank_name'}
                variant='outlined'
                label={<FormattedMessage {...messages.bank_name} />}
                value={recordForApproveReject?.record?.bank_name}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name={'bank_account_holder_name'}
                variant='outlined'
                label={
                  <FormattedMessage {...messages.bank_account_holder_name} />
                }
                value={recordForApproveReject?.record?.bank_account_name}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name={'bankAccountNumber'}
                variant='outlined'
                label={<FormattedMessage {...messages.bankAccountNumber} />}
                value={recordForApproveReject?.record?.bank_account_number}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name={'bank_code'}
                variant='outlined'
                label={<FormattedMessage {...messages.bank_ifsc} />}
                value={recordForApproveReject?.record?.bank_code}
                fullWidth
                size='small'
                disabled
              />
            </Grid>
            {recordForApproveReject?.record?.bank_iban &&
              recordForApproveReject?.record?.bank_iban !== '' && (
                <Grid item xs={12}>
                  <TextField
                    name={'bank_iban'}
                    variant='outlined'
                    label={<FormattedMessage {...messages.bank_iban} />}
                    value={recordForApproveReject?.record?.bank_iban}
                    fullWidth
                    size='small'
                    disabled
                  />
                </Grid>
              )}
            <Grid item xs={12}>
              <TextField
                name={'bank_address'}
                variant='outlined'
                label={<FormattedMessage {...messages.bank_address} />}
                value={recordForApproveReject?.record?.bank_address}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name={'remark'}
                variant='outlined'
                label={<FormattedMessage {...messages.addRemark} />}
                // onChange={handleChange}/
                onChange={(e) => {
                  setFieldTouched('remark', true);
                  handleChange(e);
                }}
                onBlur={() => setFieldTouched('remark', true)}
                value={values.remark}
                fullWidth
                size='small'
                required
                type='text'
                error={touched.remark && Boolean(errors.remark)}
                helperText={touched.remark && errors.remark}
              />
            </Grid>
          </Grid>
          <Box mt={1}>
            <Typography>
              {
                recordForApproveReject?.record?.user_document?.latest_version
                  ?.filename
              }
            </Typography>

            <RenderDocumentContent
              contentType={contentType}
              extension={extension}
              documentUrl={documentUrl}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: isMobile ? 1 : 0, mb: 2, mr: 3 }}>
        <Button
          disabled={status === REQUEST_STATUS.PENDING}
          variant='contained'
          onClick={() => handleSubmit()}
        >
          {recordForApproveReject.action === BANK_ACCOUNT_STATUS.APPROVED ? (
            <FormattedMessage {...messages.approve} />
          ) : (
            <FormattedMessage {...messages.reject} />
          )}
        </Button>
        <Button variant='outlined' onClick={onCancel}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRejectBankAccount;
