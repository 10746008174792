import React, { useState } from 'react';

import { Box, Button, Typography } from '@mui/material';
import useDocumentUpload, {
  TUploadDocMutationVars,
} from '../useDocumentUpload';
import SuccessModal from '~/components/SuccessModal';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useIsMobile } from '../useIsMobile';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';

interface FileUploadProps {
  onUploadSuccess?: (documentId: string) => void; // Callback to return the uploaded document ID
  disabled?: boolean;
  documentMappingId: number | string; // Dynamic document mapping ID
  userId?: number;
  setIsUpdateSuccess?: any;
  isKycUpate?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onUploadSuccess,
  disabled,
  documentMappingId,
  userId,
  setIsUpdateSuccess,
  isKycUpate = false,
}) => {
  const [fileName, setFileName] = useState<string | null>(null);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const { showToastNotification } = useShowToastNotifications();
  const isMobile = useIsMobile();

  const { uploadDoc, isError } = useDocumentUpload({
    userId: userId,
    mutationOptions: {
      onSuccess: (response) => {
        setFileName(null);
        isKycUpate && setIsUpdateSuccess(true);
        setIsSuccessModalOpen(true);
      },
      onError: (error) => {
        showToastNotification(`${error?.data.errors[0].detail}`, 'error');
      },
    },
    storeUploadDocKey: () => {
      // Optional: Store or cache document information if needed
    },
  });

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setFileName(file.name);

    const payload: TUploadDocMutationVars = {
      file,
      fileName: file.name,
      documentMappigId: documentMappingId,
    };

    uploadDoc(payload);
  };

  return (
    <>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='flex-start'
        position='relative'
        gap={2}
      >
        <Button
          component='label'
          variant='outlined'
          disabled={disabled}
          sx={{
            textTransform: 'none',
            color: '#768098',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            height: '40px',
            mx: '12px',
          }}
        >
          {isMobile ? (
            <UploadFileIcon />
          ) : (
            <Typography variant='body2'>Upload</Typography>
          )}

          <input type='file' hidden onChange={handleFileUpload} />
        </Button>

        {fileName && (
          <Typography
            variant='body2'
            position={'absolute'}
            top={'40px'}
            width={'100%'}
            color={isError ? 'error' : 'text.secondary'}
          >
            {isError
              ? 'Failed to upload.'
              : `${
                  fileName.length > 10
                    ? `${fileName.slice(0, 12)}...`
                    : fileName
                }`}
          </Typography>
        )}
      </Box>
      <SuccessModal
        open={isSuccessModalOpen}
        onClose={() => setIsSuccessModalOpen(false)}
        message={`Kyc Document uploaded successfully!`}
      />
    </>
  );
};

export default FileUpload;
