import {
  CONTENT_TYPE_PDF,
  CONTENT_TYPES_IMAGE,
  FILE_EXTENSION_PDF,
} from '~/constants/index';
import { TDocumentsTypes, TFilteredBankFile } from '~/config/types';

import dayjs from 'dayjs';
import { BANK_STATEMENT_DOCUMENT_DETAIL } from '~/config/constants';
import { MINIMUM_AGE_ALLOWED } from '~/config/constants';
export const isDocumentPdf = (
  contentType: string,
  extension: string,
): boolean =>
  [contentType, extension].includes(CONTENT_TYPE_PDF) ||
  [extension].includes(FILE_EXTENSION_PDF);

export const isDocumentImage = (contentType: string): boolean =>
  CONTENT_TYPES_IMAGE.includes(
    contentType as (typeof CONTENT_TYPES_IMAGE)[number],
  );

export const getMaxDateAsCurrentDate = (date: Date) => {
  const maxDateAsCurrentDate = date;
  maxDateAsCurrentDate.setFullYear(maxDateAsCurrentDate.getFullYear());
  return maxDateAsCurrentDate;
};

// Utility function to format the date
export const formatDate = (
  date: Date | null,
  format: string = 'DD-MM-YYYY',
): string => (date ? dayjs(date).format(format) : '');

export const updateFieldValueToUpperCase =
  (setFieldValue: any) => (event: any) => {
    const { name, value } = event.target;
    setFieldValue(name, value.toUpperCase());
  };

export function findIndexByDocType(dataArray: Array<any>, type: string): any {
  const index = dataArray.findIndex((item) => item.document_side === type);
  const data = dataArray[index];
  const formRequiredValues = {
    name: data?.latest_version?.filename,
    id: data?.id,
    status: data?.latest_version?.status?.status_name,
    file_location: data?.latest_version?.original_doc_location,
    remark: data?.latest_version?.remark,
  };
  return formRequiredValues;
}
export function findOtherDocsIndexByDocType(
  dataArray: Array<any>,
  type: string,
  fieldId: number,
): any {
  const data = dataArray[fieldId];
  const formRequiredValues = {
    name: data?.latest_version?.filename,
    id: data?.id,
    status: data?.latest_version?.status?.status_name,
    file_location: data?.latest_version?.original_doc_location,
    remark: data?.latest_version?.remark,
  };
  return formRequiredValues;
}

export const previewOrOpenFileInNewTab = (
  url: string,
  file: any,
  setViewFile: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      file: any;
    }>
  >,
) => {
  if (url) {
    window.open(url, '_blank');
  } else {
    setViewFile(() => ({ open: true, file }));
  }
};

export const isDocumentKYCIncomplete = (data: any[]): boolean =>
  data &&
  data?.some(
    (item: any) =>
      item?.step?.name === 'documents' && item?.is_completed === false,
  );

export const fileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const handleDownload = (
  url: any,
  filename: any,
  showToastNotification: any,
) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    })
    .catch((error) =>
      showToastNotification('Error downloading the file', 'error'),
    );
};

export const filterBankFile = (data: TDocumentsTypes): TFilteredBankFile => {
  const bankDocuments = data.Bank || [];

  const bankStatement = bankDocuments.find(
    (document) => document.document_name === 'Bank Statement',
  );

  const frontSide = bankStatement?.sides.find(
    (side) => side.document_side === 'Front',
  );

  return frontSide
    ? { document_name: bankStatement.document_name, id: frontSide.id }
    : BANK_STATEMENT_DOCUMENT_DETAIL; // default value if not found
};

export const getMaxDOBFromCurrentDate = (date: Date) => {
  const maxDateForDateOfBirth = date;
  maxDateForDateOfBirth.setFullYear(
    maxDateForDateOfBirth.getFullYear() - MINIMUM_AGE_ALLOWED,
  );
  return maxDateForDateOfBirth;
};
