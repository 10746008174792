export const QUERY_KEYS = {
  FETCH_KYC: 'kycDocuments',
  APPROVE_REJECT_KYC: 'approveRejectKyc',
  ACCOUNT_TYPE: 'accountType',

  USER_ACCOUNT: 'userAccount',

  GET_BANK_ACCOUNT: 'getBankAccount',

  GET_LOCAL_CURRENCY: 'getLocalCurrency',

  GET_MT5_ACCOUNT: 'getMT5Account',

  GET_PAYMENT_METHODS: 'getPaymentMethods',

  GET_DOC_TYPES_LIST: 'getDocTypesList',

  GET_WALLET_BALANCE: 'getWalletBalance',

  GET_LEVERAGE: 'getLeverage',

  GET_DETAIL_TRADING: 'getDetailTradingAccount',

  GET_TRANSFER_TYPE: 'getTransferType',

  GET_ALL_USER_ACCOUNTS: 'allAccountType',

  GET_NETWORK_TRANSFER: 'getNetworkTransfer',

  GET_CRYPTO_CURRENCY: 'getCryptoCurrency',

  USER_TYPE: 'userType',
} as const;
