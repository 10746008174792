import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  approveModalTitle: {
    id: 'bankAccounts.approveModalTitle',
    defaultMessage: 'Approve Bank Account?',
  },
  rejectModalTitle: {
    id: 'bankAccounts.rejectModalTitle',
    defaultMessage: 'Reject Bank Account?',
  },
  approveModalHelpText: {
    id: 'bankAccounts.approveModalHelpText',
    defaultMessage:
      'Warning: You confirm that you’ve read the document by approving. If not reviewed,',
  },
  rejectModalHelpText: {
    id: 'bankAccounts.rejectModalHelpText',
    defaultMessage:
      'Warning: You confirm that you’ve read the document by rejecting. If not reviewed,',
  },
  bankAccountNumber: {
    id: 'bankAccounts.bankAccountNumber',
    defaultMessage: 'Bank Account Number',
  },
  approved: {
    id: 'bankAccounts.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'bankAccounts.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'bankAccounts.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'bankAccounts.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'bankAccounts.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'bankAccounts.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'bankAccounts.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'bankAccounts.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  clickToView: {
    id: 'bankAccounts.clickToView',
    defaultMessage: 'click here to view it.',
  },
  clickToClose: {
    id: 'bankAccounts.clickToClose',
    defaultMessage: 'click here to close it.',
  },
  approveSuccessMessage: {
    id: 'bankAccounts.approveSuccessMessage',
    defaultMessage: 'Bank account approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'bankAccounts.rejectSuccessMessage',
    defaultMessage: 'Bank account rejected successfully!',
  },
  approveFailedMessage: {
    id: 'bankAccounts.approveFailedMessage',
    defaultMessage: 'Bank account approval failed!',
  },
  rejectFailedMessage: {
    id: 'bankAccounts.rejectFailedMessage',
    defaultMessage: 'Bank account rejection failed!',
  },
  view: {
    id: 'bankAccounts.view',
    defaultMessage: 'View',
  },
  viewBankAccount: {
    id: 'bankAccounts.viewBankAccount',
    defaultMessage: 'View Bank Account ',
  },
  bank_account: {
    id: 'bankAccounts.bankAccount',
    defaultMessage: 'Bank Account',
  },
  account_details: {
    id: 'bankAccounts.accountDetails',
    defaultMessage: 'Account Details',
  },
  close: {
    id: 'bankAccounts.close',
    defaultMessage: 'Close',
  },
  //
  bank_name: {
    id: 'bankAccounts.bankName',
    defaultMessage: 'Bank Name',
  },
  bank_account_name: {
    id: 'bankAccounts.accountName',
    defaultMessage: 'Account Name',
  },
  bank_account_number: {
    id: 'bankAccounts.accountNumber',
    defaultMessage: 'Account Number',
  },
  bank_iban: {
    id: 'bankAccounts.bankIban',
    defaultMessage: 'Bank IBAN',
  },
  bank_ifsc: {
    id: 'bankAccounts.bankIFSC',
    defaultMessage: 'Bank IFSC',
  },
  bank_address: {
    id: 'bankAccounts.bankAddress',
    defaultMessage: 'Bank Address',
  },

  document_name: {
    id: 'bankAccounts.documentName',
    defaultMessage: 'Document Name',
  },
  documents: {
    id: 'bankAccounts.documents',
    defaultMessage: 'Documents',
  },

  bank_accounts: {
    id: 'bankAccounts.bankAccounts',
    defaultMessage: 'Bank Accounts',
  },

  choose_bank: {
    id: 'bankAccounts.chooseBank',
    defaultMessage: 'Choose Bank Account',
  },
  bank_name_required: {
    id: 'bankAccounts.bankNameRequired',
    defaultMessage: 'Bank name is required',
  },
  bank_account_name_required: {
    id: 'bankAccounts.accountNameRequired',
    defaultMessage: 'Account name is required',
  },
  bank_account_number_required: {
    id: 'bankAccounts.accountNumberRequired',
    defaultMessage: 'Account number is required',
  },
  bank_account_number_must_be_numeric: {
    id: 'bankAccounts.accountNumberMustBeNumeric',
    defaultMessage: 'Account number must be numeric',
  },
  bank_iban_required: {
    id: 'bankAccounts.ibanRequired',
    defaultMessage: 'IBAN is required',
  },
  bank_ifsc_required: {
    id: 'bankAccounts.ifscRequired',
    defaultMessage: 'IFSC is required',
  },
  bank_address_required: {
    id: 'bankAccounts.addressRequired',
    defaultMessage: 'Bank address is required',
  },
  user_document_required: {
    id: 'bankAccounts.documentRequired',
    defaultMessage: 'Document is required',
  },
  bankNameValidation: {
    id: 'bankAccounts.bankNameValidation',
    defaultMessage: 'Bank name can only contain letters',
  },
  bankAccountNumberValidation: {
    id: 'bankAccounts.bankAccountNumberValidation',
    defaultMessage: 'Bank account number can only contain letters and numbers',
  },
  accountNameValidation: {
    id: 'bankAccounts.accountNameValidation',
    defaultMessage: 'Account name can only contain letters',
  },

  bankNameMinLength: {
    id: 'bankAccounts.bankNameMinLength',
    defaultMessage: 'Bank name should be at least 3 characters',
  },
  bankNameMaxLength: {
    id: 'bankAccounts.bankNameMaxLength',
    defaultMessage: 'Bank name should be at most 50 characters',
  },
  accountNameMinLength: {
    id: 'bankAccounts.accountNameMinLength',
    defaultMessage: 'Account name should be at least 3 characters',
  },
  accountNameMaxLength: {
    id: 'bankAccounts.accountNameMaxLength',
    defaultMessage: 'Account name should be at most 50 characters',
  },
  ifscValidation: {
    id: 'bankAccounts.ifscValidation',
    defaultMessage:
      'IFSC must be exactly 11 characters long and contain only uppercase letters and numbers',
  },

  bankAddressMinLength: {
    id: 'bankAccounts.bankAddressMinLength',
    defaultMessage: 'Bank address should be at least 3 characters',
  },
  bankAddressMaxLength: {
    id: 'bankAccounts.bankAddressMaxLength',
    defaultMessage: 'Bank address should be at most 100 characters',
  },
  bankIbanValidation: {
    id: 'bankAccounts.bankIbanValidation',
    defaultMessage: 'Bank IBAN can only contain letters and numbers',
  },
  bankIbanMinLength: {
    id: 'bankAccounts.bankIbanMinLength',
    defaultMessage: 'Bank IBAN should be at least 8 characters',
  },
  bankIbanMaxLength: {
    id: 'bankAccounts.bankIbanMaxLength',
    defaultMessage: 'Bank IBAN should be at most 25 characters',
  },
});
