import React, { useMemo } from 'react';
import TableFilters from '~/components/TableFilters';
import Box from '~app/components/Box';
import DataGridWithQuery from '~app/components/DataGridWithQuery';
import { getIbNetworkTableColumns } from './IbNetworkTableColumn';
import { FILTERS } from '~/config/constants';
import useFilters from '~app/hooks/useFilters';
import { API_URLS } from '../../api/config';
import { useLocation } from 'react-router-dom';

const IbNetwork = () => {
  const userId = useLocation();
  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchIBNetwork',
  });
  const memoizedColumns = useMemo(() => getIbNetworkTableColumns({}), []);
  return (
    <Box>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'26px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.GET_MY_NETWORK_HISTORY(userId?.state),
            queryKeyProp: ['fetchIBNetwork'],
            select: (data: any) => data,
          }}
          columns={memoizedColumns}
          pageCount={10}
          filters={filters}
          key={userId?.state}
        />
      </Box>
    </Box>
  );
};

export default IbNetwork;
