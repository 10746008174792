import messages from './messages';
import BankAccounts from './modules/BankAccount/index';
import IbAgreement from './modules/IbAgreement';
import IbGenealogy from './modules/IbGenealogy';
import IbNetwork from './modules/IbNetwork';
import IbStatement from './modules/IbStatement';
import KycDocs from '../Kyc';
import ProfileTabs from './modules/Profile/ProfileTabs';

const QUERY_KEYS = {
  FETCH_USER_PROFILE: 'fetchUserProfile',
  FETCH_BANK_ACCOUNT_INFOS: 'fetchBankAccountInfos',
  FETCH_KYC: 'kycDocuments',
  FETCH_TRADING_ACCOUNTS: 'fetchTradingAccounts',
  FETCH_WALLET: 'fetchWalletDetails',
  FETCH_DEPOSIT: 'fetchUserDeposits',
  FETCH_WITHDRAWAL: 'fetchUserWithdrawals',
};

export const USER_PROFILE_TABS = [
  {
    profile: 1,
    label: messages.userProfile,
    component: <ProfileTabs />,
  },
  {
    bank_Account: 2,
    label: messages.bankAccount,
    component: <BankAccounts />,
  },
  {
    Kyc_Document: 3,
    label: messages.kycDocument,
    component: <KycDocs />,
  },
  {
    genealogy: 4,
    label: messages.genealogy,
    component: <IbGenealogy />,
  },
  {
    network: 5,
    label: messages.network,
    component: <IbNetwork />,
  },
  {
    statement: 6,
    label: messages.statement,
    component: <IbStatement />,
  },
  {
    agreement: 7,
    label: messages.agreement,
    component: <IbAgreement />,
  },
];

export const TABS_CONFIG = {
  initialPosition: 0,
};

export const MODES = [
  {
    id: 5,
    name: 'APPROVE',
  },
  {
    id: 3,
    name: 'CREATE',
  },
  {
    id: 4,
    name: 'DELETE',
  },
  {
    id: 2,
    name: 'EDIT',
  },
  {
    id: 8,
    name: 'EXPORT',
  },
  {
    id: 6,
    name: 'REJECT',
  },
  {
    id: 7,
    name: 'UPLOAD',
  },
  {
    id: 1,
    name: 'VIEW',
  },
];

export const IB_AGREEMENT_INITIAL_VALUES = {
  rebate_structure: null as any,
  tier: null as any,
  user: null as any,
};

export const SPREAD_TABLE_COLUMNS = [
  {
    accessorKey: 'max_rebate_percent',
    header: 'Tier Max Rebate Percent',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'override_rebate_percent',
    header: 'Tier override rebate percent',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'max_levels',
    header: 'Max Levels',
    enableColumnFilter: false,
  },
];
export const FIXED_REBATE_TABLE_COLUMNS = [
  {
    accessorKey: 'user.level',
    header: 'Level',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'mt5_account_type.type',
    header: 'MT5 Group Type',
    enableColumnFilter: false,
  },

  {
    accessorKey: 'asset_class.name',
    header: 'MT5 Symbol Type',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'max_value',
    header: 'Max Fixed Rebate Value',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'override_amount',
    header: 'Override Amount',
    enableColumnFilter: false,
  },
];
