import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React from 'react';
import { theme } from '~/shell/theme/theme';

const YearPickCalender = ({
  value,
  onChange,
  label,
  maxDate,
  disabled,
  minDate,
  error,
  helperText,
  width,
  textColor,
  required = false,
  onBlur,
}: {
  value: string | dayjs.Dayjs | Date;
  onChange: (newValue: string | dayjs.Dayjs | Date) => void;
  label?: string;
  maxDate?: string | dayjs.Dayjs | Date;
  minDate?: string | dayjs.Dayjs | Date;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  width?: string | number;
  textColor?: string;
  required?: boolean;
  onBlur?: () => void;
}) => {
  const [cleared, setCleared] = React.useState(false);

  React.useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false);
      }, 1500);

      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [cleared]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        sx={{
          width: width,
          '& :hover': {
            color: !error ? 'black !important' : 'error !important',
          },
        }}
        slotProps={{
          textField: {
            fullWidth: true,
            size: 'small',
            InputLabelProps: {
              style: {
                color: textColor || theme.palette.text.primary,
              },
            },
            error: error,
            helperText: helperText,
            onBlur: onBlur,
          },
          field: { clearable: true, onClear: () => setCleared(true) },
        }}
        format='YYYY-MM-DD'
        label={label}
        value={typeof value === 'string' ? dayjs(value, 'YYYY-MM-DD') : value}
        onChange={(newValue) => {
          if (newValue && dayjs(newValue).isValid()) {
            onChange(dayjs(newValue).add(1, 'day').toDate());
          } else {
            onChange(null);
          }
        }}
        views={['year', 'month', 'day']}
        maxDate={maxDate || dayjs()}
        minDate={minDate}
        // yearsOrder='desc'
        openTo='year'
        disabled={disabled}
        key={label}
      />
    </LocalizationProvider>
  );
};
export default YearPickCalender;
