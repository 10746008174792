import { useMemo } from 'react';
import ApiServices from '../../api';
import { MUTATION_KEYS } from './constants';
import {
  MutationDetails,
  TUpdateKycDocument,
  TUploadKycDocumentResponse,
} from './types';
import { useLocation } from 'react-router-dom';

const {
  UPLOAD_USER_KYC_DOCUMENT,
  UPDATE_KYC_DOCUMENT,
  UPLOAD_KYC_FORM,
  UPDATE_BULK_DOCUMENT,
} = MUTATION_KEYS;
const useMutationFactory = <TData, TError = Error>() => {
  const location = useLocation();
  const mutationDetails = useMemo<MutationDetails<TData, TError>>(
    () => ({
      [UPLOAD_USER_KYC_DOCUMENT]: (id: number) => ({
        mutationKey: [UPLOAD_USER_KYC_DOCUMENT],
        mutationFn: (payload) =>
          ApiServices.uploadUserKycDocument<TUploadKycDocumentResponse>(
            payload,
            location.state,
          ),
      }),
      [UPDATE_KYC_DOCUMENT]: () => ({
        mutationKey: [UPDATE_KYC_DOCUMENT],
        mutationFn: ({ payload, id }: TUpdateKycDocument) =>
          ApiServices.updateKycDocument({ payload, id }),
      }),
      [UPLOAD_KYC_FORM]: () => ({
        mutationKey: [UPLOAD_KYC_FORM],
        mutationFn: ({ payload }: any) =>
          ApiServices.uploadKycFormApi({ payload }),
      }),
      [UPDATE_BULK_DOCUMENT]: (id: number) => ({
        mutationKey: [UPDATE_BULK_DOCUMENT],
        mutationFn: (payload) =>
          ApiServices.updateBulkUserKycDocument(payload, location.state),
      }),
    }),
    [],
  );
  return mutationDetails;
};

export default useMutationFactory;
