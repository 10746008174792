export const API_URLS = {
  FETCH_DEPOSIT_REPORTS: 'crm/transaction',
  DOWNLOAD_DEPOSIT_REPORTS: (selectedFormat: string) =>
    `crm/transaction?report_type=deposit&export=${selectedFormat}`,
  FETCH_WITHDRAWAL_REPORTS: 'crm/transaction',
  DOWNLOAD_WITHDRAWAL_REPORTS: (selectedFormat: string) =>
    `crm/transaction?report_type=withdrawal&export=${selectedFormat}`,

  FETCH_TRANSFER_REPORTS: 'crm/transfer',
  DOWNLOAD_TRANSFER_REPORTS: (selectedFormat: string) =>
    `crm/transfer?export=${selectedFormat}`,

  FETCH_CREDIT_REPORTS: 'crm/credit',
  DOWNLOAD_CREDIT_REPORTS: (selectedFormat: string) =>
    `crm/credit?export=${selectedFormat}`,

  // GX_INJECT_API_URL
};

export const QUERY_KEYS = {
  FETCH_DEPOSIT_REPORTS: 'fetchDepositReports',
  FETCH_WITHDRAWAL_REPORTS: 'fetchWithdrawalReports',
  FETCH_TRANSFER_REPORTS: 'fetchTransferReports',
  FETCH_CREDIT_REPORTS: 'fetchCreditReports',
  // GX_INJECT_QUERY_KEY
};
