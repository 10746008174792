import { styled } from 'v2s-ui-core/app/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    boxShadow: theme.shadows[1],
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightRegular,

    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    maxWidth: 800,
    maxHeight: 300,
    border: `1px solid ${theme.palette.common.black}`,
    overflow: 'auto',
  },
}));

export default CustomTooltip;