import { CircularProgress, Typography } from '@mui/material';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import AsyncDropdown from 'v2s-ui-core/app/components/AsyncDropdown';
import Box from 'v2s-ui-core/app/components/Box';
import Button from 'v2s-ui-core/app/components/Button';
import Divider from 'v2s-ui-core/app/components/Divider';
import Grid from 'v2s-ui-core/app/components/Grid';
import IconButton from 'v2s-ui-core/app/components/IconButton';
import edit from '~/assets/images/edit.svg';
import { MODE } from '~/constants';
import { theme } from '~/shell/theme/theme';
import { API_URLS } from '../../api/config';
import useIbAgreement from '../../hooks/useIbAgreement';
import messages from '../../messages';
import { QUERY_KEYS } from '../../services/queries/constants';
import {
  getAgreementQueryKey,
  getIbLevelValue,
  getResourceApi,
} from '../../utility';
import IBAgreementHistory from './IBAgreementHistory';
import SubIbDetailCard from './SubIbDetail';

const IbAgreement = () => {
  const {
    formik,
    formatMessage,
    userId,
    mode,
    setMode,
    columns,
    agreementData,
    renderListItem,
    location,
    isAgreementDataPending,
  } = useIbAgreement();
  if (isAgreementDataPending) {
    return (
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'26px'}>
          <Grid
            container
            alignItems='center'
            spacing={4}
            justifyContent='space-between'
          >
            {((agreementData as any)?.user?.level ||
              (agreementData as any)?.data?.user?.level) && (
              <Grid container item xs={12} md={12} alignItems='center'>
                <Grid
                  container
                  item
                  spacing={2}
                  width={200}
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Grid item>
                    <Typography variant='h5'>
                      <FormattedMessage {...messages.ibLevel} />
                    </Typography>
                  </Grid>

                  <Grid item>
                    <Box
                      className='status'
                      borderRadius='4px'
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      width='80px'
                      height='40px'
                      bgcolor={theme.palette.primary.light}
                      color={theme.palette.primary.main}
                    >
                      <Typography variant='body1' color='main'>
                        {formatMessage(messages.level, {
                          id: getIbLevelValue(agreementData),
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!!(agreementData as any)?.data?.sub_ib && (
              <SubIbDetailCard data={agreementData} />
            )}
            <Grid container item xs={12} spacing={2} sm={'auto'}>
              <Grid
                item
                width={{ md: '308px', sm: '274px' }}
                xs={12}
                sm={'auto'}
              >
                <AsyncDropdown
                  InputProps={{
                    label: <FormattedMessage {...messages.rebateStructure} />,
                    placeholder: formatMessage(messages.rebateStructure),
                    name: 'rebate_structure',
                    disabled: mode === MODE.EDIT,
                  }}
                  disabled={mode === MODE.EDIT}
                  componentName={'rebate_structure'}
                  queryProps={{
                    resource: API_URLS.FETCH_REBATES,
                    queryKeyProp: [QUERY_KEYS.FETCH_REBATES],
                    select: (data: any) => data.data,
                  }}
                  value={formik.values.rebate_structure}
                  onChange={(e, value) =>
                    formik.setFieldValue('rebate_structure', value || '')
                  }
                  getOptionLabel={(option) => option?.name || ''}
                  getOptionKey={(option) => option.id}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Grid>
              <Grid
                item
                width={{ md: '308px', sm: '274px' }}
                xs={12}
                sm={'auto'}
              >
                <AsyncDropdown
                  InputProps={{
                    label: <FormattedMessage {...messages.tier} />,
                    placeholder: formatMessage(messages.tier),
                    name: 'tier',
                    disabled: mode === MODE.EDIT,
                  }}
                  disabled={mode === MODE.EDIT}
                  queryProps={{
                    resource: API_URLS.FETCH_TIERS,
                    queryKeyProp: [QUERY_KEYS.FETCH_TIERS],
                    select: (data: any) => data.data,
                  }}
                  componentName='tier'
                  value={formik.values.tier}
                  onChange={(e, value) =>
                    formik.setFieldValue('tier', value || '')
                  }
                  getOptionLabel={(option) => option?.tier || ''}
                  getOptionKey={(option) => option.id}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                />
              </Grid>
            </Grid>
            <Grid item justifyContent={'end'}>
              {mode === MODE.EDIT && !(agreementData as any)?.data?.sub_ib && (
                <IconButton sx={{ float: 'right' }}>
                  <img
                    onClick={() => setMode(MODE.ADD)}
                    src={edit}
                    alt='edit'
                    title='Edit'
                  />
                </IconButton>
              )}
            </Grid>
            <Grid item md={12} xs={12}>
              <Divider />
            </Grid>
            {formik.values?.rebate_structure && formik.values?.tier && (
              <Grid item width={'100%'}>
                <IBAgreementHistory
                  resource={API_URLS.GET_AGREEMENT(userId)}
                  queryKeyProp={
                    formik.values.rebate_structure?.id === 1
                      ? QUERY_KEYS.GET_AGREEMENT
                      : QUERY_KEYS.GET_AGREEMENT
                  }
                  columns={columns}
                  type={formik.values.rebate_structure?.id}
                  userId={userId}
                  formik={formik}
                  mode={mode}
                  RESOURCE_API_URL={getResourceApi(mode, location, formik)}
                  AGREEMENT_QUERY_KEY={getAgreementQueryKey(
                    mode,
                    location,
                    formik,
                  )}
                  renderListItem={renderListItem}
                />
              </Grid>
            )}
            {mode === MODE.ADD && (
              <Grid container item spacing={2} xs='auto'>
                <Grid item width={'108px'}>
                  <Button
                    onClick={() => formik.handleSubmit()}
                    variant='contained'
                    sx={{ width: '100px', height: '40px' }}
                    disabled={!formik?.values?.rebate_structure}
                  >
                    <FormattedMessage {...messages.submit} />
                  </Button>
                </Grid>
                <Grid item width={'108px'}>
                  <Button
                    variant='outlined'
                    onClick={() => formik.resetForm()}
                    sx={{ width: '100px', height: '40px' }}
                  >
                    <FormattedMessage {...messages.cancel} />
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default IbAgreement;
