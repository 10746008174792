import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import messages from '../../../../messages';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
import { RenderDocumentContent } from '~/components/ShowDoc';
import { Box, Typography } from '@mui/material';
import { viewBankAccountProps } from '~/modules/BankAccounts/types';

const ViewBankAccount = ({
  onCancel,
  recordToView,
}: {
  onCancel: () => void;
  recordToView: viewBankAccountProps;
}) => {
  const isMobile = useIsMobile();
  const { latest_version: documentData = {} } =
    recordToView?.user_document || {};
  const {
    original_doc_location: documentUrl = '',
    content_type: contentType = '',
    extension = '',
  } = documentData as any;

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: isMobile ? '100vw' : '800px',
          margin: isMobile ? 1 : 0,
        },
      }}
      fullScreen={false}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        <FormattedMessage {...messages.viewBankAccount} />
        <CloseButton onClose={onCancel} />
      </DialogTitle>

      <DialogContent sx={{ width: '100%', padding: isMobile ? 2 : 1 }}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent={'center'}
          gap={isMobile ? 0 : 4}
          px={isMobile ? 0 : 2}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name={'bank_name'}
                variant='outlined'
                label={<FormattedMessage {...messages.bank_name} />}
                value={recordToView?.bank_name}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name={'bank_account_holder_name'}
                variant='outlined'
                label={
                  <FormattedMessage {...messages.bank_account_holder_name} />
                }
                value={recordToView?.bank_account_name}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name={'bankAccountNumber'}
                variant='outlined'
                label={<FormattedMessage {...messages.bankAccountNumber} />}
                value={recordToView?.bank_account_number}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                name={'bank_code'}
                variant='outlined'
                label={<FormattedMessage {...messages.bank_ifsc} />}
                value={recordToView?.bank_code}
                fullWidth
                size='small'
                disabled
              />
            </Grid>
            {recordToView?.bank_iban && recordToView?.bank_iban !== '' && (
              <Grid item xs={12}>
                <TextField
                  name={'bank_iban'}
                  variant='outlined'
                  label={<FormattedMessage {...messages.bank_iban} />}
                  value={recordToView?.bank_iban}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name={'bank_address'}
                variant='outlined'
                label={<FormattedMessage {...messages.bank_address} />}
                value={recordToView?.bank_address}
                fullWidth
                size='small'
                disabled
              />
            </Grid>
          </Grid>
          <Box my={1}>
            <Typography>
              {recordToView?.user_document?.latest_version?.filename}
            </Typography>
            <RenderDocumentContent
              contentType={contentType}
              extension={extension}
              documentUrl={documentUrl}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: isMobile ? 1 : 0, my: 2, mr: 3 }}>
        <Button onClick={onCancel} variant='outlined' size='medium'>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewBankAccount;
