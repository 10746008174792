import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import useToast from '~/hooks/useToast';
import { MutationDetails } from '../../services/mutations/types';
import useMutationFactory from '../../services/mutations/useMutationFactory';
import { QUERY_KEYS } from '../../services/queries/constants';

const useUpdatePersonalInfo = <TData, TError = Error>(
  args?: ReturnType<MutationDetails<TData, TError>['updateUserProfile']>,
) => {
  const { triggerErrorToast } = useToast();

  const queryClient = useQueryClient();

  const onSuccessHandler = (data: any) => {
    // showToastNotification(data?.message, 'success');
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_USER_PROFILE] });
  };

  const onError = (error: any) => {
    triggerErrorToast(error);
  };
  const mutationDetails = useMutationFactory<TData, TError>();
  const {
    mutate: updateUserProfile,
    status,
    data,
    error,
    isPending,
  } = useMutation({
    ...mutationDetails.updateUserProfile(),
    ...args,
    onSuccess: (data: any) => onSuccessHandler(data),
    onError: onError,
  });
  return { updateUserProfile, status, data, error, isLoading: isPending };
};

export default useUpdatePersonalInfo;
