import {
  ALLOWED_KEYS_FOR_NUMBER_INPUT,
  SINGLE_DIGIT_REGEX,
} from '~/config/constants';
import { StyledTextField } from './style';

export const NumberInput = ({ isPasteAllowed = false, ...props }) => {
  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.maxLength && e.target.value.length > props.maxLength) {
      e.target.value = e.target.value.slice(0, props.maxLength);
    }
    // Call parent onInput handler if provided
    if (props.onInput) props.onInput(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isModifierKeyCombination =
      (e.ctrlKey || e.metaKey) && (e.key === 'v' || e.key === 'V'); // Allow Ctrl+V or Cmd+V for paste

    if (
      !SINGLE_DIGIT_REGEX.test(e.key) && // Prevent non-numeric keys
      !ALLOWED_KEYS_FOR_NUMBER_INPUT.includes(e.key) &&
      !isModifierKeyCombination
    ) {
      e.preventDefault();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (!isPasteAllowed) {
      e.preventDefault();
      return;
    }

    const pastedData = e.clipboardData.getData('Text');
    if (!SINGLE_DIGIT_REGEX.test(pastedData)) {
      e.preventDefault(); // Prevent pasting non-numeric characters
      return;
    }

    const sanitizedData = props.maxLength
      ? pastedData.slice(0, props.maxLength)
      : pastedData;

    e.preventDefault();
    props.onChange?.({
      target: {
        name: props.name,
        value: sanitizedData,
      },
    } as any);
  };

  return (
    <StyledTextField
      type='text'
      onInput={handleInput}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      inputMode='numeric'
      {...props}
    />
  );
};
