import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import { CloseButton } from '~/components/Actions/CloseButton';
import { KYC_STATUS_MESSAGE } from '../../constants';
import { useApproveRejectKyc } from '~/hooks/useApproveRejectKycAccount';
import messages from './messages';
import { isDocTypePOA, isDocTypePOI } from './util';
import { ViewPOAFields } from './ViewPOAFields';
import { DOCUMENT_STATUS } from '~/constants';
import { RenderDocumentContent } from '~/components/ShowDoc';
import { Box, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useIsMobile } from '~/hooks/useIsMobile';
import TruncatedHeader from '~/components/TruncatedHeader';
// eslint-disable-next-line complexity
const ApproveRejectKyc = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
  onApproveRecord,
  onRejectRecord,
}: any) => {
  const {
    formik: {
      handleSubmit,
      handleChange,
      values,
      errors,
      touched,
      setFieldTouched,
    },
    setStatus,
  } = useApproveRejectKyc({
    onConfirmApproveReject,
    recordForApproveReject,
  });
  const isMobile = useIsMobile();
  const { record = {} } = recordForApproveReject || {};
  const {
    document_type = '',
    document_name = '',
    document_side = '',
    latest_version: documentData = {},
  } = record || {};
  const {
    original_doc_location: documentUrl = '',
    content_type: contentType = '',

    extension = '',
  } = documentData;

  const handleApprove = () => {
    setStatus(DOCUMENT_STATUS.APPROVE);
    onApproveRecord(
      recordForApproveReject?.record,
      KYC_STATUS_MESSAGE.APPROVED,
    );
    handleSubmit();
  };

  const handleReject = () => {
    setStatus(DOCUMENT_STATUS.REJECT);
    onRejectRecord(recordForApproveReject?.record, KYC_STATUS_MESSAGE.REJECTED);
    handleSubmit();
  };

  return (
    <Dialog
      open={Boolean(recordForApproveReject)}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: isMobile ? '100vw' : '800px',
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        <FormattedMessage {...messages.kycModalTitle} />
        <CloseButton onClose={onCancel} />
      </DialogTitle>

      <DialogContent>
        <Box
          display='flex'
          justifyContent={'space-between'}
          alignItems='center'
          gap={isMobile ? 0 : 4}
          // bgcolor={'red'}
          // px={isMobile ? 0 : 2}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid container spacing={2} mt={1}>
            {isDocTypePOI(document_type, document_side) && (
              <Grid display={'flex'} alignItems={'center'} gap={1} item xs={12}>
                <InfoIcon sx={{ width: '18px', height: '18px' }} />
                <Typography fontSize={'14px'}>
                  Please enter POI number to approve this document
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                name='document_name'
                variant='outlined'
                label={<FormattedMessage {...messages.documentName} />}
                value={document_name || ''}
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant='outlined'
                label={<FormattedMessage {...messages.document_type} />}
                value={document_type || ''}
                fullWidth
                size='small'
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                label={<FormattedMessage {...messages.fullName} />}
                value={
                  recordForApproveReject?.record?.user_detail?.full_name || ''
                }
                fullWidth
                size='small'
                disabled
              />
            </Grid>

            {isDocTypePOA(document_type) ? (
              <ViewPOAFields
                user_details={recordForApproveReject?.record?.user_detail}
              />
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField
                    size='small'
                    fullWidth
                    label={<FormattedMessage {...messages.dob} />}
                    disabled
                    value={
                      recordForApproveReject?.record?.user_detail
                        ?.date_of_birth || ''
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label={<FormattedMessage {...messages.country} />}
                    value={
                      recordForApproveReject?.record?.user_detail?.country || ''
                    }
                    fullWidth
                    size='small'
                    disabled
                  />
                </Grid>

                {isDocTypePOI(document_type, document_side) && (
                  <Grid item xs={12}>
                    <TextField
                      size='small'
                      label={<FormattedMessage {...messages.poi_number} />}
                      name='document_no'
                      onChange={(e) => {
                        setFieldTouched('document_no', true);
                        handleChange({
                          target: {
                            name: 'document_no',
                            value: e.target.value.toUpperCase(),
                          },
                        });
                      }}
                      fullWidth
                      onBlur={() => setFieldTouched('document_no', true)}
                      value={values.document_no || ''}
                      error={touched.document_no && Boolean(errors.document_no)}
                      helperText={touched.document_no && errors.document_no}
                    />
                  </Grid>
                )}
              </>
            )}

            <Grid item xs={12}>
              <TextField
                name='remark'
                variant='outlined'
                label={<FormattedMessage {...messages.addRemark} />}
                onChange={handleChange}
                value={values.remark}
                size='small'
                required
                error={touched.remark && Boolean(errors.remark)}
                helperText={touched.remark && errors.remark}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box mt={1}>
            {/* <Typography>
              {recordForApproveReject?.record?.latest_version?.filename}
            </Typography> */}
            <TruncatedHeader
              maxLength={34}
              text={recordForApproveReject?.record?.latest_version?.filename}
              useTooltip
            />
            <RenderDocumentContent
              contentType={contentType}
              extension={extension}
              documentUrl={documentUrl}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: 3 }}>
        <Button
          variant='contained'
          disabled={
            !isDocTypePOA(document_type) &&
            isDocTypePOI(document_type, document_side) &&
            values.document_no === ''
          }
          onClick={handleApprove}
        >
          <FormattedMessage {...messages.approve} />
        </Button>
        <Button variant='contained' onClick={handleReject}>
          <FormattedMessage {...messages.reject} />
        </Button>
        <Button variant='outlined' onClick={onCancel}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRejectKyc;
