import { Backdrop, CircularProgress } from '@mui/material';

const FullPageLoader = ({ open }: { open: boolean }) => (
  <Backdrop
    open={open}
    sx={{
      color: '#fff',
      zIndex: (theme) => theme.zIndex.modal + 1,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }}
  >
    <CircularProgress color='inherit' size={24} />
  </Backdrop>
);

export default FullPageLoader;
