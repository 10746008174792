import React from 'react';
import Tooltip from '@mui/material/Tooltip';

interface TruncatedHeaderProps {
  text: string;
  maxLength: number;
  useTooltip?: boolean;
}

const TruncatedHeader: React.FC<TruncatedHeaderProps> = ({
  text,
  maxLength,
  useTooltip = true,
}) => {
  const truncatedText =
    text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  if (useTooltip) {
    return (
      <Tooltip title={text} arrow>
        <span>{truncatedText}</span>
      </Tooltip>
    );
  }

  return <span>{truncatedText}</span>;
};

export default TruncatedHeader;
