import { Tabs } from '@mui/material';
import { styled } from 'v2s-ui-core/app/styles';
import Tab from '@mui/material/Tab';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: `${theme.palette.secondary.main}`,
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&.Mui-selected': {
    color: theme.palette.secondary.main,
  },
}));
