import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { LeverageHistoryTableColumns } from './LeverageHistoryTable';
import { ColumnDef } from '~app/components/DataGridCore';
import { CloseButton } from '~/components/Actions/CloseButton';
import { API_URLS, QUERY_KEYS } from '../../../api/config';
import Box from '~app/components/Box';
import TableFilters from '~/components/TableFilters/index';
import useFilters from '~app/hooks/useFilters';
import { FILTERS } from '~/config/constants';

// eslint-disable-next-line arrow-body-style

const LeverageHistoryModal = ({ id, onCancel }: any) => {
  const memoizedColumns = React.useMemo(
    () => LeverageHistoryTableColumns({}),
    [],
  );

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: QUERY_KEYS.FETCH_LEVERAGE_CHANGE_HISTORY,
  });

  return (
    <>
      <Dialog
        open={true}
        PaperProps={{
          component: 'form',
          sx: {
            minWidth: '70%',
            paddingX: '0px',
            paddingY: '0px',
          },
        }}
      >
        <DialogTitle>
          Leverage Change History
          <CloseButton onClose={onCancel} />
        </DialogTitle>
        <DialogContent>
          <Box className='tableContainer' width={'100%'}>
            <Box display='flex' mb={'20px'}>
              <TableFilters filters={filters} applyFilters={applyFilters} />
            </Box>
          </Box>
          <Box className='ScrollableTableContainer'>
            <DataGridWithQuery
              queryProps={{
                resource: API_URLS.FETCH_LEVERAGE_CHANGE_HISTORY,
                queryKeyProp: [QUERY_KEYS.FETCH_LEVERAGE_CHANGE_HISTORY, id],
                select: (data: any) => data,
              }}
              columns={memoizedColumns as ColumnDef<unknown>[]}
              filters={{ trading_account: id, ...filters }}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default LeverageHistoryModal;
