import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { useEdit } from '~/hooks/Edit/useEdit';
import { API_URLS } from '../api/config';
import { QUERY_KEYS } from '../services/queries/constants';
import messages from '../messages';
import { useState } from 'react';
import { validationSchema } from '../constants';

export const useBankInfoForm = ({
  account,
  isEdit,
  setIsEdit,
}: {
  account: any;
  isEdit: boolean;
  setIsEdit: (boolean: boolean) => void;
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { mutate: editBankAccount } = useEdit({
    API_URL: API_URLS.EDIT_BANK_ACCOUNT,
    QUERY_KEY: QUERY_KEYS.FETCH_BANK_ACCOUNT_INFOS,
    messages: {
      successMessage: 'Bank account updated successfully',
      errorMessage: 'Failed to update bank account',
    },
  });

  const formik = useFormik({
    initialValues: {
      bank_name: account?.bank_name || '',
      bank_account_name: account?.bank_account_name || '',
      bank_account_number: account?.bank_account_number || '',
      bank_iban: account?.bank_iban || '',
      bank_code: account?.bank_code || '',
      bank_address: account?.bank_address || '',
      user_document: account?.user_document?.id || '',
      // status: account?.status || '',
    },
    validationSchema: validationSchema(
      formatMessage,
      account?.created_by?.country?.countryEn,
    ),
    onSubmit: (values, { resetForm }) => {
      if (account?.id) {
        editBankAccount(
          { id: account.id, ...values },
          {
            onSuccess: () => {
              resetForm();
              setIsEdit(false);
              setShowSuccessModal(true); // Trigger modal
            },
          },
        );
      }
    },

    enableReinitialize: true,
  });

  return { formik, showSuccessModal, setShowSuccessModal };
};
