import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { TUpdateCorporateDetailsPayload } from '../../services/mutations/types';
import { CORPORATE_INFO_VALUES, getCorporateInfoSchema } from '../constants';
import useGetCorporateDetail from './useGetCorporateDetail';
import useUpdateCorporateDetails from './useUpdateCorporatelDetails';
import { useLocation } from 'react-router-dom';

const useCorporateDetailsForm = ({ disabled, onTabChange }: any) => {
  const location = useLocation();
  const userId = location.state;

  const { corporateInfo, isLoading } = useGetCorporateDetail(userId);

  const { updateCorporateDetails, status, isUpdateLoading } =
    useUpdateCorporateDetails();

  const [state, setState] = useState({
    showCorporateSuccessModal: false,
    disabledCorporateFormFields: true,
  });
  const { showCorporateSuccessModal, disabledCorporateFormFields } = state;

  useEffect(() => {
    if (status === 'success') {
      setState(() => ({
        ...state,
        showCorporateSuccessModal: true,
      }));
    }
  }, [status]);
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const handleCloseSuccessModal = () => {
    setState(() => ({
      ...state,
      showCorporateSuccessModal: false,
    }));
    onTabChange(0);
  };

  const initialValues = useMemo(() => {
    if (corporateInfo) {
      return { ...CORPORATE_INFO_VALUES, ...corporateInfo };
    } else {
      return CORPORATE_INFO_VALUES;
    }
  }, [corporateInfo]);

  const onUpdateValues = (values: any) => {
    const updatedPayload = {
      ...values,
      company_country: values.company_country?.id,
    };

    const payload: TUpdateCorporateDetailsPayload = {
      values: updatedPayload,
      userId,
    };
    updateCorporateDetails(payload);
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: (values) => {
      onUpdateValues(values);
    },
    validationSchema: getCorporateInfoSchema(formatMessage),
    enableReinitialize: true,
  });

  return {
    ...formik,
    showCorporateSuccessModal,
    handleCloseSuccessModal,
    formik,
    disabledCorporateFormFields,
    isLoading,
    isUpdateLoading,
  };
};

export default useCorporateDetailsForm;
