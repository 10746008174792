import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS, QUERY_KEYS } from '../../api/config';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import TableFilters from '~/components/TableFilters';
import Box from '~app/components/Box';
import { getCreditInTableColumns } from './CreditInTableColumn';
import useFilters from 'v2s-ui-core/app/hooks/useFilters';
import { FILTERS } from '~/config/constants';

const CreditIn: FC = () => {
  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: QUERY_KEYS.FETCH_CREDIT_HISTORY,
  });

  const memoizedColumns = useMemo(() => getCreditInTableColumns({}), []);
  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'16px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_CREDIT_HISTORY,
            queryKeyProp: [QUERY_KEYS.FETCH_CREDIT_HISTORY],
            select: (data: any) => data,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
          filters={{
            credit_type_id: 1,
            ...filters,
          }}
        />
      </Box>
    </>
  );
};

export default CreditIn;
