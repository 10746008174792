import messages from './messages';
import PersonalInfo from './PersonalDetails';
import { FormattedMessage } from '~app/Integrations/Localization';
import { IGetTabConfig } from './PersonalDetails/constants';
import FinancialInfo from './FinancialInfo';
import CorporateInfo from './Corporate';
import JointAccount from './JointAccount';

export const TABS_CONFIG = {
  initialPosition: 0,
};

export const getTabConfig = ({
  onTabSubmit,
  userType,
  onTabChange,
}: IGetTabConfig) => {
  const tabConfig: any[] = [];
  tabConfig.push({
    position: 0,
    label: <FormattedMessage {...messages.Personal_Information} />,
    key: 'personal-info',
    element: <PersonalInfo key={0} onTabChange={onTabChange} />,
  });

  // Below tabs are under development
  tabConfig.push({
    position: 1,
    label: <FormattedMessage {...messages.Financial_Information} />,
    key: 'financial-info',
    element: <FinancialInfo key={1} onTabChange={onTabChange} />,
  });

  if (userType === 'Trader_Corporate' || userType === 'IB_Corporate') {
    tabConfig.push({
      position: 2,
      label: <FormattedMessage {...messages.corporateInformation} />,
      key: 'corporate-info',
      element: (
        <CorporateInfo key={tabConfig.length} onTabChange={onTabChange} />
      ),
    });
  }

  if (userType === 'Trader_Joint' || userType === 'IB_Joint') {
    tabConfig.push({
      position: 2,
      label: <FormattedMessage {...messages.jointAccountInfo} />,
      key: 'joint-account',
      element: (
        <JointAccount key={tabConfig.length} onTabChange={onTabChange} />
      ),
    });
  }

  return tabConfig;
};
