export const API_URLS = {
  // GET_USER_PROFILE: (id: string | number) => `/user-profile/${id}`,
  GET_USER_PROFILE: 'user/',

  UPDATE_USER_PROFILE: (id: string | number) => `user/${id}`,

  GET_FINANCIAL_DETAILS: `user-financial-employement-details/`,

  UPDATE_FINANCIAL_DETAILS: (id: string | number) =>
    `user-financial-employement-details/${id}`,

  UPDATE_JOINT_ACCOUNT_DETAILS: (id: string | number) => `user-details/${id}`,

  UPDATE_CORPORATE_DETAILS: 'user-company-details/',

  GET_CORPORATE_DETAILS: 'user-company-details/',

  GET_JOINT_ACCOUNT_DETAILS: 'user-details/',

  GET_USER_TYPE: 'client/user-types?type=trader',
  // GX_INJECT_API_URL
};
