import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useIsMobile } from '~/hooks/useIsMobile';
import { blue } from '@mui/material/colors';

const PreviewTemplate = ({ setShowPreview, values }: any) => {
  const isMobile = useIsMobile();
  return (
    <Dialog
      open={true}
      onClose={() => {
        setShowPreview(false);
      }}
      PaperProps={{
        component: 'form',
        sx: {
          width: isMobile ? '100vw' : '600px',
        },
      }}
    >
      <DialogTitle sx={{ bgcolor: blue[50] }}>
        {values.template_name}
      </DialogTitle>
      <DialogContentText>{values.subject}</DialogContentText>

      <DialogContent>
        <DialogContentText dangerouslySetInnerHTML={{ __html: values.body }} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowPreview(false);
          }}
          color='primary'
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PreviewTemplate;
