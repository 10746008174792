import { useMediaQuery } from '@mui/material';
import Box from 'v2s-ui-core/app/components/Box';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import SearchFilter from '../SearchFilter';
import { getMaxDateAsCurrentDate } from '~/utils';
import CloseIcon from '@mui/icons-material/Close';
import { formatDate } from '~/utils/index';
import { IconButton } from '@mui/material';
import React from 'react';
const FILTERS = {
  START_DATE: 'start_date',
  END_DATE: 'end_date',
};

const TableFilters = ({
  applyFilters,
  filters,
  moreFiltersNode = <></>,
  includeDateFilters = true,
  includeSearchFilter = true,
  filterType,
}: {
  includeDateFilters?: boolean;
  includeSearchFilter?: boolean;
  applyFilters: (args: { type: string; values: any }) => void;
  filters: Record<string, any>;
  moreFiltersNode?: React.ReactNode;
  filterType?: string;
}) => {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [cleared, setCleared] = React.useState(false);

  const onClearValue =
    (
      setCleared: React.Dispatch<React.SetStateAction<boolean>>,
      applyFilters: (args: { type: string; values: any }) => void,
      filterType: string,
    ) =>
    () => {
      applyFilters({ type: filterType, values: null });
      setCleared(true);
    };

  return (
    <div>
      <Box
        display='flex'
        flexDirection={isMobile ? 'column' : 'row'}
        gap={isMobile ? '16px' : '60px'}
      >
        {includeSearchFilter && (
          <SearchFilter applyFilters={applyFilters} value={filters.SEARCH} />
        )}
        {includeDateFilters && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              display='flex'
              flexDirection={isMobile ? 'row' : 'row'}
              gap={isMobile ? '16px' : '32px'}
            >
              <DatePicker
                label='Start Date'
                value={
                  (filters.start_date &&
                    dayjs(filters.start_date, 'DD-MM-YYYY')) ||
                  null
                }
                maxDate={dayjs(getMaxDateAsCurrentDate(new Date()))}
                onChange={(newValue: any) => {
                  applyFilters({
                    type: FILTERS.START_DATE,
                    values: formatDate(newValue),
                  });
                }}
                format='DD-MM-YYYY'
                openTo='year'
                views={['year', 'month', 'day']}
                sx={{
                  width: isMobile ? '100%' : '210px',
                }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () =>
                      onClearValue(setCleared, applyFilters, filterType),
                  },
                  textField: {
                    size: 'small',
                    InputLabelProps: {
                      style: {
                        color: 'gray',
                      },
                    },
                  },
                }}
              />

              <DatePicker
                label='End Date'
                value={
                  filters.end_date
                    ? dayjs(filters.end_date, 'DD-MM-YYYY')
                    : null
                }
                onChange={(newValue: any) => {
                  if (newValue) {
                    applyFilters({
                      type: FILTERS.END_DATE,
                      values: formatDate(newValue),
                    });
                  }
                }}
                maxDate={dayjs(getMaxDateAsCurrentDate(new Date()))}
                openTo='year'
                format='DD-MM-YYYY'
                views={['year', 'month', 'day']}
                sx={{
                  width: isMobile ? '100%' : '210px',
                }}
                slotProps={{
                  field: {
                    clearable: true,
                    onClear: () =>
                      onClearValue(setCleared, applyFilters, filterType),
                  },
                  textField: {
                    size: 'small',
                    InputLabelProps: {
                      style: {
                        color: 'gray',
                      },
                    },
                  },
                }}
              />
            </Box>
          </LocalizationProvider>
        )}
        {moreFiltersNode}
      </Box>
    </div>
  );
};
export default TableFilters;
