import { useState, useMemo } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { EXPIRY_STATUS } from '~/modules/Kyc/constants';
import { API_URLS } from '../../../../api/config';
import SuccessModal from '../../../../components/SuccessModal';
import { useIsMobile } from '~/hooks/useIsMobile';
import { DOCUMENT_TYPES } from '~/constants';
import { useLocation } from 'react-router-dom';
import { QUERY_KEYS } from '~/services/queries/constants';
import { FormattedMessage } from '~app/Integrations/Localization';
import { useSetDocumentExpiry } from '~/hooks/useDocumentExpiry';
import messages from './messages';
const DocumentExpiry = ({ document }: { document: any }) => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const {
    confirmExpiryUpdate,
    closeSuccessModal,
    successMessage,
    showSuccessModal,
  } = useSetDocumentExpiry({
    QUERY_KEYS: `${QUERY_KEYS.FETCH_KYC}-${location.state}`,
    API_URLS: API_URLS.SET_EXPIRY_DATE,
    STATUS: EXPIRY_STATUS,
    messages: messages,
    documentType: document?.document_type,
  });

  const expireAt = useMemo(
    () => ({
      POIExpiryDate: null as string | null,
      POAExpiryDate: null as string | null,
    }),
    [],
  );

  const [expiryDates, setExpiryDates] = useState(expireAt);

  const poiId = useMemo(
    () =>
      document?.document_type === DOCUMENT_TYPES.POI
        ? document?.document_type_mapping
        : null,
    [document],
  );

  const poaId = useMemo(
    () =>
      document?.document_type === DOCUMENT_TYPES.POA
        ? document?.document_type_mapping
        : null,
    [document],
  );

  const formatDate = (date: any) =>
    date ? dayjs(date).format('YYYY-MM-DD') : '';

  const handleExpiry = (type: 'POI' | 'POA') => {
    const id = type === DOCUMENT_TYPES.POI ? poiId : poaId;
    const expire_at =
      type === DOCUMENT_TYPES.POI
        ? expiryDates.POIExpiryDate
        : expiryDates.POAExpiryDate;

    confirmExpiryUpdate({
      id: document?.user,
      expire_at: expire_at || document?.expire_at,
      document_type_mapping: id,
    });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box display={'flex'}>
          {document?.document_side === 'Front' && (
            <>
              {document?.document_type === DOCUMENT_TYPES.POI && (
                <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                  <DatePicker
                    label='POI Expiry'
                    value={
                      document?.expire_at ? dayjs(document?.expire_at) : null
                    }
                    minDate={dayjs(new Date())}
                    onChange={(newDate) =>
                      setExpiryDates((prev) => ({
                        ...prev,
                        POIExpiryDate: formatDate(newDate),
                      }))
                    }
                    disabled={document?.expire_at === null}
                    format='YYYY-MM-DD'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    sx={{
                      width: isMobile ? '200px' : '235px',
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        InputLabelProps: {
                          style: {
                            color: 'gray',
                          },
                        },
                      },
                    }}
                  />
                  {document?.expire_at !== null && (
                    <Button
                      variant='contained'
                      sx={{ height: '40px', width: '113px' }}
                      onClick={() => handleExpiry(DOCUMENT_TYPES.POI)}
                    >
                      Update
                    </Button>
                  )}
                </Box>
              )}

              {document?.document_type === DOCUMENT_TYPES.POA && (
                <Box display={'flex'} gap={'10px'} alignItems={'center'}>
                  <DatePicker
                    label='POA Expiry'
                    value={
                      document?.expire_at ? dayjs(document?.expire_at) : null
                    }
                    minDate={dayjs(new Date())}
                    onChange={(newDate) =>
                      setExpiryDates((prev) => ({
                        ...prev,
                        POAExpiryDate: formatDate(newDate),
                      }))
                    }
                    format='YYYY-MM-DD'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    disabled={document?.expire_at === null}
                    sx={{
                      width: isMobile ? `200px` : '235px',
                    }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        InputLabelProps: {
                          style: {
                            color: 'gray',
                          },
                        },
                      },
                    }}
                  />

                  {document?.expire_at !== null && (
                    <Button
                      variant='contained'
                      sx={{ height: '40px', width: '113px' }}
                      onClick={() => handleExpiry(DOCUMENT_TYPES.POA)}
                    >
                      Update
                    </Button>
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
      </LocalizationProvider>
      <SuccessModal
        onClose={closeSuccessModal}
        open={showSuccessModal}
        message={successMessage}
      />
    </>
  );
};

export default DocumentExpiry;
