import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../services/queries/useQueryFactory';

const useGetIBAgreement = <TData, TError = Error>(userId: number) => {
  const queryDetails = useQueryFactory<TData, TError>();
  const { data, isLoading } = useQuery({
    ...queryDetails.getIbAgreement(userId),
    enabled: true,
  });

  return { data, isAgreementDataPending: isLoading };
};

export default useGetIBAgreement;
