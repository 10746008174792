import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { useState } from 'react';
import { IApproveRejectKycProps } from '~/utils/types';
import { DOCUMENT_STATUS } from '~/constants';
import { getKycApproveRejectSchema } from '~/constants/kycValidationSchema';

export const useApproveRejectKyc = ({
  onConfirmApproveReject,
  recordForApproveReject,
}: {
  onConfirmApproveReject: IApproveRejectKycProps['onConfirmApproveReject'];
  recordForApproveReject: IApproveRejectKycProps['recordForApproveReject'];
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const [status, setStatus] = useState('');

  const formik = useFormik({
    initialValues: {
      remark: '',

      ...(recordForApproveReject?.record.document_type === 'POI' && {
        date_of_birth:
          recordForApproveReject?.record?.user_detail?.date_of_birth,
        document_no: '',
      }),
      first_name: recordForApproveReject?.record?.user_detail?.first_name,
      last_name: recordForApproveReject?.record?.user_detail?.last_name,
      expire_at: recordForApproveReject?.record?.expire_at,
      country: recordForApproveReject?.record?.user_detail?.country,

      ...(recordForApproveReject?.record.document_type === 'POA' && {
        address_line1:
          recordForApproveReject?.record?.user_detail.address_line1,
        address_line2:
          recordForApproveReject?.record?.user_detail.address_line2,
        city_town: recordForApproveReject?.record?.user_detail.city_town,
        state_province:
          recordForApproveReject?.record?.user_detail.state_province,
        zip_postal_code:
          recordForApproveReject?.record?.user_detail.zip_postal_code,
      }),
    },
    onSubmit: (values, { resetForm }) => {
      const POIPayload = {
        date_of_birth: values.date_of_birth,
        document_no: values.document_no,
        expire_at: values.expire_at,
      };
      const POAPayload = {
        address_line1: values.address_line1,
        address_line2: values.address_line2,
        city_town: values.city_town,
        state_province: values.state_province,
        zip_postal_code: values.zip_postal_code,
        expire_at: values.expire_at,
      };

      const basePayload = {
        remark: values.remark,
        id: recordForApproveReject?.record.id,
        status,
        first_name: values.first_name,
        last_name: values.last_name,
        country: values.country.id,
      };

      const payload =
        recordForApproveReject?.record.document_type === 'POI'
          ? { ...basePayload, ...POIPayload }
          : recordForApproveReject?.record.document_type === 'Others'
          ? { ...basePayload }
          : { ...basePayload, ...POAPayload };

      onConfirmApproveReject(payload);
      resetForm();
    },
    validationSchema: getKycApproveRejectSchema(
      formatMessage,
      recordForApproveReject?.record.document_type,
      recordForApproveReject?.record.document_side,
    ),
    enableReinitialize: true,
    validateOnMount: false,
  });

  return { formik, setStatus };
};
