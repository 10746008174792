/* eslint-disable complexity */
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
import messages from '../../messages';
import { FormattedMessage } from '~app/Integrations/Localization';
import { useAddBankAccount } from '../../hook/useAddBankAccount';
import { filterBankFile, updateFieldValueToUpperCase } from '~/utils';
import BankFileUpload from '~/hooks/BankDocUpload';
import { useLocation } from 'react-router-dom';
import { useUserAuth } from '~/shell/components/Auth/AuthProvider';
import InfoIcon from '@mui/icons-material/Info';
import { theme } from '~/shell/theme/theme';
import SuccessModal from '~/components/SuccessModal';

const AddBankAccount = ({
  showAddBankAccountModal,
  setShowAddBankAccountModal,
}: {
  showAddBankAccountModal: boolean;
  setShowAddBankAccountModal: (showAddBankAccountModal: boolean) => void;
}) => {
  const isMobile = useIsMobile();
  const {
    formik: {
      values,
      touched,
      setFieldTouched,
      handleChange,
      errors,
      setFieldValue,
      handleSubmit,
      resetForm,
    },
    showSuccessModal,
    onSuccessClose,
  } = useAddBankAccount({
    setShowAddBankAccountModal,
    showAddBankAccountModal,
  });
  const { DocumentTypeList } = useUserAuth();
  const filteredFileTypeData = filterBankFile(DocumentTypeList);

  const location = useLocation();
  const id = location.state;
  const handleModalclose = () => {
    resetForm();
    setShowAddBankAccountModal(false);
  };

  return (
    <>
      <Dialog
        open={showAddBankAccountModal}
        onClose={() => handleModalclose()}
        PaperProps={{
          component: 'form',
          sx: {
            minWidth: isMobile ? '100vw' : '800px',
            margin: isMobile ? 1 : 0,
          },
        }}
      >
        <DialogTitle
          sx={{
            width: '100%',
            height: '48px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: blue[50],
          }}
        >
          <FormattedMessage {...messages.addBankAccount} />
          <CloseButton onClose={() => handleModalclose()} />
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ mt: 2, mb: 1, pl: 2 }}>
            <FormattedMessage {...messages.addbankAccountSubtitle} />
          </Typography>

          <Grid container spacing={2} px={isMobile ? 0 : 2}>
            <Grid item xs={12} md={6}>
              <TextField
                size='small'
                name='bank_name'
                fullWidth
                label={<FormattedMessage {...messages.bank_name} />}
                value={values.bank_name || ''}
                onChange={(e) => {
                  setFieldTouched('bank_name', true);
                  handleChange(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_name', true);
                }}
                error={touched.bank_name && Boolean(errors.bank_name)}
                helperText={touched.bank_name && errors.bank_name?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_account_name} />}
                name='bank_account_name'
                value={values.bank_account_name || ''}
                onChange={(e) => {
                  setFieldTouched('bank_account_name', true);
                  handleChange(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_account_name', true);
                }}
                error={
                  touched.bank_account_name && Boolean(errors.bank_account_name)
                }
                helperText={
                  touched.bank_account_name &&
                  errors.bank_account_name?.toString()
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_account_number} />}
                name='bank_account_number'
                type='number'
                value={values.bank_account_number || ''}
                onChange={(e) => {
                  setFieldTouched('bank_account_number', true);
                  handleChange(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_account_number', true);
                }}
                error={
                  touched.bank_account_number &&
                  Boolean(errors.bank_account_number)
                }
                helperText={
                  touched.bank_account_number &&
                  errors.bank_account_number?.toString()
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_iban} />}
                name='bank_iban'
                value={values.bank_iban || ''}
                onChange={(e) => {
                  setFieldTouched('bank_iban', true);
                  updateFieldValueToUpperCase(setFieldValue)(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_iban', true);
                }}
                error={touched.bank_iban && Boolean(errors.bank_iban)}
                helperText={touched.bank_iban && errors.bank_iban?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_ifsc} />}
                name='bank_code'
                onChange={(e) => {
                  setFieldTouched('bank_code', true);
                  updateFieldValueToUpperCase(setFieldValue)(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_code', true);
                }}
                value={values.bank_code || ''}
                error={touched.bank_code && Boolean(errors.bank_code)}
                helperText={touched.bank_code && errors.bank_code?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                size='small'
                autoComplete='off'
                label={<FormattedMessage {...messages.bank_address} />}
                name='bank_address'
                multiline
                value={values.bank_address || ''}
                onChange={(e) => {
                  setFieldTouched('bank_address', true);
                  handleChange(e);
                }}
                onBlur={() => {
                  setFieldTouched('bank_address', true);
                }}
                error={touched.bank_address && Boolean(errors.bank_address)}
                helperText={
                  touched.bank_address && errors.bank_address?.toString()
                }
              />
            </Grid>
            <Grid item>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                gap={1}
                alignItems={'center'}
              >
                <InfoIcon
                  sx={{
                    height: '20px',
                    width: '20px',
                    color: theme.palette.secondary.main,
                  }}
                />
                <FormattedMessage {...messages.addbankInfo} />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <BankFileUpload
                onUploadSuccess={(documentId: string) => {
                  if (documentId) {
                    setFieldValue('user_document', documentId);
                  }
                }}
                // fileName={filteredFileTypeData?.document_name}
                documentMappingId={filteredFileTypeData?.id}
                userId={id}
                isBankUpload={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pb: 2 }}>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            disabled={!values.user_document}
            variant='contained'
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessModal
        open={showSuccessModal}
        onClose={() => {
          onSuccessClose();
        }}
        message='Bank account added successfully'
      />
    </>
  );
};

export default AddBankAccount;
