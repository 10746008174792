export const API_URLS = {
  FETCH_BANK_ACCOUNT_INFO: (id: string | number) =>
    `/crm/user/${id}/bank_account_infos`,

  APPROVE_REJECT_BANK_ACCOUNT: (id: string | number) =>
    `/crm/bank_account_infos/${id}`,

  EDIT_BANK_ACCOUNT: (id: string | number) => `/crm/bank_account_infos/${id}`,

  ADD_BANK_ACCOUNT: 'crm/bank_account_infos',
  FETCH_USER_DATA: 'user/',

  // GX_INJECT_API_URL
};
