import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Button from 'v2s-ui-core/app/components/Button';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
import { INTERNAL_TRANSFERS_STATUS } from '../../constants';
import { useApproveRejectInternalTransfers } from '../../hooks/useApproveRejectInternalTransfers';
import messages from '../../messages';
import { ApproveRejectInternalTransferProps } from '../../types';
const ApproveRejectInternalTransfer = ({
  onCancel,
  recordForApproveReject,
  onConfirmApproveReject,
}: //props
ApproveRejectInternalTransferProps) => {
  const { handleChange, handleSubmit, errors, touched, setFieldTouched } =
    useApproveRejectInternalTransfers({
      onConfirmApproveReject,
      recordForApproveReject,
    });
  const isMobile = useIsMobile();

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          // minWidth: '636px',
          width: isMobile ? '100vw' : '636px',
          margin: isMobile ? 1 : 0,
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        {recordForApproveReject?.action ===
        INTERNAL_TRANSFERS_STATUS.APPROVED ? (
          <FormattedMessage {...messages.approveModalTitle} />
        ) : (
          <FormattedMessage {...messages.rejectModalTitle} />
        )}
        <CloseButton onClose={onCancel} />
      </DialogTitle>
      <DialogContent sx={{ padding: 1 }}>
        <DialogContentText ml={2} mt={2}>
          {recordForApproveReject?.action ===
          INTERNAL_TRANSFERS_STATUS.APPROVED ? (
            <FormattedMessage {...messages.approveModalHelpText} />
          ) : (
            <FormattedMessage {...messages.rejectModalHelpText} />
          )}
        </DialogContentText>
        {/* Form Fields */}
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <TextField
              name={'remark'}
              variant='outlined'
              onChange={(e) => {
                setFieldTouched('remark', true);
                handleChange(e);
              }}
              onBlur={() => setFieldTouched('remark', true)}
              // label={<FormattedMessage {...messages.addRemark} />}
              size='small'
              required
              type='text'
              error={touched?.remark && Boolean(errors?.remark)}
              helperText={touched?.remark && errors?.remark}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ padding: 3 }}>
        <Button variant='contained' onClick={() => handleSubmit()}>
          {recordForApproveReject?.action ===
          INTERNAL_TRANSFERS_STATUS.APPROVED ? (
            <FormattedMessage {...messages.approve} />
          ) : (
            <FormattedMessage {...messages.reject} />
          )}
        </Button>
        <Button onClick={onCancel} variant='outlined'>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ApproveRejectInternalTransfer;
