import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  kycModalTitle: {
    id: 'kyc.kyc_Status',
    defaultMessage: 'Approve/Reject Kyc ',
  },
  noDocumentsFound: {
    id: 'kyc.noDocumentsFound',
    defaultMessage: 'No documents found',
  },
  approved: {
    id: 'kyc.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'kyc.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'kyc.pending',
    defaultMessage: 'Pending',
  },
  approveSuccessMessage: {
    id: 'kyc.approveSuccessMessage',
    defaultMessage: 'Kyc approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'kyc.rejectSuccessMessage',
    defaultMessage: 'Kyc rejected successfully!',
  },
  approveFailedMessage: {
    id: 'kyc.approveFailedMessage',
    defaultMessage: 'Kyc approval failed!',
  },
  rejectFailedMessage: {
    id: 'kyc.rejectFailedMessage',
    defaultMessage: 'Kyc rejection failed!',
  },
});
