import { FormattedMessage } from '~app/Integrations/Localization';
import { myKycMessages } from '../../myKycMessages';
import Grid from '~app/components/Grid';
import TextField from '~app/components/Input/TextField';

export const ViewPOAFields = ({ user_details }: any) => (
  <>
    <Grid item xs={12} sm={12}>
      <TextField
        variant='outlined'
        label={<FormattedMessage {...myKycMessages.addressLine1} />}
        value={user_details?.address_line1 || ''}
        fullWidth
        size='small'
        disabled
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <TextField
        variant='outlined'
        label={<FormattedMessage {...myKycMessages.addressLine2} />}
        value={user_details?.address_line2 || ''}
        fullWidth
        size='small'
        disabled
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <TextField
        variant='outlined'
        label={<FormattedMessage {...myKycMessages.cityOrTown} />}
        value={user_details?.city_town || ''}
        fullWidth
        size='small'
        disabled
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <TextField
        variant='outlined'
        label={<FormattedMessage {...myKycMessages.stateProvince} />}
        value={user_details?.state_province || ''}
        fullWidth
        size='small'
        disabled
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <TextField
        variant='outlined'
        label={<FormattedMessage {...myKycMessages.zipPostalCode} />}
        value={user_details?.zip_postal_code || ''}
        fullWidth
        size='small'
        disabled
      />
    </Grid>

    <Grid item xs={12} sm={6}>
      <TextField
        variant='outlined'
        label={<FormattedMessage {...myKycMessages.country} />}
        value={user_details?.country || ''}
        fullWidth
        size='small'
        disabled
      />
    </Grid>
  </>
);
