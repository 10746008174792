import * as yup from 'yup';
import messages from '../messages';
import { getDropdownRequiredSchema } from '~/schema/ProfileSchema';

export const FINANCIAL_INFO_KEYS = {
  EDUCATION_LEVEL: 'education_level',
  TRADING_PURPOSE: 'trading_purpose',
  EMPLOYMENT_STATUS: 'employment_status',
  ESTIMATED_NET_WORTH: 'estimated_net_worth',
  ESTIMATED_ANNUAL_INCOME: 'estimated_annual_income',
  ESTIMATED_INVESTMENT_AMOUNT: 'estimated_investment_amount',
  SOURCE_OF_FUNDS: 'source_of_funds',
  EXPECTED_ORIGIN_OF_FUNDS: 'expected_origin_of_funds',
};

export type IFinancialInfoPayload = Record<
  (typeof FINANCIAL_INFO_KEYS)[keyof typeof FINANCIAL_INFO_KEYS],
  string | boolean | number | object
>;

export const getFinancialInfoSchema = (formatMessage: (msg: any) => string) =>
  yup.object().shape({
    education_level: getDropdownRequiredSchema(
      formatMessage,
      messages.educationLevelRequired,
    ),
    trading_purpose: getDropdownRequiredSchema(
      formatMessage,
      messages.tradingPurposeRequired,
    ),
    employment_status: getDropdownRequiredSchema(
      formatMessage,
      messages.employmentStatusRequired,
    ),
    estimated_net_worth: getDropdownRequiredSchema(
      formatMessage,
      messages.estimatedNetWorthRequired,
    ),
    estimated_annual_income: getDropdownRequiredSchema(
      formatMessage,
      messages.estimatedAnnualIncomeRequired,
    ),
    estimated_investment_amount: getDropdownRequiredSchema(
      formatMessage,
      messages.estimatedInvestmentAmountRequired,
    ),
    source_of_funds: getDropdownRequiredSchema(
      formatMessage,
      messages.sourceOfFundsRequired,
    ),
    expected_origin_of_funds: getDropdownRequiredSchema(
      formatMessage,
      messages.expectedOriginOfFundsRequired,
    ),
  });

export const FINANCIAL_INFO_VALUES: IFinancialInfoPayload = {
  education_level: '',
  trading_purpose: '',
  employment_status: '',
  estimated_net_worth: '',
  estimated_annual_income: '',
  estimated_investment_amount: '',
  source_of_funds: '',
  expected_origin_of_funds: '',
};
