import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { WithdrawalTableColumns } from '~/modules/Withdrawal/components/WithdrawalTable/WithdrawalTableColumns';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import ApproveRejectWithdrawal from '~/modules/Withdrawal/components/WithdrawalTable/ApproveRejectWithdrawal';
import { WITHDRAWALS_STATUS } from '~/modules/Withdrawal/constants';
import messages from '~/modules/Withdrawal/messages';
import { useLocation } from 'react-router-dom';
import SuccessModal from '~/components/SuccessModal';
import { TRANSACTION_TYPE } from '../constants';
import TableFilters from '~/components/TableFilters/index';
import useFilters from '~app/hooks/useFilters';
import { ACTIONS_COLUMNS_TO_PIN, FILTERS } from '~/config/constants';
import Box from '~app/components/Box';
import { API_URLS, QUERY_KEYS } from './api/config';
import ViewWithdrawal from '~/modules/Withdrawal/components/WithdrawalTable/ApproveRejectWithdrawal/ViewWithdrawal';
const WithdrawalTable: FC<{}> = () => {
  const location = useLocation();
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    status,
    successMessage,
    showSuccessModal,
    viewRecord,
    setViewRecord,
    onViewRecord,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_WITHDRAWALS,
    API_URLS: () =>
      API_URLS.APPROVE_REJECT_WITHDRAWAL(recordForApproveReject?.record?.id),
    STATUS: WITHDRAWALS_STATUS,
    user: true,
    messages: messages,
  });

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchUserWithdrawals',
  });

  const memoizedColumns = useMemo(
    () =>
      WithdrawalTableColumns({
        onViewRecord,
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' my={'20px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_WITHDRAWALS(location.state),
            queryKeyProp: [QUERY_KEYS.FETCH_WITHDRAWALS, location.state],
            select: (data: any) => data,
          }}
          filters={{
            transaction_type: TRANSACTION_TYPE.WITHDRAWAL,
            ...filters,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
          columnsToPin={ACTIONS_COLUMNS_TO_PIN}
        />
      </Box>
      {recordForApproveReject && (
        <ApproveRejectWithdrawal
          status={status}
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}
      {viewRecord && (
        <ViewWithdrawal
          onCancel={() => {
            setViewRecord(null);
          }}
          recordToView={viewRecord}
        />
      )}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default WithdrawalTable;
