import { useState } from 'react';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import { patch } from 'v2s-ui-core/app/Integrations/ApiService';

// Interfaces
interface ApproveRejectActionProps {
  QUERY_KEYS: string;
  API_URLS: (user?: string | number, id?: string | number) => string;
  STATUS: Record<string, string>;
  user?: boolean;
  messages: any;
}

interface Payload {
  [key: string]: any;
}

// Custom Hook
// eslint-disable-next-line max-statements
export const useApproveRejectActions = ({
  QUERY_KEYS,
  API_URLS,
  STATUS,
  user,
  messages,
}: ApproveRejectActionProps) => {
  // States
  const [recordForApproveReject, setRecordForApproveReject] = useState(null);
  const [viewRecord, setViewRecord] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  // Hooks
  const { showToastNotification } = useShowToastNotifications();
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const queryClient = useQueryClient();

  // Functions
  const approveReject = async <T>(payload: Payload) => {
    const url = API_URLS(payload.id);
    try {
      const response = await patch<T>(url, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationOptions = {
    mutationFn: approveReject,
  };

  const { mutate, status, data } = useMutation(mutationOptions);

  const onApproveRecord = (record: object) => {
    setRecordForApproveReject({ record, action: STATUS.APPROVED });
  };

  const onRejectRecord = (record: object) => {
    setRecordForApproveReject({ record, action: STATUS.REJECTED });
  };

  const onViewRecord = (record: any) => {
    setViewRecord(record);
  };

  const onCancel = () => {
    setRecordForApproveReject(null);
  };

  const onSuccess = () => {
    const message =
      recordForApproveReject?.action === STATUS.APPROVED
        ? messages.approveSuccessMessage
        : messages.rejectSuccessMessage;

    setSuccessMessage(formatMessage(message));
    setShowSuccessModal(true);
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS] });
    onCancel();
  };

  const onError = (error: any) => {
    const message =
      recordForApproveReject.action === STATUS.APPROVED
        ? messages.approveFailedMessage
        : messages.rejectFailedMessage;
    showToastNotification(
      `${formatMessage(message)}:  ${error?.data.errors[0].detail}`,
      'error',
    );
  };

  const onConfirmApproveReject = (payload: Payload) => {
    mutate(payload, { onSuccess, onError });
  };

  const onSuccessClose = () => {
    setShowSuccessModal(false);
    setSuccessMessage('');
  };

  // Returns
  return {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    showSuccessModal,
    status,
    onViewRecord,
    setViewRecord,
    viewRecord,
  };
};
