import { useFormik } from 'formik';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { getApproveRejectSchema } from './../services/mutations/constants';
import { ApproveRejectWithdrawalProps } from './../types';
import { useFetchData } from '~/hooks/fetchData/useFetchData';
import { API_URLS, QUERY_KEYS } from '../api/config';

export const useApproveRejectWithdrawalAccount = ({
  onConfirmApproveReject,
  recordForApproveReject,
}: {
  onConfirmApproveReject: ApproveRejectWithdrawalProps['onConfirmApproveReject'];
  recordForApproveReject: ApproveRejectWithdrawalProps[`recordForApproveReject`];
}) => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const formik = useFormik({
    initialValues: {
      remark: '',
      trading_amount: recordForApproveReject?.record?.trading_amount,
      local_amount: recordForApproveReject?.record?.local_amount,
    },

    onSubmit: (values, { resetForm }) => {
      onConfirmApproveReject({
        remark: values.remark,
        ...(recordForApproveReject?.action === 'APPROVED' && {
          trading_amount: values.trading_amount,
          local_amount: values.local_amount,
          trading_currency: recordForApproveReject?.record?.trading_currency,
        }),
        status:
          recordForApproveReject.action === 'APPROVED'
            ? 'APPROVED'
            : 'REJECTED',
        id: recordForApproveReject?.record?.id,
      });
    },

    validationSchema: getApproveRejectSchema(
      formatMessage,
      recordForApproveReject.action,
    ),
    enableReinitialize: true,
  });
  return { formik };
};
