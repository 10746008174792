import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { type AxiosError } from 'axios';

import baseApiInstance from 'v2s-ui-core/app/Integrations/ApiService/baseApiInstance';
// import { isEmpty } from 'v2s-ui-core/app/utils';
import Backdrop from 'v2s-ui-core/app/components/Backdrop';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
// import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';

import APPLICATION_URLS from '../../Routing/config/appsConfig';
import { useSessionStorage } from '~/shell/context/sessionStorage/context';
import { useUserAuth } from '../Auth/AuthProvider';

export const AUTH_TOKENS_KEY_MAP = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
} as const;

const UnauthorizedInterceptor = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // eslint-disable-next-line
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const { showToastNotification } = useShowToastNotifications();
  const { dispatch: setSessionStorage } = useSessionStorage();
  const { setAuth } = useUserAuth();
  const navigate = useNavigate();

  const onStorageChange = useCallback((event: StorageEvent) => {
    if (
      event.key === AUTH_TOKENS_KEY_MAP.ACCESS_TOKEN &&
      event.newValue === null
    ) {
      setIsSessionExpired(true);
      showToastNotification(
        'Session Expired, Please login to continue!',
        'error',
      );
      setTimeout(() => {
        setIsSessionExpired(false);
        window.location.replace(APPLICATION_URLS.LANDING_PAGE);
      }, 4000);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleUnauthorizedError = (error: AxiosError<unknown, unknown>) => {
      if (error && error.response && error.response.status === 401) {
        // clear session storage
        setSessionStorage({
          payload: AUTH_TOKENS_KEY_MAP.ACCESS_TOKEN,
          type: 'REMOVE_SESSION_VALUE',
        });
        setSessionStorage({
          payload: AUTH_TOKENS_KEY_MAP.REFRESH_TOKEN,
          type: 'REMOVE_SESSION_VALUE',
        });
        setAuth(null);
        navigate(APPLICATION_URLS.LOGIN);
        localStorage.clear();
        sessionStorage.clear();
      }
      return error;
    };

    baseApiInstance.interceptors.response.use(
      (response) => response,
      handleUnauthorizedError,
    );

    window.addEventListener('storage', onStorageChange);

    return () => {
      window.removeEventListener('storage', onStorageChange);
    };
  }, [navigate, onStorageChange, setSessionStorage]);

  return (
    <>
      {children}
      <Backdrop open={isSessionExpired} sx={{ zIndex: 2000 }} />
    </>
  );
};

export default UnauthorizedInterceptor;
