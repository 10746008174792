import { useFormik } from 'formik';
import { useLocaleContext } from '~app/Integrations/Localization';
import { usePostRecord } from '~/hooks/usePostRecord';
import { validationSchema } from '../constants';
import { API_URLS, QUERY_KEYS } from '../../../api/config';

export const useAddTemplateForm = () => {
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();

  const { onConfirmAddRecord, showSuccessModal, onSuccessClose } =
    usePostRecord({
      API_URL: API_URLS.ADD_NEW_TEMPLATE,
      QUERY_KEYS: QUERY_KEYS.FETCH_EMAIL_TEMPLATES,
    });

  const formik = useFormik({
    initialValues: {
      template_name: '',
      subject: '',
      event: null,
      body: '',
      is_html_template: null,
    },
    onSubmit: (values, { resetForm }) => {
      const processedBody =
        values.is_html_template === false
          ? values.body.replace(/<\/?[^>]+(>|$)/g, '')
          : values.body;

      onConfirmAddRecord({
        template_name: values.template_name,
        subject: values.subject,
        event: values.event.id,
        body: processedBody,
        is_html_template: values.is_html_template,
      });

      resetForm();
    },
    validationSchema: validationSchema(formatMessage),
    enableReinitialize: true,
    validateOnChange: true,
  });

  return { formik, showSuccessModal, onSuccessClose };
};
