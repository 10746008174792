import { useState } from 'react';
import { put } from 'v2s-ui-core/app/Integrations/ApiService';
import {
  useMutation,
  UseMutationOptions,
} from 'v2s-ui-core/app/Integrations/react-query';
import useToast, { TErrorResponse } from './useToast';
import { useLocaleContext } from '~app/Integrations/Localization';

const useDocumentUpload = <
  TData extends TUploadDocResponse,
  TError extends TErrorResponse,
>(args: {
  mutationOptions?: UseMutationOptions<TData, TError, TUploadDocMutationVars>;
  storeUploadDocKey: (uploadDocResponse: TUploadDocResponse) => void;
  userId: number; // Add userId as a parameter
}) => {
  const [uploadDocResponse, setUploadDocResponse] =
    useState<TUploadDocResponse | null>(null);

  const { triggerErrorToast, triggerSuccessToast } = useToast();

  const onSuccessHandler = (data: TUploadDocResponse) => {
    args.storeUploadDocKey(data);
    setUploadDocResponse(data);
    triggerSuccessToast(data.message);
  };
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const documentUploadMutation = useMutation({
    mutationFn: (payload) =>
      uploadDocumentService(`/user-documents/${args.userId}`, payload), // Use args.userId instead of userId
    onSuccess: onSuccessHandler,
    onError: (error) => triggerErrorToast(error),
    ...args.mutationOptions,
  });

  const uploadDoc = async (payload: TUploadDocMutationVars) => {
    documentUploadMutation.mutate(payload);
  };

  return {
    ...documentUploadMutation,
    uploadDocResponse,
    uploadDoc,
    formatMessage,
  };
};

const uploadDocumentService = async (
  url: string,
  payload: TUploadDocMutationVars,
) => {
  const __payload = {
    file_name: payload.fileName,
    content: payload.file,
    document_mapping_id: payload?.documentMappigId,
    document_name: payload.fileName,
  };

  const createRequestPayload = async () => {
    const fileReader = new FileReader();
    const content = await new Promise((resolve) => {
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(__payload?.content);
    });

    const fileName = `${__payload?.file_name}_${new Date()
      .toISOString()
      .replace(/:/g, '-')}.${__payload.content.type.split('/')[1]}`;

    return {
      document_mapping_id: __payload?.document_mapping_id,
      file_name: fileName,
      content,
    };
  };

  try {
    const requestPayload = await createRequestPayload();
    const response = await put<TUploadDocResponse>(url, requestPayload);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default useDocumentUpload;

export interface TUploadDocMutationVars {
  file: File;
  fileName: string;
  documentMappigId: number | string;
}

export interface TUploadDocResponse {
  data: Data;
  status_code: number;
  message: string;
}

export interface Data {
  id: number;
  user: number;
  document_type_mapping: number;
  document_name: string;
  document_type: string;
  document_side: string;
  guid: string;
  expire_at: null;
  latest_version: LatestVersion;
}

export interface LatestVersion {
  version: number;
  original_doc_location: string;
  extension: string;
  content_type: string;
  size: number;
  remark: null;
  status: Status;
  created_at: Date;
  filename: string;
}

export interface Status {
  id: number;
  status_name: string;
}
