import { FC, useEffect, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import TableFilters from '~/components/TableFilters';
import Box from '~app/components/Box';
import useFilters from 'v2s-ui-core/app/hooks/useFilters';
import { FILTERS } from '~/config/constants';
import { DepositReportTableColumns } from './depositReportTableColumn';
import { EXPORT_TYPE_OPTIONS, TRANSACTION_TYPE } from '../../constants';
import DownloadReport from '~/components/Popover';
import { useFetchReports } from '~/hooks/useReports';
import { API_URLS, QUERY_KEYS } from '../../api/config';
import { useReportsActions } from '~/hooks/useReportsActions';

const DepositReportTable: FC<{}> = () => {
  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchDeposits',
  });

  const { selectedFormat, handleFormatChange } = useReportsActions();

  const { data, refetch, isLoading } = useFetchReports({
    url: API_URLS.DOWNLOAD_DEPOSIT_REPORTS(selectedFormat?.value),
    queryKey: QUERY_KEYS.FETCH_DEPOSIT_REPORTS,
    enabled: false,
    filters: {
      ...filters,
    },
  });

  useEffect(() => {
    selectedFormat && refetch();
  }, [selectedFormat]);

  const memoizedColumns = useMemo(() => DepositReportTableColumns(), []);

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box
          display='flex'
          alignItems={'center'}
          mb={'16px'}
          justifyContent={'space-between'}
        >
          <TableFilters filters={filters} applyFilters={applyFilters} />
          <DownloadReport
            options={EXPORT_TYPE_OPTIONS}
            data={data}
            handleFormatChange={handleFormatChange}
            selectedFormat={selectedFormat}
            isLoading={isLoading}
          />
        </Box>
      </Box>

      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_DEPOSIT_REPORTS,
            queryKeyProp: [QUERY_KEYS.FETCH_DEPOSIT_REPORTS],
            select: (data: any) => data?.data,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
          filters={{
            report_type: TRANSACTION_TYPE.DEPOSIT,
            ...filters,
          }}
        />
      </Box>
    </>
  );
};

export default DepositReportTable;
