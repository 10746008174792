export const API_URLS = {
  FETCH_DEPOSITS: (id: string | number) => `crm/users/${id}/transactions`,
  APPROVE_REJECT_DEPOSIT: (id: string | number) =>
    `crm/user/transactions/${id}`,

  // GX_INJECT_API_URL
};

export const QUERY_KEYS = {
  FETCH_DEPOSITS: 'fetchDeposits',
  APPROVE_REJECT_DEPOSIT: 'approveRejectDeposit',
  // GX_INJECT_QUERY_KEY
};
