import { useMemo } from 'react';
import ApiServices from '../../api';
import { QUERY_KEYS } from './constants';
import { QueryDetails } from './types';

export const useQueryFactory = <TData, TError = Error>() => {
  const { GET_IB_AGREEMENT } = QUERY_KEYS;

  const queryDetails = useMemo<QueryDetails<TData, TError>>(
    () => ({
      [GET_IB_AGREEMENT]: (userId) => ({
        queryKey: [`${GET_IB_AGREEMENT}_${userId}`],
        queryFn: () => ApiServices.getIBAgreement<TData>(userId),
      }),
    }),

    [],
  );
  return queryDetails;
};
