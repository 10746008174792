import Appbar from './components/Appbar';
import CssBaseline from '@mui/material/CssBaseline';
import { useLayout } from './useLayout';
import Box from 'v2s-ui-core/app/components/Box';
import Sidebar from './components/Sidebar';
import { StyledProtectedLayout } from './styles';
import { DRAWER_WIDTH } from './components/Sidebar/constants';
import MobileView from './components/Sidebar/MobileView';
import useMediaQuery from '@mui/material/useMediaQuery';

type TLayoutProps = React.PropsWithChildren;

const ProtectedLayout = (props: TLayoutProps) => {
  const { isSidebarOpen, setIsSidebarOpen } = useLayout();
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <StyledProtectedLayout>
      <CssBaseline />

      <Appbar open={isSidebarOpen} onSidebarToggle={setIsSidebarOpen} />
      <Box className='content'>
        {isMobile ? (
          <MobileView
            open={isSidebarOpen}
            setIsSidebarOpen={setIsSidebarOpen}
          />
        ) : (
          <Sidebar open={isSidebarOpen} onSidebarToggle={setIsSidebarOpen} />
        )}
        <Box
          className='content-body'
          sx={{
            '@media (max-width: 600px)': {
              marginLeft: 0,
            },
            marginLeft: isSidebarOpen ? `${DRAWER_WIDTH}px` : 8,
          }}
        >
          {props.children}
        </Box>
      </Box>
    </StyledProtectedLayout>
  );
};

export default ProtectedLayout;
