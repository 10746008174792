export const EXPORT_TYPE_OPTIONS = [
  {
    label: ' CSV',
    value: 'csv',
  },
  {
    label: ' PDF',
    value: 'pdf',
  },
  {
    label: ' Excel',
    value: 'xlsx',
  },
];

export const TRANSACTION_TYPE = {
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
};
