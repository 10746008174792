import { Route, Routes } from 'react-router-dom';
import { SUB_ROUTES_FOR_REPORTS_MAP } from './routes';

const Reports = () => (
  <Routes>
    {SUB_ROUTES_FOR_REPORTS_MAP.map((route) => (
      <Route
        key={route.path}
        path={route.path}
        element={<route.element {...route.AppBaseLayoutProps} />}
      />
    ))}
  </Routes>
);

export default Reports;
