import { useState } from 'react';
import { Box, Grid, Button, TextField, Typography } from '@mui/material';
import { ShowStatus } from '~/components/Status/ShowStatus';
import viewDoc from '~/assets/images/viewDoc.svg';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from './messages';
import ViewKycDocModal from './ViewKycDoc';
import DocumentExpiry from './DocumentExpiry';
import FileUpload from '~/hooks/FileUpload';
import { useIsMobile } from '~/hooks/useIsMobile';
import { documentLabel, documentOrder } from '../../constants';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const KycForm = ({ data, onApproveRejectRecord, setIsUpdateSuccess }: any) => {
  const [document, setDocument] = useState<{
    open: boolean;
    documentDetail: any | null;
  }>({
    open: false,
    documentDetail: null,
  });
  const isMobile = useIsMobile();

  const sortedKycData = data.sort(
    (
      a: { document_type: string; document_side: string },
      b: { document_type: string; document_side: string },
    ) => {
      const orderA = documentOrder[`${a?.document_type} ${a?.document_side}`];
      const orderB = documentOrder[`${b?.document_type} ${b?.document_side}`];
      return orderA - orderB;
    },
  );

  return (
    <Box mt='32px'>
      <Grid
        rowSpacing={4}
        container
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {sortedKycData?.map((document: any) => (
          <Grid key={document?.id} item display='flex' alignItems='center'>
            <TextField
              label={documentLabel(
                document.document_type,
                document.document_side,
              )}
              variant='outlined'
              size='small'
              InputProps={{
                readOnly: true,
              }}
              value={
                document?.document_name + document?.latest_version?.filename
              }
              sx={{
                width: isMobile ? '120px' : '220px',
                input: { cursor: 'pointer' },
              }}
              onClick={() =>
                window.open(document?.latest_version?.original_doc_location)
              }
            />
            {document?.latest_version.status.status_name !== 'Pending' && (
              <Button
                variant='outlined'
                sx={{
                  textTransform: 'none',
                  color: '#768098',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  height: '40px',
                  mx: 1,
                }}
                onClick={() =>
                  setDocument((prev) => ({
                    ...prev,
                    open: true,
                    documentDetail: document,
                  }))
                }
              >
                <img src={viewDoc} alt='viewDoc' />
                {!isMobile && <FormattedMessage {...messages.view} />}
              </Button>
            )}

            {document?.latest_version.status.status_name === 'Pending' ? (
              <Button
                onClick={() => onApproveRejectRecord(document)}
                sx={{
                  height: '40px',
                  width: 'auto',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  ml: 1,
                }}
                variant='contained'
                color='primary'
                disabled={false}
              >
                <RemoveRedEyeIcon sx={{ height: '16px', width: '16px' }} />
                <FormattedMessage {...messages.actions} />

                {/* <PanToolAltIcon /> */}
              </Button>
            ) : (
              <ShowStatus
                status={document?.latest_version.status.status_name.toUpperCase()}
              />
            )}
            {(document?.latest_version.status.status_name === 'Rejected' ||
              document?.latest_version.status.status_name === 'Pending' ||
              document?.latest_version.file) && (
              <FileUpload
                documentMappingId={document?.document_type_mapping}
                userId={document?.id}
                setIsUpdateSuccess={setIsUpdateSuccess}
                isKycUpate
              />
            )}
          </Grid>
        ))}
      </Grid>

      <Box mt={4}>
        <Typography sx={{ fontSize: '20px', fontWeight: '500', my: 2 }}>
          <FormattedMessage {...messages.documentExpiry} />
        </Typography>
        {sortedKycData?.map((document: any) => (
          <DocumentExpiry key={document.id} document={document} />
        ))}
      </Box>

      <ViewKycDocModal setDocument={setDocument} document={document} />
    </Box>
  );
};

export default KycForm;
