import { useMemo } from 'react';
import ApiServices from '../../api';
// import { TJointAccountResponse } from '../../components/JointAccount/constants/types';
// import { TPersonalInfoResponse } from '../../components/PersonalDetails/constants/types';
import { QUERY_KEYS } from './constants';
import { QueryDetails, TFinancialInfoResponse } from './types';

const {
  GET_USER_PROFILE,
  GET_FINANCIAL_DETAILS,
  GET_JOINT_ACCOUNT_DETAILS,
  GET_CORPORATE_DETAILS,
} = QUERY_KEYS;

export type TPersonalInfoResponse = {
  data: {
    user_type_dd: {
      type: string;
      id: number;
    };
    preferred_language: string;
    first_name: string;
    last_name: string;
    email: string;
    country: string;
    phone_number: string;
    address_line1: string;
    address_line2: string;
    city_town: string;
    state_province: string;
    zip_postal_code: string;
    date_of_birth: string;
    gender: string;
    nationality: string;
  };
};

export type TJointAccountResponse = {
  data: {
    id: number;
    secondary_name: string;
    secondary_gender: string;
    secondary_address_line1: string;
    secondary_address_line2: string;
    secondary_city_town: string;
    secondary_state_province: string;
    secondary_zip_postal_code: string;
    secondary_date_of_birth: string;
  };
};

export const useQueryFactory = <TData, TError = Error>() => {
  const queryDetails = useMemo<QueryDetails<TData, TError>>(
    () => ({
      [GET_USER_PROFILE]: (userId) => ({
        queryKey: [GET_USER_PROFILE, userId],

        queryFn: () =>
          ApiServices.getUserProfile<TPersonalInfoResponse>(userId),
      }),
      [GET_FINANCIAL_DETAILS]: (id) => ({
        queryKey: [GET_FINANCIAL_DETAILS, id],
        queryFn: () =>
          ApiServices.getFinancialDetails<TFinancialInfoResponse>(id),
      }),
      [GET_JOINT_ACCOUNT_DETAILS]: (id) => ({
        queryKey: [GET_JOINT_ACCOUNT_DETAILS, id],
        queryFn: () =>
          ApiServices.getJointAccountDetails<TJointAccountResponse>(id),
      }),
      [GET_CORPORATE_DETAILS]: (id) => ({
        queryKey: [GET_CORPORATE_DETAILS, id],
        queryFn: () => ApiServices.getCorporateDetails<TData>(id),
      }),
    }),
    [],
  );

  return queryDetails;
};
