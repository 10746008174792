import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { useQueryFactory } from '../../services/queries/useQueryFactory';

const useGetFinancialDetail = <TData, TError = Error>(id: number) => {
  const queryDetails = useQueryFactory<TData, TError>();
  const { data, isLoading } = useQuery({
    ...queryDetails.getFinancialDetails(id),
  });

  return { financialInfo: data?.data, isLoading };
};

export default useGetFinancialDetail;
