import { useState } from 'react';
import { useShowToastNotifications } from 'v2s-ui-core/app/containers/ToastNotifications/Provider';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import { patch } from 'v2s-ui-core/app/Integrations/ApiService';
import { UpdateDocumentExpiryProps, Payload } from '~/config/types';

const DOC_TYPE = {
  POI: 'POI',
  POA: 'POA',
};

// Custom Hook
export const useSetDocumentExpiry = ({
  QUERY_KEYS,
  API_URLS,
  STATUS,
  documentType,
  messages,
}: UpdateDocumentExpiryProps) => {
  const [expiryRecord, setExpiryRecord] = useState(null);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const { showToastNotification } = useShowToastNotifications();
  const queryClient = useQueryClient();

  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const updateExpiry = async <T>(payload: Payload) => {
    const url = API_URLS(payload.id);
    try {
      const response = await patch<T>(url, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const mutationOptions = {
    mutationFn: updateExpiry,
  };

  const { mutate } = useMutation(mutationOptions);

  const onExpiryUpdateSuccess = () => {
    const message = `${formatMessage(
      documentType === DOC_TYPE.POI
        ? messages.poiExpiryDateUpdated
        : messages.poaExpiryDateUpdated,
    )}.`;
    setSuccessMessage(message);
    setShowSuccessModal(true);
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS] });
    setExpiryRecord(null);
  };

  const onExpiryUpdateError = (error: any) => {
    showToastNotification(
      `Failed to update expiry: ${error?.data?.errors?.[0]?.detail}`,
      'error',
    );
  };

  const confirmExpiryUpdate = (payload: Payload) => {
    mutate(payload, {
      onSuccess: onExpiryUpdateSuccess,
      onError: onExpiryUpdateError,
    });
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
    setSuccessMessage('');
  };

  return {
    expiryRecord,
    confirmExpiryUpdate,
    closeSuccessModal,
    successMessage,
    showSuccessModal,
  };
};
