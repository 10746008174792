import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  AddNewTemplate: {
    id: 'Emailing.AddTemplate',
    defaultMessage: 'Add New Template',
  },
  TemplateHeaderText: {
    id: 'Emailing.TemplateHeader',
    defaultMessage: 'Please enter the following data to add new template.',
  },
  templateName: {
    id: 'Emailing.templateName',
    defaultMessage: 'Template Name',
  },
  templateNameRequired: {
    id: 'Emailing.templateNameRequired',
    defaultMessage: 'Template Name is required',
  },
  templateNameValidation: {
    id: 'Emailing.templateNameValidation',
    defaultMessage: 'Template Name can only contain letters',
  },
  subject: {
    id: 'Emailing.subject',
    defaultMessage: 'Subject',
  },
  subjectRequired: {
    id: 'Emailing.subjectRequired',
    defaultMessage: 'Subject is required',
  },
  subjectValidation: {
    id: 'Emailing.subjectValidation',
    defaultMessage: 'Subject can only contain letters',
  },
  body: {
    id: 'Emailing.body',
    defaultMessage: 'Body',
  },
  bodyRequired: {
    id: 'Emailing.bodyRequired',
    defaultMessage: 'Body is required',
  },
  event: {
    id: 'Emailing.eventName',
    defaultMessage: 'Event',
  },
  eventRequired: {
    id: 'Emailing.eventRequired',
    defaultMessage: 'Event is required',
  },
  header: {
    id: 'Emailing.header',
    defaultMessage: 'Header',
  },
  headerRequired: {
    id: 'Emailing.headerRequired',
    defaultMessage: 'Header is required',
  },
  footer: {
    id: 'Emailing.footer',
    defaultMessage: 'Footer',
  },
  footerRequired: {
    id: 'Emailing.footerRequired',
    defaultMessage: 'Footer is required',
  },

  cancel: {
    id: 'Emailing.cancel',
    defaultMessage: 'Cancel',
  },
  yes: {
    id: 'Emailing.yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'Emailing.no',
    defaultMessage: 'No',
  },
  Preview: {
    id: 'Emailing.Preview',
    defaultMessage: 'Preview',
  },
  testMail: {
    id: 'Emailing.testMail',
    defaultMessage: 'Send test mail',
  },
  AddTemplate: {
    id: 'Emailing.AddTemplate',
    defaultMessage: 'Add Template',
  },
  isHtmlTemplate: {
    id: 'Emailing.isHtmlTemplate',
    defaultMessage: 'Is html template?',
  },
});
