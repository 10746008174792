import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import TruncatedHeader from '~/components/TruncatedHeader/index';

export const DepositReportTableColumns = (): ColumnDef<unknown>[] => [
  {
    accessorKey: 'id',
    header: 'Transaction ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user',
    header: 'User ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by.email',
    header: 'User Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'account_id',
    header: 'Account ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'account_type',
    header: 'Account Type',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'balance',
    header: 'Balance',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'local_amount',
    header: 'Local Amount',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'local_currency',
    header: 'Local Currency',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_amount',
    header: 'Trading Amount',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_currency',
    header: 'Trading Currency',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'payment_method',
    header: 'Payment Method',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'payment_reference',
    header: 'Payment Reference',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'additional_reference',
    header: () => (
      <TruncatedHeader text='Additional Reference' maxLength={14} useTooltip />
    ),

    enableColumnFilter: true,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'requested_date',
    header: 'Requested Date',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'ip_address',
    header: 'IP Address',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by.email',
    header: 'Created By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_by.email',
    header: 'Updated By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'deleted_by',
    header: 'Deleted By',
    enableColumnFilter: true,
  },
  {
    // cell: ({ row }) => formatDate(row?.original?.requested_date),
    header: 'Requested Date',
    enableColumnFilter: true,
    accessorKey: 'requested_date',
  },
];
