import { get, post, put } from 'v2s-ui-core/app/Integrations/ApiService';
import {
  TUpdateKycDocument,
  TUploadUserKycDocumentPayload,
} from '../services/mutation/types';
import { API_URLS } from './config';
const ApiServices = {
  getDocTypesList: async <T>() => {
    try {
      const response = await get<T>(API_URLS.GET_DOC_TYPES_LIST);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getUploadedDocList: async <T>(id: number) => {
    try {
      const response = await get<T>(
        `${API_URLS.GET_UPLOADED_DOC_LIST}/${id}/documents`,
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  uploadUserKycDocument: async <T>(
    payload: TUploadUserKycDocumentPayload,
    id: number,
  ) => {
    try {
      const response = await post<T>(API_URLS.UPLOAD_KYC_DOCUMENT(id), payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateBulkUserKycDocument: async <T>(
    payload: TUploadUserKycDocumentPayload,
    id: number,
  ) => {
    try {
      const response = await put<T>(API_URLS.UPLOAD_KYC_DOCUMENT(id), payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  updateKycDocument: async <T>({ payload, id, userId }: TUpdateKycDocument) => {
    // console.log(payload, id, 'payload, id');
    const data = async () => {
      const fileReader = new FileReader();
      const content = await new Promise((resolve) => {
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.readAsDataURL(payload?.content);
      });

      const fileName = `${payload?.file_name}`;

      return {
        file_name: fileName,
        content,
      };
    };

    try {
      const responseData = await data();
      const response = await put<T>(
        API_URLS.UPDATE_KYC_DOCUMENT(id),
        responseData,
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  },

  uploadKycFormApi: async <T>(payload: any) => {
    try {
      const response = await post<T>(API_URLS.UPLOAD_KYC_FORM, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default ApiServices;
