import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  preferred_language: {
    id: 'profile.preferred_language',
    defaultMessage: 'Language',
  },
  first_name: {
    id: 'profile.first_name',
    defaultMessage: 'First name',
  },
  last_name: {
    id: 'profile.last_name',
    defaultMessage: 'Last name',
  },
  update: {
    id: 'profile.update',
    defaultMessage: 'Update',
  },
  email: {
    id: 'profile.email',
    defaultMessage: 'Email',
  },
  address_line1: {
    id: 'profile.address_line1',
    defaultMessage: 'Address line 1',
  },
  address_line2: {
    id: 'profile.address_line2',
    defaultMessage: 'Address line 2',
  },
  city_town: {
    id: 'profile.city_town',
    defaultMessage: 'City/Town',
  },
  state_province: {
    id: 'profile.state_province',
    defaultMessage: 'State/Province',
  },
  country: {
    id: 'profile.country',
    defaultMessage: 'Country',
  },
  zip_postal_code: {
    id: 'profile.zip_postal_code',
    defaultMessage: 'Zip/Postal code',
  },
  phone_number: {
    id: 'profile.phone_number',
    defaultMessage: 'Phone number',
  },
  date_of_birth: {
    id: 'profile.date_of_birth',
    defaultMessage: 'Date of birth',
  },
  nationality: {
    id: 'profile.nationality',
    defaultMessage: 'Nationality',
  },
  gender: {
    id: 'profile.gender',
    defaultMessage: 'Gender',
  },
  // finacial details
  trading_purpose: {
    id: 'profile.trading_purpose',
    defaultMessage: 'Intended purpose & nature of trading',
  },
  education_level: {
    id: 'profile.education_level',
    defaultMessage: 'Education level',
  },
  employment_status: {
    id: 'profile.employment_status',
    defaultMessage: 'Employment status',
  },
  estimated_net_worth: {
    id: 'profile.estimated_net_worth',
    defaultMessage: 'Estimated Net Worth (USD)',
  },
  estimated_annual_income: {
    id: 'profile.estimated_annual_income',
    defaultMessage: 'Estimated Annual Income (USD)',
  },
  estimated_investment_amount: {
    id: 'profile.estimated_investment_amount',
    defaultMessage: 'Estimated Annual Income (USD)',
  },
  source_of_funds: {
    id: 'profile.source_of_funds',
    defaultMessage: 'Source of Funds',
  },
  source_of_wealth: {
    id: 'profile.source_of_wealth',
    defaultMessage: 'Source of Wealth',
  },
  expected_origin_of_funds: {
    id: 'profile.expected_origin_of_funds',
    defaultMessage: 'Expected origin of incoming funds to the trading account',
  },
  // tabs name
  Personal_Information: {
    id: 'profile.Personal_Information',
    defaultMessage: 'Personal Information',
  },
  Financial_Information: {
    id: 'profile.Financial_Information',
    defaultMessage: 'Financial Information',
  },
  poiNumber: {
    id: 'profile.poiNumber',
    defaultMessage: 'POI Number',
  },

  jointAccountSubHeader: {
    id: 'userMenu.jointAccountSubHeader',
    defaultMessage: 'Enter the information below for second account holder',
  },
  dobTitle: {
    id: 'userMenu.dobTitle',
    defaultMessage: 'Date of Birth',
  },
  successJointAccountUpdate: {
    id: 'userMenu.successJointAccountUpdate',
    defaultMessage: 'Joint account has been successfully updated',
  },

  corporateInformation: {
    id: 'userMenu.corporateInformation',
    defaultMessage: 'Corporate Information',
  },
  jointAccountInfo: {
    id: 'userMenu.jointAccountInfo',
    defaultMessage: 'Joint Account Information',
  },
  personalInfoSuccessMessage: {
    id: 'userMenu.personalInfoSuccessMessage',
    defaultMessage: 'Personal information has been successfully updated',
  },
});
