import * as yup from 'yup';
import {
  getAddressLine1Schema,
  getAddressLine2Schema,
  getCityTownSchema,
  getCompanyLicenseSchema,
  getCompanyNameSchema,
  getCompDateOfBirthSchema,
  getCountrySchema,
  getStateProvinceSchema,
  getZipPostalCodeSchema,
} from '~/schema/ProfileSchema';

export const CORPORATE_INFO_KEYS = {
  COMPANY_NAME: 'company_name',
  COMPANY_LICENSE: 'company_license',
  COMPANY_ADDRESS_LINE1: 'company_address_line1',
  COMPANY_ADDRESS_LINE2: 'company_address_line2',
  COMPANY_CITY_TOWN: 'company_city_town',
  COMPANY_ZIP_POSTAL_CODE: 'company_zip_postal_code',
  COMPANY_STATE_PROVINCE: 'company_state_province',
  COMPANY_COUNTRY: 'company_country',
  COMPANY_DATE_OF_BIRTH: 'company_date_of_birth',
};

export type ICorporateInfoPayload = Record<
  (typeof CORPORATE_INFO_KEYS)[keyof typeof CORPORATE_INFO_KEYS],
  string | boolean | number | object
>;

export const getCorporateInfoSchema = (formatMessage: (msg: any) => string) =>
  yup.object().shape({
    company_name: getCompanyNameSchema(formatMessage),
    company_license: getCompanyLicenseSchema(formatMessage),
    company_address_line1: getAddressLine1Schema(formatMessage),
    company_address_line2: getAddressLine2Schema(formatMessage),
    company_city_town: getCityTownSchema(formatMessage),
    company_zip_postal_code: getZipPostalCodeSchema(formatMessage),
    company_state_province: getStateProvinceSchema(formatMessage),
    company_date_of_birth: getCompDateOfBirthSchema(formatMessage),
    company_country: getCountrySchema(formatMessage),
  });

export const CORPORATE_INFO_VALUES: ICorporateInfoPayload = {
  company_name: '',
  company_license: '',
  company_address_line1: '',
  company_address_line2: '',
  company_city_town: '',
  company_zip_postal_code: '',
  company_state_province: '',
  company_country: '',
  company_date_of_birth: '',
};
