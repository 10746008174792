import { FC, useMemo, useState } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { API_URLS } from '../../api/config';
import { QUERY_KEYS } from '../../services/queries/constants';
import { getBankAccountsTableColumns } from './BankAccountsTableColumns';
import ApproveRejectBankAccount from './components/ApproveRejectBankAccount';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { BANK_ACCOUNT_STATUS } from '../../constants';
import messages from '../../messages';
import SuccessModal from '~/components/SuccessModal';
import Box from '~app/components/Box';
import TableFilters from '../../../../components/TableFilters';
import useFilters from '~app/hooks/useFilters';
import { FILTERS, ACTIONS_COLUMNS_TO_PIN } from '~/config/constants';
import ViewBankAccount from './components/ApproveRejectBankAccount/ViewBankAccount';

const BankAccountsTable: FC<{}> = () => {
  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: QUERY_KEYS.FETCH_BANK_ACCOUNTS,
  });

  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    status,
    showSuccessModal,
    onViewRecord,
    viewRecord,
    setViewRecord,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_BANK_ACCOUNTS,
    API_URLS: API_URLS.APPROVE_REJECT_BANK_ACCOUNT,
    STATUS: BANK_ACCOUNT_STATUS,
    user: false,
    messages: messages,
  });

  const memoizedColumns = useMemo(
    () =>
      getBankAccountsTableColumns({
        onViewRecord,
        onApproveRecord,
        onRejectRecord,
      }),
    [onApproveRecord, onRejectRecord],
  );

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' mb={'16px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_BANK_ACCOUNTS,
            queryKeyProp: [QUERY_KEYS.FETCH_BANK_ACCOUNTS],
            select: (data: any) => data,
          }}
          columns={memoizedColumns}
          filters={filters}
          columnsToPin={ACTIONS_COLUMNS_TO_PIN}
        />
      </Box>
      {recordForApproveReject && (
        <ApproveRejectBankAccount
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
          status={status}
        />
      )}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />

      {viewRecord && (
        <ViewBankAccount
          onCancel={() => {
            setViewRecord(null);
          }}
          recordToView={viewRecord}
        />
      )}
    </>
  );
};

export default BankAccountsTable;
