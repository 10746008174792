import { Box, Typography } from '@mui/material';
import { isDocumentImage, isDocumentPdf } from '~/utils/index';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
import { useIsMobile } from '~/hooks/useIsMobile';

export const RenderDocumentContent = ({
  contentType,
  extension,
  documentUrl,
}: {
  contentType: string;
  extension: string;
  documentUrl: string;
}) => {
  const isMobile = useIsMobile();
  return (
    <>
      {isDocumentPdf(contentType, extension) ? (
        <iframe
          src={documentUrl}
          width={isMobile ? '100%' : '350x'}
          height='350px'
          style={{ border: '1px solid #e0e0e0' }}
          title='Document Preview'
        />
      ) : isDocumentImage(contentType) ? (
        <Box className='pan-container'>
          <ReactPanZoom image={documentUrl} alt='Image alt text' />
        </Box>
      ) : (
        <Typography color='error'>No Document Found</Typography>
      )}
    </>
  );
};
