import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useFetchData } from '~/hooks/fetchData/useFetchData';
import ApproveRejectKyc from './components/KycForm/ApproveRejectModal';
import { KYC_STATUS_MESSAGE } from './constants';
import { useLocation } from 'react-router-dom';
import { API_URLS } from '../../api/config';
import { FormattedMessage } from '~app/Integrations/Localization';
import messages from './messages';
import { KycDoc } from './types';
import CircularProgress from '@mui/material/CircularProgress';
import SuccessModal from '~/components/SuccessModal';
import KycForm from '~/modules/Kyc/components/KycForm';
import { useKycApproveRejectActions } from '~/hooks/useApproveRejectActions';
import { QUERY_KEYS } from '~/services/queries/constants';

const KycDocs = () => {
  const location = useLocation();
  const {
    data: fetchDocument,
    isLoading,
    refetch,
  } = useFetchData<KycDoc>({
    url: `/users/${location.state}/documents`,
    queryKey: `${QUERY_KEYS.FETCH_KYC}-${location.state}`,
  });
  const [isUpdateSuccess, setIsUpdateSuccess] = React.useState(false);

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      setIsUpdateSuccess(false);
    }
  }, [isUpdateSuccess]);

  const {
    recordForApproveReject,
    onApproveRecord,
    onApproveRejectRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccess,
    showSuccessModal,
    successMessage,
    onSuccessClose,
  } = useKycApproveRejectActions({
    QUERY_KEYS: `${QUERY_KEYS.FETCH_KYC}-${location.state}`,
    API_URLS: API_URLS.APPROVE_REJECT_KYC,
    STATUS: KYC_STATUS_MESSAGE,
    user: false,
    messages: messages,
  });

  if (isLoading) {
    return <CircularProgress size='3rem' />;
  }
  if (fetchDocument?.data.length === 0) {
    return (
      <Typography sx={{ fontSize: '24px', fontWeight: '400' }}>
        <FormattedMessage {...messages.noDocumentsFound} />
      </Typography>
    );
  }

  return (
    <Box mx={{ xs: '0px', sm: '20px' }}>
      <Typography sx={{ fontSize: '24px', fontWeight: '400' }}>
        <FormattedMessage {...messages.kycModalTitle} />
      </Typography>
      {fetchDocument?.data?.length > 0 && (
        <KycForm
          data={fetchDocument?.data}
          setIsUpdateSuccess={setIsUpdateSuccess}
          onApproveRejectRecord={onApproveRejectRecord}
        />
      )}
      {recordForApproveReject !== null && (
        <ApproveRejectKyc
          recordForApproveReject={recordForApproveReject}
          onApproveRecord={onApproveRecord}
          onRejectRecord={onRejectRecord}
          onCancel={onCancel}
          onConfirmApproveReject={onConfirmApproveReject}
          onSuccess={onSuccess}
          onApproveRejectRecord={onApproveRejectRecord}
        />
      )}

      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </Box>
  );
};

export default KycDocs;
