import { FormattedMessage } from '~app/Integrations/Localization';
import Grid from '~app/components/Grid';
import TextField from '~app/components/Input/TextField';
import messages from './messages';

// eslint-disable-next-line complexity
export const ViewPOAFields = ({
  values,
  setFieldValue,
  setFieldTouched,
  errors,
  touched,
  handleChange,
}: any) => (
  <>
    <Grid item xs={12} sm={12}>
      <TextField
        variant='outlined'
        name='address_line1'
        label={<FormattedMessage {...messages.addressLine1} />}
        value={values?.address_line1 || ''}
        multiline
        onChange={(e) => {
          setFieldTouched('address_line1', true);
          handleChange(e);
        }}
        onBlur={(e) => {
          setFieldTouched('address_line1', true);
        }}
        error={touched.address_line1 && Boolean(errors.address_line1)}
        helperText={touched.address_line1 && errors.address_line1}
        fullWidth
        size='small'
      />
    </Grid>

    <Grid item xs={12} sm={12}>
      <TextField
        variant='outlined'
        name='address_line2'
        multiline
        label={<FormattedMessage {...messages.addressLine2} />}
        value={values?.address_line2 || ''}
        onChange={(e) => {
          setFieldTouched('address_line2', true);
          handleChange(e);
        }}
        onBlur={(e) => {
          setFieldTouched('address_line2', true);
        }}
        error={touched.address_line2 && Boolean(errors.address_line2)}
        helperText={touched.address_line2 && errors.address_line2}
        fullWidth
        size='small'
      />
    </Grid>

    <Grid item xs={12}>
      <TextField
        variant='outlined'
        name='city_town'
        label={<FormattedMessage {...messages.cityOrTown} />}
        value={values?.city_town || ''}
        onChange={(e) => {
          setFieldTouched('city_town', true);
          handleChange(e);
        }}
        onBlur={(e) => {
          setFieldTouched('city_town', true);
        }}
        error={touched.city_town && Boolean(errors.city_town)}
        helperText={touched.city_town && errors.city_town}
        fullWidth
        size='small'
      />
    </Grid>

    <Grid item xs={12}>
      <TextField
        variant='outlined'
        name='state_province'
        label={<FormattedMessage {...messages.stateProvince} />}
        value={values?.state_province || ''}
        onChange={(e) => {
          setFieldTouched('state_province', true);
          handleChange(e);
        }}
        onBlur={(e) => {
          setFieldTouched('state_province', true);
        }}
        error={touched.state_province && Boolean(errors.state_province)}
        helperText={touched.state_province && errors.state_province}
        fullWidth
        size='small'
      />
    </Grid>

    <Grid item xs={12}>
      <TextField
        variant='outlined'
        name='zip_postal_code'
        type='number'
        label={<FormattedMessage {...messages.zipPostalCode} />}
        value={values?.zip_postal_code || ''}
        onChange={(e) => {
          setFieldTouched('zip_postal_code', true);
          handleChange(e);
        }}
        onBlur={(e) => {
          setFieldTouched('zip_postal_code', true);
        }}
        error={touched.zip_postal_code && Boolean(errors.zip_postal_code)}
        helperText={touched.zip_postal_code && errors.zip_postal_code}
        fullWidth
        size='small'
      />
    </Grid>
  </>
);
