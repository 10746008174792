/* eslint-disable complexity */
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import dayjs from 'dayjs';
import {
  FormattedMessage,
  useLocaleContext,
} from 'v2s-ui-core/app/Integrations/Localization';
import { isMobile } from 'v2s-ui-core/app/utils';
import YearPickCalender from '~/components/DOB_Calender';
import FormSkeleton from '~/components/Skeleton/FormSkeleton';
import { theme } from '~/shell/theme/theme';
import { getMaxDOBFromCurrentDate } from '~/utils';
import SuccessMessageModal from '~/components/SuccessMessageModal';
import { GENDERS, IMyProfileTabProps } from '../PersonalDetails/constants';
import messages from '../messages';
import useJointAccountDetailsForm from './hooks/useJointAccountDetailsForm';

const JointAccount = ({ onTabChange }: IMyProfileTabProps) => {
  const {
    values,
    handleChange,
    errors,
    handleSubmit,
    setFieldValue,
    isJointSuccessModalOpen,
    handleCloseSuccessModal,
    touched,
    setFieldTouched,
    disabledJointFormFields,
    isLoading,
    isUpdateLoading,
  } = useJointAccountDetailsForm({ onTabChange });
  const handleTabSubmit = () => {
    handleSubmit();
  };

  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  if (isLoading || isUpdateLoading) {
    return (
      <Box>
        <FormSkeleton />
      </Box>
    );
  }
  return (
    <Box
      paddingX={2}
      paddingBottom={5}
      paddingTop={2}
      display={'flex'}
      flexDirection={'column'}
    >
      <Typography variant='body1'>
        <FormattedMessage {...messages.jointAccountSubHeader} />
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} mt={2} mb={{ xs: -1, md: 0 }}>
          <Autocomplete
            id='gender'
            size='small'
            value={
              GENDERS.find((data) => data.value === values?.secondary_gender) ||
              null
            }
            fullWidth
            options={GENDERS}
            autoHighlight
            disableClearable
            disabled={disabledJointFormFields}
            clearIcon={false}
            onChange={(event, value) =>
              setFieldValue('secondary_gender', value.value)
            }
            getOptionLabel={(option) => option.label}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box
                  key={key}
                  component='li'
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...optionProps}
                >
                  {option.label}
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                label='Gender'
                placeholder='Gender'
                disabled={disabledJointFormFields}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                value={GENDERS.find(
                  (data) => data.value === values?.secondary_gender,
                )}
                onBlur={() => {
                  setFieldTouched('secondary_gender', true);
                }}
                error={
                  !!(errors?.secondary_gender && touched?.secondary_gender)
                }
                helperText={
                  touched?.secondary_gender &&
                  errors?.secondary_gender?.toString()
                }
                onChange={handleChange}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='Full name'
            placeholder='Enter full name'
            margin='normal'
            fullWidth
            name='secondary_name'
            disabled={disabledJointFormFields}
            value={values?.secondary_name}
            onBlur={() => {
              setFieldTouched('secondary_name', true);
            }}
            error={!!(errors?.secondary_name && touched?.secondary_name)}
            helperText={
              touched?.secondary_name && errors?.secondary_name?.toString()
            }
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4} mt={isMobile() ? 0 : 2}>
          <YearPickCalender
            value={values.secondary_date_of_birth || undefined}
            onChange={(date: Date) => {
              if (date?.toString() === 'Invalid Date') {
                setFieldValue('secondary_sdate_of_birth', null);
              }
              setFieldValue(
                'secondary_date_of_birth',
                date?.toISOString()?.split('T')[0],
              );
            }}
            label={formatMessage(messages.dobTitle)}
            maxDate={dayjs(getMaxDOBFromCurrentDate(new Date()))}
            disabled={disabledJointFormFields}
            onBlur={() => {
              setFieldTouched('secondary_date_of_birth', true);
            }}
            error={!!errors?.secondary_date_of_birth}
            helperText={errors?.secondary_date_of_birth?.toString()}
            textColor={
              disabledJointFormFields
                ? theme.palette.text.disabled
                : theme.palette.text.primary
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} gap={6} mt={isMobile() ? 1 : 0}>
        <TextField
          required
          size='small'
          autoComplete='off'
          label='Address line 1'
          placeholder='Address line 1'
          margin='normal'
          fullWidth
          name='secondary_address_line1'
          disabled={disabledJointFormFields}
          value={values?.secondary_address_line1}
          onBlur={() => {
            setFieldTouched('secondary_address_line1', true);
          }}
          error={
            !!(
              errors?.secondary_address_line1 && touched.secondary_address_line1
            )
          }
          helperText={
            touched?.secondary_address_line1 &&
            errors?.secondary_address_line1?.toString()
          }
          onChange={handleChange}
        />
        <TextField
          size='small'
          required
          autoComplete='off'
          label='Address line 2'
          placeholder='Address line 2'
          margin='normal'
          fullWidth
          name='secondary_address_line2'
          disabled={disabledJointFormFields}
          value={values.secondary_address_line2}
          onBlur={() => {
            setFieldTouched('secondary_address_line2', true);
          }}
          error={
            !!(
              errors?.secondary_address_line2 && touched.secondary_address_line2
            )
          }
          helperText={
            touched?.secondary_address_line2 &&
            errors?.secondary_address_line2?.toString()
          }
          onChange={handleChange}
        />
      </Grid>
      <Grid container spacing={isMobile() ? 0 : 2}>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='City/Town'
            placeholder='City/Town'
            margin='normal'
            fullWidth
            name='secondary_city_town'
            disabled={disabledJointFormFields}
            value={values.secondary_city_town}
            onBlur={() => {
              setFieldTouched('secondary_city_town', true);
            }}
            error={
              !!(errors?.secondary_city_town && touched.secondary_city_town)
            }
            helperText={
              touched?.secondary_city_town &&
              errors?.secondary_city_town?.toString()
            }
            onChange={handleChange}
            sx={{ marginRight: '48px' }}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='State/province'
            placeholder='State/province'
            margin='normal'
            fullWidth
            name='secondary_state_province'
            disabled={disabledJointFormFields}
            value={values.secondary_state_province}
            onBlur={() => {
              setFieldTouched('secondary_state_province', true);
            }}
            error={
              !!(
                errors?.secondary_state_province &&
                touched.secondary_state_province?.toString()
              )
            }
            helperText={
              touched?.secondary_state_province &&
              errors?.secondary_state_province
            }
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            size='small'
            autoComplete='off'
            label='Zip/Postal code'
            placeholder='Zip/Postal code'
            margin='normal'
            fullWidth
            name='secondary_zip_postal_code'
            disabled={disabledJointFormFields}
            value={values?.secondary_zip_postal_code}
            onBlur={() => {
              setFieldTouched('secondary_zip_postal_code', true);
            }}
            error={
              !!(
                errors?.secondary_zip_postal_code &&
                touched.secondary_zip_postal_code
              )
            }
            helperText={
              touched?.secondary_zip_postal_code &&
              errors?.secondary_zip_postal_code?.toString()
            }
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      {!disabledJointFormFields && (
        <Box
          mt={2}
          display={'flex'}
          justifyContent={isMobile() ? 'center' : 'flex-start'}
          sx={{ width: '113px' }}
        >
          <Button
            variant='contained'
            size='medium'
            onClick={() => handleTabSubmit()}
          >
            <FormattedMessage {...messages.update} />
          </Button>
        </Box>
      )}
      {!!isJointSuccessModalOpen && (
        <SuccessMessageModal
          message={formatMessage(messages.successJointAccountUpdate)}
          handleOk={handleCloseSuccessModal}
          open={isJointSuccessModalOpen}
        />
      )}
    </Box>
  );
};

export default JointAccount;
