import { AGREEMENT_TYPE, MODE, REBATE_TYPE } from '~/constants';
import { API_URLS } from './api/config';
import { QUERY_KEYS } from './services/queries/constants';

export function getAgreementQueryKey(mode: any, location: any, formik: any) {
  const QUERY_KEY =
    mode === MODE.EDIT
      ? `${QUERY_KEYS.GET_IB_AGREEMENT}_${location.state}_${formik.values?.rebate_structure?.name}_${formik.values?.tier?.id}`
      : `${QUERY_KEYS.GET_DEFAULT_AGREEMENT}_${location.state}_${formik.values?.rebate_structure?.name}_${formik.values?.tier?.id}`;
  return QUERY_KEY;
}

export const getResourceApi = (mode: any, location: any, formik: any) => {
  if (mode === MODE.EDIT) {
    return API_URLS.GET_AGREEMENT(location.state);
  }

  return `${API_URLS.GET_DEFAULT_AGREEMENT(
    formik?.values?.rebate_structure?.name === AGREEMENT_TYPE.FIXED_REBATE
      ? REBATE_TYPE.FIXED_REBATE
      : REBATE_TYPE.SPREAD_REVENUE,
  )}`;
};

export const getIbLevelValue = (agreementData: any) =>
  (agreementData as any)?.data?.sub_ib
    ? (agreementData as any)?.data?.sub_ib?.level
    : (agreementData as any)?.user?.level ||
      (agreementData as any)?.data?.user?.level;
