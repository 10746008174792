/* eslint-disable max-params */
import { GET_KYC_UPLOAD_MODE } from '~/config/constants';
import { TDocuments, TDocumentsTypes } from '~/config/types';
import { findIndexByDocType, findOtherDocsIndexByDocType } from '~/utils';

export const setFieldsValues = ({
  setDropDownValue,
  uploadedData,
  options,
  formik,
  docType,
  setUpdateLoader,
}: {
  setDropDownValue: (dropDownValue: any) => void;
  uploadedData: any[];
  options: TDocuments[];
  formik: any;
  docType: any;
  setUpdateLoader?: (isLoading: boolean) => void;
}) => {
  setDropDownValue((prevState: any) => ({
    ...prevState,
    documentName: uploadedData?.[0].document_name,
    documentSide: {
      FrontId:
        (options?.find(
          (opt: any) => opt.document_name === uploadedData?.[0].document_name,
        )?.sides[0].id as number) || null,
      BackId:
        (options?.find(
          (opt: any) => opt.document_name === uploadedData?.[0].document_name,
        )?.sides[1].id as number) || null,
      BackSideRequired:
        (options.find(
          (opt) => opt.document_name === uploadedData?.[0].document_name,
        )?.sides[1]?.is_required as boolean) || false,
      FrontSideRequired:
        (options.find(
          (opt) => opt.document_name === uploadedData?.[0].document_name,
        )?.sides[0]?.is_required as boolean) || false,

      poa_front_doc_id: uploadedData?.[0]?.id || 0,
      poa_back_doc_id: uploadedData?.[1]?.id || 0,
      poi_back_doc_id: uploadedData?.[1]?.id || 0,
      poi_front_doc_id: uploadedData?.[0]?.id || 0,
    },
  }));
  formik.setFieldValue(
    `${docType.toLowerCase()}_front`,
    findIndexByDocType(uploadedData, 'Front'),
  );
  formik.setFieldValue(
    `${docType.toLowerCase()}_back`,
    findIndexByDocType(uploadedData, 'Back'),
  );
  setUpdateLoader(false);
};
export const setOtherFieldsValues = ({
  setDropDownValue,
  uploadedData,
  options,
  formik,
  docType,
  setUpdateLoader,
}: {
  setDropDownValue: (dropDownValue: any) => void;
  uploadedData: any[];
  options: TDocuments[];
  formik: any;
  docType: any;
  setUpdateLoader?: (isLoading: boolean) => void;
}) => {
  setDropDownValue((prevState: any) => ({
    ...prevState,
    documentName: 'Others Document',
    documentSide: {
      FrontId:
        (options?.find(
          (opt: any) => opt.document_name === uploadedData?.[0].document_name,
        )?.sides[0].id as number) || null,
      FrontSideRequired: false,
      other_doc_1: uploadedData?.[0]?.id || 0,
      other_doc_2: uploadedData?.[1]?.id || 0,
      other_doc_3: uploadedData?.[2]?.id || 0,
      other_doc_4: uploadedData?.[3]?.id || 0,
      other_doc_5: uploadedData?.[4]?.id || 0,
    },
  }));
  [0, 1, 2, 3, 4].map((fieldId) =>
    formik.setFieldValue(
      `${docType.toLowerCase()}_front_${fieldId}`,
      findOtherDocsIndexByDocType(uploadedData, 'Front', fieldId),
    ),
  );

  setUpdateLoader(false);
};

export const onDocumentSelection = (
  documentName: string,
  options: TDocuments[],
  setDropDownValue: (dropDownValue: any) => void,
  uploadedFiles: any,
  setUploadedFiles: any,
  docType: keyof TDocumentsTypes,
  formik: any,
  isOtherDoc?: boolean,
  indexNumber?: number,
) => {
  const selectedDocumentSide = {
    FrontId:
      (options.find((opt) => opt.document_name === documentName)?.sides[0]
        .id as number) ?? 15,
    BackId: options.find((opt) => opt.document_name === documentName)?.sides[1]
      .id as number,
    BackSideRequired: options.find((opt) => opt.document_name === documentName)
      ?.sides[1]?.is_required as boolean,
    FrontSideRequired: options.find((opt) => opt.document_name === documentName)
      ?.sides[0]?.is_required as boolean,
  };

  setDropDownValue((prevState: any) => ({
    ...prevState,
    documentName,
    documentSide: selectedDocumentSide,
  }));

  const newUploadedFiles = uploadedFiles.filter((file: any) => {
    if (
      file.docType === docType.toLowerCase() &&
      file.document_mapping_id !== selectedDocumentSide.FrontId
    ) {
      return false;
    }
    return true;
  });

  setUploadedFiles(newUploadedFiles);
  if (isOtherDoc) {
    formik.setFieldValue(`${docType.toLowerCase()}_${indexNumber}`, null);
  } else {
    formik.setFieldValue(`${docType.toLowerCase()}_front`, null);
    formik.setFieldValue(`${docType.toLowerCase()}_back`, null);
  }
};

export const disableBulkKycBtn = (formik: any, uploadedDoc: any) => {
  if (!formik || !formik.values) {
    return true;
  }

  if (
    formik.values.bulk_files?.length > 0 &&
    formik.values.poa_front?.name &&
    formik.values.poi_front?.name &&
    formik.values.poi_back?.name
  ) {
    return false;
  }

  return true;
};

export const kycMode = (formik: any, uploadedFiles: any[]) =>
  formik.getFieldProps('poi_front').value?.id ||
  formik.getFieldProps('poa_front').value?.id ||
  uploadedFiles?.length > 0
    ? GET_KYC_UPLOAD_MODE.EDIT
    : GET_KYC_UPLOAD_MODE.ADD;

export const addNewOtherDoc = (
  fieldList: any,
  setFieldList: (fieldList: any) => void,
) => {
  if (fieldList.length < 5) {
    setFieldList([...fieldList, {}]);
  }
};

export const frontDocFieldValue = (
  index: number,
  formik: any,
  docType: string,
) => formik.values[`${docType.toLowerCase()}_front_${index}`];

export const url_front = (index: number, formik: any, docType: string) =>
  frontDocFieldValue(index, formik, docType)?.file_location || null;
