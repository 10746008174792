import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  poiExpiryDateUpdated: {
    id: 'poi.expiry.date.updated',
    defaultMessage: 'POI expiry date updated successfully',
  },
  poaExpiryDateUpdated: {
    id: 'poa.expiry.date.updated',
    defaultMessage: 'POA expiry date updated successfully',
  },
});
