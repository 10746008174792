import React from 'react';
import { Column } from '@tanstack/react-table';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { formatDate } from '~/utils';

const DateFilterComponent: React.FC<{
  column: Column<unknown, unknown>;
  style?: React.CSSProperties;
}> = ({ column, style }) => {
  const handleDateChange = (date: Date | null) => {
    const formattedDate = formatDate(date); // Format the date using the utility function
    column.setFilterValue(formattedDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={
          column.getFilterValue()
            ? new Date(String(column.getFilterValue()))
            : null
        }
        onChange={handleDateChange}
        format='DD-MM-YYYY'
        openTo='year'
        views={['year', 'month', 'day']}
        //@ts-ignore
        maxDate={dayjs()}
        sx={{
          '& :hover': {
            color: 'black !important',
          },
          //   ...style,
        }}
        slotProps={{
          textField: {
            size: 'small',
            InputProps: {
              sx: {
                padding: '4px 8px',
                backgroundColor: '#fff',
                borderRadius: '4px',
                fontSize: '14px',
                height: '33px',
                width: '168px',
                '& :hover': {
                  color: 'black !important',
                },
              },
            },
            inputProps: {
              style: {
                height: '16px',
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};

export default DateFilterComponent;
