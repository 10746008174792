/* eslint-disable complexity */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { blue } from '@mui/material/colors';
import { FormattedMessage } from 'v2s-ui-core/app/Integrations/Localization';
import Grid from 'v2s-ui-core/app/components/Grid';
import TextField from 'v2s-ui-core/app/components/Input/TextField';
import Button from 'v2s-ui-core/app/components/Button';
import messages from '~/modules/Deposits/messages';
import { getAccountLabel, getAccountValue } from '~/modules/Deposits/constants';
import { CloseButton } from '~/components/Actions/CloseButton';
import { useIsMobile } from '~/hooks/useIsMobile';
import { RenderDocumentContent } from '~/components/ShowDoc';
import { Box, Typography } from '@mui/material';
import { ViewDepositProps } from '~/modules/Deposits/types';
import { PAYMENT_METHODS } from '~/components/Actions/constant';

const ViewDeposit = ({
  onCancel,
  recordToView,
}: {
  onCancel: () => void;
  recordToView: ViewDepositProps;
}) => {
  const isMobile = useIsMobile();
  const { latest_version: documentData = {} } =
    recordToView?.user_document || {};
  const {
    original_doc_location: documentUrl = '',
    content_type: contentType = '',
    extension = '',
  } = documentData as any;

  return (
    <Dialog
      open={true}
      onClose={onCancel}
      PaperProps={{
        component: 'form',
        sx: {
          minWidth: isMobile ? '100vw' : '800px',
          margin: isMobile ? 1 : 0,
          pb: isMobile ? 2 : 1,
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: blue[50] }}>
        <FormattedMessage {...messages.deposit_details} />
        <CloseButton onClose={onCancel} />
      </DialogTitle>
      <DialogContent sx={{ width: '100%', padding: isMobile ? 2 : 1 }}>
        {/*Show bank docs*/}
        <Box
          display='flex'
          alignItems='center'
          justifyContent={'center'}
          gap={isMobile ? 0 : 4}
          px={isMobile ? 0 : 2}
          flexDirection={isMobile ? 'column-reverse' : 'row'}
        >
          <Grid container spacing={2} mt={0}>
            <>
              <Grid item xs={12}>
                <TextField
                  name={'trading_amount'}
                  value={recordToView?.trading_amount}
                  required
                  type='number'
                  variant='outlined'
                  label={<FormattedMessage {...messages.tradingAmount} />}
                  size='small'
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={'trading_currency'}
                  variant='outlined'
                  value={recordToView?.trading_currency}
                  label={<FormattedMessage {...messages.tradingCurrency} />}
                  size='small'
                  disabled
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name={'local_amount'}
                  variant='outlined'
                  disabled
                  value={recordToView?.local_amount}
                  label={<FormattedMessage {...messages.local_amount} />}
                  size='small'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name={'local_currency'}
                  variant='outlined'
                  value={recordToView?.local_currency}
                  label={<FormattedMessage {...messages.local_currency} />}
                  size='small'
                  disabled
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  value={getAccountValue(recordToView)}
                  label={getAccountLabel(recordToView)}
                  size='small'
                  disabled
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  name={'notes'}
                  variant='outlined'
                  label={<FormattedMessage {...messages.notes} />}
                  value={recordToView?.note}
                  fullWidth
                  size='small'
                  disabled
                />
              </Grid>
            </>
          </Grid>
          <Box>
            <Typography mt={1}>
              {recordToView?.user_document?.latest_version?.filename}
            </Typography>
            {(recordToView?.payment_method === PAYMENT_METHODS.wire ||
              recordToView?.payment_method === PAYMENT_METHODS.local) && (
              <RenderDocumentContent
                contentType={contentType}
                extension={extension}
                documentUrl={documentUrl}
              />
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: isMobile ? 2 : 3 }}>
        <Button onClick={onCancel} variant='outlined'>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewDeposit;
