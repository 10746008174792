import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  approveModalTitle: {
    id: 'bankAccounts.approveModalTitle',
    defaultMessage: 'Approve Bank Account?',
  },
  rejectModalTitle: {
    id: 'bankAccounts.rejectModalTitle',
    defaultMessage: 'Reject Bank Account?',
  },
  approveModalHelpText: {
    id: 'bankAccounts.approveModalHelpText',
    defaultMessage:
      ' You confirm that you’ve read the document by approving. If not reviewed ',
  },
  rejectModalHelpText: {
    id: 'bankAccounts.rejectModalHelpText',
    defaultMessage:
      'You confirm that you’ve read the document by rejecting. If not reviewed',
  },
  bankAccountNumber: {
    id: 'bankAccounts.bankAccountNumber',
    defaultMessage: 'Account Number',
  },
  approved: {
    id: 'bankAccounts.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'bankAccounts.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'bankAccounts.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'bankAccounts.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'bankAccounts.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'bankAccounts.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'bankAccounts.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'bankAccounts.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  clickToView: {
    id: 'bankAccounts.clickToView',
    defaultMessage: 'click here to view it.',
  },
  clickToClose: {
    id: 'bankAccounts.clickToClose',
    defaultMessage: 'click here to close it.',
  },
  approveSuccessMessage: {
    id: 'bankAccounts.approveSuccessMessage',
    defaultMessage: 'Bank account approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'bankAccounts.rejectSuccessMessage',
    defaultMessage: 'Bank account rejected successfully!',
  },
  approveFailedMessage: {
    id: 'bankAccounts.approveFailedMessage',
    defaultMessage: 'Bank account approval failed!',
  },
  rejectFailedMessage: {
    id: 'bankAccounts.rejectFailedMessage',
    defaultMessage: 'Bank account rejection failed!',
  },
  viewBankAccount: {
    id: 'bankAccounts.viewBankAccount',
    defaultMessage: ' Bank Account Details ',
  },
  bankName: {
    id: 'bankAccounts.bankName',
    defaultMessage: 'Bank Name',
  },
  accountName: {
    id: 'bankAccounts.accountName',
    defaultMessage: 'Account Name',
  },
  accountNumber: {
    id: 'bankAccounts.accountNumber',
    defaultMessage: 'Account Number',
  },
  bankIban: {
    id: 'bankAccounts.bankIban',
    defaultMessage: 'Bank IBAN',
  },
  bankIFSC: {
    id: 'bankAccounts.bankIFSC',
    defaultMessage: 'Bank IFSC',
  },
  bankAddress: {
    id: 'bankAccounts.bankAddress',
    defaultMessage: 'Bank Address',
  },

  remarkValidation: {
    id: 'bankAccounts.remarkValidation',
    defaultMessage:
      'Please use only letters, numbers, spaces, and special characters like . * + ? ^ $ { } ( ) [ ] |  /',
  },
  remarkMinLength: {
    id: 'bankAccounts.remarkMinLength',
    defaultMessage: 'Remark should be at least 4 characters',
  },
  remarkMaxLength: {
    id: 'bankAccounts.remarkMaxLength',
    defaultMessage: 'Remark should be at most 100 characters',
  },
  bank_account_holder_name: {
    id: 'bankAccounts.bank_account_holder_name',
    defaultMessage: 'Account Holder Name',
  },
  bank_name: {
    id: 'bankAccounts.bank_name',
    defaultMessage: 'Bank Name',
  },
  bank_ifsc: {
    id: 'bankAccounts.bank_ifsc',
    defaultMessage: 'Bank IFSC ',
  },
  bank_iban: {
    id: 'bankAccounts.bank_iban',
    defaultMessage: 'Bank IBAN',
  },
  bank_document_name: {
    id: 'bankAccounts.bank_document_name',
    defaultMessage: 'Document Name',
  },
  bank_address: {
    id: 'bankAccounts.bank_address',
    defaultMessage: 'Bank Address',
  },
  documentNumberValidation: {
    id: 'bankAccounts.document_number_validation',
    defaultMessage: 'Document number should contain only numbers or letters',
  },
  documentNumberMinLength: {
    id: 'bankAccounts.document_number_min_length',
    defaultMessage: 'Document number should be at least 4 characters',
  },
  documentNumberMaxLength: {
    id: 'bankAccounts.document_number_max_length',
    defaultMessage: 'Document number should be at most 30 characters',
  },
});
