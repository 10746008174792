import { useMemo } from 'react';
import ApiServices from '../../api';
import { MUTATION_KEYS } from './constants';
import {
  MutationDetails,
  TUpdateCorporateDetailsResponse,
  TUpdateFinancialDetailsResponse,
  TUpdateJointDetailsResponse,
  TUpdateUserProfileResponse,
} from './types';

const {
  UPDATE_USER_PROFILE,
  UPDATE_FINANCIAL_DETAILS,
  UPDATE_JOINT_DETAILS,
  UPDATE_CORPORATE_DETAILS,
} = MUTATION_KEYS;

const useMutationFactory = <TData, TError = Error>() => {
  const mutationDetails = useMemo<MutationDetails<TData, TError>>(
    () => ({
      [UPDATE_USER_PROFILE]: () => ({
        mutationKey: [UPDATE_USER_PROFILE],
        mutationFn: ({ userId, values }) =>
          ApiServices.updateUserProfile<TUpdateUserProfileResponse>({
            userId,
            values,
          }),
      }),
      [UPDATE_FINANCIAL_DETAILS]: () => ({
        mutationKey: [UPDATE_FINANCIAL_DETAILS],
        mutationFn: ({ userId, values }) =>
          ApiServices.updateFinancialDetails<TUpdateFinancialDetailsResponse>({
            userId,
            values,
          }),
      }),
      [UPDATE_JOINT_DETAILS]: () => ({
        mutationKey: [UPDATE_JOINT_DETAILS],
        mutationFn: ({ userId, values }) =>
          ApiServices.updateJointAccountDetails<TUpdateJointDetailsResponse>({
            userId,
            values,
          }),
      }),
      [UPDATE_CORPORATE_DETAILS]: () => ({
        mutationKey: [UPDATE_CORPORATE_DETAILS],
        mutationFn: ({ userId, values }) =>
          ApiServices.updateCorporateDetails<TUpdateCorporateDetailsResponse>({
            userId,
            values,
          }),
      }),
    }),
    [],
  );

  return mutationDetails;
};

export default useMutationFactory;
