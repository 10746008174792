import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import useToggle from '~app/hooks/useToggle';
import { useBankAccount } from './hook/useBankAccount';
import { API_URLS } from './api/config';
import { QUERY_KEYS } from './services/queries/constants';
import BankAccountForm from './Components/BankAccountForm';
import ApproveRejectBtn from './Components/ApproveRejectBtn';
import ViewDocModal from './Components/ViewDocModal';
import { useApproveRejectActions } from '~/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import { BANK_ACCOUNT_STATUS } from './constants';
import ApproveRejectBankAccount from './Components/ApproveRejectBankAccModal/index';
import { AccountType } from './types';
import messages from './messages';
import AccountHeader from './Components/AccountHeader';
import SuccessModal from '~/components/SuccessModal';
import { FormattedMessage } from '~app/Integrations/Localization';
import AddBankAccount from './Components/AddBankAccount';
import { useBankInfoForm } from './hook/useBankInfoForm';

const BankAccounts = () => {
  const [account, setAccount] = React.useState<AccountType>(null);
  const { data, isLoading } = useBankAccount();
  const { toggle, setValue, value } = useToggle();
  const [isEdit, setIsEdit] = React.useState(true);
  const [showAddBankAccountModal, setShowAddBankAccountModal] =
    React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setAccount(
        data?.data.find((acc: AccountType) => acc?.id === account?.id) ||
          data?.data[0],
      );
    }, 500);
    return () => clearTimeout(timer);
  }, [data, account?.id]);

  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    showSuccessModal,
    successMessage,
    onSuccessClose,
    status,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_BANK_ACCOUNT_INFOS,
    API_URLS: API_URLS.APPROVE_REJECT_BANK_ACCOUNT,
    STATUS: BANK_ACCOUNT_STATUS,
    user: false,
    messages: messages,
  });

  return (
    <Box>
      {/* account header */}
      <AccountHeader
        setShowAddBankAccountModal={setShowAddBankAccountModal}
        account={account}
        setAccount={setAccount}
        data={data}
      />

      {/* View Doc Modal */}
      <ViewDocModal
        account={account}
        toggle={toggle}
        setValue={setValue}
        value={value}
      />

      {/* bank account form */}
      <BankAccountForm
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        account={account}
        setValue={setValue}
        isLoading={isLoading}
      />

      {/* approve reject actions btn */}
      {data?.data.length !== 0 && (
        <ApproveRejectBtn
          // isEdit={isEdit}
          account={account}
          onApproveRecord={onApproveRecord}
          onRejectRecord={onRejectRecord}
          isLoading={isLoading}
          // handleSubmit={handleSubmit}
          // showSuccessModal={showSuccessModalDialog}
          // setShowSuccessModal={setShowSuccessModal}
        />
      )}

      {/* approve reject modal */}
      {recordForApproveReject && (
        <ApproveRejectBankAccount
          onCancel={onCancel}
          status={status}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
        />
      )}

      {/* add bank account modal */}
      <AddBankAccount
        showAddBankAccountModal={showAddBankAccountModal}
        setShowAddBankAccountModal={setShowAddBankAccountModal}
      />

      {/* success modal */}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </Box>
  );
};

export default BankAccounts;
