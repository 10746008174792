import { useMemo } from 'react';
import { useQuery } from 'v2s-ui-core/app/Integrations/react-query';
import { get } from 'v2s-ui-core/app/Integrations/ApiService';

const ApiServices = {
  fetchData: async <T>(url: string, filters?: any) => {
    try {
      const response = await get<T>(url, {
        params: filters,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export const useFetchReports = <TData, TError = Error>(props: {
  url: string;
  queryKey: string;
  enabled?: boolean;
  filters?: any;
}) => {
  const { url, queryKey, enabled = true, filters } = props;

  const queryDetails = {
    queryKey: [queryKey, filters],
    queryFn: () => ApiServices.fetchData<TData>(url, filters),
    enabled,
  };

  const { data, isLoading, error, refetch } = useQuery<TData, TError>(
    queryDetails,
  );

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};
