export const API_URLS = {
  GET_EDUCATION_LEVELS: '/education-levels',

  GET_INTENDED_PURPOSES: '/intended-purposes',

  GET_EMPLOYMENT_STATUSES: '/employment-statuses',

  GET_NET_WORTH_RANGES: '/net-worth-ranges',

  GET_ANNUAL_INCOME_RANGES: '/annual-income-ranges',

  GET_INVESTMENT_AMOUNT_RANGES: '/investment-amount-ranges',

  GET_SOURCE_OF_FUNDS_AND_WEALTH: '/source-of-funds-and-wealth',

  GET_EXPECTED_ORIGIN_OF_FUNDS: '/expected-origin-of-funds',
};
