import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { DEPOSIT_STATUS, DEPOSIT_STATUS_CONFIG } from '../../constants';
import { DepositStatus } from '~/modules/Deposits/types';
import ActionButtons from '~/components/Actions/ActionButtons';
import TruncatedHeader from '~/components/TruncatedHeader/index';
import DateFilterComponent from '~/components/DateFIlter';

export const DepositsTableColumns = (props: {
  onApproveRecord?: Function;
  onViewRecord?: Function;
  onRejectRecord?: Function;
}): ColumnDef<DepositStatus>[] => [
  {
    accessorKey: 'id',
    header: 'Transaction ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'user_id',
    cell: ({ row }: any) => row.original.user,
    header: 'User ID',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_by',
    cell: ({ row }: any) => row.original?.created_by?.email,
    header: 'User Email',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'account_type',
    cell: ({ row }: any) => row.original?.account_id?.trading_account || '-',
    header: 'MT5 Login ID',
    enableColumnFilter: false,
  },
  {
    accessorKey: 'account_type_name',
    cell: ({ row }: any) => row.original?.account_type,
    header: 'Account Type',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'local_amount',
    header: 'Local Amount',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'local_currency',
    header: 'Local Currency',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_amount',
    header: 'Trading Amount',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'trading_currency',
    header: 'Trading Currency',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'payment_method',
    header: 'Payment Method',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'payment_reference',
    header: 'Payment Reference',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'additional_reference',
    header: () => (
      <TruncatedHeader text='Additional Reference' maxLength={14} useTooltip />
    ),

    enableColumnFilter: true,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'requested_date',
    header: 'Requested Date',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'ip_address',
    header: 'IP Address',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'updated_at',
    header: 'Updated At',
    enableColumnFilter: true,
    meta: {
      FilterComponent: (props) => <DateFilterComponent {...props} />,
    },
  },
  {
    accessorKey: 'created_by',
    cell: ({ row }: any) => row.original.created_by?.email,
    header: 'Created By',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'updated_by',
    cell: ({ row }: any) => row.original.updated_by?.email,
    header: 'Updated By',
    enableColumnFilter: true,
  },

  {
    accessorKey: 'actions',
    header: 'Actions',
    enablePinning: true,
    enableColumnFilter: false,
    cell: ({ cell }) => {
      const { row } = cell;
      const status = row.original.status;

      return (
        <ActionButtons
          onViewRecord={props?.onViewRecord}
          viewDepositDoc
          status={status}
          onApproveRecord={props?.onApproveRecord}
          onRejectRecord={props?.onRejectRecord}
          rowData={row.original}
          showViewButton={false}
          ACCOUNT_STATUS={DEPOSIT_STATUS}
          ACCOUNT_STATUS_CONFIG={DEPOSIT_STATUS_CONFIG}
        />
      );
    },
  },
];
