import { useFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { useLocaleContext } from 'v2s-ui-core/app/Integrations/Localization';
import { TUpdateJointDetailsPayload } from '../../services/mutations/types';
import { JOINT_ACCOUNT_VALUES, getJointDetailsInfoSchema } from '../constants';
import useGetJointAccountDetails from './useGetJointAccountDetails';
import useUpdateJointAccountDetails from './useUpdateJointAccountDetails';
import { useLocation } from 'react-router-dom';

const useJointAccountDetailsForm = ({ onTabChange }: any) => {
  const location = useLocation();
  const userId = location.state;
  const { updateJointAccountDetails, status, isUpdateLoading } =
    useUpdateJointAccountDetails();
  const { jointAccountInfo, isLoading } = useGetJointAccountDetails(userId);
  const [state, setState] = useState({
    isJointSuccessModalOpen: false,
    disabledJointFormFields: false,
  });
  const { isJointSuccessModalOpen, disabledJointFormFields } = state;

  useEffect(() => {
    if (status === 'success') {
      setState(() => ({
        ...state,
        isJointSuccessModalOpen: true,
      }));
      onTabChange(0);
    }
  }, [status]);
  const {
    messageFormatter: { formatMessage },
  } = useLocaleContext();
  const handleCloseSuccessModal = () => {
    setState(() => ({
      ...state,
      isJointSuccessModalOpen: false,
    }));
    onTabChange(0);
  };

  const initialValues = useMemo(() => {
    if (jointAccountInfo) {
      return { ...JOINT_ACCOUNT_VALUES, ...jointAccountInfo };
    } else {
      return JOINT_ACCOUNT_VALUES;
    }
  }, [jointAccountInfo]);

  const onUpdateValues = (values: any) => {
    const payload: TUpdateJointDetailsPayload = {
      values,
      userId,
    };
    updateJointAccountDetails(payload);
  };

  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    setFieldTouched,
  } = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      onUpdateValues(values);
    },
    validationSchema: getJointDetailsInfoSchema(formatMessage),
    enableReinitialize: true,
  });

  return {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    touched,
    setFieldValue,
    errors,
    isJointSuccessModalOpen,
    handleCloseSuccessModal,
    setFieldTouched,
    disabledJointFormFields,
    isLoading,
    isUpdateLoading,
  };
};

export default useJointAccountDetailsForm;
