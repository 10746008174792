import { defineMessages } from '~app/Integrations/Localization';

export default defineMessages({
  uploadSuccessMessage: {
    id: 'uploadSuccessMessage',
    defaultMessage: 'Bank Document uploaded successfully!',
  },

  upload: {
    id: 'upload',
    defaultMessage: 'Upload Bank Statement',
  },
  documentNumberValidation: {
    id: 'document_number_validation',
    defaultMessage: 'Document number should contain only numbers or letters',
  },
  documentNumberMinLength: {
    id: 'document_number_min_length',
    defaultMessage: 'Document number should be at least 4 characters',
  },
  documentNumberMaxLength: {
    id: 'document_number_max_length',
    defaultMessage: 'Document number should be at most 30 characters',
  },
  firstNameRequired: {
    id: 'firstNameRequired',
    defaultMessage: 'First name is required',
  },
  firstNameValidation: {
    id: 'firstNameValidation',
    defaultMessage: 'First name can only contain letters',
  },
  firstNameMinLength: {
    id: 'firstNameMinLength',
    defaultMessage: 'First name should be at least 3 characters',
  },
  firstNameMaxLength: {
    id: 'firstNameMaxLength',
    defaultMessage: 'First name should be at most 50 characters',
  },
  remarkRequired: {
    id: 'remarkRequired',
    defaultMessage: 'Remark is required',
  },
  remarkValidation: {
    id: 'remarkValidation',
    defaultMessage: 'Remark can only contain letters and numbers',
  },
  remarkMinLength: {
    id: 'remarkMinLength',
    defaultMessage: 'Remark should be at least 4 characters',
  },
  remarkMaxLength: {
    id: 'remarkMaxLength',
    defaultMessage: 'Remark should be at most 100 characters',
  },
  lastNameRequired: {
    id: 'lastNameRequired',
    defaultMessage: 'Last name is required',
  },
  lastNameValidation: {
    id: 'lastNameValidation',
    defaultMessage: 'Last name can only contain letters',
  },
  lastNameMinLength: {
    id: 'lastNameMinLength',
    defaultMessage: 'Last name should be at least 3 characters',
  },
  lastNameMaxLength: {
    id: 'lastNameMaxLength',
    defaultMessage: 'Last name should be at most 50 characters',
  },
  expireAtRequired: {
    id: 'expireAtRequired',
    defaultMessage: 'Expiry date is required',
  },
  fileToLarge: {
    id: 'fileToLarge',
    defaultMessage: 'Please choose a file that is 4 MB or smaller.',
  },
  documentNumberRequired: {
    id: 'documentNumberRequired',
    defaultMessage: 'POI number is required',
  },
  fileFormatAllowed: {
    id: 'fileFormatAllowed',
    defaultMessage:
      'Please choose only JPG, JPEG, PNG, and PDF files to upload.',
  },
});
