import { useState } from 'react';

export const useTabToggle = () => {
  const [tabPosition, setTabPosition] = useState<number>(0);
  const [editInfoDisabled, setEditInfoDisabled] = useState<boolean>(false);

  const onTabChange = (position: number) => {
    setTabPosition(position);
    setEditInfoDisabled(false);
  };

  const disableEditInfo = () => {
    setEditInfoDisabled(true);
  };

  const enableViewInfo = () => {
    setEditInfoDisabled(false);
  };

  const onTabSubmit = () => {
    setTabPosition(tabPosition + 1);
  };

  return {
    tabPosition,
    editInfoDisabled,
    enableViewInfo,
    onTabChange,
    disableEditInfo,
    onTabSubmit,
  };
};
