import { Box, Typography } from '@mui/material';
import { blue, green, red } from '@mui/material/colors';
import CheckFilled from '../../assets/images/CheckFilled.svg';
import CloseFilled from '../../assets/images/CloseFilled.svg';
import PendingFilled from '../../assets/images/PendingFilled.svg';
import { ACCOUNT_STATUS } from '../../config/constants';

export const ShowStatus = ({
  status,
  sx,
  height,
  width,
}: {
  status: string;
  sx?: any;
  height?: string;
  width?: string;
}) => (
  <Box
    className='status'
    borderRadius='4px'
    display='flex'
    sx={sx}
    alignItems='center'
    justifyContent='center'
    width={width || '133px'}
    height={height || '30px'}
    bgcolor={
      {
        APPROVED: green[50],
        PENDING: blue[50],
        REJECTED: red[50],
      }[status]
    }
    color={
      {
        APPROVED: green[500],
        PENDING: blue[500],
        REJECTED: red[500],
      }[status]
    }
  >
    <img
      src={
        status === ACCOUNT_STATUS.APPROVED || status === ACCOUNT_STATUS.ACTIVE
          ? CheckFilled
          : status === ACCOUNT_STATUS.PENDING
          ? PendingFilled
          : CloseFilled
      }
      alt={status}
      style={{ height: '20px', marginRight: '5px' }}
    />
    <Typography variant='subtitle2'>{status}</Typography>
  </Box>
);
