export const API_URLS = {
  GET_MY_NETWORK_HISTORY: (id: number) => `/ib/${id}/network-users`,
  GET_IB_GENEALOGY: (id: number) => `/get-ib-network/${id}`,
  FETCH_TIERS: '/client/tiers',
  FETCH_REBATES: '/client/rebate-structures',
  GET_AGREEMENT: (id: number) => `/users/${id}/agreements`,
  CREATE_AGREEMENT: '/users/agreements',
  GET_DEFAULT_AGREEMENT: (rebate: any) => `/client/rebate-structure/${rebate}`,

  // GX_INJECT_API_URL
};
