import {
  CONTENT_TYPE_PDF,
  CONTENT_TYPES_IMAGE,
  FILE_EXTENSION_PDF,
} from './constants';

export const isDocumentPdf = (
  contentType: string,
  extension: string,
): boolean =>
  [contentType, extension].includes(CONTENT_TYPE_PDF) ||
  [extension].includes(FILE_EXTENSION_PDF);

export const isDocumentImage = (contentType: string): boolean =>
  CONTENT_TYPES_IMAGE.includes(
    contentType as (typeof CONTENT_TYPES_IMAGE)[number],
  );

export const convertFileToBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const handleFileContent = (fileContent: string) => {
  const base64Data = fileContent?.split(',')[1];
  const binary = atob(base64Data);
  const array = new Uint8Array(binary?.length);

  for (let i = 0; i < binary?.length; i++) {
    array[i] = binary?.charCodeAt(i);
  }

  const blob = new Blob([array], { type: 'application/pdf' });
  return blob;
};
