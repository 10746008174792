import React from 'react';
import { useCreditActions } from '../../hooks/useCredit';
import Button from '~app/components/Button';
import { QUERY_KEYS, API_URLS } from '../../api/config';
import { CREDIT_STATUS } from '../../constants';
import { CreditModal } from '../CreditModal';
import messages from '../../messages';
import SuccessModal from '~/components/SuccessModal';
import { FormattedMessage } from '~app/Integrations/Localization';

const CreditActionButton = ({ credit_type }: { credit_type: string }) => {
  const {
    onCreditIn,
    onCreditOut,
    onCancel,
    onConfirmCredit,
    creditRecord,
    onSuccessClose,
    successMessage,
    showSuccessModal,
    status,
  } = useCreditActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_CREDIT_HISTORY,
    API_URLS: API_URLS.SET_CREDIT,
    STATUS: CREDIT_STATUS,
    user: true,
    messages: messages,
  });

  return (
    <>
      {credit_type === CREDIT_STATUS.CREDIT_IN ? (
        <Button variant='contained' onClick={() => onCreditIn('CREDIT_IN')}>
          <FormattedMessage {...messages.creditIn} />
        </Button>
      ) : (
        <Button variant='contained' onClick={() => onCreditOut('CREDIT_OUT')}>
          <FormattedMessage {...messages.creditOut} />
        </Button>
      )}
      {creditRecord && (
        <CreditModal
          onCancel={onCancel}
          creditRecord={creditRecord}
          onConfirmCredit={onConfirmCredit}
          status={status}
        />
      )}

      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default CreditActionButton;
