import { defineMessages } from 'v2s-ui-core/app/Integrations/Localization';

export default defineMessages({
  viewKyc: {
    id: 'kyc.viewKyc',
    defaultMessage: 'KYC ',
  },
  document_name: {
    id: 'kyc.document_name',
    defaultMessage: 'Document Name',
  },
  view: {
    id: 'kyc.view',
    defaultMessage: 'View',
  },
  actions: {
    id: 'kyc.actions',
    defaultMessage: 'Actions',
  },
  close: {
    id: 'kyc.close',
    defaultMessage: 'Close',
  },
  kycDetails: {
    id: 'kyc.kycDetails',
    defaultMessage: 'KYC Details',
  },
  documentDetails: {
    id: 'kyc.documentDetails',
    defaultMessage: 'Document Details',
  },
  document_type: {
    id: 'kyc.documentType',
    defaultMessage: 'Document Type',
  },
  fullName: {
    id: 'kyc.fullName',
    defaultMessage: 'Full Name',
  },
  dob: {
    id: 'kyc.dob',
    defaultMessage: 'Date of birth',
  },
  country: {
    id: 'kyc.country',
    defaultMessage: 'Country',
  },
  poi_number: {
    id: 'kyc.poi_number',
    defaultMessage: 'POI Number',
  },
  address_line1: {
    id: 'kyc.address_line1',
    defaultMessage: 'Address line 1',
  },
  address_line2: {
    id: 'kyc.address_line2',
    defaultMessage: 'Address line 2',
  },
  city_town: {
    id: 'kyc.city_town',
    defaultMessage: 'City/Town',
  },
  state_province: {
    id: 'kyc.state_province',
    defaultMessage: 'state/Province',
  },
  zip_postal_code: {
    id: 'kyc.zip_postal_code',
    defaultMessage: 'Zip/Postal Code',
  },
  expire_at: {
    id: 'kyc.expireAt',
    defaultMessage: 'Expire At',
  },
  approveModalTitle: {
    id: 'kyc.approveModalTitle',
    defaultMessage: 'Approve Kyc?',
  },
  rejectModalTitle: {
    id: 'kyc.rejectModalTitle',
    defaultMessage: 'Reject Kyc?',
  },
  approved: {
    id: 'kyc.approved',
    defaultMessage: 'Approved',
  },
  rejected: {
    id: 'kyc.rejected',
    defaultMessage: 'Rejected',
  },
  pending: {
    id: 'kyc.pending',
    defaultMessage: 'Pending',
  },
  addRemark: {
    id: 'kyc.addRemark',
    defaultMessage: 'Add Remark',
  },
  approve: {
    id: 'kyc.approve',
    defaultMessage: 'Approve',
  },
  reject: {
    id: 'kyc.reject',
    defaultMessage: 'Reject',
  },
  cancel: {
    id: 'kyc.cancel',
    defaultMessage: 'Cancel',
  },
  remarkRequired: {
    id: 'kyc.remarkRequired',
    defaultMessage: 'Remark is required',
  },
  clickToView: {
    id: 'kyc.clickToView',
    defaultMessage: 'click here to view it.',
  },
  clickToClose: {
    id: 'kyc.clickToClose',
    defaultMessage: 'click here to close it.',
  },
  approveSuccessMessage: {
    id: 'kyc.approveSuccessMessage',
    defaultMessage: 'Kyc approved successfully!',
  },
  rejectSuccessMessage: {
    id: 'kyc.rejectSuccessMessage',
    defaultMessage: 'Kyc rejected successfully!',
  },
  approveFailedMessage: {
    id: 'kyc.approveFailedMessage',
    defaultMessage: 'Kyc approval failed!',
  },
  rejectFailedMessage: {
    id: 'kyc.rejectFailedMessage',
    defaultMessage: 'Kyc rejection failed!',
  },
  documentName: {
    id: 'kyc.documentName',
    defaultMessage: 'Document Name',
  },
  kycModalTitle: {
    id: 'kyc.kyc_Status',
    defaultMessage: 'Approve/Reject Kyc ',
  },
  addressLine1: {
    id: 'kyc.addressLine1',
    defaultMessage: 'Address Line 1',
  },
  addressLine2: {
    id: 'kyc.addressLine2',
    defaultMessage: 'Address Line 2',
  },
  cityOrTown: {
    id: 'kyc.cityOrTown',
    defaultMessage: 'City or Town',
  },
  stateProvince: {
    id: 'kyc.stateProvince',
    defaultMessage: 'State/Province',
  },
  zipPostalCode: {
    id: 'kyc.zipPostalCode',
    defaultMessage: 'Zip/Postal Code',
  },
  documentExpiry: {
    id: 'kyc.documentExpiry',
    defaultMessage: 'Document Expiry',
  },
});

export const ExpiryMessage = defineMessages({
  approveSuccessMessage: {
    id: 'kyc.approveSuccessMessage',
    defaultMessage: 'Expiry date added successfully!',
  },
  rejectSuccessMessage: {
    id: 'kyc.rejectSuccessMessage',
    defaultMessage: 'Expiry date added successfully!',
  },
  approveFailedMessage: {
    id: 'kyc.approveFailedMessage',
    defaultMessage: 'Expiry date addition failed!',
  },
  rejectFailedMessage: {
    id: 'kyc.rejectFailedMessage',
    defaultMessage: 'Kyc rejection failed!',
  },
});
