export const API_URLS = {
  FETCH_EVENTS: '/events',
  ADD_NEW_EVENT: '/events',
  FETCH_EMAIL_TEMPLATES: '/email_templates',
  ADD_NEW_TEMPLATE: 'email_templates',

  // GX_INJECT_API_URL
};

export const QUERY_KEYS = {
  FETCH_EVENTS: 'fetchEvents',
  FETCH_EMAIL_TEMPLATES: 'fetchEmailTemplates',

  // GX_INJECT_QUERY_KEY
};
