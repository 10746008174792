import { useState } from 'react';

export const useReportsActions = () => {
  const [selectedFormat, setSelectedFormat] = useState<any>(null);

  const handleFormatChange = (value: string | null) => {
    setSelectedFormat(value);
  };

  return {
    selectedFormat,
    setSelectedFormat,
    handleFormatChange,
  };
};
