import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';

export const getTemplateTableColumns = (props: {}): ColumnDef<unknown>[] => [
  {
    accessorKey: 'template_name',
    header: 'Name',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'is_html_template',
    header: 'Is html Template?',
    enableColumnFilter: true,
    cell: ({ row }: any) => (row.original.is_html_template ? 'Yes' : 'No'),
  },
  {
    accessorKey: 'event',
    header: 'Event',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'created_at',
    header: 'Created at',
    enableColumnFilter: true,
  },
  {
    accessorKey: 'Last Updated At',
    header: 'Updated At',
    enableColumnFilter: true,
  },
];
