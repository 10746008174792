import {
  useMutation,
  useQueryClient,
} from 'v2s-ui-core/app/Integrations/react-query';
import useToast from '~/hooks/useToast';
import { MutationDetails } from '../../services/mutations/types';
import useMutationFactory from '../../services/mutations/useMutationFactory';
import { QUERY_KEYS } from '../../services/queries/constants';

const useUpdateCorporateDetails = <TData, TError = Error>(
  args?: ReturnType<MutationDetails<TData, TError>['updateCorporateDetails']>,
) => {
  const { triggerErrorToast } = useToast();
  const queryClient = useQueryClient();

  const onSuccessHandler = (data: any) => {
    // showToastNotification(data?.message, 'success');
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CORPORATE_DETAILS],
    });
  };
  const onError = (error: any) => {
    triggerErrorToast(error);
  };
  const mutationDetails = useMutationFactory<TData, TError>();
  const {
    mutate: updateCorporateDetails,
    status,
    data,
    error,
    isPending,
  } = useMutation({
    ...mutationDetails.updateCorporateDetails(),
    ...args,
    onSuccess: (data: any) => onSuccessHandler(data),
    onError: onError,
  });
  return {
    updateCorporateDetails,
    status,
    data,
    error,
    isUpdateLoading: isPending,
  };
};

export default useUpdateCorporateDetails;
