import { FC, useMemo } from 'react';
import DataGridWithQuery from 'v2s-ui-core/app/components/DataGridWithQuery';
import { DepositsTableColumns } from '~/modules/Deposits/components/DepositsTable/DepositsTableColumns';
import ApproveRejectDeposit from '~/modules/Deposits/components/DepositsTable/ApproveRejectDeposit/index';
import { DEPOSIT_STATUS } from '~/modules/Deposits/constants';
import { useApproveRejectActions } from 'src/hooks/ApproveRejectCustomHook/useApproveRejectActions';
import messages from '~/modules/Deposits/messages';
import { ColumnDef } from 'v2s-ui-core/app/components/DataGridCore';
import { useLocation } from 'react-router-dom';
import SuccessModal from '~/components/SuccessModal';
import { TRANSACTION_TYPE } from '../constants';
import TableFilters from '~/components/TableFilters/index';
import useFilters from '~app/hooks/useFilters';
import { ACTIONS_COLUMNS_TO_PIN, FILTERS } from '~/config/constants';
import Box from '~app/components/Box';
import ViewDeposit from '~/modules/Deposits/components/DepositsTable/ApproveRejectDeposit/ViewDeposit';
import { API_URLS, QUERY_KEYS } from './api/config';
const DepositsTable: FC<{}> = () => {
  const location = useLocation();
  const {
    recordForApproveReject,
    onApproveRecord,
    onRejectRecord,
    onCancel,
    onConfirmApproveReject,
    onSuccessClose,
    successMessage,
    showSuccessModal,
    status,
    viewRecord,
    setViewRecord,
    onViewRecord,
  } = useApproveRejectActions({
    QUERY_KEYS: QUERY_KEYS.FETCH_DEPOSITS,
    API_URLS: () =>
      API_URLS.APPROVE_REJECT_DEPOSIT(recordForApproveReject?.record?.id),
    STATUS: DEPOSIT_STATUS,
    messages: messages,
  });

  const { filters, applyFilters } = useFilters({
    filterTypes: FILTERS,
    namespace: 'fetchUserDeposits',
  });

  const memoizedColumns = useMemo(
    () =>
      DepositsTableColumns({
        onViewRecord,
        onApproveRecord,
        onRejectRecord,
      }),
    [],
  );

  return (
    <>
      <Box className='tableContainer' width={'100%'}>
        <Box display='flex' my={'20px'}>
          <TableFilters filters={filters} applyFilters={applyFilters} />
        </Box>
      </Box>
      <Box className='ScrollableTableContainer'>
        <DataGridWithQuery
          queryProps={{
            resource: API_URLS.FETCH_DEPOSITS(location.state),
            queryKeyProp: [QUERY_KEYS.FETCH_DEPOSITS, location.state],
            select: (data: any) => data,
          }}
          columns={memoizedColumns as ColumnDef<unknown>[]}
          filters={{ transaction_type: TRANSACTION_TYPE.DEPOSIT, ...filters }}
          columnsToPin={ACTIONS_COLUMNS_TO_PIN}
        />
      </Box>
      {recordForApproveReject && (
        <ApproveRejectDeposit
          onCancel={onCancel}
          recordForApproveReject={recordForApproveReject}
          onConfirmApproveReject={onConfirmApproveReject}
          status={status}
        />
      )}

      {viewRecord && (
        <ViewDeposit
          onCancel={() => {
            setViewRecord(null);
          }}
          recordToView={viewRecord}
        />
      )}
      <SuccessModal
        open={showSuccessModal}
        onClose={onSuccessClose}
        message={successMessage}
      />
    </>
  );
};

export default DepositsTable;
