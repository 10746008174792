export const API_URLS = {
  FETCH_WITHDRAWALS: (id: string | number) => `crm/users/${id}/transactions`,
  APPROVE_REJECT_WITHDRAWAL: (id?: string | number) =>
    `crm/user/transactions/${id}`,

  // GX_INJECT_API_URL
};

export const QUERY_KEYS = {
  FETCH_WITHDRAWALS: 'fetchWithdrawals',
  APPROVE_REJECT_WITHDRAWAL: 'approveRejectWithdrawal',
  // GX_INJECT_QUERY_KEY
};
