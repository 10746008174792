import APPLICATION_URLS from '~/shell/Routing/config/appsConfig';
import AppBaseLayout, {
  AppBaseLayoutProps,
} from '~/shell/components/Layout/components/AppLayout';
import ClientsList from '../modules/ClientsList';
import DepositReport from '../modules/Deposit';
import WithdrawalReportTable from '../modules/Withdrawal';
import TransferReportTable from '../modules/Transfer';
import CreditReportTable from '../modules/Credit';
const SUB_ROUTES_FOR_REPORTS_MAP: {
  path: string;
  element: typeof AppBaseLayout;
  AppBaseLayoutProps: AppBaseLayoutProps;
}[] = [
  {
    path: APPLICATION_URLS.CLIENTS_LIST,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Clients List',
      },
      contentProps: {
        content: <ClientsList />,
      },
    },
  },
  {
    path: APPLICATION_URLS.DEPOSIT_REPORT,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Deposit Report',
      },
      contentProps: {
        content: <DepositReport />,
      },
    },
  },
  {
    path: APPLICATION_URLS.WITHDRAWAL_REPORT,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Withdrawal Report',
      },
      contentProps: {
        content: <WithdrawalReportTable />,
      },
    },
  },
  {
    path: APPLICATION_URLS.TRANSFER_REPORT,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Transfer Report',
      },
      contentProps: {
        content: <TransferReportTable />,
      },
    },
  },

  {
    path: APPLICATION_URLS.CREDIT_REPORTS,
    element: AppBaseLayout,
    AppBaseLayoutProps: {
      headerProps: {
        title: 'Credit Report',
      },
      contentProps: {
        content: <CreditReportTable />,
      },
    },
  },
];
export { SUB_ROUTES_FOR_REPORTS_MAP };
